<template>
<form method="POST" id="restaurantsignup-form" name="restaurantsignup" @submit.prevent="submit" data-parsley-validate >
  <div id="maiz-wizard">
    <!-- step-1 -->
    <section class="tab1">
    <fieldset v-if="step == 1">

      <h2>Let's create your account.</h2>
      <p>Signing up for Fireflyy is fast and free - no commitments or long-term contracts.
      </p>
      <div class="row">


        <div class="col-md-6">
          <div class="form-outer-sec">
            <label>First Name</label>
            <input type="name" placeholder="First Name" class="form-control form-poup" v-model="fields.fname" required data-parsley-group='step-1'>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-outer-sec">
            <label>Last Name</label>
            <input type="name" placeholder="Last Name" class="form-control form-poup" v-model="fields.lname" required data-parsley-group='step-1'>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-outer-sec">
            <label>Enter your email</label>
            <input type="email" placeholder="You@example.com" class="form-control form-poup" v-model="fields.email" required data-parsley-group='step-1'>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-outer-sec">
            <label>Create a password</label>
            <input type="password" placeholder="Password" class="form-control form-poup" id="regpassword" v-model="fields.password" data-parsley-minlength="6" required data-parsley-group='step-1'>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-outer-sec">
            <label>Confirm password</label>
            <input type="password" placeholder="Password" class="form-control form-poup" v-model="fields.confirm_password" data-parsley-minlength="6" data-parsley-equalto="#regpassword" required data-parsley-group='step-1'>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-outer-sec">
            <label>Country</label>
            <select class="form-control form-poup" v-model="fields.country" required data-parsley-group='step-1' >
            <option v-for="(cc, index) in countries"   :value="cc.id" :key="index"  > {{ cc.name }}
            </option>
            </select>
          </div>
        </div>
        <div class="col-md-12">
          <div class="outer-line-hr">
            <hr>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-outer-sec">
          <div
            id="g-recaptcha"
            class="g-recaptcha"
            :data-sitekey="captcha_key">
            </div>

            <label>CAPTCHA</label>

            <div id="captcha_err" style="font-size:12px;color:red" v-model="fields.verified_captcha"></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-outer-sec">


            <label class="form-check-label">
              <input type="checkbox" class="form-check-input" v-model="contract_check"  name="contract" required="true" data-parsley-group='step-1'>I agree to
              
              <a :href="contractRoute" target="_blank"><strong class="terms-policy-text" >
                fireflyy contract</strong></a>
                
            </label>
            <label class="form-check-label">
              <input type="checkbox" class="form-check-input" v-model="privacy_check"  name="privacyp" required="true" data-parsley-group='step-1'>I agree to
              Fireflyy
              <a :href="policyRoute" target="_blank"><strong class="terms-policy-text" >Terms, Privacy Policy, and E-sign
                Consent.</strong></a>
                
            </label>
          </div>
        </div>
        <div class="col-md-12">
          <div>
            <hr>
          </div>
        </div>

      </div>
    </fieldset>
    </section>
    <!-- step-2 -->
    <section class="tab1">
    <fieldset v-if="step == 2">
      <h2>Give us some details of your food truck</h2>
      <p>Signing up for Fireflyy is fast and free - no commitments or long-term contracts.
      </p>
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="form-outer-sec">
            <label>Food truck Name</label>
            <input type="text" placeholder="Name of Foodtruck"
              class="form-control" v-model="fields.restaurant_name"  required data-parsley-group='step-2'>
          </div>
        </div>
        <div class="col-md-10 offset-md-1">
          <div class="form-outer-sec">
            <label>Food truck Description</label>
            <input type="text" placeholder="Description"
              class="form-control" v-model="fields.restaurant_description"  required data-parsley-group='step-2'>
          </div>
        </div>
        <div class="col-md-10 offset-md-1">
          <div class="form-outer-sec">
            <label>Food truck Logo</label>
            <input type="file" name="picture" class="form-poup new-type-file" id="picture" accept="image/x-png,image/jpeg" @change="onFileChange" data-parsley-group='step-2'>
          </div>
        </div>
        <div class="col-md-10 offset-md-1">
              <div class="form-outer-sec">
                <label>Street Address</label>
                <input type="text" placeholder="0000 Address Line1"
                  class="form-control" v-model="fields.address"  required data-parsley-group='step-2'>
              </div>
        </div>
        <div class="col-md-10 offset-md-1">
          <div class="row">

            <div class="col-md-5">
              <div class="form-outer-sec">
                <label>State</label>
                <select class="form-poup" data-parsley-required-message="Please select state" v-model="fields.state" required data-parsley-group='step-2' @change='getCities()'>
                  <option value="" selected>-- State --</option>
                  <option v-for="state in states"  :value="state.id"> {{ state.state_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-outer-sec">
                <label>City</label>
                <select class="form-poup" data-parsley-required-message="Please select city" v-model="fields.city" required data-parsley-group='step-2' autofocus >
                  <option value="" selected>-- City --</option>
                  <option v-for="city in cities"  :value="city.id" > {{ city.city }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-outer-sec">
                <label>Zipcode</label>
                <input data-parsley-type="integer" placeholder="00000"
                  class="form-control" v-model="fields.zipcode" pattern="/^\d+$/" maxlength="5"  required data-parsley-group='step-2'>
              </div>
            </div>

            <div class="col-md-12 map-resturent">
                <!-- <google-maps :mapConfig="mapConfig" :apiKey="map_api_key"></google-maps> -->
                <div id="latlngelement"></div>
            </div>
            <div class="col-md-2" style="display:none">
              <div class="form-outer-sec">
                <label>Lat</label>
                <input
                  class="form-control" v-model="fields.latitude"  id="lat" disabled  data-parsley-group='step-2' data-parsley-errors-container="#latlngelement" data-parsley-error-message="Please select location from map">
              </div>
            </div>
            <div class="col-md-2" style="display:none">
              <div class="form-outer-sec">
                <label>Lng</label>
                <input
                  class="form-control"  v-model="fields.longitude"  id="lng"  disabled data-parsley-group='step-2' >
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div>
            <hr>
          </div>
        </div>


      </div>
    </fieldset>
    </section>
    <!-- step-3 -->
    <section class="tab1">
  <fieldset v-if="step == 3">
      <h2>Everything you need to get started.</h2>
      <p><b>Take any payment anywhere at no additional cost.*</b></p>
      <div class="row">
        <div class="col-md-6">
            <div class="form-outer-sec">
               <img :src="'/storage/restaurant_logo/terminal.png'" />
            </div>
          
        </div>
      
        <div class="col-md-6">
          <div class="form-outer-sec">
            <p style="text-align:left;"> Although the magic of our business includes seamless QR ordering, we do offer new 
            merchants <b>one (1) 
            free physical terminal*</b> for customers that want to pay with their physical card. </p>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="terminalYes" v-model="fields.terminal" value="yes">
              <label class="" for="terminalYes">Please send me a card reader</label>
              <p style="margin: 0;text-align: left;font-size: 14px;padding: 0;">
                If you need more than one terminal, we sell them for <b>$75 each.</b></p>
          </div>
        
        </div>
      </div>
    </div>
  </fieldset>
    </section>
    <!-- step-4 -->
    <section class="tab1">
    <fieldset v-if="step == 4">
      <h2>You're ready to get started!</h2>
      <p class="last-para-minutes">Get your foodtruck up in minutes.
      Check your email and click on the link to verify.
      </p>
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="login-into-your-account">
            <button class="btn" onclick="window.location = '/login';">Log Into Your Account</button>
          </div>
        </div>

        <div class="col-md-12">
          <div style="margin-bottom: 60px;">
            <hr>
          </div>
        </div>
      </div>
    </fieldset>
    </section>

     
    <!-- main-button start -->
    <div v-if="stepexist" class="col-md-12">
      <div class="row">


       <hr />
        <div class="col-md-6" v-if="step > 1 " >
            <div class="form-outer-sec bottom-section-deatils">
              <button v-if="enableprev"  @click.prevent="prev()" class="btn prevbtn btn-info">Previous</button>
            </div>
        </div>
        <div class="col-md-7" v-if="step == 1">
          <div class="form-outer-sec bottom-section-deatils">
                    <p>Already have a Fireflyy account? <a href="/login">Sign in</a></p>
                  </div>
        </div>

        <div class="col-md-5">
            <div class="form-outer-sec bottom-section-deatils">
              <button v-if="nextstep" @click.prevent="next()" class="btn" >CONTINUE</button>
              <button v-if="laststep" @click.prevent="next()" class="btn" id="saveBtn">CONTINUE</button>
              <div v-if="isLoading">Please Wait...</div>
            </div>
        </div>
      </div>
    </div>
    <!-- main-button end -->








    <!-- <div class="row">
      <div class="col-md-6">
        <div class="form-outer-sec bottom-section-deatils">
          <p>Alreday have a Firefly account? <a href="#">Sign in</a></p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-outer-sec bottom-section-deatils">
          <button class="btn">CONTINUE</button>
        </div>
      </div>
    </div> -->
    
    <!-- last-step-5 -->
    
  </div>

</form>
</template>

<script>

    import GoogleMaps from './GoogleMapLoader';
    //let countries = require('../../countries.js');
    export default {
    props: ['captcha_key','map_api_key','us_country'],
    data() {
        return {
            policyRoute: '/owner/policy',
            contractRoute: '/owner/contract',
            privacy_check:'',
            contract_check:'',
            stepexist:true,
            nextstep:true,
            enableprev:false,
            laststep:false,
            //countryList: countries,
            step:1,
            image_src1: '/img/frontend/android-store.png',
            image_src2: '/img/frontend/apple-store.png',
            fields: {
            terminal:'',
            name:'',
            fname:'',
            lname:'',
            email:'',
            password:'',
            confirm_password:'',
            country:'',
            restaurant_name:'',
            restaurant_description:'',
            address:'',
            address2:'',
            state:'',
            zipcode:'',
            table_count:'',
            waitresses_count:'',
            device_count:'',
            type_of_menus:[],
            verified_captcha:'',
            restaurant_logo:'',
            picture:'',
            latitude:'',
            longitude:'',
            restaurant_type:[],
            service_types:[],
            },
            countries: {},
            errors: {},
            success: false,
            validaterestaurant: true,
            isLoading: false,
            loaded: true,
            problem_in_save_error:'',
            rtypes:[],
            rservices:[],
            country_id:'',
            state_id:'',
            city_id:'',
            states: {},
            cities: {},
            selcitylat:31.442778,
            selcitylng:-100.450279,
            mapConfig:{},
            selectedItem:'',
        }

    },
    watch: {

    },
    components:{
      'google-maps': GoogleMaps
    },


    created: function () {
      // axios.get('/country_list').then(response => {
      // this.countries = response.data;
      // }).catch(error => {
      //
      // });
      let formData = new FormData();

      formData.append("nicename", this.us_country);
      axios.post('/get-country-by-name',formData).then(response => {
        this.countries = response.data.response;
        this.country_id = response.data.response[0].id;
        this.fields.country = response.data.response[0].id;
        this.getStates();
      }).catch(error => {

      });


      this.restaurantTypes();
      this.restaurantServices();
      this.setmapConfig();
    },
    methods:{
        
      restaurantTypes(){
        axios.get('/restaurant_types_list').then(response => {
        this.rtypes = response.data.data.restaurant_types;
        }).catch(error => {

        });
      },
      restaurantServices(){
        axios.get('/restaurant_services_list').then(response => {
        this.rservices = response.data.data.service_types;
        }).catch(error => {

        });
      },
      prev() {
          this.step--;
          this.nextstep = true;
          this.laststep = false;
          if(this.step == 1){
            this.enableprev =  false;
          }
      },
      next() {
      let vm = this;

      if(vm.step === 1 && vm.fields.verified_captcha != 1){
        if(grecaptcha.getResponse() == "") {
         $('#captcha_err').text("You can't proceed. Please verify CAPTCHA.");
        return false;
         // vm.fields.verified_captcha = 1;
          //$('#captcha_err').text('');
        }else{
          vm.fields.verified_captcha = 1;
          $('#captcha_err').text('');
        }
      }

      $('#restaurantsignup-form').parsley().whenValidate({
        group: 'step-' + this.step
      }).done(function() {

          if(vm.step > 0){
            vm.enableprev = true;
          }
          if(vm.step === 2) {
            vm.step++;
          }else if(vm.step == 1){
            axios.post('/owner/validate-restaurant-email', vm.fields).then(response => {
              console.log(response);
              if(response.data == 'notexists'){
                vm.step++;
              }else{
                alert('Email already exists');
                return false;
              }

            }).catch(error => {

            });
          }else if(vm.step === 2){
            if(vm.fields.restaurant_name !== '' && vm.fields.city !== '' && vm.fields.state !== '' && vm.fields.zipcode !== ''){
            axios.post('/owner/validate-restaurant', vm.fields).then(response => {
              console.log(response);
              if(response.data == 'notexists'){
                vm.step++;
              }else{
                alert('Restaurant already exists');
                return false;
              }

            }).catch(error => {

            });
            }
          }
          else if (vm.step === 3) {
            if (vm.fields.terminal === undefined) {
              alert('Please select whether you want a terminal or not.');
              return false;
            }
            $('#saveBtn').prop( "disabled", true );
            vm.submit();
          }else{
           vm.step++; // Move to the next step
          }
      });

      },
      onFileChange(event){
        this.fields.picture = event.target.files[0];
        this.fields.restaurant_logo = this.fields.picture.name;
      },
      submit() {
      let vm = this;
      vm.laststep = true;
      vm.enableprev = false;
      vm.nextstep = false;

      vm.isLoading = true;

      if (vm.loaded) {
        vm.loaded = false;
        vm.success = false;
        vm.errors = {};
        let formData = new FormData();

          formData.append("picture", this.fields.picture);
          formData.append("restaurant_logo", this.fields.restaurant_logo);

        formData.append("first_name", this.fields.fname);
        formData.append("last_name", this.fields.lname);
        formData.append("name", this.fields.fname +" "+this.fields.lname);
        formData.append("email", this.fields.email);
        formData.append("city", this.fields.city);
        formData.append("password", this.fields.password);
        formData.append("confirm_password", this.fields.confirm_password);
        formData.append("country", this.fields.country);
        formData.append("restaurant_name", this.fields.restaurant_name);
        formData.append("restaurant_description", this.fields.restaurant_description);

        formData.append("address", this.fields.address);
        formData.append("address2", this.fields.address2);
        formData.append("state", this.fields.state);
        formData.append("zipcode", this.fields.zipcode);


        formData.append("latitude", this.fields.latitude);
        formData.append("longitude", this.fields.longitude);
        formData.append("privacy_check", this.privacy_check);
        formData.append("terminal", this.fields.terminal);
        formData.append("contract_check", this.contract_check);
        console.log('/owner/register-foodtruck');
        console.log(formData);
        axios.post('/owner/register-foodtruck', formData).then(response => {
          vm.fields = {}; //Clear input fields.
          vm.loaded = true;
          vm.success = true;
          vm.isLoading = false; 
          vm.step = 4;
          vm.stepexist = false;

        }).catch(error => {
          vm.loaded = true;
          console.log(error.response.data.errors);
          console.log(error.response.status);
          if (error.response.status === 400) {
            vm.problem_in_save_error = error.response.data.response.message || {};
            $('#saveBtn').prop( "disabled", false );
            vm.isLoading = false;
            vm.enableprev = true;
          }else{
            vm.errors = error.response.data.errors || {};
            $('#saveBtn').prop( "disabled", false );
            vm.isLoading = false;
            vm.enableprev = true;
          }
        });
      }
      },
      getStates(){
        let formData = new FormData();

        formData.append("country_id", this.country_id);
        axios.post('/get-states',formData).then(response => {
          this.states = response.data.response.states;
        }).catch(error => {

        });
      },
      getCities(){
        this.cities = {};
        let formData = new FormData();

        formData.append("state_id", this.fields.state);
        axios.post('/get-cities',formData).then(response => {
          this.cities = response.data.response.cities;
        }).catch(error => {

        });
      },


      setmapConfig () {
        this.mapConfig =  {
          zoom: 4,
          center: {  lat:  this.selcitylat, lng: this.selcitylng }
        };
      },
      disbaleMe(resType){
          if(this.selectedItem == ''){
              this.selectedItem = resType;
           }
      },

    },

    watch:{
    }




    }

</script>
