<template>
  <div class="row">
    <div class="col-lg-12">
      <div class=" w-100">
        <div class="clearfix order-card-title-head schedule-list-box shedule_date_box" id="card-emp-sc-id">
          <span class="loader sc_loader" style="display:none">
          <span class="content"><i class="fa fa-spinner fa-spin"></i> Please wait...</span>
          </span>

          <div class="justify-content-between schedule-padd">
            <!--<div class="bd-highlight">
              <h5 class="card-title mb-0">Schedule</h5>
            </div>-->

            <div class="bd-highlight">
              <div class="new-dated-middle date_calender_ass">
                <span >
                  <b-form-datepicker placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="search_by_date" class="form-poup-search-type float-left" @input="filterSchedules()"></b-form-datepicker>
                  <!-- <input type="date" class="form-poup-search-type float-left" v-model="search_by_date" @change="filterSchedules()"/> -->
                  <!-- <button type="button" class="btn search-shift-btn float-right" id="search_sc" @click="filterSchedules();">Search</button> -->
                </span>


                <!-- <a ><i class='fas'>&#xf053;</i></a>
                <span v-for="(edate,index) in employeesScheduleDates" v-if="current_date_formated == edate">{{current_date_formated}}</span>
                <a ><i class='fas'>&#xf054;</i></a> -->
              </div>
            </div>
            <div class="bd-highlight">
              <a class="mb-0 add_schedule" data-toggle="modal" data-target="#addScheduleShift">
                <i class="align-middle fas fa-fw fa-plus"></i> Add to Schedule
              </a>
            </div>
          </div>



          <!-- list- -->
          <div v-if="employees_schedule_list.length > 0">
            <div class="row main-ouetr-shedule">
              <div class="col-4">
                <div class="text-right">
                  <span>6am</span>
                </div>
              </div>
              <div class="col-4">
                <div class="text-center">
                  <span>12pm</span>
                </div>
              </div>
              <div class="col-4">
                <div class="text-left">
                  <span>11pm</span>
                </div>
              </div>
            </div>
            <div  v-for="(item,index) in employees_schedule_list"  class="row schedule-tag">

              <div class="col-xl-3 col-md-4">
                <h5 class="left-sec-user">
                  <template v-for="empval in item.schedule_detail.employee.restraurant_employee_attributes">
                    <template v-if="empval.name == 'profile_photo'">
                      <img v-if="empval.pivot.value !='' " :src="'/storage/employee_pictures/'+empval.pivot.value" :alt="item.schedule_detail.employee.user.name">
                      <img v-if="empval.pivot.value =='' " src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" :alt="item.schedule_detail.employee.user.name">
                    </template>
                  </template>
                  <img v-if="item.schedule_detail.employee.restraurant_employee_attributes == '' " src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" :alt="item.schedule_detail.employee.user.name">

                  {{item.schedule_detail.employee.user.name}}

                  <div class="time-schedule">{{item.schedule_detail.shift_time_range}}</div>
                </h5>
              </div>
              <div class="col-xl-7 col-md-6">
                <div class="progress progress-menu-time">
                  <div class="" v-bind:style="{ width: item.schedule_detail.shift_time_range_st_per+ '%' }"></div>
                  <div class="progress-bar" v-bind:style="{ width: item.schedule_detail.shift_time_range_mid_per+ '%' }"></div>
                  <div class="" v-bind:style="{ width: item.schedule_detail.shift_time_range_end_per+ '%' }"></div>
                </div>
              </div>
              <div class="col-xl-2 col-md-2">
                <div class="edit-sec-user"><a @click.prevent="editSchedulePop(item)"><i class="fas fa-pencil-alt"></i></a></div>
              </div>
            </div>
          </div>

          <div v-else class="row">
            <div class="col-xl-3 col-md-4">
              No Schedules found for this date yet...
            </div>
          </div>
          <!-- list- -->
          <!-- popup -->
          <div class="modal fade" id="addScheduleShift" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header add-schedule-model-header">
                  <h5 class="modal-title add-employee-popup">Add to Schedule</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeaddpopup()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form method="POST" id="addempschedule-form" name="addempschedulefrm" @submit.prevent="submit_schedule" data-parsley-validate enctype="multipart/form-data">
                  <div class="m-4">
                      <h5 id="card-menu-title-id-1" class="shedule_title">Select Employee</h5>
                    <div class="form-poup-select empschedule">
                      <v-select v-model="schedule_data.employee_id"  :options="employees" :searchable="true" :clearable="false"  placeholder="Select Employee" class="select-css " required
                      >

                      <template #search="{attributes, events}">
                        <input
                          class="vs__search"
                          data-parsley-errors-container="#empsel-errors"
                          :required="!schedule_data.employee_id"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>

                        <template slot="option" slot-scope="option" >
                          <img :src="option.imagepath" class="emp-circle-pic"/> {{option.text}}
                        </template>

                        <template slot="selected-option" slot-scope="option" :value="option.text">
                          <img :src=" option.imagepath" class="emp-circle-pic"/> {{option.text}}
                        </template>
                      </v-select>
                      <div id="empsel-errors"></div>
                    </div>
                    <div class="clearfix table-card-title-head-menu">
                      <h5 id="card-menu-title-id-1" class="shedule_title">Select Date</h5>
                      <!-- <input type="date" class="form-poup-shift-type float-right" :min="current_date"  v-model="schedule_data.shift_date" data-parsley-errors-container="#datepicksc-errors" required> -->
                      <b-form-datepicker :min="current_date" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="schedule_data.shift_date" class="form-poup-shift-type float-right" required></b-form-datepicker>
                    </div>
                    <div id="datepicksc-errors"></div>
                    <div class="clearfix table-card-title-head-menu">
                      <h5 id="card-menu-title-id-1" class="shedule_title float-left">Shifts</h5>
                      <a class="text-white add-shift-menu mb-0 float-right" @click="addShift">+ Add Shift</a>
                    </div>

                    <!-- shift-add-edit-delet -->
                    <template v-for="(empshift,inx) in empshifts">
                      <div class="shift-type"  >
                        <div class="row">

                            <div class="col-sm-5 col-12">
                              <label>Shift Type</label>
                              <select class="form-poup-shift-type" v-model="empshift.shift_type" required>
                                <option v-for="(item,index) in shift_types" :value="item.id" :key="index">{{item.title}}</option>
                               
                              </select>
                            </div>
                            <div class="col-sm-3 col-5">
                              <label>Shift Time</label>
                              <!-- <input type="texttime" v-model="empshift.start_time" placeholder="starts at" required="required"  class="form-poup timepickclass" onKeyDown="return false"> -->
                              <vue-timepicker hide-clear-button v-model="empshift.start_time" format="hh:mm A" :minute-interval="30" input-class="vtime-awesome-picker"></vue-timepicker>

                            </div>
                            <div class="col-sm-3 col-5">
                              <label>End Time</label>
                              <!-- <input type="text" v-model="empshift.end_time" placeholder="closed at" required="required" class="form-poup timepickclass1" onKeyDown="return false"> -->
                              <vue-timepicker hide-clear-button v-model="empshift.end_time" format="hh:mm A" :minute-interval="30" input-class="vtime-awesome-picker"></vue-timepicker>
                            </div>
                            <div class="col-sm-1 col-2">
                              <a @click="deleteShift(inx)" class="delet-shift-sec"><i class="far fa-trash-alt"></i></a>
                            </div>


                        </div>
                      </div>
                    </template>
                    <div class="clearfix main-btn-sec-shift">
                      <button @click.prevent="add_schedule()" id="add_sc" class="btn save-shift-btn float-right">Save</button>
                    </div>

                  </div>
                  <div class="row text-center">
                    <div v-if="errors" class="text-danger col-12">
                      <div v-for="(v, k) in errors" :key="k">
                        <p v-for="error in v" :key="error" class="text-sm">
                          {{ error }}
                        </p>
                      </div>
                    </div>
                    <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                      <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                        {{ problem_in_save_error }}
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!--edit popup -->
          <div class="modal fade" id="editScheduleModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header add-schedule-model-header">
                  <h5 class="modal-title add-employee-popup">Edit Schedule for {{edit_schedule_detail.schedule_data.formated_shift_date}}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeaeditpopup()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form method="POST" id="editempschedule-form" name="editempschedulefrm" @submit.prevent="update_schedule" data-parsley-validate enctype="multipart/form-data">
                  <div class="m-4">

                    <div class="form-poup-select">
                      <div class="card">
                        <div class="card-body ">

                          <div class="col-12">
                            <h5 class="left-sec-user schedule-edit-tag">
                              <template v-for="empval in edit_schedule_detail.schedule_data.employee.restraurant_employee_attributes">
                                <template v-if="empval.name == 'profile_photo'">
                                  <img v-if="empval.pivot.value !='' " :src="'/storage/employee_pictures/'+empval.pivot.value" alt="waiters-img">
                                  <img v-if="empval.pivot.value =='' " src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" alt="">
                                </template>
                              </template>
                              <img v-if="edit_schedule_detail.schedule_data.employee.restraurant_employee_attributes == '' " src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" alt="">

                              {{edit_schedule_detail.schedule_data.employeeuser}}
                            </h5>
                          </div>


                        </div>
                      </div>
                    </div>

                    <div id="datepicksc-errors"></div>
                    <div class="clearfix table-card-title-head-menu">
                      <h5 id="card-menu-title-id-1" class="card-title mb-0 float-left table-card-menu">Shifts</h5>
                      <a class="text-white add-shift-menu mb-0 float-right" @click="addShiftOnEdit">+ Add Shift</a>
                    </div>

                    <!-- shift-add-edit-delet -->
                    <template v-for="(empshift,inx) in edit_schedule_detail.empshifts">
                      <input type="hidden" v-model="empshift.schedule_id" />
                      <div class="shift-type"  >
                        <div class="row">

                            <div class="col-sm-5 col-12">
                              <label>Shift Type</label>
                              <select class="form-poup-shift-type" v-model="empshift.shift_type" required>
                                <option v-for="(item,index) in shift_types" :value="item.id" :key="index">{{item.title}}</option>
                              </select>
                            </div>
                            <div class="col-sm-3 col-5">
                              <label>Shift Time</label>
                              <vue-timepicker hide-clear-button v-model="empshift.start_time" format="hh:mm A" :minute-interval="30" input-class="vtime-awesome-picker"></vue-timepicker>
                              <p>{{empshift.start_time}}</p>
                            </div>
                            <div class="col-sm-3 col-5">
                              <label>End Time</label>
                              <vue-timepicker hide-clear-button v-model="empshift.end_time" format="hh:mm A" :minute-interval="30" input-class="vtime-awesome-picker"></vue-timepicker>
                              <p>{{empshift.end_time }}</p>
                              
                            </div>
                            <div class="col-sm-1 col-2">
                              <a @click="deleteEditShift(inx)" class="delet-shift-sec"><i class="far fa-trash-alt"></i></a>
                            </div>


                        </div>
                      </div>
                    </template>
                    <div class="clearfix main-btn-sec-shift">
                      <a @click.prevent="removeEmpSchedule(edit_schedule_detail.schedule_data.shift_date,edit_schedule_detail.schedule_data.employee_id)" id="remove_sc" class="remove-shift-btn mb-0 float-left">Remove from Schedule</a>
                      <button @click.prevent="validate_update_schedule()" id="edit_sc" class="btn save-shift-btn float-right">Save</button>
                    </div>

                  </div>
                  <div class="row text-center">
                    <div v-if="errors" class="text-danger col-12">
                      <div v-for="(v, k) in errors" :key="k">
                        <p v-for="error in v" :key="error" class="text-sm">
                          {{ error }}
                        </p>
                      </div>
                    </div>
                    <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                      <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                        {{ problem_in_save_error }}
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</template>
<script src="{{ asset('js/frontend/jtimepicker.min.js') }}"></script>
<script>

    export default {
    props: ['restaurant_id','current_date','current_date_formated'],
    data() {
        return {
          slideIndex:1,
          employeesScheduleDates:{},
          options:{
              height:'800px',
              color: '#e9986f',
              size: '10px',
              position: 'right',
              distance:'5px'
          },
          empshifts: [
            {
              shift_type:'',
              start_time:'',
              end_time:'',
            }
          ],
          schedule_data:{
            employee_id:'',
            shift_date:''
          },
          employees:[],
          shiftData:[],
          shift_types:[],
          // rules:{
          //   select: [(v) => !!v || "Item is required"],
          //   select2: [(v) =>  v.length>0 || "Item is required in select 2"],
          // }
          errors: {},
          iserrors: false,
          success: false,
          isLoading: false,
          loading:false,
          problem_in_save_error:'',
          search_by_date:this.current_date,
          employees_schedule_list:{},
          edit_schedule_detail:{
            empshifts: [
              {
                shift_type:'',
                start_time:'',
                end_time:'',
                schedule_id:''
              }
            ],
            schedule_data:{
              employee_id:'',
              shift_date:'',
              employee:'',
              employeeuser:'',
              formated_shift_date:''
            },
            defaultValue:''
          }
        }

    },

    created: function () {
      this.getEmployees();
      this.getShifts();
      this.getEmployeesShifts();
    },
    mounted(){
      // $('.timepickclass').timepicker();
        this.edit_schedule_detail.defaultValue = this.employees.filter((option, idx) =>
           option.text = "hiih"
        );
    },
    methods:{
     
      getShifts(){
        axios.get('/owner/restaurant_employee/shift-types').then(response => {
          this.shift_types = response.data.data.list;
        }).catch(error => {
        });
      },
      getEmployees(table_id=null){
        let postData = new FormData();
        postData.append("type", 'Server');
        postData.append("table_id", '');
        axios.post('/owner/restaurant_employee/get-unused-employees',postData).then(response => {
        this.employees = response.data.data.employees;
        }).catch(error => {
        });
      },
      addShift: function(){
		      this.empshifts.push({
        	    shift_type: '',
        	    start_time: '',
          	  end_time: ''
        });
	     },
      deleteShift(enx) {
        this.empshifts.splice(enx, 1);
      },
      add_schedule(){
        let vm = this;
        $('#addempschedule-form').parsley().whenValidate({

        }).done(function() {
        vm.submit_schedule();
        });
      },
      submit_schedule(){
        let vm = this;
        $('#add_sc').prop('disabled',true);
        vm.success = false;
        vm.errors = {};
        let postData = new FormData();
        postData.append("empshifts", JSON.stringify(vm.empshifts));
        postData.append("schedule_data", JSON.stringify(vm.schedule_data));
        axios.post('/owner/restaurant_employee/store-employee-schedule',postData).then(response => {
          console.log(response);
          $('#addScheduleShift').modal('hide');
          vm.empshifts = [];
          vm.empshifts.push({
        	    shift_type: '',
        	    start_time: '',
          	  end_time: ''
          });
          vm.schedule_data = {};
          $('#add_sc').prop('disabled',false);
          $('#card-emp-sc-id').addClass('loading-spin');
          $('.sc_loader').show();
          setTimeout( () => {
            vm.getEmployeesShifts();
            $('#card-emp-sc-id').removeClass('loading-spin');
            $('.sc_loader').hide();
          }, 5000);
        }).catch(error => {
          $('#add_sc').prop('disabled',false);
          if (error.response.status === 400) {
            vm.problem_in_save_error = error.response.data.response.message || {};
          }
          else if (error.response.status === 422) {
            vm.errors = error.response.data.errors;
          }else{
            vm.errors = error.response.data.response.message || {};
          }

        });
      },
      getEmployeesShifts(){
        console.log(this.search_by_date);
        //return false;

        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        postData.append("search_by_date",this.search_by_date);
        axios.post('/owner/restaurant_employee/employees-schedule',postData).then(response => {
         this.employees_schedule_list = response.data.response.emp_schedules;
        }).catch(error => {
        });
      },
      editSchedulePop(scitem){
        $('#editScheduleModal').modal('show');
         this.edit_schedule_detail.schedule_data.employee = scitem.schedule_detail.employee;
         this.edit_schedule_detail.schedule_data.employeeuser = scitem.schedule_detail.employee.user.name;
         this.edit_schedule_detail.schedule_data.formated_shift_date = scitem.schedule_detail.formated_shift_date;
         this.edit_schedule_detail.schedule_data.employee_id = scitem.schedule_detail.restaurant_employee_id;
         this.edit_schedule_detail.schedule_data.shift_date = scitem.schedule_detail.shift_date;
         this.edit_schedule_detail.empshifts = scitem.shifts;
         this.edit_schedule_detail.empshifts.start_time = scitem.start_time;
         this.edit_schedule_detail.empshifts.end_time = scitem.end_time;
         
      },
      validate_update_schedule(){

        let vm = this;
        $('#editempschedule-form').parsley().whenValidate({

        }).done(function() {
        vm.update_schedule();
        });
      },
      addShiftOnEdit(){
        this.edit_schedule_detail.empshifts.push({
            shift_type: '',
            start_time: '',
            end_time: '',
            schedule_id:''
        });
      },
      deleteEditShift(enx) {
        this.edit_schedule_detail.empshifts.splice(enx, 1);
      },
      update_schedule(){
        let vm = this;
        console.log(this.edit_schedule_detail);
        $('#edit_sc').prop('disabled',true);
        vm.success = false;
        vm.errors = {};
        let postData = new FormData();
        postData.append("empshifts", JSON.stringify(vm.edit_schedule_detail.empshifts));
        postData.append("schedule_data", JSON.stringify(vm.edit_schedule_detail.schedule_data));
        axios.post('/owner/restaurant_employee/update-employee-schedule',postData).then(response => {
          console.log(response);
          $('#editScheduleModal').modal('hide');
          vm.empshifts = [];
          vm.empshifts.push({
        	    shift_type: '',
        	    start_time: '',
          	  end_time: ''
          });
          vm.schedule_data = {};

          $('#card-emp-sc-id').addClass('loading-spin');
          $('.sc_loader').show();
          setTimeout( () => {
            vm.getEmployeesShifts();
            $('#card-emp-sc-id').removeClass('loading-spin');
            $('.sc_loader').hide();
          }, 5000);


          $('#edit_sc').prop('disabled',false);
        }).catch(error => {
        });
      },
      removeEmpSchedule(s_date,s_emp_id){
        $('#remove_sc').prop('disabled',true);
        let vm = this;
        vm.success = false;
        vm.errors = {};
        let postData = new FormData();
        postData.append("shift_date", s_date);
        postData.append("employee_id", s_emp_id);
        axios.post('/owner/restaurant_employee/remove-employee-schedule',postData).then(response => {
          console.log(response);
          $('#editScheduleModal').modal('hide');

          $('#remove_sc').prop('disabled',false);
          $('#card-emp-sc-id').addClass('loading-spin');
          $('.sc_loader').show();
          setTimeout( () => {
            vm.getEmployeesShifts();
            $('#card-emp-sc-id').removeClass('loading-spin');
            $('.sc_loader').hide();
          }, 5000);
        }).catch(error => {
          $('#remove_sc').prop('disabled',false);
        });
      },
      closeaddpopup(){
        let vm = this;
        vm.empshifts = [];
        vm.empshifts.push({
            shift_type: '',
            start_time: '',
            end_time: ''
        });
        vm.schedule_data = {};
        vm.problem_in_save_error = '';
        vm.errors = {};
      },
      closeaeditpopup(){
        let vm = this;

        vm.problem_in_save_error = '';
        vm.errors = {};
      },

      filterSchedules(){
        $('#search_sc').prop('disabled',true);
        $('#card-emp-sc-id').addClass('loading-spin');
        $('.sc_loader').show();
        setTimeout( () => {
          this.getEmployeesShifts();
          $('#card-emp-sc-id').removeClass('loading-spin');
          $('.sc_loader').hide();
          $('#search_sc').prop('disabled',false);
        }, 5000);
      }

    }
  }
</script>
