<template>
    <div>
        <div class="col-md-6" style=" padding: 0;"> 
          <div class="calender_main_wrap">
              <div class="calender_sec">
                    <div class="events-container"></div>
                   <div v-if="items.length > 0">
                        <div v-for="(item, index) in items" :key="item.event.id" @click="handleSecondClick(item.event.id)" class="date_header b1">
                    <div :class="[{'dt_tlt': item.event.id === activeEventId}, {'dt_tlt2': item.event.id !== activeEventId}]">
                        <p>{{ currentDate(item.event.start_date) }}</p>
                    </div>
                    <div :class="[{'evt_dtl': item.event.id === activeEventId}, {'evt_dtl2': item.event.id !== activeEventId}]">
                            <p class="loc_name">
                                <i class="align-middle fas fa-map-marker-alt "></i>
                                {{ item.event.name }}
                            </p>
                            <p>
                            <i class="align-middle fas fa-clock"></i>
                            {{ convertedStartTime(item.event.start_time) }} -
                            {{ convertedEndTime(item.event.end_time) }}
                            </p>
                            <span>
                                
                                    <div v-if="item.event.estimated_attendance && item.event.booking_fee != null">
                                        <p style="font-weight: 600;">
                                        <i class="align-middle fas fa-users"></i>
                                        {{ item.event.estimated_attendance }}
                                        </p>
                                    </div>
                                   
                            </span>
                        </div>
                    </div>
                   </div>
                    <div v-else class="calender_main_wrap">
                        <div class="event-name">No events have been found</div>
                    </div>
                </div>
            </div>
        </div>
                <div class="col-md-6" style=" padding: 0;float: right;">
                    <event-favorite-detail-component :firstevent="firstevent" v-if="loaded"></event-favorite-detail-component>
                </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            firstevent: [],
            activeEventId: null,
            items: [],
            temp: [],
            loaded: false,
            isDataLoaded: false,
            highlightedDates: []
        };
    },
    mounted() {
       const vm = this;
       vm.geteventdata().then(() => {
        vm.highlightedDates = vm.items.map(item => item.event.start_date);
        $('.calendarfav').datepicker({
            inline:true,
            firstDay: 7,
            showOtherMonths:true,
            dayNamesMin:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            beforeShowDay: vm.highlightEvent,
            onSelect: function(dateText, inst) {
                var clickedDate = $(this).datepicker('getDate');
                var formattedDate = $.datepicker.formatDate("yy-mm-dd", clickedDate);
                let formData = new FormData();
                formData.append("date", formattedDate);
                axios.post('/owner/favorite-get-date-event',formData).then(response => {
                    vm.items = response.data.dateevent.filter(date => date.event !== null).map(date => date);
                    let shouldstore = vm.items.some(item => item.event.start_date === formattedDate);
                    vm.highlightedDates.push(shouldstore);
                    vm.handleSecondClick(vm.items[0].event.id);
                    if(vm.items.length === 0){
                        vm.loaded = false;
                      }else {
                        vm.loaded = true; 
                      }
                    console.log('/owner/favorite-get-date-event');
                    console.log(vm.items);
                }).catch(error => {
                console.log(error);
                });
             }
        });
      });
    },
    methods: {
       geteventdata(){
        return axios.get('/owner/favorite-get-event-data').then(response => {
            this.temp = response.data.data;
            console.log('/owner/favorite-get-event-data');
            console.log(this.temp);
            // this.temp = response.data.data.filter(date => date.event !== null).map(date => date.event);
            Vue.set(this, 'items', this.temp);
            this.isDataLoaded = true; 
             this.handleSecondClick(this.temp[0].event.id);
        }).catch(error => {
          console.log(error);
        });
      },
    // highlightEvent(date) {
    //      if (!this.isDataLoaded) {
    //         return [true, '', ''];
    //         }
    //         let formattedDate = $.datepicker.formatDate('yy-mm-dd', date);
    //         if(this.highlightedDates.includes(formattedDate)){
    //             return [true, 'event-highlight', '•'];
    //         }else {
    //          return [true, '', ''];
    //         }
    //     },
    highlightEvent(date) {
        if (!this.isDataLoaded) {
            return [true, '', ''];
        }
        // Format the current date to match the stored date format (YYYY-MM-DD)
        let formattedDate = formatDate(date);
        // Function to format the date to YYYY-MM-DD
        function formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        }

        // Check if the formattedDate is included in any event's date range
        let isHighlighted = this.items.some(item => {
            let startDate = item.event.start_date;
            let endDate = item.event.end_date;
            return startDate <= formattedDate && formattedDate <= endDate;
        });

        // If the date is in any event's date range, highlight it
        if (isHighlighted) {
            return [true, 'event-highlight', '•'];
        } else {
            return [true, '', ''];
        }
    },
        currentDate(item) {
            const date = new Date(item);
            return date.toLocaleDateString("en-US", {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric"
            });
        },
        handleSecondClick(itemid) {
            let formData = new FormData();
            formData.append("id", itemid);
            this.activeEventId = itemid;
            axios.post("/owner/get-single-favorite-event", formData).then(response => {
                    this.firstevent = response.data.firstevent;
                    this.loaded = true;
                    // this.$emit('handle-second-click-event', this.firstevent);
                }).catch(error => {
                    console.log(error);
               });
        },
        convertedStartTime(time) {
            const [hours, minutes] = time.split(":");
            const d = new Date();
            d.setHours(hours);
            d.setMinutes(minutes);
            return d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        },
        convertedEndTime(time) {
            const [hours, minutes] = time.split(":");
            const d = new Date();
            d.setHours(hours);
            d.setMinutes(minutes);
            return d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        }
    }
};
</script>
