<template>

</template>

<script>
    export default {
        mounted() {
            console.log('Component mounted.');
          }
        }

</script>
