<template>
  <div class="container">
    <div v-if="isCartLoading" class="d-flex justify-content-center">
      <div  class="patron-loader spinner-border" role="status">
          <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="cartdata.length == 0 && !showPaymentPage">
      Cart is empty. Please add some item from menu.
      <button type="button" class="btn menuitem-btn"  @click="callMenu">View Menu</button>
    </div>
    <div v-if="cartdata.length > 0 && !showPaymentPage">
    <div  class="row">
      <div class="col-md-12" >

          <div class="receipt-yours-screen text-center">
            <h2>Thanks, Jonathan!</h2>
            <p>Your order number is:123
              <span>Transaction # 1234567890</span>
            </P>
            </div>
            <div class="receipt_item">
              <div class="row">
                <div class="col-sm-7 col-7">
                  <div class="receipt_name">
                    <span>1</span>
                    <p>Roasted Turkey Sandwich <br>
                      <span title="click to see special request" style="cursor: pointer; color: rgb(255, 88, 2); font-size: 12px;">other data</span>
                    </p>
                  </div>
                </div>
                <div class="col-sm-5 col-5" style="padding-left: 0px;">
                  <div class="item-price"><span>$ 3.00</span>
                </div>
              </div>
             </div>
             <div class="receipt_item">
              <div class="row">
                <div class="col-sm-7 col-7">
                  <div class="receipt_name">
                    <span>1</span>
                    <p>Roasted Turkey Sandwich </p>
                  </div>
                </div>
                <div class="col-sm-5 col-5" style="padding-left: 0px;">
                  <div class="item-price"><span>$ 3.00</span>
                </div>
              </div>
             </div>
            </div>

            <div class="col-lg-12">
            <div class="cart-items-amt-list">
              <div class="clearfix menu-list-recepit-list">
                <h4 class="float-left">Subtotal</h4>
                <span class="float-right">$10</span>
              </div>
              <div class="clearfix menu-list-recepit-list">
                <h4 class="float-left">Tip</h4>
                <span class="float-right">$0.50</span>
              </div>
              <div class="clearfix menu-list-recepit-list">
                <h4 class="float-left">Taxes & Fees</h4>
                <span class="float-right">$0.40</span>
              </div>
              <div class="clearfix menu-list-recepit-list">
                <h4 class="float-left"> S1 off reward</h4>
                <span class="float-right" style="color: #00a51c;">$0.10</span>
              </div>

              <div class="clearfix menu-list-recepit-list total_recipt">
                <h4 class="float-left"> S1 off reward <br/>
                <p>VIsa ****3847</p></h4>

                <span class="float-right">$0.10</span>
              </div>

            </div>
          </div>
          </div>
        </div>
    </div>
    <div class="new-total-pay-tip" >
      <div class="row">
        <div class="col-md-12">
          <div class="top-tip-sec-bottom">
            <div class="clearfix">
              <span class="float-left">Please rate your service</span>

             </div>
             <div v-if="restauranttype == 'foodtruck'" class="btn-group " role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio1" autocomplete="off" v-model="fields.tipVal" @click.prevent="calculate_tip(0,'btnradio1label')">
                <label class="label-tip-btn btn rating_bg" for="btnradio1" id="btnradio1label"><i class="fa fa-star rating_icon" aria-hidden="true" style="color: #fff!important;"></i></label>

                <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio2" autocomplete="off" v-model="fields.tipVal"  @click.prevent="calculate_tip(5,'btnradio2label')">
                <label class="label-tip-btn btn rating_bg " for="btnradio2" id="btnradio2label"><i class="fa fa-star rating_icon" aria-hidden="true" style="color: #fff!important;"></i></label>

                <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio3" autocomplete="off" v-model="fields.tipVal" @click.prevent="calculate_tip(10,'btnradio3label')">
                <label class="label-tip-btn btn rating_bg" for="btnradio3" id="btnradio3label"><i class="fa fa-star rating_icon" aria-hidden="true" style="color: #fff!important;"></i></label>

                <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio4" autocomplete="off" v-model="fields.tipVal" @click.prevent="calculate_tip(15,'btnradio4label')">
                <label class="label-tip-btn btn rating_bg" for="btnradio4" id="btnradio4label"><i class="fa fa-star rating_icon" aria-hidden="true" style="color: #fff!important;"></i></label>

                <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio5" autocomplete="off" v-model="fields.tipVal" @click.prevent="calculate_tip(20,'btnradio5label')">
                <label class="label-tip-btn btn" style="1px solid #ccc;" for="btnradio5" id="btnradio5label"><i class="fa fa-star rating_icon" aria-hidden="true" style="color: #ccc!important;"></i></label>
              </div>
           </div>

        </div>
      </div>
    </div>
    <div class="modal" id="signInModalPrimaryPay">
    <!-- <div class="modal fade" id="signInModalPrimaryPay" tabindex="-1" role="dialog" aria-hidden="true"> -->
      <signin-pay v-on:setguestpageactive="setGuestPaymentPage" v-on:setloginonpay="setUserLoginOnPay" :connaccidv="connaccid" :paymntkeyvalv="paymntkeyval" :tipval="fields.tipVal" :restaurant_id="restaurant_id" :qrtokenid="qrtokenid" :finalcarttotal="fields.payable_amount" :country_id="country_id" :cartdatalist="cartdata" :tipfinval="fields.final_tips" :taxfinval="fields.final_tax"></signin-pay>
    </div>
    <div class="modal fade" id="signUpStepModalPrimary" tabindex="-1" role="dialog" aria-hidden="true">
      <step-wise-signup :finalcarttotal="fields.payable_amount" :country_id="country_id" :cartdatalist="cartdata" :tipfinval="fields.final_tips" :taxfinval="fields.final_tax" :platformfeefinal="fields.platform_fee" :platformfeelabel="platform_fee_label" :finvibereward="fields.vibe_reward" v-on:setlogin="setUserLogin" ></step-wise-signup>
    </div>

    <div class="modal" id="guestSignUpModalPrimary" data-bs-backdrop="static">
    <!-- <div v-if="!auth_user" class="modal fade" id="guestSignUpModalPrimary" tabindex="-1" role="dialog" aria-hidden="true"> -->
      <patron-guest-pay v-if="showPaymentGuestPage" :enableguestpay="showPaymentGuestPage" :tipval="fields.tipVal" :restaurant_id="restaurant_id" :qrtokenid="qrtokenid" :finalcarttotal="fields.payable_amount" :country_id="country_id" :cartdatalist="cartdata" :tipfinval="fields.final_tips" :taxfinval="fields.final_tax" :platformfeefinal="fields.platform_fee" :platformfeelabel="platform_fee_label" :finvibereward="fields.vibe_reward" :connaccidv="connaccid" :paymntkeyvalv="paymntkeyval"></patron-guest-pay>
    </div>
    </div>

    <payment-page v-if="showPaymentPage" :auth_user="loginUser" :table_id="table_id" :restaurant_id="restaurant_id" :qrtokenid="qrtokenid" :tipval="fields.tipVal" :connaccidv="connaccid" :paymntkeyvalv="paymntkeyval" v-on:ordercompletepageev="callForEnableHeader"></payment-page>
  </div>


</template>

<script>
    import stepwiseSignup from  './3StepSignUpComponent';
    import signInPay from  './SignInForPayComponent';
    //import stripePayment from  './StripePaymentComponent';
    import PaymentPage from  './PaymentPageComponent';
    import patronGuestPay from  './guestSignUpComponent';

    export default {

    props: ['restaurant_id','auth_user','currenttab','country_id','qrtokenid','table_id','restauranttype'],
    data() {
        return {
          discounttitle:'',
          is_discount:false,
          discount_amt:'',
          fields : {
            final_cart_total:0,
            final_tips:0,
            tipVal:20,
            tax_percentage:0,
            final_tax:0,
            vibe_reward:0,
            payable_amount:0,
            user_id:0,
            platform_fee:0
          },
          cartdata:{},
          isloggedin:false,
          isCartLoading:true,
          showPaymentPage:false,
          loginUser:this.auth_user,
          tilled_account_status:'',
          platform_fee_perc:1,
          platform_fee_label:'...',
          connaccid:'',
          paymntkeyval:'',
          showPaymentGuestPage:false,
        }
    },
    components:{
      'step-wise-signup': stepwiseSignup,
      'signin-pay':signInPay,
      'patron-guest-pay':patronGuestPay,
      'payment-page':PaymentPage,
    },
    created: function () {
      this.list();
      this.check_restaurant_payment_gateway_status();
    },
    mounted() {
      let vm = this;



      Echo.channel('item-updated-in-cart-from-dashboard.'+vm.qrtokenid)
      .listen('UpdatedCartItemFromAdmin', (e) => {
        vm.list();
      });
      Echo.channel('item-deleted-cart-from-dashboard.'+vm.qrtokenid)
      .listen('CartItemDeletedFromAdmin', (e) => {
        vm.list();
      });
      Echo.channel('add-item-to-customercart.'+vm.qrtokenid)
      .listen('AddItemToCustomerCartFromBackend', (e) => {
        vm.list();
      });

      vm.get_restaurant_settings();
    },
    methods:{
      callForEnableHeader(){
        this.$emit('enablealltabs');
      },
      get_restaurant_settings(){
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        axios.post('/api/restaurant-settings',postData).then(response => {
          //console.log(response.data.data.platform_fee_label);
          this.platform_fee_label = response.data.data.platform_fee_label;
          this.connaccid = response.data.data.accountId;
          this.paymntkeyval = response.data.data.tilled_p_key;
        });
      },
      check_restaurant_payment_gateway_status(){
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        axios.post('/patron/restaurant-payment-gateway-status',postData).then(response => {
          //console.log(response.data);
          this.tilled_account_status = response.data;
          if(response.data == 0){
            toastr.options = {
            "closeButton": false,
            "positionClass": "toast-top-full-width mt-2",
            "hideDuration": 9000
            };
            toastr.warning('Payment menthod not set. Please contact restaurant owner.');


          }
        }).catch(error => {

        });
      },
      setGuestPaymentPage(valev){
        this.showPaymentGuestPage = valev;
      },
      list(){
        //alert("sdfsdfsd");
        console.log("auth_userc===",this.auth_user);
        let postData = new FormData();
        if(this.auth_user != null){
          this.isloggedin = true;
          postData.append("user_id", this.auth_user.id);
        }

        postData.append("restaurant_id", this.restaurant_id);
        postData.append("confirmed", 1);
        postData.append("table_id", this.table_id);
        postData.append("qrtoken_id", this.qrtokenid);
        axios.post('/patron/cart-list',postData).then(response => {
          this.cartdata = response.data.data.cart_list;
          this.fields.final_cart_total = response.data.data.cartTotal;

          if(response.data.data.discounted_amt_tol > 0){
            this.discounttitle = response.data.data.discount_title;
            this.is_discount = true;
            this.discount_amt = response.data.data.discounted_amt_tol;
          }else{
            this.discounttitle = '';
            this.is_discount = false;
            this.discount_amt = 0;
          }
          //console.log(this.fields.final_cart_total);

          $('#confirmCartCount').text(this.cartdata.length);
          this.fields.tax_percentage = response.data.data.tax_per;
          this.isCartLoading = false;

          this.check_patron_login_status();
          if(this.restauranttype == 'foodtruck'){
            this.calculate_tip(5,'btnradio2label');
          }else{
            this.calculate_tip(5,'btnradio2label');
          }

          this.calculate_platform_fee();
          this.calculate_tax();
          //this.getRewardDetail();
          //this.calculate_payable_amount();
        }).catch(error => {
        });
      },
      check_patron_login_status(){
        axios.post('/patron/check-patron-auth-status').then(response => {
          if(response.data.response.user != ''){
            this.isloggedin = true;
            this.fields.user_id = response.data.response.user.id;
          }
        }).catch(error => {
        });
      },
      calculate_cart_total(){
        let final_cart_total_arr = [] ;
        let ci;
        for ( ci = 0; ci < this.cartdata.length; ci++) {
          final_cart_total_arr[ci] = this.cartdata[ci].quantity * this.cartdata[ci].menuitem.price;
        }

        let final_cart_total = final_cart_total_arr.reduce((acc, item) => acc + item, 0);

        return final_cart_total;
      },
      calculate_platform_fee(){

        this.fields.platform_fee = 0;
        let t_food_cost = this.fields.final_cart_total;

        let cal_platform_fee =   (t_food_cost/100)*this.platform_fee_perc;
        this.fields.platform_fee = cal_platform_fee.toFixed(2);

        this.calculate_tax();
        this.calculate_payable_amount();
      },
      calculate_tip(tipcal,clsname){
        //alert(this.fields.final_cart_total)
        //alert(tipcal)

        this.fields.final_tips = 0;
        //this.fields.final_cart_total = this.final_cart_total;

        let cal_final_tips =   (this.fields.final_cart_total/100)*tipcal;
        this.fields.final_tips = cal_final_tips;
        $('.label-tip-btn').removeClass('check-active');
        $('#'+clsname).addClass('check-active');
        this.fields.tipVal = tipcal;
        this.calculate_platform_fee();
        this.calculate_tax();
        this.calculate_payable_amount();
      },
      calculate_tax(){
        //this.fields.final_cart_total = this.final_cart_total;
        let tippluscartval =   parseFloat(this.fields.final_cart_total);
        let cal_final_tax =   (tippluscartval/100)*this.fields.tax_percentage;
        this.fields.final_tax = cal_final_tax.toFixed(2);
        this.calculate_payable_amount();
      },
      /*getRewardDetail(){
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        if(this.auth_user != null){
          postData.append("user_id", this.auth_user.id);
        }
        axios.post('/patron/get-survey-points',postData).then(response => {
          //console.log(response.data);
          this.fields.vibe_reward = response.data.response.getRewardof;
          //console.log(this.getRewardof);
          this.calculate_payable_amount();
        }).catch(error => {
        });
      },*/
      calculate_payable_amount(){
        let fin_payable_amount = parseFloat(this.fields.final_cart_total) + parseFloat(this.fields.final_tips)  + parseFloat(this.fields.final_tax) + parseFloat(this.fields.platform_fee);

        this.fields.payable_amount =  fin_payable_amount.toFixed(2);
        //alert(this.fields.payable_amount);
      },

      showLoginForm : function(){
        $('#signInModalPrimaryPay').modal('show');
      },
      setUserLogin(){
        this.isloggedin = true;
        axios.post('/patron/check-patron-auth-status').then(response => {
          if(response.data.response.user != ''){
            this.isloggedin = true;
            this.fields.user_id = response.data.response.user.id;
            this.loginUser = response.data.response.user;
            this.$emit('setloginonmenu',this.loginUser);

          }
        }).catch(error => {
        });
      },
      setUserLoginOnPay(logguserdata){
        //alert("hihih");
        this.isloggedin = true;
        this.showPaymentPage = true;
        this.loginUser = logguserdata;
        this.$emit('setloginonmenu',this.loginUser);

      },

      placeOrder(){
        this.$emit('disablealltabs');
        this.showPaymentPage = true;
        this.isCartLoading = false;
      },
      callMenu(){
        this.$emit('callmenusTab','menu');
        $('.new-tablist-tabs a[href="#menu"]').trigger('click');
      }
    },

}

</script>
