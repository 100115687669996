<template>
    <div>    
        <div class="modal-content">
                    <div class="modal-header add-schedule-model-header">
                                <button @click="close_popup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                    </div>
                    <form method="POST" id="adddiscount-form123" name="adddiscountfrm" @submit.prevent="submit_discount" >
                          <div class="m-4">
                              <div class="row">
                                <div class="col-md-12">
                                    <div class="clearfix edit-treat-rewards">
                                        <h3 class="float-left">Add Special Menu</h3>
                                       
                                        <!-- <h3 class="float-left">Add Treat & Reward</h3> -->
                                    </div>
                                </div>
                                  <div class="col-md-12">
                                      <div class="treat-itle">
                                          <label>Special Menu Title </label>
                                          <input type="text" v-model="discount_fields.title" class="form-poup-shift-type" placeholder="$1.00 Off any appetizer" required="required">
                                      </div>
                                      <div class="row">
                                          <div class="col-md-8">
                                              <div class="item-descount">
  
                                                  <label>Select item or category to discount</label>
                                                  <treeselect v-model="discount_fields.menu_item_ids" :multiple="true" :options="menu_items" :flat="true" :auto-deselect-descendants="true"  :disable-branch-nodes="true" placeholder="Open the menu item" noOptionsText="No items available" :show-count="true"  required/>
  
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-5 col-12">
                                      <div class="treat-amount">
                                          <label>Special Menu Amount</label>
                                      </div>
                                      <div class="row">
                                          <div class="col-md-12">
                                              <input type="radio" name="discount_type" v-model="discount_fields.type" value="amount" class="" > <label>Dollars Off</label>
                                              <input type="radio" name="discount_type" v-model="discount_fields.type" value="percentage" class="" > <label>Percentage</label>
                                              <input type="text"  v-model="discount_fields.treat" placeholder="1.00" class="form-poup-shift-type" data-parsley-type='number' required>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-2 col-12"></div>
                                  <div class="col-md-5 col-12">
                                      <div class="treat-amount">
                                          <label>Special Menu Duration</label>
                                      </div>
                                      <div class="row">
                                          <div class="col-md-6">
                                              <label class="treat-label">Start Date</label>
                                              <b-form-datepicker :min="current_date" id="valid_from" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="discount_fields.valid_from" class="form-poup-shift-type" required></b-form-datepicker>
                                              <!-- <input type="date" :min="current_date" id="valid_from" v-model="discount_fields.valid_from" placeholder="11/02/2020" class="form-poup-shift-type" required> -->
                                          </div>
                                          <div class="col-md-6">
                                              <label class="treat-label">End Date</label>
                                              <b-form-datepicker :min="current_date" id="valid_to" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="discount_fields.valid_to" class="form-poup-shift-type" required></b-form-datepicker>
                                              <!-- <input type="date" :min="current_date" id="valid_to" v-model="discount_fields.valid_to" placeholder="11/31/2020" class="form-poup-shift-type date" required> -->
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                    <label class="checkbox-container">Active
                                      <input type="checkbox" name="discount_status" v-model="discount_fields.active" value="1" class=""/>
                                      <span class="checkmark"></span>
                                    </label>
                                </div>
                              </div>
                              <div class="clearfix main-btn-sec-shift">
                                  <button class="btn save-shift-btn float-right" id="save_discount" @click.prevent="add_discount()">Save</button>
                              </div>
                          </div>
                      </form>
                </div>
    </div>
  </template>
  
  <script>
  
    export default {
    props:['current_date'],
    data() {
        return {
            fields: {
              points:'',
              amount:'',
              reward_id:''
            },
            reward:{},
            errors: {},
            blastStatus: true,
            iserrors: false,
            success: false,
            isLoading: false,
            loading:false,
            problem_in_save_error:'',
            menu_categories:{},
            menu_items:[],
            menus:{},
            menu_id:'',
            discount_fields: {
              menu_item_ids: [],
              title: '',
              valid_from: '',
              valid_to: '',
              treat: '',
              menu_id:'',
              type:'amount',
              active:false
            },
            selectoptionData: [],
            selectEventoptionData: [],
            edit_discount_fields: {
              menu_item_ids: [],
              days: '',
              title: '',
              valid_from: '',
              valid_to: '',
              treat: '',
              id:'',
              menu_id:'',
              type:'',
              sel_menu_item_ids:[],
              active:'',
              collection_for:'',
              reaminingdays:'',
              userdetails: {},
              emailcount: '',
              terms_and_service:0,
              amount_to_pay: 0,
              phonecount: '',
              mailtype: 'Text and Email'
            }
        }
    },
    watch: {
      'edit_discount_fields.valid_from': 'updateDaysAndAmount',
      'edit_discount_fields.valid_to': 'updateDaysAndAmount',
      'edit_discount_fields.reaminingdays': 'updateDaysAndAmount'
    },
    computed: {
      getFormattedOptionText() {
        return `<p>${this.edit_discount_fields.title} <br /><span>${this.edit_discount_fields.reaminingdays}</span></p>`;
      },
    },
    // created() {
    //   this.get_menus();
    //   this.getMenuItems();
    // },
    mounted(){
      this.get_menus();
      this.getMenuItems();
    },
    methods:{
      updateDaysAndAmount() {
        const validFrom = new Date(this.edit_discount_fields.valid_from);
        const validTo = new Date(this.edit_discount_fields.valid_to);
  
        if (!isNaN(validFrom) && !isNaN(validTo)) {
          const timeDiff = Math.abs(validTo - validFrom);
          const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  
          this.edit_discount_fields.days = daysDiff;
          this.edit_discount_fields.reaminingdays = daysDiff + ' day' + (daysDiff !== 1 ? 's' : '') + ' remaining Expires ' + this.edit_discount_fields.valid_to;
          this.edit_discount_fields.amount_to_pay = daysDiff * 5;
        } else {
          // Handle invalid date input
          this.edit_discount_fields.days = null;
          this.edit_discount_fields.amount_to_pay = null;
        }
      },
      
      displayRemainingDays(validFrom, validTo) {
      const currentDate = new Date();
      const startDate = new Date(validFrom);
      const endDate = new Date(validTo);
      const daysDiff = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
  
      let blastStatus = true;
  
      if (daysDiff < 0) {
        blastStatus = false;
        return 'Expired at ' + validTo;
      } else if (daysDiff === 0) {
        blastStatus = true;
        return '1 day remaining Expires ' + validTo;
      } else {
        blastStatus = true;
        return daysDiff + ' day' + (daysDiff !== 1 ? 's' : '') + ' remaining Expires ' + validTo;
      }
    },
    
      get_menus(){
        axios.get('/owner/menus/get-restaurant-menus').then(response => {
         
        this.menus = response.data.response.menus;
        this.edit_discount_fields.emailcount = response.data.response.emailcount;
        this.edit_discount_fields.phonecount = response.data.response.phonecount;
        this.edit_discount_fields.userdetails = response.data.response.emailphonelist;
        }).catch(error => {
          console.error('Error fetching menus:', error);
        });
      },
      getMenuItems(){
        axios.get('/owner/manage_treats_rewards/new_get_r_menu_categories').then(response => {
          this.menu_items = response.data.response.item_options;
          this.discount_fields.menu_id = response.data.response.menu_id;
          console.log('getMenuItems');
        console.log(this.menu_items);
  
        }).catch(error => {
          console.log(error);
        });
  
      },
      
      add_discount() {
        let vm = this;
        $('#adddiscount-form123').parsley().whenValidate({
  
        }).done(function() {
        vm.submit_discount();
        });
  
      },
      submit_discount() {
        let vm = this;
          $('#save_discount').prop('disabled',true);
          vm.success = false;
          vm.errors = {};
          axios.post('/owner/manage_treats_rewards/add_discount', vm.discount_fields).then(response => {
            vm.success = true;
            vm.discount_fields = {}
            vm.discount_fields.type = 'amount';
            $("#addDiscount123 .close").click();
            toastr.success('Special Menu added successfully.');
            setTimeout( () => {
              vm.get_menus();
              location.reload();
             }, 3000);
            $('#save_discount').prop('disabled',false);
          }).catch(error => {
            $('#save_discount').prop('disabled',false);
            if (error.response.status === 400) {
              vm.problem_in_save_error = error.response.data.response.message || {};
            }
            else if (error.response.status === 422) {
              vm.errors = error.response.data.errors;
            }else{
              vm.errors = error.response.data.response.message || {};
            }
          });
      },
      close_popup(){
        $('#adddiscount-form123').parsley().reset();
      },
      close_edit_popup(){
        $('#editdiscount-form').parsley().reset();
      }
      
  }
  
  
    }
  </script>