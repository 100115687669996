<template>
<div>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="close handheld-close-popup" data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <form class="patron_signin_item form-horizontal" method="POST" id="signnform123" name="signnform123"  data-parsley-validate @submit.prevent="doLogin">
            <div class="m-3">
              <div class="login_popup">
                <img style="width: 57%; margin-right: -17px;margin-top: 4px;" :src="'/img/frontend/firefly-final-logo.png'" alt="">
                <div class="login_popup_title">
                    <p>Log into your Account</p>
                </div>
              </div>
            <div>
              <div>  
                <label for="email" class="col-form-label user_login_comp">Username</label>
                <input type="email" name="form_field" v-model="form_field.email" class="form-poup"
                placeholder="Email Address" data-parsley-required-message="Please enter email address"  required>
              </div>

              <div>
                <label for="password" class=" col-form-label user_login_comp">Password</label>
                <input type="password" name="form_field" v-model="form_field.password" class="form-poup"
                placeholder="*******" data-parsley-required-message="Please enter password" required>
              </div>
          </div>
          <div class="mb-2 text-center">

              <div v-if="validation_errors" class="text-danger">
                <div v-for="(v, k) in validation_errors" :key="k">
                    {{v[0]}}
                </div>
              </div>
              <div v-if="error" class="text-danger text-danger text-center">
                <p v-if="error" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>

          </div>
          <div class="clearfix">
            <!--<label class="text-center">Create new Account</label>-->
            <!--<span class="text-center d-block forget-account-popup"><a >Forgot Password</a></span>-->
          </div>
          <div class="clearfix">
            <button style="margin-bottom: 0 !important;" class="btn add-signin-btn" id="login_btn">Sign In</button>
          </div>

            <div class="login_new">
                <a href="#" data-toggle="modal" :data-target='`#signUpModalPrimary${modalid}`' class="float-left d-block create-account-popup">Create new account</a>
                <a href="/password/reset" class="float-right">Forgot password?</a>
            </div>


          <!-- <div class="clearfix text-center">
            <label class="guest-sign-up-popup" data-toggle="modal" data-target="#guestSignUpModalPrimary">Continue as guest</label>
          </div> -->
        </div>
      </form>
    </div>
  </div>
</div>
</div>
</template>
<script src="{{asset('js/frontend/parsley.js')}}" type="text/javascript"></script>
<script>
    export default {
  props:['modalid'],
    data() {
        return {
          form_field:{
            email:'',
            password:''
          },
          problem_in_login:'',
          errors: {},
          validation_errors:{},
          error:''
        }

    },

    created: function () {
      
    },
    mounted(){
      console.log('modalid = '+this.modalid);
    },
    methods:{
      // ValidateForm() {
      //   let vm = this;
      //   $('#signnform123').parsley().whenValidate({

      //   }).done(function() {
      //     vm.doLogin();
      //   });
      // },
      doLogin: function () {
        let vm = this;
        $('#login_btn').prop('disabled',true);
        axios.post('/patron/login',vm.form_field).then(response => {
          console.log(response.data.response.message);
          console.log(response.data.response.url);
          $('#login_btn').prop('disabled',false);
          $("#signInModalPrimary .close").click();
          toastr.success(response.data.response.message);
           window.location.href = "/"+response.data.response.url;
          //location.reload();
        }).catch(error => {
          console.log(error);
          $('#login_btn').prop('disabled',false);
          if(error.response.data.errors){
            vm.validation_errors = error.response.data.errors || {};
          }
          if(error.response.data.response.message){
            vm.error = error.response.data.response.message || {};
          }

        });
      }
    },
  mounted: function() {

  }
}

</script>
