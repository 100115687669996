<template>
    <div>
        <div v-for="(item, index) in items" :key="item.id" v-if="items.length && items.length > 0">
        <div class="dt_tlt" data-toggle="modal"  data-target="#bookevent" type="button">
           <p>{{ currentDate(item.start_date) }}</p>
        </div>
        <div class="mbl_event sch">
            <a :href="'/eventdetails/' + item.id">
                <p class="loc_name"  style="width: 120%;"><i class="align-middle fas fa-map-marker-alt "></i> 
                 {{ item.name }}
                </p>
            </a>
            <p style="color: #656280;font-weight: normal;width: 63%;"><i class="align-middle fas fa-clock" ></i>
             {{ convertedStartTime(item.start_time) }} - {{ convertedEndTime(item.end_time) }}
             </p> 
            <span>
               <i  v-if="userid" class="align-middle far fa-heart like"></i>
            </span> 
        </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['items','userid'],
    data() {
        return {
            firstevent: [],
            items: [],
        };
    },
    mounted() {
     //
    },
    methods: {
        currentDate(item) {
            const date = new Date(item);
            return date.toLocaleDateString("en-US", {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric"
            });
        },
        convertedStartTime(time) {
            const [hours, minutes] = time.split(":");
            const d = new Date();
            d.setHours(hours);
            d.setMinutes(minutes);
            return d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        },
        convertedEndTime(time) {
            const [hours, minutes] = time.split(":");
            const d = new Date();
            d.setHours(hours);
            d.setMinutes(minutes);
            return d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        }
    }
};
</script>