<template>
<div
  id="g-recaptcha"
  class="g-recaptcha"
  :data-sitekey="sitekey">
  </div>
</template>

<script>
export default {
  data () {
    return {
      sitekey: process.env.MIX_INVISIBLE_RECAPTCHA_SITEKEY,
      widgetId: 0
    }
  },
  methods: {
  },
  mounted () {

  }
}
</script>
