<template>
  <div class="col-lg-12">
    <!-- <div class="card card-body flex-fill w-100"> -->
      <div class="clearfix">
        <h5 class="card-title mb-0 float-left">Onsite ToGo Orders</h5>
      </div>
      <div class="row">
        <!-- table1 -->
        <div v-if="orderslength == 0" class="col-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card card-block d-flex table-card no-order" >
            <div class="card-body align-items-center d-flex justify-content-center empty_order_text">
              Loading...
            </div>
          </div>
        </div>
        <div v-for="(item, index) in orders" class="col-12 col-md-6 col-lg-4 col-xl-4" >
          <div class="table-card c-table-card">
            <div class="user-headeading-booking clearfix">
              <div class="float-left">
                <h3 class="card-title mb-0 float-left">Order {{index + 1}}</h3>
              </div>
            </div>
            <hr>
            <div class="" style="height:200px; overflow-y:auto !important">
              <div class="outer-food-details" >
                <div v-if="item.cartitems.length > 0 " class="">
                  <div v-for="(ritem,index) in item.cartitems" class="row">
                    <div class="col-sm-8 col-8">
                      <div class="order-name">
                        <span>{{ ritem.quantity }}</span>
                        <p>{{ritem.menuitem.title}}</p>
                      </div>
                    </div>
                    <div class="col-sm-4 col-4" style="padding-left: 0;">
                      <div class="item-price">
                        <span>$ {{parseFloat(ritem.quantity * ritem.menuitem.price).toFixed(2)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- buttons-->
            <div  class="mt-2">
              <div  class="total-timing-price clearfix">
                <div class="float-left left-timing">
                  <i class="align-middle  far fa-fw fa-clock" ></i><a :id="'timer_clk_'+item.id">
                    {{calltablescantimer(item.created_at,'timer_clk_'+item.id)}}
                  </a>
                </div>
                <div v-if="item.carttotalp > 0 " class="float-right total-price-number">
                  <span>$ {{parseFloat(item.carttotalp).toFixed(2)}}</span>
                </div>
              </div>
              <div class="outer-btn-edit-close">
                <div class="row">
                  <div v-if="item.order_paid == null" class="col-sm-6 col-6 innterbtn-edit">
                    <button v-if="item.carttotalp > 0 " class="btn" @click="editOrderModal(item)"><i
                        class="align-middle  far fa-fw fa-edit"></i>
                      Edit</button>
                  </div>
                  <div v-if="item.order_paid != null" class="col-sm-6 col-6 innterbtn-edit">
                    <button class="btn btn-sm btn-success" disabled>Paid</button>
                  </div>
                  <div class="col-sm-6 col-6 innterbtn-edit"
                    style="padding-left: 0px;">
                    <button class="btn" @click.prevent="close_out(item.id)"><i
                        class="align-middle  far fa-fw fa-window-close"></i>
                      Close Out</button>
                  </div>
                </div>
                <div v-if="item.order_paid == null" class="row">
                  <div class="col-sm-12">
                    <a class="btn btn-sm pay_onsite_order" :href="'/owner/onsite_togo_orders/make-payment/'+item.id">Pay</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- edit order modal -->
      <!--edit order items-->
      <div class="modal fade"   id="showModalEdit" tabindex="-1" role="dialog"  aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header padding-header">
                <h3 class="float-left">Order</h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="bar-add-order-section">
                <div class="clearfix bar-direct-order-name-inner">
                  <div class="float-right"><a title="Add New Item" @click.prevent="showMenus()"><i class="fas fa-plus"></i></a></div>
                </div>
                <div class="new-seat-table-collection">
                  <div class="new-seat-table-section">
                  <div class="order-name-edit-outer">
                      <div>
                        <div v-for="(item,index) in order_items_array" class="clearfix top-order-name-inner" style="border: medium none;">
                          <div class="float-left">
                            <h3>{{item.quantity}} {{item.menuitem.title}}</h3>
                            <p>{{item.menuitem.description}}</p>
                          </div>
                          <div class="float-right d-flex order-name-edit-price">
                            <div class="price-total">$ {{parseFloat(item.quantity * item.menuitem.price).toFixed(2)}}</div>
                            <a title="Update" @click.prevent="editItem(item,order_items_array,index)"><i class="fas fa-pencil-alt"></i></a>
                            <a title="Remove" @click.prevent="removeItem(item.id,order_items_array,index)"><i class="fas fa-trash"></i></a>
                          </div>
                       </div>
                     </div>
                  </div>
                  </div>
                  <div class="taxes-and-fee-price">
                    <div class="taxes-and-fee-price-inner">
                      <div class="clearfix"><h3 class="float-left">Taxes &amp; Fees</h3>
                        <span class="float-right">$0.00</span></div>
                      </div>

                      <div class="taxes-and-fee-price-inner-total">
                        <div class="clearfix">
                          <h3 class="float-left">Total</h3>
                          <span class="float-right">${{parseFloat(order_items_array.seat_total).toFixed(2)}}</span>
                        </div>
                      </div>
                      <div class="taxes-and-fee-buttons">
                        <div class="clearfix">
                          <div class="">
                            <button @click="close_out(edit_order_id)" class="btn close-out-direct-order"><img src="/img/frontend/close-icon.PNG">Close Out</button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- edit menu modal -->
      <div class="modal fade" id="editMenuItem" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <form method="POST" id="editiorderfrm" name="editiorderfrm" @submit.prevent="saveEditItem" >
            <div class="order_menu_item">
              <h4>${{parseFloat(edit_menu.menu_item.price).toFixed(2)}}</h4>
              <h4>{{edit_menu.menu_item.title}}</h4>
              <p>
                {{edit_menu.menu_item.description}}
              </p>
              <label>Special Requests?</label>
              <textarea placeholder="What are your requests?" class="form-poup" v-model="edit_menu.menu_item.specialrequest" ></textarea>
              <div class="qty-text-sec">
                <div class="row">
                  <div class="col-2">
                    <span>Qty</span>
                  </div>
                  <div class="col-5">
                    <input type="text" id="qty" class="qtyform-poup" v-model="edit_menu.menu_item.quantity" min="0"  required=""
                     data-parsley-trigger="keyup" data-parsley-type="digits">
                  </div>
                  <div class="col-5 button-service">
                    <span ><i class="fa fa-minus"  v-on:click="decrease"></i></span>
                    <span><i class="fa fa-plus"    v-on:click="increase"></i></span>
                  </div>
                </div>
              </div>
              <div>
                <button @click.prevent="validateEditItem()" class="btn add-btn-order" id="edit_item" >Update</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
      <!-- add new menu modal -->
      <div class="modal fade" id="addNewMenuItemModal" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document" >
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="m-4 tab-pane handeld-popup-relative active">
                <div class="nav handhelds-menus poup-nav-hanhelds-menu">
                    <div class="nav-item" v-for="(item,index) in menu_categories.slice(0,5)"  @click="showMenuItems($event,item.id)">
                      <a class="nav-link" :class="{ active: (activeTabInline == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                    </div>
                    <div class="nav-item menu-list-handheld" >
                      <a class="nav-link" id="final_draft"><i class="fas fa-align-justify"></i></a>
                    </div>
                </div>
                <div class="onclick-show-menu-handeld " id="mobile_toggle_nav" style="display: none;">
                  <ul class="nav flex-column">
                    <div class="close-menu" id="close_toggle_nav" >&times;</div>
                    <li class="nav-item" v-for="(item,index) in menu_categories" @click="showMenuItems($event,item.id)">
                      <a class="nav-link " :class="{ active: (activeTab == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                    </li>
                  </ul>
                </div>


                <div class="outer-menu-list-price">
                  <h3>{{activeMenuTitle}}</h3>
                  <!-- menu-1 -->

                  <div v-if="load_menu_items" class="d-flex justify-content-center">
                    <div class="patron-loader spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                  </div>


                  <div class="menu-list-price" v-for="(item,index) in menu_items" v-if="item.inventory > 0"  @click="showMenuItem(item)">
                    <div class="clearfix" >
                        <h4 class="float-left">{{item.title}}</h4>
                        <span class="float-right">${{parseFloat(item.price).toFixed(2)}}</span>
                    </div>
                    <p>{{item.description}}</p>
                  </div>
                  <div v-if="menu_items.length == 0" class="empty_menu empty_msg">No menu items found.</div>

                </div>

              </div>
            </div>
          </div>
      </div>

      <!-- view item modal-->
      <div class="modal fade" id="viewItem" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <form method="POST" id="add_new_item_frm" name="add_new_item_frm" @submit.prevent="add_new_item" >
            <div class="order_menu_item">
              <h4>${{parseFloat(new_menu_item.price).toFixed(2)}}</h4>
              <h4>{{new_menu_item.title}}</h4>
              <p>
                {{new_menu_item.description}}
              </p>
              <label>Special Requests?</label>
              <textarea placeholder="What are your requests?" class="form-poup" v-model="new_menu_item.specialrequest" ></textarea>
              <div class="qty-text-sec">
                <div class="row">
                  <div class="col-2">
                    <span>Qty</span>
                  </div>
                  <div class="col-5">
                    <input type="text" id="qty" class="qtyform-poup" v-model="new_menu_item.quantity" min="1"  required=""
                    data-parsley-validation-threshold="1" data-parsley-trigger="keyup" data-parsley-type="digits">
                  </div>
                  <div class="col-5 button-service">
                    <span ><i class="fa fa-minus"  v-on:click="decrease_new"></i></span>
                    <span><i class="fa fa-plus"    v-on:click="increase_new"></i></span>
                  </div>
                </div>
              </div>
              <div>
                <button @click.prevent="ValidateOrder()" class="btn add-btn-order" id="add_new_item" >Add to order</button>
                <!-- <a data-toggle="modal" data-target="#signInModalPrimary" class="btn add-signin-btn signin-up-btn"  v-if="!isloggedin">Sign in/ Create Account</a> -->
              </div>
            </div>
            <div class="mb-2 text-center">

                <div v-if="validation_errors" class="text-danger text-danger text-center">
                  <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                      {{v[0]}}
                  </p>
                </div>
                <div v-if="error" class="text-danger text-danger text-center">
                  <p v-if="error" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
                <div v-if="errors" class="text-danger text-danger text-center">
                  <div v-for="(v, k) in errors" :key="k">
                    <p v-for="error in v" :key="error" class="text-sm">
                      {{ error }}
                    </p>
                  </div>
                </div>

            </div>

            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

    export default {
    props: ['restaurant_id'],
    data() {
        return {
          orderslength:0,
          activeTabInline:null,
          activeTab: null,
          activeMenuTitle:null,
          load_menu_items:false,
          menu_items:{},
          menu_categories:[],
          orders:[],


          edit_menu:{
            menu_item:{
              title:'',
              description:'',
              price:'',
              quantity:1,
              user_id:'',
              specialrequest:'',

            },
            menu_item_arry:'',
            cartItms:'',
            menuItmInx:'',

          },

          new_menu_item:{
            title:'',
            description:'',
            price:'',
            quantity:1,
            user_id:'',
            specialrequest:''
          },
          validation_errors:{},
          error:'',
          errors:{},

          edit_order_id:'',
          order_items_array:{},
          existing_seat:"no"
        }
    },

    created: function () {
      this.getRestaurantOnsiteToGoOrders();
    },
    mounted() {
    },
    methods:{

      getRestaurantOnsiteToGoOrders(){
        let formData = new FormData();
        formData.append("restaurant_id", this.restaurant_id);
        axios.post('/owner/onsite_togo_orders/get-list',formData).then(response => {
          console.log(response);
          this.orders = response.data.response.list;
          this.orderslength = this.orders.length;
          if(this.orderslength == 0){
            $('.empty_order_text').text('No Order Found...');
          }
        }).catch(error => {

        });
      },

      close_out(onsitetogo_order_id){
        let vm = this;
        if(confirm("Do you really want to close out?")){
          let formData = new FormData();
          formData.append("id", onsitetogo_order_id);
          formData.append("restaurant_id", this.restaurant_id);
          axios.post('/owner/onsite_togo_orders/close-out',formData).then(response => {
            toastr.options = {
            "positionClass": "toast-bottom-right"
            };
            toastr.success('Order Closed Out successfully.');
            location.reload();
          }).catch(error => {
            if (error.response.status === 400) {
              alert(error.response.data.response.message);
            }else{
              alert("Something went wrong!");
            }
          });
        }
      },
      showMenuItems: function (event,menucatid) {
        this.load_menu_items = true;
        this.menu_items = {};
        this.activeMenuTitle = event.target.getAttribute('data-menu');
        this.activeTab = menucatid;
        this.activeTabInline = menucatid;
        let menuData = new FormData();
        menuData.append("menu_category_id", menucatid);
        axios.post('/owner/orders/get-restaurant-menu-items',menuData).then(response => {
          this.load_menu_items = false;
          this.menu_items = response.data.response.menu_items;
          $('#close_toggle_nav').click();
        }).catch(error => {
        });
      },
      editOrderModal(itm_array){
        let vm = this;
        $('#showModalEdit').modal('show');
        vm.edit_order_id  = itm_array.id;
        vm.order_items_array = itm_array.cartitems;
        vm.order_items_array.seat_total = itm_array.carttotalp;
        vm.existing_seat  ="yes";
      },
      showMenus(){
        let vm = this;
        $('#addNewMenuItemModal').modal('show');
        let formData = new FormData();
        formData.append("restaurant_id", vm.restaurant_id);
        axios.post('/owner/orders/get-menu-categories',formData).then(response => {
            console.log('get-menu-categories');
            console.log(response.data.response);
           vm.menu_categories = response.data.response.categories;
           vm.activeTab = response.data.response.categories[0].id;
           vm.activeTabInline = response.data.response.categories[0].id;
           vm.activeMenuTitle = response.data.response.categories[0].title;
           formData.append("menu_category_id", response.data.response.categories[0].id);
           axios.post('/owner/orders/get-restaurant-menu-items',formData).then(response => {
               vm.menu_items = response.data.response.menu_items;
           });
        }).catch(error => {
        });
      },
      showMenuItem: function (item) {
        let vm = this;
        $('#viewItem').modal('show');
        vm.new_menu_item.title = item.title;
        vm.new_menu_item.description = item.description;
        vm.new_menu_item.price = item.price;
        vm.new_menu_item.id = item.id;
      },
      ValidateOrder(seataddedid) {
        let vm = this;
        $('#add_new_item_frm').parsley().whenValidate({

        }).done(function() {
          vm.add_new_item(seataddedid);
        });
      },
      add_new_item: function (seataddedid) {
        let vm = this;
        $('#add_new_item').prop('disabled',true);
        let menuData = new FormData();
        menuData.append("restaurant_id", vm.restaurant_id);
        menuData.append("menu_item_id", vm.new_menu_item.id);
        menuData.append("quantity", vm.new_menu_item.quantity);
        menuData.append("specialrequest", vm.new_menu_item.specialrequest);
        menuData.append("register_restaurant_order_id", vm.edit_order_id);
        axios.post('/owner/orders/add-menu-to-register-order',menuData).then(response => {
          $('#add_new_item').prop('disabled',false);
          $('#viewItem').modal('hide');
          if(vm.existing_seat == 'yes'){
            vm.order_items_array.push(response.data.response.menu_added);
            vm.order_items_array.seat_total = response.data.response.order_total;
          }
          vm.getRestaurantOnsiteToGoOrders();
        }).catch(error => {
          $('#add_new_item').prop('disabled',false);
        });
      },
      increase_new: function() {
        let vm = this;
        vm.new_menu_item.quantity++;
      },
      decrease_new:  function() {
        let vm = this;
        if (vm.new_menu_item.quantity > 1) {
            vm.new_menu_item.quantity--;
        }
      },
      editItem(itmmenu,cartItms,menuItmInx){
        console.log(itmmenu);
        console.log(cartItms);
        let vm = this;
        $('#editMenuItem').modal('show');
        vm.edit_menu.menu_item.title = itmmenu.menuitem.title;
        vm.edit_menu.menu_item.description = itmmenu.menuitem.description;
        vm.edit_menu.menu_item.price = itmmenu.menuitem.price;
        vm.edit_menu.menu_item.quantity = itmmenu.quantity;
        vm.edit_menu.menu_item.id = itmmenu.id;
        vm.edit_menu.menu_item.specialrequest = itmmenu.other_data;

       vm.edit_menu.menu_item_arry = itmmenu;
       vm.edit_menu.cartItms = cartItms;
       vm.edit_menu.menuItmInx = menuItmInx;
      },
      validateEditItem(itmmenu){
        let vm = this;
        $('#editiorderfrm').parsley().whenValidate({

        }).done(function() {
          vm.saveEditItem();
        });
      },
      saveEditItem(){
        let vm = this;
        vm.validation_errors = {};
        vm.error = '';
        $('#edit_item').prop('disabled',true);
        if(vm.edit_menu.menu_item.quantity > 0){
          let formData = new FormData();
          formData.append("u_menu_item", JSON.stringify(vm.edit_menu.menu_item));
          formData.append("restaurant_id", vm.restaurant_id);
          axios.post('/owner/orders/edit-menuitem-of-register-order',formData).then(response => {

            vm.edit_menu.cartItms[vm.edit_menu.menuItmInx] = response.data.response.cart_updated_item;
            vm.order_items_array.seat_total = response.data.response.order_total;


            $('#edit_item').prop('disabled',false);
            $('#editMenuItem').modal('hide');
            vm.getRestaurantOnsiteToGoOrders();
          }).catch(error => {
          });
        }else{
          this.removeItem(vm.edit_menu.menu_item.id,vm.edit_menu.cartItms,vm.edit_menu.menuItmInx);
          $("#editMenuItem .close").click();
        }

      },
      increase: function() {
        let vm = this;
        vm.edit_menu.menu_item.quantity++;
      },
      decrease:  function() {
        let vm = this;
        if (vm.edit_menu.menu_item.quantity > 0) {
            vm.edit_menu.menu_item.quantity--;
        }
      },
      removeItem(itmId,cartItms,menuItm){
        let vm = this;
        if(confirm("Do you really want to remove?")){
          axios.delete('/owner/orders/remove-menuitem-of-register-order/'+itmId).then(response => {
            Vue.delete(cartItms, menuItm);
            vm.order_items_array.seat_total = response.data.response.order_total;
          }).catch(error => {
          });
        }
      },
     padTime(num) {
        return ("0"+num).slice(-2);
      },
      calltablescantimer(scanDate,elmId){

          let vm = this;
          setInterval(function(){
            let startTime = moment(scanDate);
            let endTime = moment();
            let seconds = endTime.diff(startTime, 'seconds');


            let minutes = Math.floor(seconds / 60);
            seconds = seconds%60;
            let hours = Math.floor(minutes/60)
            minutes = minutes%60;

            let time = vm.padTime(hours)+':'+vm.padTime(minutes)+':'+vm.padTime(seconds);
              $("#"+elmId).html(time);
            }, 1000);

      }
    },

    computed: {
    }

    }
</script>
