<template>
  <div class="">
    <div class="card card-body flex-fill w-100" id="cardId">
      <div class="clearfix header-register-sec">
        <div class="float-left">
          <span><img src="/img/frontend/avr.PNG">Register</span>
        </div>
        <!-- <div v-if="register_restaurant_order_id > 0 && neworderregisteritems.length > 0" class="float-right">
              <form :action="'/owner/onsite_togo_orders/make-payment/'+register_restaurant_order_id+'/'" class="inline">

                <button class="btn close-out"><img src="/img/frontend/close-icon.PNG">Pay old</button>
              </form>
            </div> -->
        <div v-if="register_restaurant_order_id > 0 && neworderregisteritems.length > 0" class="float-right">
          <button type="button" class="btn close-out" @click="openAddTipModal()" style="margin-right:10px;">
            <img src="/img/frontend/close-icon.PNG">Pay
          </button>
        </div>
      </div>

      <div class="d-none" id="openMemus">

        <div class="add-icon-list">
          <h3>Add</h3>
          <a @click="openMemus"><img src="/img/frontend/icons/55.jpg"></a>
          <!-- <a href="#"><img src="/img/frontend/qr-scan.PNG"></a>
            <a href="#"><img src="/img/frontend/percent.PNG"></a> -->
        </div>
      </div>
      <div class="user-details-form py-3 w-50" style="padding:10px" id="getUserDetails">
        <p style="font-size: 14px;">Please fill these mandatory fields before you proceed</p>
        <span style="position: absolute;color:red">*</span><input type="text" v-model="firstName" name="firstName"
          id="firstName" class="form-control mb-2" placeholder="First Name" required>
        <input type="text" v-model="lastName" name="lastName" id="lastName" class="form-control mb-2"
          placeholder="Last Name">
        <input type="text" v-model="phoneNumber" name="phoneNumber" id="phoneNumber" class="form-control mb-2"
          placeholder="Phone Number">
        <button @click="getUserDetails"
          style="width: 205px;height: 40px;font-size: 16px;background-color: #ff5802;font-weight: 600;border: 2px solid #ff580287;text-transform: uppercase;padding: 5px 15px; color: #ffffff; margin: 30px auto !important;"
          type="button" class="btn btn-warning">Next</button>
      </div>

      <div class="new-seat-table-section" v-if="neworderregisteritems.length > 0">
        <div v-for="(item, index) in neworderregisteritems" class="order-name-edit-outer">
          <div>
            <div class="clearfix top-order-name-inner" style="border: medium none;">
              <div class="float-left">
                <h3>{{ item.quantity }} {{ item.menuitem.title }}</h3>
                <p>{{ item.menuitem.description }}</p>
              </div>
              <div class="float-right d-flex order-name-edit-price">
                <!-- <div class="price-total">$ {{item.menuitem.price * item.quantity}}</div> -->
                <div class="price-total">$ {{ parseFloat(item.menuitem.price * item.quantity).toFixed(2) }}</div>
                <a title="Update" @click.prevent="editItem(item, neworderregisteritems, index)"><i
                    class="fas fa-pencil-alt"></i></a>
                <a title="Remove" @click.prevent="removeItem(item.id, neworderregisteritems, index)"><i
                    class="fas fa-trash"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="register-total-price-new-bottm" v-if="neworderregisteritems.length > 0">
        <div class="clearfix">
          <h3 class="float-left font-taxprice">Subtotal</h3>
          <span class="float-right font-taxprice">${{ parseFloat(subtotal).toFixed(2) }}</span>
        </div>
        <div v-if="is_discount" class="clearfix">
          <h3 class="float-left font-taxprice" style="width:200px">Discount Applied: {{ discounttitle }}</h3>
          <span class="float-right font-taxprice">-${{ parseFloat(discount_amt).toFixed(2) }}</span>
        </div>
        <div class="clearfix">
          <h3 class="float-left font-taxprice">Tip</h3>
          <span class="float-right font-taxprice">${{ parseFloat(fields.final_tips).toFixed(2) }}</span>
        </div>
        <div class="clearfix">
          <h3 class="float-left font-taxprice">Tax</h3>
          <span class="float-right font-taxprice">${{ parseFloat(new_taxtotl).toFixed(2) }}</span>
        </div>
        <div class="clearfix">
          <h3 class="float-left font-taxprice">Service Charge</h3>
          <span class="float-right font-taxprice">${{ parseFloat(new_platformfee).toFixed(2) }}</span>
        </div>

        <div class="clearfix">
          <h3 class="float-left">Total</h3>
          <!-- <span class="float-right">${{new_order_total}}</span> -->
          <span class="float-right">${{ parseFloat(new_order_total).toFixed(2) }}</span>
        </div>
      </div>
    </div>
    <!--menu items modal-->
    <div class="modal fade" id="menuItemModal_ref" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
       
        <div class="modal-content">
          <button type="button" class="close close_one handheld-close-popup" data-dismiss="modal" aria-label="Close" style="position:fixed !important;">
            <span aria-hidden="true">&times;</span>
          </button>
        <div class="">
            <nav class="navbar is-dark is-fixed-top menu_scroll" role="navigation" aria-label="main navigation" style="    top: 12px !important;">
              <div class="">
                <div id="navbar" class="navbar-menu navbar-scroll">
                  <div class="navbar-start-apps">
                    <!-- <a href="#section1" class="navbar-item active">Menu-1</a> -->
                    <div v-for="(item,index) in menu_categories.slice(0, 5)" @click="showMenuItems($event,item.id)">
                        <a :href="`#section${item.id}`" class="navbar-item" :class="{ active: (activeTabInline == item.id) }" :data-menu="`${item.title}`">{{ item.title.length > 15 ? item.title.slice(0, 15) + '...' : item.title }}</a>
                    </div>
                  </div>
                </div>
                <div class="nav-item menu-list-handheld menu_listdropdown mobl_menu_listdropdown new_menu">
                      <a class="nav-link final_draft" id="final_draft">
                        <i class="fas fa-align-justify" style="left: 0px;padding:2px 7px 1px 7px; padding-left: 0;"></i>
                      </a>
                </div>
              </div>
              <div class="onclick-show-menu-handeld onclick_menu onclick_poup_menu" id="mobile_toggle_nav" style="display: none;">
                <ul class="nav flex-column">
                  <div class="close-menu" id="close_toggle_nav" >&times;</div>
                  <li class="nav-item" v-for="(item,index) in menu_categories" @click="showMenuItems($event,item.id)">
                    <a :href="`#section${item.id}`" class="navbar-item" :class="{ active: (activeTabInline == item.id) }" :data-menu="`${item.title}`">{{item.title}}</a>
                  </li>
                </ul>
              </div>
            </nav>

            
              <div class="container">
                  <div class="" v-for="(item,index) in menu_categories">     
                    <h3 style="margin-top: 79px;margin-bottom: -77px;">{{item.title}}</h3>
                    <section :id="`section${item.id}`" class="page">
                      <div class="title menu-list-price" v-for="(citem,index) in item.menuitems" v-if="citem.inventory > 0"  @click="showMenuItem(citem)">
                          <div class="clearfix">
                            <h4 class="float-left" style="width: 89%;">{{citem.title}}</h4> 
                            <span class="float-right">${{parseFloat(citem.price).toFixed(2)}}</span>
                          </div> 
                          <p>{{citem.description}}</p>
                      </div>
                      <div v-if="item.menuitems.length == 0" class="empty_menu empty_msg">No menu items found.</div>
                    </section>
                  </div>
                </div>
           
            
         </div>   
        </div>
      </div>
    </div>
    <!--view menu item modal-->
    <div class="modal fade" id="reg_viewItem" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" class="close handheld-close-popup" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <form method="POST" id="addiorderfrm" name="addiorderfrm" @submit.prevent="addOrderToRegisterSeat">
            <div class="order_menu_item">
              <!-- <h4>${{new_menu_item.price}}</h4> -->
              <h4>${{ parseFloat(new_menu_item.price).toFixed(2) }}</h4>
              <h4>{{ new_menu_item.title }}</h4>
              <p>
                {{ new_menu_item.description }}
              </p>
              <label>Special Requests?</label>
              <textarea placeholder="What are your requests?" class="form-poup"
                v-model="new_menu_item.specialrequest"></textarea>
              <div class="qty-text-sec">
                <div class="row">
                  <div class="col-2">
                    <span>Qty</span>
                  </div>
                  <div class="col-5">
                    <input type="text" id="qty" class="qtyform-poup" v-model="new_menu_item.quantity" min="1"
                      required="" data-parsley-validation-threshold="1" data-parsley-trigger="keyup"
                      data-parsley-type="digits">
                  </div>
                  <div class="col-5 button-service">
                    <span><i class="fa fa-minus" v-on:click="decrease_new"></i></span>
                    <span><i class="fa fa-plus" v-on:click="increase_new"></i></span>
                  </div>
                </div>
              </div>
              <div>
                <button @click.prevent="ValidateRegisterOrder()" class="btn add-btn-order" id="add_order">Add to
                  order</button>
              </div>
            </div>
            <div class="mb-2 text-center">
              <div v-if="validation_errors" class="text-danger text-danger text-center">
                <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                  {{ v[0] }}
                </p>
              </div>
              <div v-if="error" class="text-danger text-danger text-center">
                <p v-if="error" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>
              <div v-if="errors" class="text-danger text-danger text-center">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--edit menu moda-->
    <div class="modal fade" id="editMenuItem" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" class="close handheld-close-popup" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <form method="POST" id="editorderfrm" name="editorderfrm" @submit.prevent="saveEditItem">
            <div class="order_menu_item">
              <!-- <h4>${{edit_menu.menu_item.price}}</h4> -->
              <h4>${{ parseFloat(edit_menu.menu_item.price).toFixed(2) }}</h4>
              <h4>{{ edit_menu.menu_item.title }}</h4>
              <p>
                {{ edit_menu.menu_item.description }}
              </p>
              <label>Special Requests?</label>
              <textarea placeholder="What are your requests?" class="form-poup"
                v-model="edit_menu.menu_item.specialrequest"></textarea>
              <div class="qty-text-sec">
                <div class="row">
                  <div class="col-2">
                    <span>Qty</span>
                  </div>
                  <div class="col-5">
                    <input type="text" id="qty" class="qtyform-poup" v-model="edit_menu.menu_item.quantity" min="0"
                      required="" data-parsley-trigger="keyup" data-parsley-type="digits">
                  </div>
                  <div class="col-5 button-service">
                    <span><i class="fa fa-minus" v-on:click="decrease"></i></span>
                    <span><i class="fa fa-plus" v-on:click="increase"></i></span>
                  </div>
                </div>
              </div>
              <div>
                <button @click.prevent="validateEditItem()" class="btn add-btn-order" id="edit_item">Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--tip modal-->
    <div class="modal fade orderTipModalCls" id="orderTipModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-center" id="exampleModalLabel">Please select a tip option</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeTipModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="btn-group " role="group" aria-label="Basic radio toggle button group" style="margin: 0 auto;float: left;width: 100%;">


              <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio1" autocomplete="off"
                v-model="fields.tipVal" @click.prevent="calculate_tip(10, 'btnradio1label')">
              <label class="label-tip-btn btn btn-outline-primary check-active" for="btnradio1"
                id="btnradio1label">10%</label>

              <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio2" autocomplete="off"
                v-model="fields.tipVal" @click.prevent="calculate_tip(15, 'btnradio2label')">
              <label class="label-tip-btn btn btn-outline-primary " for="btnradio2" id="btnradio2label">15%</label>

              <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio3" autocomplete="off"
                v-model="fields.tipVal" @click.prevent="calculate_tip(20, 'btnradio3label')">
              <label class="label-tip-btn btn btn-outline-primary" for="btnradio3" id="btnradio3label">20%</label>

              <!-- <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio4" autocomplete="off"
                v-model="fields.tipVal" @click.prevent="calculate_tip(25, 'btnradio4label')">
              <label class="label-tip-btn btn btn-outline-primary" for="btnradio4" id="btnradio4label">25%</label> -->


            </div>
          </div>
          <div class="modal-footer btn_custom" style="float: left;margin: 0px auto;width: 70%;margin-left: 14px;">
            <input type="radio" class="btn-check-tip" name="btnradio" id="btnradiocustom" autocomplete="off"
              v-model="fields.tipVal" @click.prevent="calculate_tip('custom', 'btnradiocustomlabel')">
            <label class="label-tip-btn btn btn-outline-primary" for="btnradiocustom"
              id="btnradiocustomlabel">Custom</label>
            <span class="tip_icon"
              id="custompercnt">$</span>
            <input type="text" id="customtaxid" class="form-control tips_input" v-on:keypress="AmountOnly"
              @blur="validatecustomtip()" @keyup="calculate_custom_tip($event.target.value, 'btnradiocustomlabel')"
              style="" />
            <button style="width: 31%;" type="button" class="btn add-btn-order" @click="submitOrder()">Done</button>
          </div>
          <div class="ordsummary">
            <span class="clearfix">Tip Amount: ${{ parseFloat(fields.final_tips).toFixed(2) }}</span>
            <span class="clearfix">Total Amount: ${{ parseFloat(new_order_total).toFixed(2) }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>





<script>
export default {
  props: ['restaurant_id'],
  data() {
    return {
      orderslength: 0,
      activeTabInline: null,
      activeTab: null,
      activeMenuTitle: null,
      load_menu_items: false,
      menu_items: {},
      menu_categories: [],
      register_restaurant_order_id: '',
      neworderregisteritems: [],
      new_order_total: 0,
      new_taxtotl: 0,
      new_platformfee: 0,
      new_menu_item: {
        title: '',
        description: '',
        price: '',
        quantity: 1,
        user_id: '',
        specialrequest: '',
        inventory: 0
      },
      firstName: '',
      lastName: '',
      phoneNumber: '',
      validation_errors: {},
      error: '',
      errors: {},

      edit_menu: {
        menu_item: {
          title: '',
          description: '',
          price: '',
          quantity: 1,
          user_id: '',
          specialrequest: '',
          inventory: 0
        },
        menu_item_arry: '',
        cartItms: '',
        menuItmInx: '',

      },
      selectedseatid: '',
      stindex: '',
      fields: {
        final_cart_total: 0,
        final_tips: 0,
        tipVal: 0,
        tipType: '',
      },
      discounttitle: '',
      is_discount: false,
      discount_amt: '',
      subtotal: 0,
      cart_item_total: 0,
      menu_cat_discounts_title: '',
    }
  },

  created: function () {
    //this.register_new_order();

  },
  mounted() {
  },
  methods: {
    handleScroll() {
  const modalContent = document.querySelector('#menuItemModal_ref .modal-content');
  const contentSections = modalContent.querySelectorAll('.page');
  const scrollPosition = modalContent.scrollTop;

  // Loop through each section and update the active navbar item
  contentSections.forEach((section, index) => {
    const sectionTop = section.offsetTop;
    const sectionHeight = section.clientHeight;

    // Check if the scroll position is within the bounds of the current section
    if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
      // Update the activeIndex to the current section
      this.activeIndex = index;

      // Update the active class of the corresponding navbar item
      const navbarItems = document.querySelectorAll('#menuItemModal_ref .navbar-item');
      navbarItems.forEach((item, itemIndex) => {
        if (index === itemIndex) {
          item.classList.add('active');
        } else {
          item.classList.remove('active');
        }
      });
    }
  });
}
,
    register_new_order() {
      let vm = this;
      let formData = new FormData();
      formData.append("restaurant_id", vm.restaurant_id);
      axios.post('/owner/orders/register-new-order', formData).then(response => {
        vm.register_restaurant_order_id = response.data.response.newOrder.id;
      }).catch(error => {
      });
    },
    showMenuItems: function (event, menucatid) {
      this.menu_cat_discounts_title = '';
      this.load_menu_items = true;
      this.menu_items = {};
      this.activeMenuTitle = event.target.getAttribute('data-menu');
      this.activeTab = menucatid;
      this.activeTabInline = menucatid;
      let menuData = new FormData();
      menuData.append("menu_category_id", menucatid);
      menuData.append("qrtoken_id", this.register_restaurant_order_id);
      menuData.append("order_type", 'onsitetogo');
      menuData.append("restaurant_id", this.restaurant_id);
      axios.post('/owner/orders/get-restaurant-menu-items', menuData).then(response => {
        this.load_menu_items = false;
        this.menu_items = response.data.response.menu_items;
        if (response.data.response.discounts.menu_cat_discounts != null) {
          this.menu_cat_discounts_title = response.data.response.discounts.menu_cat_discounts.title;
        }
        $('#close_toggle_nav').click();
      }).catch(error => {
      });
    },
    openMemus() {
      let vm = this;
      vm.menu_cat_discounts_title = '';
      $("#openMemus").addClass('d-block');
      $("#getUserDetails").addClass('d-none');
      $('#menuItemModal_ref').modal('show');

      $('#menuItemModal_ref .modal-content').on('scroll', vm.handleScroll);

      
      let formData = new FormData();
      formData.append("restaurant_id", vm.restaurant_id);
      axios.post('/owner/orders/get-menu-categories', formData).then(response => {
        vm.menu_categories = response.data.response.categories;
        vm.activeTab = response.data.response.categories[0].id;
        vm.activeTabInline = response.data.response.categories[0].id;
        vm.activeMenuTitle = response.data.response.categories[0].title;
        formData.append("menu_category_id", response.data.response.categories[0].id);
        formData.append("order_type", 'onsitetogo');
        formData.append("restaurant_id", vm.restaurant_id);
        axios.post('/owner/orders/get-restaurant-menu-items', formData).then(response => {
          vm.menu_items = response.data.response.menu_items;
          if (response.data.response.discounts.menu_cat_discounts != null) {
            vm.menu_cat_discounts_title = response.data.response.discounts.menu_cat_discounts.title;
          }
          vm.handleScroll();
        });
      }).catch(error => {
      });
    },

    showMenuItem: function (item) {
      console.log("sdfsdfs", item);
      let vm = this;

      let formData = new FormData();
      formData.append("restaurant_id", vm.restaurant_id);
      formData.append("menu_item_id", item.id);
      axios.post('/owner/orders/get-menuitem-cartcnt', formData).then(response => {
        console.log("ggg", response);
      });
      $('#reg_viewItem').modal('show');
      vm.new_menu_item.title = item.title;
      vm.new_menu_item.description = item.description;
      vm.new_menu_item.price = item.price;
      vm.new_menu_item.id = item.id;
      vm.new_menu_item.inventory = item.inventory;
    },
    ValidateRegisterOrder() {
      let vm = this;
      $('#addiorderfrm').parsley().whenValidate({

      }).done(function () {
        vm.addOrderToRegisterSeat();
      });
    },
    addOrderToRegisterSeat: function () {
      let vm = this;
      vm.validation_errors = {};
      vm.error = '';
      $('#add_order').prop('disabled', true);
      let menuData = new FormData();
      menuData.append("menu_item_id", vm.new_menu_item.id);
      menuData.append("quantity", vm.new_menu_item.quantity);
      menuData.append("specialrequest", vm.new_menu_item.specialrequest);
      menuData.append("register_restaurant_order_id", vm.register_restaurant_order_id);
      menuData.append("restaurant_id", vm.restaurant_id);

      /*if(vm.new_menu_item.quantity > vm.new_menu_item.inventory){
          alert('The quantity is higher than the maximum allowed quantity');
          $('#add_order').prop('disabled',false);
          $('#add_order').text("Add to order");
          return false;
        }*/

      axios.post('/owner/orders/add-menu-to-register-order', menuData).then(response => {
        console.log(response.data.response.menu_added);
        // if(response.data.response.newmenu == 1){
        //     vm.neworderregisteritems.push(response.data.response.menu_added);
        // }
        vm.neworderregisteritems = response.data.response.cartList;
        vm.new_order_total = response.data.response.order_total;
        vm.subtotal = response.data.response.subtotal;
        vm.final_cart_total = response.data.response.order_total;
        vm.cart_item_total = response.data.response.cart_item_total
        if (response.data.response.discounted_amt_tol > 0) {
          vm.discounttitle = response.data.response.discount_title;
          vm.is_discount = true;
          vm.discount_amt = response.data.response.discounted_amt_tol;
        } else {
          vm.discounttitle = '';
          vm.is_discount = false;
          vm.discount_amt = 0;
        }
        vm.new_taxtotl = response.data.response.taxtotl;
        vm.new_platformfee = response.data.response.platformfee;

        vm.new_menu_item.specialrequest = '';
        vm.new_menu_item.quantity = 1;
        $('#reg_viewItem').modal('hide');
        $('#add_order').prop('disabled', false);

      }).catch(error => {
      });

    },

    increase_new: function () {
      let vm = this;
      vm.new_menu_item.quantity++;
    },
    decrease_new: function () {
      let vm = this;
      if (vm.new_menu_item.quantity > 1) {
        vm.new_menu_item.quantity--;
      }
    },


    editItem(itmmenu, cartItms, menuItmInx) {
      console.log("sdfsdfs", itmmenu.cart_addedmenuitem);
      let vm = this;
      $('#editMenuItem').modal('show');
      vm.edit_menu.menu_item.title = itmmenu.menuitem.title;
      vm.edit_menu.menu_item.description = itmmenu.menuitem.description;
      vm.edit_menu.menu_item.price = itmmenu.menuitem.price;
      vm.edit_menu.menu_item.quantity = itmmenu.quantity;
      vm.edit_menu.menu_item.id = itmmenu.id;
      vm.edit_menu.menu_item.inventory = itmmenu.menuitem.inventory;

      vm.edit_menu.menu_item_arry = itmmenu;
      vm.edit_menu.cartItms = cartItms;
      vm.edit_menu.menuItmInx = menuItmInx;
    },
    validateEditItem(itmmenu) {
      let vm = this;
      $('#editorderfrm').parsley().whenValidate({

      }).done(function () {
        vm.saveEditItem();
      });
    },
    saveEditItem() {
      let vm = this;
      vm.validation_errors = {};
      vm.error = '';
      $('#edit_item').prop('disabled', true);
      if (vm.edit_menu.menu_item.quantity > 0) {
        let formData = new FormData();
        formData.append("u_menu_item", JSON.stringify(vm.edit_menu.menu_item));
        formData.append("restaurant_id", vm.restaurant_id);

        /*if(vm.edit_menu.menu_item.quantity > vm.edit_menu.menu_item.inventory){
          alert('The quantity is higher than the maximum allowed quantity');
          $('#edit_item').prop('disabled',false);
          $('#edit_item').text("Add to order");
          return false;
        }*/

        axios.post('/owner/orders/edit-menuitem-of-register-order', formData).then(response => {
          vm.edit_menu.cartItms[vm.edit_menu.menuItmInx] = response.data.response.cart_updated_item;

          vm.new_order_total = response.data.response.order_total;
          vm.subtotal = response.data.response.subtotal;
          vm.cart_item_total = response.data.response.subtotal;
          vm.final_cart_total = response.data.response.order_total;
          if (response.data.response.discounted_amt_tol > 0) {
            vm.discounttitle = response.data.response.discount_title;
            vm.is_discount = true;
            vm.discount_amt = response.data.response.discounted_amt_tol;
          } else {
            vm.discounttitle = '';
            vm.is_discount = false;
            vm.discount_amt = 0;
          }
          vm.new_taxtotl = response.data.response.taxtotl;
          vm.new_platformfee = response.data.response.platformfee;
          $('#edit_item').prop('disabled', false);
          $('#editMenuItem').modal('hide');

        }).catch(error => {
        });
      } else {
        this.removeItem(vm.edit_menu.menu_item.id, vm.edit_menu.cartItms, vm.edit_menu.menuItmInx);
        $("#editMenuItem .close").click();
      }

    },
    /*remove item of  existing seat */
    removeItem(itmId, cartItms, menuItm) {
      let vm = this;
      if (confirm("Do you really want to remove?")) {
        axios.delete('/owner/orders/remove-menuitem-of-register-order/' + itmId).then(response => {
          Vue.delete(cartItms, menuItm);
          console.log(response.data.response);
          vm.new_order_total = response.data.response.order_total;
          vm.subtotal = response.data.response.subtotal;
          vm.cart_item_total = response.data.response.subtotal;
          vm.final_cart_total = response.data.response.order_total;
          if (response.data.response.discounted_amt_tol > 0) {
            vm.discounttitle = response.data.response.discount_title;
            vm.is_discount = true;
            vm.discount_amt = response.data.response.discounted_amt_tol;
          } else {
            vm.discounttitle = '';
            vm.is_discount = false;
            vm.discount_amt = 0;
          }
          vm.new_taxtotl = response.data.response.taxtotl;
          vm.new_platformfee = response.data.response.platformfee;
        }).catch(error => {
        });
      }
    },
    getUserDetails() {
      let vm = this;
      if (this.firstName) {
        $("#openMemus").addClass('d-block');
        // $("#cardId").addClass('d-none');
        $("#getUserDetails").addClass('d-none');
        let formData = new FormData();
        //formData.append("register_restaurant_order_id", this.register_restaurant_order_id);
        formData.append("restaurant_id", vm.restaurant_id);
        formData.append("firstname", vm.firstName);
        formData.append("last_name", vm.lastName);
        formData.append("phone", vm.phoneNumber);

        axios.post('/owner/orders/register-new-order', formData).then(response => {
          console.log("dfdfd", response.data.response.newOrder.id);
          vm.register_restaurant_order_id = response.data.response.newOrder.id;
          this.openMemus();
        }).catch(error => {
        });
        // axios.post('/owner/orders/update-register-new-order',formData).then(response => {
        //
        // }).catch(error => {
        // });

      } else {
        alert("Please enter first name");
        return false;
      }

    },
    increase: function () {
      let vm = this;
      vm.edit_menu.menu_item.quantity++;
    },
    decrease: function () {
      let vm = this;
      if (vm.edit_menu.menu_item.quantity > 0) {
        vm.edit_menu.menu_item.quantity--;
      }
    },
    calculate_tip(tipcal, clsname) {
      //alert(this.fields.final_cart_total)
      if (tipcal == 'custom') {
        $('#btnradiocustomlabel').hide();
        $('#customtaxid').show();
        let cal_final_tips = ((this.cart_item_total / 100) * 15).toFixed(2);
        $('#customtaxid').val(cal_final_tips);
        $('#customtaxid').focus();
        $('#custompercnt').show();
        tipcal = 15;
      } else {
        $('#customtaxid').val('')
        $('#customtaxid').hide();
        $('#btnradiocustomlabel').show();
        $('#custompercnt').hide();
        tipcal = tipcal;
      }
      this.fields.final_tips = 0;
      let cal_final_tips = (this.cart_item_total / 100) * tipcal;
      this.fields.final_tips = cal_final_tips;
      $('.label-tip-btn').removeClass('check-active');
      $('#' + clsname).addClass('check-active');
      this.fields.tipVal = tipcal;
      
      this.fields.tipType = 'perc';
      this.new_order_total = parseFloat(this.final_cart_total) + parseFloat(this.fields.final_tips)
    },
    calculate_custom_tip(tipcal, clsname) {
      //alert("hi");
      //alert(tipcal)
      if ($('#customtaxid').val() == '' || $('#customtaxid').val() == 0) {
        //  $('#customtaxid').val(0)
        this.fields.tipVal = 0;
        tipcal = 0;
      }
      this.fields.final_tips = 0;

      this.fields.final_tips = tipcal;
      $('.label-tip-btn').removeClass('check-active');
      $('#' + clsname).addClass('check-active');
      this.fields.tipVal = tipcal;
      this.fields.tipType = 'amount';
      this.new_order_total = parseFloat(this.final_cart_total) + parseFloat(this.fields.tipVal)
    },
    validatecustomtip() {
      if ($('#customtaxid').val() == '') {
        $('#customtaxid').val(0)
      }
    },
    AmountOnly(evt) {

      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },

    openAddTipModal() {
      $('#orderTipModal').modal('show');
      this.calculate_tip(15, 'btnradio2label');
    },
    submitOrder() {
      let formData = new FormData();
      formData.append("register_restaurant_order_id", this.register_restaurant_order_id);
      formData.append("restaurant_id", this.restaurant_id);
      formData.append("tip_perc", this.fields.tipVal);
      formData.append("tip_type", this.fields.tipType);
      axios.post('/owner/orders/update-register-new-order', formData).then(response => {
        window.location.href = "/owner/onsite_togo_orders/make-payment/" + this.register_restaurant_order_id;

      }).catch(error => {
      });

    },
    closeTipModal() {
      this.fields.tipVal = 0;
      this.fields.tipType = '';
      this.fields.final_tips = 0;
      this.new_order_total = parseFloat(this.final_cart_total) + parseFloat(this.fields.final_tips)
    }


  },

  filters: {
  },

  computed: {
  }

}
</script>
