<template>
  <div>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close handheld-close-popup" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <form method="POST" id="signup3form" name="signup3form"  data-parsley-validate >
          <div class="patron_signup_item create-account-diner">
            <fieldset class="m-3" v-if="step == 1">
              <h4>Create Account</h4>
              <p>Create a new user and add you're on the way.</p>
              <div >
                <input type="email" name="form_field" v-model="fields.email" class="form-poup"
                  placeholder="Email Address" data-parsley-required-message="Please enter email address"  required data-parsley-group='step-1'>
                <input type="password" name="form_field" v-model="fields.password" id="pp_password"  class="form-poup"
                    placeholder="Password" data-parsley-required-message="Please enter password"  required  data-parsley-group='step-1'>
                <input type="password" name="form_field" v-model="fields.confirm_password"  class="form-poup"
                        placeholder="Confirm password" data-parsley-required-message="Please enter confirm password"
                        data-parsley-equalto-message="Confirm Password should be same as password" data-parsley-equalto="#pp_password"
                        required  data-parsley-group='step-1'>
                <input type="text" name="form_field" v-model="fields.first_name" class="form-poup"
                        placeholder="First Name" data-parsley-required-message="Please enter first name"  required  data-parsley-group='step-1'>
                        <input type="text" name="form_field" v-model="fields.last_name" class="form-poup"
                                placeholder="Last Name" data-parsley-required-message="Please enter last name"  required  data-parsley-group='step-1'>
                <input type="text" name="form_field" v-model="fields.billing_address" class="form-poup"
                                placeholder="Billing Address" data-parsley-required-message="Please enter Billing Address" required required data-parsley-group='step-1'>

                
                <select class="form-poup" data-parsley-required-message="Please select city" v-model="fields.city" required data-parsley-group='step-1'>
                  <option value="" selected>-- Select City --</option>
                  <option v-for='city in cities' :value='city.id'>{{ city.city }}</option>
                </select>
                <select class="form-poup" data-parsley-required-message="Please select state" v-model="fields.state" required data-parsley-group='step-1' @change='getCities()'>
                  <option value="" selected>-- Select State --</option>
                  <option v-for="state in states"  :value="state.id"> {{ state.state_name }}
                  </option>
                </select>
                <input type="text" name="form_field" v-model="fields.billing_zipcode"  class="form-poup"
                  placeholder="Billing Zip code" data-parsley-required-message="Please enter billing zip code" v-on:keyup="removeCharForminput1($event)" pattern="[0-9]+" maxlength="5" minlength="5"  required data-parsley-group='step-1'>

              </div>

            </fieldset>


            <fieldset class="m-3" v-if="step == 2">

              <div class="row">
                <div class="col-md-12">
                  <div class="congrates-popup-acount-created">
                    <span><i class="fas fa-check-circle"></i></span>
                    <h2>Congrats</h2>
                    <p>You account has been created.</p>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="clearfix review-your-order-heading">
                    <h4 class="float-left">Review your order</h4>
                    <button class="float-right btn" @click.prevent="backTocart">Edit</button>
                  </div>
                </div>

                <div class="col-md-12">
                   <div v-for="(item,index) in cartdatalist" class="clearfix menu-list-review-list">
                      <h4 class="float-left">{{item.menuitem.title}} </h4>
                      <span class="float-right">${{item.quantity * item.menuitem.price}}</span>
                    </div>
                    <div class="clearfix menu-list-review-list">
                     <h4 class="float-left">Tips </h4>
                     <span class="float-right">${{parseFloat(tipfinval).toFixed(2)}}</span>
                   </div>
                     <div class="clearfix menu-list-review-list">
                      <h4 class="float-left">Taxes & Fees </h4>
                      <span class="float-right">${{parseFloat(taxfinval).toFixed(2)}}</span>
                    </div>
                    <div class="clearfix menu-list-review-list">
                     <h4 class="float-left">{{platformfeelabel}} </h4>
                     <span class="float-right">${{parseFloat(platformfeefinal).toFixed(2)}}</span>
                   </div>


                </div>

                <div class="col-md-12">
                  <div class="clearfix total-your-order-review">
                    <h4 class="float-left">Total</h4>
                    <span class="float-right">$ {{parseFloat(finalcarttotal).toFixed(2)}}</span>
                  </div>
                  <!-- <div class="clearfix total-your-payment-method">
                    <h4 class="float-left">Payment Method</h4>
                    <span class="float-right">********1234</span>
                  </div> -->

                  <button class="btn btn-pay-now" @click.prevent="gotopayment()">PAY NOW</button>
                </div>

              </div>




            </fieldset>

            <div class="clearfix">
            <!-- <button v-if="showprev" @click.prevent="prev()" class="btn btn-next float-left" >Previous</button> -->
            <!-- <button v-if="step == 1" @click.prevent="next()" class="btn btn-next float-right" >Next</button> -->
            <button v-if="step == 1" @click.prevent="submit()" class="btn btn-create " id="saveBtn">Create Account</button>
            </div>


          </div>
        </form>

      </div>
    </div>




  </div>
</template>
<script src="{{asset('js/frontend/parsley.js')}}" type="text/javascript"></script>
<script>

export default {
props: ['country_id','cartdatalist','finalcarttotal','tipfinval','taxfinval','finvibereward','platformfeelabel','platformfeefinal'],
data() {
    return {
      step: 1,
      fields: {
        name:'',
        email:'',
        password:'',
        confirm_password:'',
        city:'',
        state:'',
        billing_address:'',
        billing_zipcode:'',
        paymentForm:1
      },

      showfinalcheckout:false,
      states:{},
      cities:{},
      showprev:false,
      errors: {},
      success: false,
      isLoading: false,
      loaded: true,
      problem_in_save_error:''
    }
},

created: function () {
  let formData = new FormData();

  formData.append("country_id", this.country_id);
  axios.post('/get-states',formData).then(response => {
    this.states = response.data.response.states;
  }).catch(error => {

  });


},
methods:{
  removeCharForminput1(ev){
    var pv = ev.target.value;
    if (pv.match(/[^0-9]/g) !== null) {
        ev.target.value ='';
    }
  },
  getCities: function() {
    let vm = this;
    let formData = new FormData();
    formData.append("state_id", vm.fields.state);
    axios.post('/get-cities',formData).then(response => {
      vm.cities = response.data.response.cities;
    }).catch(error => {
    });
  },
  prev() {
      this.step--;
  },
  next() {
    let vm = this;
    $('#signup3form').parsley().whenValidate({
      group: 'step-' + this.step
    }).done(function() {
      vm.step++;
      // if(vm.step === 2){
      //   vm.enableprev = false;
      //
      // }
    });
  },



  submit() {
    let vm = this;
    $('#signup3form').parsley().whenValidate({
      group: 'step-' + this.step
    }).done(function() {
      $('#saveBtn').prop('disabled',true);
      axios.post('/patron/register',vm.fields).then(response => {
        vm.step = 2;
        vm.showprev = false;
        vm.enableprev = false;
        vm.form_field =  {};
        vm.showfinalcheckout = true;
        vm.setuserlogin();
      }).catch(error => {
        vm.step = 1;
        console.log(error);
        vm.showprev = true;
        vm.loaded = true;
        console.log(error.response.data.errors);
        console.log(error.response.status);
        if (error.response.status === 400) {
          vm.problem_in_save_error = error.response.data.response.message || {};
          $('#saveBtn').prop('disabled',false);
          vm.isLoading = false;
          vm.enableprev = true;
        }else{
          vm.errors = error.response.data.errors || {};
          $('#saveBtn').prop('disabled',false);
          vm.isLoading = false;
          vm.enableprev = true;
        }
      });
    });
  },
  backTocart : function(){
      $("#signUpStepModalPrimary .close").click();
      this.$emit('setlogin');
  },
  setuserlogin(){
    this.$emit('setlogin');
  },
  gotopayment(){
    $("#signUpStepModalPrimary .close").click();
    this.$emit('setlogin');
  }

},
}
</script>
