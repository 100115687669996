<template>
    <div>
        <div v-for="(item, index) in items" class="card card_new">
          <div class="card-body bdy_new"> 
            <div v-if="item.restaurant_logo" class=" evt_img fd_trk"> 
                <img :src="'/storage/restaurant_logo/'+item.restaurant_logo" />
            </div>
             <div v-else class=" evt_img fd_trk"> 
                <img :src="'/storage/restaurant_logo/noimage.jpg'" />
            </div>
            <div class="att_tlt">
                <h5>{{item.restaurant_name}} </h5>
                <p v-if="item.start_time && item.end_time"><i class="align-middle fas fa-clock"></i> {{item.start_time}} - {{item.end_time}}</p> 
                <span v-if="item.menutype">{{item.menutype}}</span>
            </div>
          </div>                
       </div>

    </div>
</template>
<script>
export default {
    props: ['ids'],
      data(){
      return {
        id: null,
         items:[]
      }
  },
  mounted(){
    this.id = this.ids;
    this.MerchantsAttending();
  },
  watch: {
        ids: function (newIds, oldIds) {
            this.id = newIds;
            this.MerchantsAttending();
        }
    },
  methods: {
    MerchantsAttending() {
        let formData = new FormData();
        formData.append("id", this.id);
        axios.post('/owner/merchants-attending',formData).then(response => {
           this.items = response.data.merchants;
        }).catch(error => {
          console.log(error);
        });
        },
  },
    
}
</script>