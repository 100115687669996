<template>
<div class="row">
  <!-- Current Tables -->
  <div class="col-lg-12">
    <div class="" style="    margin: 15px 0;">
      <div class="clearfix order-card-title-active-employee">
        <h5 class="card-title mb-0 float-left">Who's at work</h5>

        <a class="mb-0 float-right" data-toggle="modal"
          data-target="#addEmpModalPrimary"><i
            class="align-middle fas fa-fw fa-plus" ></i>ADD NEW</a>
      </div>

      <!--  tabs -->
      <div class="row">
        <div class="col-sm-12">
          <div class="servers-waiters">
            <p>Servers & Waiters</p>
          </div>

          <div class="d-flex justify-content-center" >
            <div class="spinner-border text-warning" role="status" id="emp-loader">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <!-- employee-box-->


        <!-- <div v-if="remployees.length == 0" class="empty_employees empty_msg">No Servers & Waiters found.</div> -->

        <div v-if="remployees.length > 0"  class="col-sm-6 col-md-6 col-lg-6 col-xl-4"  v-for="(item,index) in remployees"  >
          <div class="serve-waitress-box" :id="`card-emp-id-${item.id}`" @click="edit_employee(item,index)">
            <span class="loader" >
            <span class="content"><i class="fa fa-spinner fa-spin"></i> Please wait...</span>
            </span>
            <div class="row">
              <div class="col-sm-2 col-md-2 col-2">
                <div class="waiter-avtar-active-employee">
                  <template v-for="empval in item.restraurant_employee_attributes">
                    <template v-if="empval.name == 'profile_photo'">
                      <img v-if="empval.pivot.value !=''" :src="'/storage/employee_pictures/'+empval.pivot.value" alt="waiters-img">
                      <img v-if="empval.pivot.value==''" src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" alt="">
                    </template>
                  </template>
                  <img v-if="item.restraurant_employee_attributes == ''" src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" alt="">
                </div>
              </div>
              <div class="col-sm-10 col-md-10 col-10">
                <div class="waitres-details">
                  <h4>{{item.user.name}}</h4>
                  <p>
                    (<small>{{item.user.roles[0].name}}</small>)
                  </p>
                  <p>{{item.employee_present_hours}} <strong>${{item.employee_earning}}</strong></p>
                  <div class="emplist">
                  <star-rating :rating="item.employee_ratings" :read-only="true" :increment="0.01" :star-size="20"   :show-rating="false"></star-rating>
                  </div>
                  <!-- <div class="waitres-star-total-rating">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                  </div> -->
                </div>
              </div>

            </div>
          </div>
        </div>
        <div v-else class="empty_employees empty_msg" id="emptyemp">
          Loading...
        </div>
        <!-- rating-1 -->

        <!-- rating-1 -->


      </div>
    </div>
    <div class="modal fade" id="addEmpModalPrimary" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title add-employee-popup">Add Employee</h5>
            <button type="button" class="close" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form method="POST" id="addempfrm-form" name="addempfrm" @submit.prevent="submit" data-parsley-validate enctype="multipart/form-data">
          <div class="m-5">
            <label for="">Select Role</label>
            <select name="form_field"  v-model="fields.role" class="form-control mb-2" required>
              <option value="">Select</option>
              <option :value="roleitem.id" v-for="roleitem in role_list">{{roleitem.name}}</option>
            </select>
             <label for="">Employee First Name</label>
            <input type="text" name="form_field" class="form-poup" placeholder="Employee First Name" v-model="fields.first_name" required>
            <label for="">Employee Last Name</label>
           <input type="text" name="form_field" class="form-poup" placeholder="Employee Last Name" v-model="fields.last_name" required>

             <label for="">Email</label>
            <input type="email" name="form_field" class="form-poup" placeholder="Email" v-model="fields.email"  required>
            <label for="">Phone Number</label>
            <input type="text" name="form_field" class="form-poup" placeholder="Phone Number" v-model="fields.phone_number1"  required>
             <label for="">Employee ID</label>
            <input type="text" name="form_field" class="form-poup" placeholder="Employee ID" v-model="fields.employee_id">
             <label for="file" class="btn">Add Picture</label>
            <input type="file" ref="fileuploadadd" name="picture" class="form-poup new-type-file" id="picture" accept="image/x-png,image/jpeg" @change="onFileChange">
            <button @click.prevent="add()" class="add-btn add-emp-btn" id="save_emp">Save</button>
          </div>
          <div class="row text-center">
            <div v-if="errors" class="text-danger col-12">
              <div v-for="(v, k) in errors" :key="k">
                <p v-for="error in v" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>
            </div>
            <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
              <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                {{ problem_in_save_error }}
              </p>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>


    <div class="modal fade" id="editEmployeePrimary" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title add-employee-popup">Update Employee</h5>
            <button type="button" class="close" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form method="POST" id="editempfrm-form" name="editempfrm" @submit.prevent="submit_update" data-parsley-validate enctype="multipart/form-data">
          <div class="m-5">
            <input type="text" name="form_field" class="form-poup" placeholder="Employee First Name" v-model="editfields.first_name" required>
            <input type="text" name="form_field" class="form-poup" placeholder="Employee Last Name" v-model="editfields.last_name" required>
            <input type="email" name="form_field" class="form-poup" placeholder="Email" v-model="editfields.email"  required>
            <input type="text" name="form_field" class="form-poup" placeholder="Phone number1" v-model="editfields.phone_number1"  required>
            <input v-if="editfields.employee_id" type="text" name="form_field" class="form-poup" placeholder="Employee ID" v-model="editfields.employee_id">
            <input type="file" name="picture" accept="image/x-png,image/jpeg" ref="fileupload" class="form-poup new-type-file" id="picture" @change="onEditFileChange">
            <div class="clearfix">
              <button v-if="not_assigned" @click.prevent="delete_employee" :data-id="emp_id"  class="btn remove-btn float-left" >Delete</button>
              <button @click.prevent="edit()" class="btn add-btn float-right" id="edit_emp">Save</button>
            </div>

          </div>
          <div class="row text-center">
            <div v-if="errors" class="text-danger col-12">
              <div v-for="(v, k) in errors" :key="k">
                <p v-for="error in v" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>
            </div>
            <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
              <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                {{ problem_in_save_error }}
              </p>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>

    export default {

    data() {
        return {
            remployees: {},
            restaurant_id:'',
            message:'',
            fields: {
            role:'',
            first_name:'',
            last_name:'',
            email:'',
            profile_photo:'',
            phone_number1:'',
            employee_id:'',
            restaurant_id:'',
            },
            editfields: {
            first_name:'',
            last_name:'',
            email:'',
            profile_photo:'',
            phone_number1:'',
            employee_id:'',
            restaurant_id:'',
            id:''
            },
            role_list:{},
            errors: {},
            iserrors: false,
            success: false,
            isLoading: false,
            loading:false,
            problem_in_save_error:'',
            editindex:'',
            emp_id:'',
            not_assigned: true
        }
    },

    created: function () {
      axios.get('/owner/restaurant_employee/getEmployees').then(response => {
      this.remployees = response.data.restaurantEmployees.employee_detail;
      this.fields.restaurant_id = response.data.restaurant_id;
      this.role_list = response.data.role_list;
      $('#emp-loader').hide();
      if(this.remployees.length == 0){
        $('#emptyemp').text('No Servers & Waiters found.');
      }
      }).catch(error => {

      });
    },
    methods:{
      onFileChange(event){
        this.fields.picture = event.target.files[0];
        this.fields.profile_photo = this.fields.picture.name;
      },
      onEditFileChange(event){
        this.editfields.picture = event.target.files[0];
        this.editfields.profile_photo = this.editfields.picture.name;
      },
      add() {
        let vm = this;
        $('#addempfrm-form').parsley().whenValidate({

        }).done(function() {
        vm.submit();
        });

      },
      submit() {
        let vm = this;
        $('#save_emp').prop('disabled',true);
          vm.success = false;
          vm.errors = {};
          let formData = new FormData();
          if(this.fields.profile_photo !=''){
            formData.append("picture", this.fields.picture);
          }

          if(this.fields.profile_photo !=''){
            formData.append("profile_photo", this.fields.profile_photo);
          }


          formData.append("first_name", this.fields.first_name);
          formData.append("last_name", this.fields.last_name);
          formData.append("email", this.fields.email);
          formData.append("phone_number1", this.fields.phone_number1);
          formData.append("employee_id", this.fields.employee_id);
          formData.append("role", this.fields.role);
          formData.append("active", 1);

          $('#save_emp').text('Please Wait...');
          axios.post('/owner/restaurant_employee/add', formData).then(response => {
            vm.fields = {}; //Clear input fields.

            vm.success = true;
            $("#addEmpModalPrimary .close").click();
            toastr.success('Employee added successfully.');
            this.remployees.push(response.data.response.restaurantEmployee.employee_detail);
            this.fields.restaurant_id = response.data.response.restaurant_id;
            $('#save_emp').prop('disabled',false);
            this.fields.picture = '';
            this.fields.profile_photo = '';
            this.$refs.fileuploadadd.value=null;
            $('#save_emp').text('Save');
          }).catch(error => {
            $('#save_emp').prop('disabled',false);
            $('#save_emp').text('Save');
            if (error.response.status === 400) {
              vm.problem_in_save_error = error.response.data.response.message || {};
            }
            else if (error.response.status === 422) {
              vm.errors = error.response.data.errors;
            }else{
              vm.errors = error.response.data.response.message || {};
            }
          });
      },
      edit_employee: function (item,index) {
        let vm = this;
        vm.not_assigned = true;
        console.log(index,item);
        $('#editEmployeePrimary').modal('show');
        vm.editfields.first_name = item.user.first_name;
        vm.editfields.last_name = item.user.last_name;
        vm.editfields.email = item.user.email;
        let phone_number_val;
        let employee_id_val;
        item.restraurant_employee_attributes.forEach((v,i)=>{
          if(v.name == 'phone_number1'){
            phone_number_val = v.pivot.value;
          }
          if(v.name == 'employee_id'){
            employee_id_val = v.pivot.value;
          }
        })
        vm.editfields.phone_number1 = phone_number_val;
        vm.editfields.employee_id = employee_id_val;

        vm.editfields.id = item.id;
        vm.editindex = index;
        vm.emp_id = item.id;

        if(item.employee_tables.length > 0 && item.employee_tables[0].id != ''){
          vm.not_assigned = false
        }

      },
      edit() {
        let vm = this;
        $('#editempfrm-form').parsley().whenValidate({

        }).done(function() {
        vm.submit_update();
        });

      },
      submit_update() {
        let vm = this;
        $('#edit_emp').prop('disabled',true);
        $('#edit_emp').text('Please Wait...');
          vm.success = false;
          vm.errors = {};
          let formData = new FormData();
          if(this.editfields.profile_photo !=''){
            formData.append("picture", this.editfields.picture);
          }

          formData.append("profile_photo", this.editfields.profile_photo);
          formData.append("first_name", this.editfields.first_name);
          formData.append("last_name", this.editfields.last_name);
          formData.append("email", this.editfields.email);
          formData.append("phone_number1", this.editfields.phone_number1);
          formData.append("employee_id", this.editfields.employee_id);
          formData.append("id", this.editfields.id);

          axios.post('/owner/restaurant_employee/update', formData).then(response => {
            vm.success = true;
            $("#editEmployeePrimary .close").click();
            $('#card-emp-id-'+response.data.response.restaurantEmployee.employee_detail.id).addClass('loading-spin');
            setTimeout( () => {
              this.remployees.splice(this.editindex, 1, response.data.response.restaurantEmployee.employee_detail);
              $('#card-emp-id-'+response.data.response.restaurantEmployee.employee_detail.id).removeClass('loading-spin');
            }, 5000);

            $('#edit_emp').prop('disabled',false);
            toastr.success('Employee updated successfully.');
            $('#edit_emp').text('Save');

            this.editfields.picture = '';
            this.editfields.profile_photo = '';
            this.editfields.first_name = '';
            this.editfields.last_name = '';
            this.editfields.email = '';
            this.editfields.phone_number1 = '';
            this.editfields.employee_id = '';
            this.$refs.fileupload.value=null;

            if(response.data.response.restaurantEmployee.employee_detail.employee_tables.length > 0 && response.data.response.restaurantEmployee.employee_detail.employee_tables[0].id != ''){
              this.not_assigned = false
            }

          }).catch(error => {
            console.log(error);
            $('#edit_emp').prop('disabled',false);
            $('#edit_emp').text('Save');
            if (error.response.status === 400) {
              vm.problem_in_save_error = error.response.data.response.message || {};
            }
            else if (error.response.status === 422) {
              vm.errors = error.response.data.errors;
            }else{
              vm.errors = error.response.data.response.message || {};
            }
          });
      },
      delete_employee: function (event,item) {
        let vm = this;
        var id = event.target.getAttribute('data-id');
        if (id) {
        if(confirm("Do you really want to delete?")){
          $('.remove-btn').prop('disabled',true);
          let formData = new FormData();

          formData.append("id", id);
            axios.post('/owner/restaurant_employee/destroy', formData).then(response => {
              setTimeout( () => {
                $("#editEmployeePrimary .close").click();
                //this.remployees.splice(this.remployees.indexOf(item), 1);
                Vue.delete(this.remployees, this.editindex);
                toastr.success('Employee deleted successfully.');
                $('.remove-btn').prop('disabled',false);
              }, 3000);

            }).catch(error => {
              $('.remove-btn').prop('disabled',false);
            });
        }
        }
      },
  }


    }
</script>
