window._ = require('lodash');
window.Swal = require('sweetalert2');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

try {
    const originalToLocaleString = Date.prototype.toLocaleString;

    // Override the toLocaleString method
    // Date.prototype.toLocaleString = function (locales, options) {
    //     const timezone = window.appTimezone || 'America/New_York'; // Use dynamic timezone from Laravel
    //
    //     // Merge the timezone option with the existing options
    //     return originalToLocaleString.call(this, locales, {
    //         timeZone: timezone,
    //         ...options,
    //     });
    // };

    // Save the original toLocaleDateString method
    const originalToLocaleDateString = Date.prototype.toLocaleDateString;

    // Override the toLocaleDateString method
    Date.prototype.toLocaleDateString = function (locales, options) {
        const timezone = window.appTimezone || 'America/New_York'; // Use dynamic timezone from Laravel

        // Ensure we are not creating an infinite loop by calling the original method
        return originalToLocaleDateString.call(this, locales, {
            timeZone: timezone,
            ...options,
        });
    };

    (function() {
        // Save the original Date constructor
        const OriginalDate = Date;

        // Custom Date constructor
        function CustomDate(...args) {
            if (args.length === 0) {
                // If no arguments, return the current date and time in the specified timezone
                return new OriginalDate();
            }

            if (args.length === 1 && typeof args[0] === 'string') {
                const dateString = args[0];
                // If the input is a date string in the format 'YYYY-MM-DD', treat it as the specified timezone
                if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
                    return createDateInTimezone(dateString);
                }
            }

            // For all other uses, fallback to the original Date constructor
            return new OriginalDate(...args);
        }

        // Generic function to create a date object for the specified timezone based on 'YYYY-MM-DD'
        function createDateInTimezone(dateString) {
            const [year, month, day] = dateString.split('-').map(Number);

            // Here we assume -04:00 for EDT and -05:00 for EST, you can enhance this based on DST logic
            const timezoneOffset = window.appTimezone === 'America/New_York' ? '-04:00' : '-05:00';

            // Create a date string that includes the time as midnight in the specified timezone
            const timezoneDateString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T00:00:00${timezoneOffset}`;

            // Parse the date string with the offset for the specified timezone
            return new OriginalDate(timezoneDateString);
        }

        // Copy static methods and properties from the original Date constructor
        CustomDate.now = OriginalDate.now;
        CustomDate.UTC = OriginalDate.UTC;
        CustomDate.parse = OriginalDate.parse;
        CustomDate.prototype = OriginalDate.prototype;

        // Replace the global Date constructor with the custom one
        window.Date = CustomDate;
    })();

} catch (e) {
    console.error('Error overriding Date constructor:', e);
}


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
});

//Pusher.logToConsole = true;
