<template>
  <div>
    <div allowFullScreen="true" id="card-scan-element">
      <button style="margin-top: 12px;margin-left: 9px;" class="btn btn-outline-secondary" @click="scanCard">
        <img :src="'/img/frontend/camera.png '" alt="">SCAN
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['restaurant_id','qrtokenid','userid'],
  data() {
      return {
        platform_fee_label:'',
        connaccid:'',
        paymntkeyval:'',
        tilledobj: "",
        tilledform:"",
        payment_intent_client_secret:'',
        ordid:'',
        payment_method_id:'',
        sandbox_mode_status:'',
        cardScanLoaded: null 
      }
  },
  async mounted() {
      $('#makepaymentbtn').prop('disabled',true)
      let postData = new FormData();
      postData.append("restaurant_id", this.restaurant_id);
      await axios.post('/api/restaurant-settings',postData).then(response => {
        this.connaccid = response.data.data.accountId;
        this.paymntkeyval = response.data.data.tilled_p_key;
        this.sandbox_mode_status = response.data.data.sandbox_mode_status;
      });

      var account_id = this.connaccid;
      var pk_PUBLISHABLE_KEY = this.paymntkeyval;
      const tilledAccountId = account_id;

      if(this.sandbox_mode_status == true){
        this.tilledobj = new Tilled(
          pk_PUBLISHABLE_KEY,
          tilledAccountId,
          { sandbox: this.sandbox_mode_status }

          );
      }else{
        this.tilledobj = new Tilled(
          pk_PUBLISHABLE_KEY,
          tilledAccountId
          );
      }
        this.tilledform =  await this.tilledobj.form({
          payment_method_type: 'card',
        });

        // Optional styling of the fields
        const fieldOptions = {
          styles: {
            base: {
              fontFamily: 'Helvetica Neue, Arial, sans-serif',
              color: '#304166',
              fontWeight: '400',
              fontSize: '16px',
            },
            invalid: {
              ':hover': {
                textDecoration: 'underline dotted red',
              }
            },
            valid: {
              color: '#00BDA5',
            },
          },
        };
        // Added card scan element code
         if (this.tilledform.fields) {
            this.tilledform.createField('_cardScanElement').inject('#card-scan-element');
            this.tilledform.fields._cardScanElement.on('cardscanloaded', () => {
              console.log('Card scan loaded');
            });
            this.tilledform.fields._cardScanElement.on('cardscanerror', (error) => {
              console.log('Card Scan error: ' + error?.message);
            });
            this.tilledform.build();
          }
  },

  methods:{
    scanCard() {
      if (this.tilledform.fields && this.tilledform.fields._cardScanElement) {
        const scannedCard = this.tilledform.fields._cardScanElement.getValue();
        if (scannedCard) {
          this.tilledform.fields.cardNumber.setValue(scannedCard.cardNumber);
          this.tilledform.fields.cardExpiry.setValue(scannedCard.cardExpiry);
          this.tilledform.fields.cardCvv.setValue(scannedCard.cardCvv);
        }
      }
    },
  }
}
</script>
<style>
@import 'material-design-icons/iconfont/material-icons.css';
</style>