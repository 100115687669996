<template>
  <div class="container">


    <div class="row">
      <div class="col-md-12">
        <div class="congrates-popup-acount-created">
          <span><i class="fas fa-check-circle"></i></span>
          <h2>Your're all set! {{ order_id }}</h2>
          <p>Feel free to order more if you want!</p>
        </div>
      </div>

      <div class="col-md-12">
        <p>Download the app below and collect points from visiting more restaurants and venues.</p>
        <div class="store-app-img">
          <ul>
            <li><img :src="image_src1" alt="google-store"></li>
            <li><img :src="image_src2" alt="apple-store"></li>
          </ul>
        </div>
      </div>

      <div class="col-md-12">
        <p>Your feedback is valuable to us. Please give your feedback <a @click.prevent="callratingtab">here</a></p>
        <!-- <p>Your feedback is valuable to us. Please give your feedback <a @click.prevent="callratingtab" class="feedback-link-cls">here</a></p> -->
      </div>



    </div>



  </div>

</template>

<script>

    export default {

    props: ['restaurant_id','auth_user','country_id','order_id'],
    data() {
        return {
          fields : {
          },
          image_src1: '/img/frontend/android-store.png',
          image_src2: '/img/frontend/apple-store.png',
          // order_id:this.order_id,

        }
    },
    components:{

    },
    created: function () {

    },
    methods:{
      order_completed_call(){
      //  alert("fjshfsdj");
      },
      callratingtab(){
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
          var clean_uri = uri.substring(0,uri.indexOf("#"));
          window.history.replaceState({},document.title, clean_uri);
          window.location.href += "#rating";
        }else{
          window.location.href += "#rating";
        }
      //  var hash = window.location.hash.substring(1);
      //  window.location.href += "#rating";
        location.reload();
      //  this.$emit('enableallheadtabs','rating');
      //  this.$emit('callmenutab','rating');
      }
    }
}

</script>
