<template>
  <div>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close handheld-close-popup" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <form class="patron_signin_item" method="POST" id="signinform" name="signinform"  >
          <div class="row">
            <div class="col-md-12">
                <div>
                  <h1>Congrats!</h1>
                  <p>
                    Your account has been created.
                  </p>
                </div>
                <div class="receipt-yours-screen">
                  <h2>Review Your Order</h2>
                  <button @click="backTocart" class="backtocart-btn btn">Edit</button>
                </div>
                <hr/>

                <div class="clearfix menu-list-recepit-list">
                  <h4 class="float-left">Tips</h4>
                  <span class="float-right">${{parseFloat(fields.final_tips).toFixed(2)}}</span>
                </div>
                <div class="clearfix menu-list-recepit-list">
                  <h4 class="float-left">Taxes & Fees</h4>
                  <span class="float-right">${{parseFloat(fields.final_tax).toFixed(2)}}</span>
                </div>

            </div>
          </div>
          <div class="new-total-pay-tip" >
            <div class="row">

              <div class="col-md-12">
                <div class="top-tip-sec-bottom">
                  <div class="clearfix">
                    <span class="float-left">Total</span>
                    <span class="float-right">$ {{parseFloat(fields.payable_amount).toFixed(2)}}</span>
                  </div>

                  <button class="bottom-fix-pay-btn btn" >PAY</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  </div>
  </template>

<script>

    export default {

    props: ['cart_data_rows'],
    data() {
        return {
          fields : {
            final_cart_total:0,
            final_tips:0,
            tipVal:15,
            tax_percentage:10,
            final_tax:1,
            vibe_reward:1,
            payable_amount:0
          }
        }
    },

    created: function () {
      this.calculate_tip(15,'btnradio2label');
      this.calculate_tax();
      this.calculate_payable_amount();
    },
    methods:{
      calculate_cart_total(){
        let final_cart_total_arr = [] ;
        let ci;
        for ( ci = 0; ci < this.cartdata.length; ci++) {
          final_cart_total_arr[ci] = this.cartdata[ci].quantity * this.cartdata[ci].menuitem.price;
        }

        let final_cart_total = final_cart_total_arr.reduce((acc, item) => acc + item, 0);
        return final_cart_total;
      },
      calculate_tip(tipcal,clsname){
        this.fields.final_tips = 0;
        //this.fields.final_cart_total = this.calculate_cart_total();
        this.fields.final_cart_total = 1;

        let cal_final_tips =   (this.fields.final_cart_total/100)*tipcal;
        this.fields.final_tips = cal_final_tips.toFixed(2);
        $('.label-tip-btn').removeClass('check-active');
        $('#'+clsname).addClass('check-active');
        this.fields.tipVal = tipcal;
      },
      calculate_tax(){
        //this.fields.final_cart_total = this.calculate_cart_total();
        this.fields.final_cart_total = 1;
        let cal_final_tax =   (this.fields.final_cart_total/100)*this.fields.tax_percentage;
        this.fields.final_tax = cal_final_tax.toFixed(2);

      },
      calculate_payable_amount(){
        let fin_payable_amount = parseFloat(this.fields.final_cart_total) + parseFloat(this.fields.final_tips) + parseFloat(this.fields.final_tax) - parseFloat(this.fields.vibe_reward);

        this.fields.payable_amount =  fin_payable_amount.toFixed(2);
      },
      backTocart : function(){
          this.$emit('backtocart');
      },
      showLoginForm : function(){
        $('#signInModalPrimaryPay').modal('show');
      }
    },
    mounted: function() {

    },
}

</script>
