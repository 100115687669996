<template>
  <div class="container rounded cart">
    <div v-if="cartdata.length == 0">
      Cart is empty. Please add some item from menu.
      <button type="button" class="btn menuitem-btn" @click="callMenuTab">
        View Menu
      </button>
    </div>
    <div v-if="load_order_items" class="d-flex justify-content-center">
      <div class="patron-loader spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="row" v-if="cartdata.length > 0 && showcartlist">
      <div class="col-lg-12 padding-10" id="Div1">
        <div class="tab-list-order-heading">
          <div class="your-order-list-inner clearfix">
            <h3 class="float-left">Your Order</h3>
            <span class="float-right your_order_add_btn" @click="callMenuTab"><i class="fas fa-fw fa-plus"> </i> ADD
              ITEM</span>
          </div>
          <div v-for="(item, index) in cartdata" v-bind:key="item.id" class="tab-order-name-edit-outer">
            <div class="clearfix tab-top-order-name-inner menu_list_img">
              <div class="menu_add_img" v-if="item.menuitem.images != ''">
                <img v-if="item.menuitem.images" :src="'/storage/menu_item_images/original/' + item.menuitem.images
                  " :alt="item.menuitem.title" />

                <span class="float-right add_img_add_btn count_bg"><i class="fas fa-regular fa-xmark"></i>x{{
                  item.quantity
                }}</span>
              </div>

              <div class="menu_add_img null_img" v-else>
                <img v-if="item.menuitem.images" :src="'/storage/menu_item_images/original/' + item.menuitem.images
                  " :alt="item.menuitem.title" />
                <span class="float-right add_img_add_btn count_bg"><i class="fas fa-regular fa-xmark"></i>x{{
                  item.quantity
                }}</span>
              </div>

              <div class="tab-order-name-edit-price">
                <h3 class="float-left">{{ item.menuitem.title }}</h3>
                <p v-if="item.other_data">{{ item.other_data }}</p>

                <span>${{
                  parseFloat(item.quantity * item.menuitem.price).toFixed(2)
                }}</span>
              </div>
              <div class="edit_btn">
                <a @click.prevent="showMenuItemEdit(item, index)">
                  <i class="fas fa-pencil-alt" style="margin-right: 10px"></i>
                  EDIT ITEM
                </a>
              </div>
            </div>
          </div>
          <!-- view item modal-->
          <!---pickup date and time box---->
          <div v-if="preorder == 1" class="tab-order-name-edit-outer" style="border: 2px solid #ff5802;">
           
           <div v-if="selectedItem && Object.keys(selectedItem).length > 0" class="clearfix tab-top-order-name-inner menu_list_img">
             <div class="tab-order-name-edit-price" style="width:100%;">
                <h3 class="float-left"><i class="align-middle fas fa-clock" style="color:#ff5802;"></i>Selected</h3>
               <div class="edit_btn" style="text-align:left;">
                 <div @click="eventdatetime"><i class="align-middle fas fa-calendar-alt"></i> {{selectedItem.start_date}} , {{selectedTime}} </div>
               </div>
             </div>
           </div>
           <div v-else class="clearfix tab-top-order-name-inner menu_list_img">
             <div class="tab-order-name-edit-price" style="width:100%;">
               <h3 class="float-left"><i class="align-middle fas fa-clock" style="color:#ff5802;"></i>Pick up date and time</h3>
               <div class="edit_btn" style="text-align:left;">
                 <div @click="eventdatetime"><i class="align-middle fas fa-calendar-alt"></i> Choose date and time </div>
               </div>
             </div>
           </div>
           
           </div>
         <!--End pickup date and time box---->

          <div class="modal fade" id="viewItemEdit" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <button type="button" class="close handheld-close-popup" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <form method="POST" id="editiorderfrm" name="editiorderfrm" @submit.prevent="updateMenuItem">
                  <div class="order_menu_item">
                    <!-- <h4>${{update_cart_item.price}}</h4> -->
                    <h4>
                      ${{ parseFloat(update_cart_item.price).toFixed(2) }}
                    </h4>
                    <h4>{{ update_cart_item.title }}</h4>
                    <p>
                      {{ update_cart_item.description }}
                    </p>
                    <label>Special Requests?</label>
                    <textarea placeholder="What are your requests?" class="form-poup"
                      v-model="update_cart_item.specialrequest"></textarea>
                    <div class="qty-text-sec">
                      <div class="row">
                        <div class="col-2">
                          <span>Qty</span>
                        </div>
                        <div class="col-5">
                          <input type="text" id="qty" class="qtyform-poup" v-model="update_cart_item.quantity" required=""
                            data-parsley-trigger="keyup" data-parsley-type="digits" />
                        </div>
                        <div class="col-5 button-service">
                          <span><i class="fa fa-minus" v-on:click="decrease"></i></span>
                          <span><i class="fa fa-plus" v-on:click="increase"></i></span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button @click.prevent="validateUpdateItem()" class="btn add-btn-order" id="update_order">
                        Update order
                      </button>
                      <!-- <a data-toggle="modal" data-target="#signInModalPrimary" class="btn add-signin-btn signin-up-btn"  v-if="!isloggedin">Sign in/ Create Account</a> -->
                    </div>
                  </div>
                  <div class="mb-2 text-center">
                    <div v-if="validation_errors" class="text-danger text-danger text-center">
                      <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                        {{ v[0] }}
                      </p>
                    </div>
                    <div v-if="error" class="text-danger text-danger text-center">
                      <p v-if="error" :key="error" class="text-sm">
                        {{ error }}
                      </p>
                    </div>
                    <div v-if="errors" class="text-danger text-danger text-center">
                      <div v-for="(v, k) in errors" :key="k">
                        <p v-for="error in v" :key="error" class="text-sm">
                          {{ error }}
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="cart-items-final-cls">
              <div class="clearfix menu-list-recepit-list">
                <h4 class="float-left" style="font-size: 18px">Subtotal</h4>
                <span class="float-right" style="font-size: 18px; font-weight: bold">${{ parseFloat(subtotal).toFixed(2)
                }}</span>
              </div>
              <div v-if="is_discount" class="clearfix menu-list-recepit-list mb-2">
                <h4 class="float-left" style="width: 200px">
                  Discount Applied: {{ discounttitle }}
                </h4>
                <span class="float-right" style="color: #019f01">-${{ parseFloat(discount_amt).toFixed(2) }}</span>
              </div>
              <div class="clearfix menu-list-recepit-list">
                <h4 class="float-left">Tips</h4>
                <span class="float-right">${{ parseFloat(fields.final_tips).toFixed(2) }}</span>
              </div>
              <div class="clearfix menu-list-recepit-list">
                <h4 class="float-left">Taxes & Fees</h4>
                <span class="float-right">${{ parseFloat(fields.final_tax).toFixed(2) }}</span>
              </div>
              <div class="clearfix menu-list-recepit-list">
                <h4 class="float-left">{{ platform_fee_label }}</h4>
                <span class="float-right">${{ parseFloat(fields.platform_fee).toFixed(2) }}</span>
              </div>
            </div>
          </div>
          <hr class="hr_line" />
          <div class="" style="float: left; width: 100%; position: absolute">
            <div class="clearfix top-tip-sec">
              <span class="float-left">Tip</span>
              <div class="float-right">
                <div v-if="restauranttype == 'foodtruck'" class="btn-group" role="group"
                  aria-label="Basic radio toggle button group">
                  <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio1" autocomplete="off"
                    v-model="fields.tipVal" @click.prevent="calculate_tip(10, 'btnradio1label')" />
                  <label class="label-tip-btn btn btn-outline-primary btnradio1label" for='btnradio1' id="btnradio1label">10%</label>

                  <input type="radio" class="btn-check-tip" name="btnradio" id='btnradio2' autocomplete="off"
                    v-model="fields.tipVal" @click.prevent="calculate_tip(15, 'btnradio2label')" />
                  <label class="label-tip-btn btn btn-outline-primary btnradio2label check-active" for='btnradio2'
                    id="btnradio2label">15%</label>

                  <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio3" autocomplete="off"
                    v-model="fields.tipVal" @click.prevent="calculate_tip(20, 'btnradio3label')" />
                  <label class="label-tip-btn btn btn-outline-primary btnradio3label" for="btnradio3" id="btnradio3label">20%</label>

                  <input type="radio" class="btn-check-tip" name="btnradio" id="btnradiocustom" autocomplete="off"
                    v-model="fields.tipVal" @click.prevent="
                      calculate_tip('custom', 'btnradiocustomlabel')
                      " />
                  <label class="label-tip-btn btn btn-outline-primary btnradiocustomlabel" for="btnradiocustom"
                    id="btnradiocustomlabel">Custom</label>
                  <span style="
                      display: none;
                      right: 59px;
                      top: 3px;
                      position: absolute;
                    " id="custompercnt">$</span><input type="text" id="customtaxid" class="form-control"
                    v-on:keypress="AmountOnly" @blur="validatecustomtip()" @keyup="
                      calculate_custom_tip(
                        $event.target.value,
                        'btnradiocustomlabel'
                      )
                      " style="
                      display: none;
                      width: 75px;
                      padding-left: 18px;
                      border-radius: 0px !important;
                    " />

                  <!-- <div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1">@</span>
  </div>
  <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
</div> -->
                </div>
                <div v-else class="btn-group" role="group" aria-label="Basic radio toggle button group">dynamicKey
                  <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio1" autocomplete="off"
                    v-model="fields.tipVal" @click.prevent="calculate_tip(0, 'btnradio1label')" />
                  <label class="label-tip-btn btn btn-outline-primary btnradio1label" for="btnradio1" id="btnradio1label">0%</label>

                  <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio2" autocomplete="off"
                    v-model="fields.tipVal" @click.prevent="calculate_tip(5, 'btnradio2label')" />
                  <label class="label-tip-btn btn btn-outline-primary check-active btnradio2label" for="btnradio2"
                    id="btnradio2label">5%</label>

                  <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio3" autocomplete="off"
                    v-model="fields.tipVal" @click.prevent="calculate_tip(10, 'btnradio3label')" />
                  <label class="label-tip-btn btn btn-outline-primary btnradio3label" for="btnradio3" id="btnradio3label">10%</label>

                  <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio4" autocomplete="off"
                    v-model="fields.tipVal" @click.prevent="calculate_tip(15, 'btnradio4label')" />
                  <label class="label-tip-btn btn btn-outline-primary btnradio4label" for="btnradio4" id="btnradio4label">15%</label>

                  <input type="radio" class="btn-check-tip" name="btnradio" id="btnradio5" autocomplete="off"
                    v-model="fields.tipVal" @click.prevent="calculate_tip(20, 'btnradio5label')" />
                  <label class="label-tip-btn btn btn-outline-primary btnradio5label" for="btnradio5" id="btnradio5label">20%</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="new-total-pay-tip">
        <div class="row">
          <div class="col-md-12">
            <div class="top-tip-sec-bottom">
              <div class="clearfix">
                <span class="float-left">Total</span>
                <span class="float-right">$ {{ parseFloat(fields.payable_amount).toFixed(2) }}</span>
              </div>
            </div>

            <div>
              <button v-if="paybtn" type="button" @click.prevent="placeOrder" class="bottom-fix-pay-btn btn">
                PAY
              </button>
            </div>
            <!-- <div><button @click.prevent="validate_payment()" v-if="completeorderbtn" type="button" id="makepaymentbtn" class="bottom-fix-pay-btn btn">COMPLETE ORDER</button></div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade deletecartmodal" id="deletecartmodal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" class="close handheld-close-popup" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="order_menu_item">
            <p class="pt-3">
              {{ deletedmenuitescount }} item(s)
              <span class="itemnamestr">[{{ cartitemdeletedalter }}]</span> is
              currently not available.
            </p>
            <div>
              <button class="btn add-btn-order" data-dismiss="modal" aria-label="Close">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <final-paymentpage-component v-if="showPaymentPage" v-on:callmenusTabfromcart="setCurrentTabMenu"
      :enableguestpay="showPaymentGuestPage" :tipval="fields.tipVal" :tiptype="fields.tipType"
      :restaurant_id="restaurant_id" :loginUser="auth_user" :qrtokenid="qrtokenid" :finalcarttotal="fields.payable_amount"
      :country_id="country_id" :cartdatalist="cartdata" :tipfinval="fields.final_tips" :taxfinval="fields.final_tax"
      :platformfeefinal="fields.platform_fee" :platformfeelabel="platform_fee_label" :finvibereward="fields.vibe_reward"
      :connaccidv="connaccid" :paymntkeyvalv="paymntkeyval" :selectedTime="selectedTime" :selectedItem="selectedItem" v-on:callreceiptTabfromcart="setCurrentTabReceipt"
      :currenttab="currenttab" v-on:reloadmenus="updateMenuListing"
      v-on:reloadreviewordercnt="resetReviewOrderBtn"></final-paymentpage-component>
  </div>
</template>

<script>
import stepwiseSignup from "./3StepSignUpComponent";
import signInPay from "./SignInForPayComponent";
//import stripePayment from  './StripePaymentComponent';
import PaymentPage from "./PaymentPageComponent";
//import patronGuestPay from  './guestSignUpComponent';

export default {
  props: [
    "restaurant_id",
    "auth_user",
    "currenttab",
    "country_id",
    "qrtokenid",
    "table_id",
    "restauranttype",
    "confirmcartcount",
  ],
  data() {
    return {
      discounttitle: "",
      is_discount: false,
      discount_amt: "",
      fields: {
        final_cart_total: 0,
        final_tips: 0,
        tipVal: 15,
        tax_percentage: 0,
        final_tax: 0,
        vibe_reward: 0,
        payable_amount: 0,
        user_id: 0,
        platform_fee: 0,
        tipType: "",
      },
      cartdata: {},
      isloggedin: false,
      isCartLoading: true,
      showPaymentPage: false,
      loginUser: this.auth_user,
      tilled_account_status: "",
      platform_fee_perc: "",
      platform_fee_label: "...",
      connaccid: "",
      paymntkeyval: "",
      showPaymentGuestPage: false,
      cartTotal: 0,
      update_cart_item: {
        title: "",
        description: "",
        price: "",
        quantity: 1,
        user_id: "",
        menuItmInx: "",
        inventory: 0,
      },
      validation_errors: {},
      error: "",
      errors: {},
      load_order_items: true,
      showcartlist: true,
      paybtn: true,
      confirmccount: this.confirmcartcount,
      subtotal: 0,
      cartitemdeletedalter: "",
      deletedmenuitescount: "",
      selectedTime:null,
      preorder:null,
      selectedItem:[]

      //completeorderbtn:false
    };
  },
  components: {
    "step-wise-signup": stepwiseSignup,
    "signin-pay": signInPay,
    //'patron-guest-pay':patronGuestPay,
    "payment-page": PaymentPage,
  },
  async created() {
    await this.get_restaurant_settings();
    await this.list();
    await this.check_restaurant_payment_gateway_status();
  },
  mounted() {
    let vm = this;

    Echo.channel("item-updated-in-cart-from-dashboard." + vm.qrtokenid).listen(
      "UpdatedCartItemFromAdmin",
      (e) => {
        vm.list();
      }
    );
    Echo.channel("item-deleted-cart-from-dashboard." + vm.qrtokenid).listen(
      "CartItemDeletedFromAdmin",
      (e) => {
        vm.list();
      }
    );
    Echo.channel("add-item-to-customercart." + vm.qrtokenid).listen(
      "AddItemToCustomerCartFromBackend",
      (e) => {
        vm.list();
      }
    );
    vm.preorder = localStorage.getItem('preorder');
    vm.selectedItem = JSON.parse(localStorage.getItem('item'));
    vm.selectedTime = localStorage.getItem('selectedTime');
  },
  methods: {
    // updateFinalTips(newtips) {
    // this.fields.final_tips = newtips;
   
    // },
    // updatePayableAmount(amount) {
    // this.fields.payable_amount = amount;
   
    // },
    eventdatetime(){
        window.location.href = "/eventdatetime/"+this.restaurant_id;
    },
    get_restaurant_settings() {
      let postData = new FormData();
      postData.append("restaurant_id", this.restaurant_id);
      axios.post("/api/restaurant-settings", postData).then((response) => {
        console.log('response.data.data.platform_fee_label');
        console.log(response.data.data.platform_fee_label);
        this.platform_fee_label = response.data.data.platform_fee_label;
        this.connaccid = response.data.data.accountId;
        this.paymntkeyval = response.data.data.tilled_p_key;
        this.platform_fee_perc = response.data.data.platform_fee_rate;
      });
    },
    async list() {
      let currentqrcode = window.location.pathname.split("/").pop();
      let postDataw = new FormData();
      postDataw.append("restaurant_id", this.restaurant_id);
      axios.post("/api/restaurant-settings", postDataw).then((response) => {
        if (response.data.data.delaydetail != null) {
          //location.reload();
          window.location.href = "/restaurant/home/" + currentqrcode;
        }
      });
      //console.log("auth_userc===",this.auth_user);
      let postData = new FormData();
      if (this.auth_user != null) {
        this.isloggedin = true;
        postData.append("user_id", this.auth_user.id);
      }

      postData.append("restaurant_id", this.restaurant_id);
      postData.append("confirmed", 1);
      postData.append("table_id", this.table_id);
      postData.append("qrtoken_id", this.qrtokenid);
      postData.append("order_type", "normal");
      let titlestr = "";
      await axios
        .post("/patron/cart-list", postData)
        .then((response) => {
          this.deletedmenuitescount = response.data.data.deletedmenuitescount;
          if (response.data.data.deletedmenuitescount > 0) {
            this.$emit("updatecartcnt", response.data.data.cartItemCount);
            this.$emit("updatemenulist");
            $("#deletecartmodal").modal("show");

            let itemnames = response.data.data.deletedmenuitestitle;
            $.each(itemnames, function (key, val) {
              titlestr += val + ", ";
            });
            titlestr = titlestr.replace(/,\s*$/, "");
            this.cartitemdeletedalter = titlestr;
            //alert(response.data.data.deletedmenuitescount+" is now not available");
          }
          this.cartdata = response.data.data.cart_list;
          this.cartTotal = response.data.data.cartTotal;
          this.confirmccount = response.data.data.cartItemCount;
          this.fields.final_cart_total = response.data.data.cartTotal;

          $("#cartCount").text(this.confirmccount);

          if (response.data.data.discounted_amt_tol > 0) {
            this.discounttitle = response.data.data.discount_title;
            this.is_discount = true;
            this.discount_amt = response.data.data.discounted_amt_tol;
          } else {
            this.discounttitle = "";
            this.is_discount = false;
            this.discount_amt = 0;
          }
          //console.log(this.fields.final_cart_total);
          this.subtotal = response.data.data.subtotal;

          $("#confirmCartCount").text(this.cartdata.length);
          this.fields.tax_percentage = response.data.data.tax_per;
          this.isCartLoading = false;

          this.check_patron_login_status();
          if (this.restauranttype == "foodtruck") {
            this.calculate_tip(15, "btnradio2label");
          } else {
            this.calculate_tip(10, "btnradio2label");
          }

          this.calculate_platform_fee();
          this.calculate_tax();
          //this.getRewardDetail();
          //this.calculate_payable_amount();
          this.load_order_items = false;
        })
        .catch((error) => { });
    },
    AmountOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateMenuListing() {
      this.$emit("reloadmenulistfromcart");
    },
    resetReviewOrderBtn() {
      this.$emit("resetrevieworderbtncnt");
    },
    setCurrentTabMenu(menuname) {
      this.$emit("callmenusTabfromcart", "menu");
    },
    setCurrentTabReceipt(menuname) {
      this.$emit("callmenusTabfromcart", "receipt");
    },
    validateUpdateItem() {
      let vm = this;
      $("#editiorderfrm")
        .parsley()
        .whenValidate({})
        .done(function () {
          vm.updateMenuItem();
        });
    },
    async removeItem(itmId, menuItm) {
      $("#reveiworderbtncnt").text("");
      if (confirm("Do you really want to remove?")) {
        var vm = this;
        await axios
          .delete("/patron/remove-cart-item/" + itmId)
          .then((response) => {
            //Vue.delete(this.cartlist, menuItm);

            let ccartCount = $("#cartCount").text();
            let nwcartCount = parseInt(ccartCount) - 1;
            $("#cartCount").text(nwcartCount);
            $("#update_order").prop("disabled", false);
            vm.list();
            this.confirmccount = nwcartCount;
            vm.$emit("updatecartcnt", nwcartCount);
            vm.$emit("updatemenulist");
          })
          .catch((error) => { });
      }
    },
    updateMenuItem: function () {
      let vm = this;
      vm.validation_errors = {};
      vm.error = "";
      //alert(vm.update_cart_item.quantity)

      $("#update_order").prop("disabled", true);
      $("#update_order").text("PLEASE WAIT...");
      /*if(vm.update_cart_item.quantity > vm.update_cart_item.inventory){
                alert('The quantity is higher than the maximum allowed quantity');
                $('#update_order').prop('disabled',false);
                $('#update_order').text("Update order");
                return false;
            }*/
      if (vm.update_cart_item.quantity > 0) {
        axios
          .post("/patron/update-cart-item", vm.update_cart_item)
          .then((response) => {
            vm.list();
            $("#viewItemEdit .close").click();

            $("#update_order").prop("disabled", false);
            $("#update_order").text("Update order");

            vm.$emit("updatecartcnt", 0);
          })
          .catch((error) => {
            $("#update_order").prop("disabled", false);
            $("#update_order").text("Update order");
            if (error.response.data.data && error.response.data.code == 422) {
              vm.validation_errors = error.response.data.data || {};
              if (error.response.data.data.length == 0) {
                vm.error = error.response.data.message || {};
              }
            } else if (error.response.data.message) {
              vm.error = error.response.data.message || {};
            }
          });
        //vm.$emit('updatecartcnt',nwcartCount);
        vm.$emit("updatemenulist");
      } else {
        this.removeItem(vm.update_cart_item.id, vm.update_cart_item.menuItmInx);
        $("#viewItemEdit .close").click();
      }
    },
    showMenuItemEdit: function (item, itmindex) {
      let vm = this;
      $("#viewItemEdit").modal("show");
      vm.update_cart_item.title = item.menuitem.title;
      vm.update_cart_item.description = item.menuitem.description;
      vm.update_cart_item.price = item.menuitem.price;
      vm.update_cart_item.quantity = item.quantity;
      vm.update_cart_item.inventory = item.menuitem.inventory;
      vm.update_cart_item.id = item.id;
      vm.update_cart_item.menuItmInx = itmindex;
      vm.update_cart_item.specialrequest = item.other_data;
    },
    increase: function () {
      let vm = this;
      vm.update_cart_item.quantity++;
    },
    decrease: function () {
      let vm = this;
      if (vm.update_cart_item.quantity > 0) {
        vm.update_cart_item.quantity--;
      }
    },
    callMenuTab() {
      this.$emit("callmenusTabfromcart", "menu");
      $('.new-tablist-tabs a[href="#menu"]').trigger("click");
    },
    callForEnableHeader() {
      this.$emit("enablealltabs");
    },

    check_restaurant_payment_gateway_status() {
      let postData = new FormData();
      postData.append("restaurant_id", this.restaurant_id);
      axios
        .post("/patron/restaurant-payment-gateway-status", postData)
        .then((response) => {
          //console.log(response.data);   
          this.tilled_account_status = response.data;
          if (response.data == 0) {
            toastr.options = {
              closeButton: false,
              positionClass: "toast-top-full-width mt-2",
              hideDuration: 9000,
            };
            toastr.warning(
              "Payment menthod not set. Please contact restaurant owner."
            );
          }
        })
        .catch((error) => { });
    },
    setGuestPaymentPage(valev) {
      this.showPaymentGuestPage = valev;
    },

    check_patron_login_status() {
      axios
        .post("/patron/check-patron-auth-status")
        .then((response) => {
          if (response.data.response.user != "") {
            this.isloggedin = true;
            this.fields.user_id = response.data.response.user.id;
          }
        })
        .catch((error) => { });
    },
    calculate_cart_total() {
      let final_cart_total_arr = [];
      let ci;
      for (ci = 0; ci < this.cartdata.length; ci++) {
        final_cart_total_arr[ci] =
          this.cartdata[ci].quantity * this.cartdata[ci].menuitem.price;
      }

      let final_cart_total = final_cart_total_arr.reduce(
        (acc, item) => acc + item,
        0
      );

      return final_cart_total;
    },
    calculate_platform_fee() {
      this.fields.platform_fee = 0;
      let t_food_cost = this.fields.final_cart_total;

      let cal_platform_fee = (t_food_cost / 100) * this.platform_fee_perc;
      this.fields.platform_fee = cal_platform_fee.toFixed(2);

      this.calculate_tax();
      this.calculate_payable_amount();
    },
    calculate_custom_tip(tipcal, clsname) {
      if ($("#customtaxid").val() == "") {
        //  $('#customtaxid').val(0)
        this.fields.tipVal = 0;
        tipcal = 0;
      }
      this.fields.final_tips = 0;

      this.fields.final_tips = tipcal;
      $(".label-tip-btn").removeClass("check-active");
      $("#" + clsname).addClass("check-active");
      this.fields.tipVal = tipcal;
      this.fields.tipType = "amount";
      this.calculate_platform_fee();
      this.calculate_tax();
      this.calculate_payable_amount();
    },
    validatecustomtip() {
      if ($("#customtaxid").val() == "") {
        $("#customtaxid").val(0);
        //  alert("please enter tip");
        //  $('#customtaxid').focus();
        //  return false;
      }
    },
    calculate_tip(tipcal, clsname) {
      console.log('MObile Screen Active');
      console.log(`tipcal = ${tipcal} , clsname = ${clsname} `);
      if (tipcal == "custom") {
        $("#btnradiocustomlabel").hide();
        $("#customtaxid").show();
        $("#customtaxid").val(0);
        $("#customtaxid").focus();
        $("#custompercnt").show();
        tipcal = 0;
      }else{
        $("#customtaxid").val("");
        $("#customtaxid").hide();
        $("#btnradiocustomlabel").show();
        $("#custompercnt").hide();
        tipcal = tipcal;
      }
      this.fields.final_tips = 0;
      let cal_final_tips = (this.fields.final_cart_total / 100) * tipcal;
      this.fields.final_tips = cal_final_tips;
      console.log(`final_tips = ${cal_final_tips} `);
      $(".label-tip-btn").removeClass("check-active");
      $("." + clsname).addClass("check-active");
      this.fields.tipVal = tipcal;
      this.fields.tipType = "perc";
      this.calculate_platform_fee();
      this.calculate_tax();
      this.calculate_payable_amount();
    },
    calculate_tax() {
      //this.fields.final_cart_total = this.final_cart_total;
      let tippluscartval = parseFloat(this.fields.final_cart_total);
      let cal_final_tax = (tippluscartval / 100) * this.fields.tax_percentage;
      this.fields.final_tax = cal_final_tax.toFixed(2);
      this.calculate_payable_amount();
    },
    /*getRewardDetail(){
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        if(this.auth_user != null){
            postData.append("user_id", this.auth_user.id);
        }
        axios.post('/patron/get-survey-points',postData).then(response => {
            //console.log(response.data);
            this.fields.vibe_reward = response.data.response.getRewardof;
            //console.log(this.getRewardof);
            this.calculate_payable_amount();
        }).catch(error => {
        });
        },*/
    calculate_payable_amount() {
      let fin_payable_amount =
        parseFloat(this.fields.final_cart_total) +
        parseFloat(this.fields.final_tips) +
        parseFloat(this.fields.final_tax) +
        parseFloat(this.fields.platform_fee);
        this.fields.payable_amount = fin_payable_amount.toFixed(2);
        console.log(`Total = ${this.fields.payable_amount}`);
      
    },

    showLoginForm: function () {
      $("#signInModalPrimaryPay").modal("show");
    },
    setUserLogin() {
      this.isloggedin = true;
      axios
        .post("/patron/check-patron-auth-status")
        .then((response) => {
          if (response.data.response.user != "") {
            this.isloggedin = true;
            this.fields.user_id = response.data.response.user.id;
            this.loginUser = response.data.response.user;
            this.$emit("setloginonmenu", this.loginUser);
          }
        })
        .catch((error) => { });
    },
    setUserLoginOnPay(logguserdata) {
      //alert("hihih");
      this.isloggedin = true;
      this.showPaymentPage = true;
      this.loginUser = logguserdata;
      this.$emit("setloginonmenu", this.loginUser);
    },

    placeOrder() {
      let currentqrcode = window.location.pathname.split("/").pop();
      let postDataw = new FormData();
      postDataw.append("restaurant_id", this.restaurant_id);
      axios.post("/api/restaurant-settings", postDataw).then((response) => {
        if (response.data.data.delaydetail != null) {
          window.location.href = "/restaurant/home/" + currentqrcode;
        } else {
          this.$emit("disablealltabs");
          this.showPaymentPage = true;
          this.isCartLoading = false;
          this.showcartlist = false;
          this.paybtn = false;
        }
      });
    },
    callMenu() {
      this.$emit("callmenusTab", "menu");
      $('.new-tablist-tabs a[href="#menu"]').trigger("click");
    },
  },
};
</script>
