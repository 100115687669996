var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content"},[(_vm.status !='')?_c('div',{staticClass:"delay_order"},[(_vm.dely_time == 'closed')?_c('p',[_c('i',{staticClass:"far fa-clock"}),_vm._v(" This food truck is currently\n                closed but "),_c('span',[_vm._v(" offers preordering.")])]):_c('p',{staticClass:"oder_text"},[_vm._v("Ordering has been dealyed for "+_vm._s(_vm.dely_time)+" min.")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"att_card"},[(_vm.currentDateTime <= _vm.closedDateTime)?_c('div',[(_vm.dely_time !== 'closed')?_c('div',{staticClass:"card card_new",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"card-body"},[_c('a',{attrs:{"href":'/patron/restaurant/make-order/'+_vm.uuid},on:{"click":_vm.removePreorder}},[_c('div',{staticClass:"pickup"},[_c('img',{attrs:{"src":'../../../img/frontend/icons/order_pickup.png',"alt":""}}),_vm._v(" "),_c('h5',[_vm._v("Order for pick up now")])])])])]):_c('div',{staticClass:"card card_new discard"},[_vm._m(0)]),_vm._v(" "),(_vm.preorderstatus !== null)?_c('div',[(_vm.preorderstatus === 1)?_c('div',{staticClass:"card card_new discard"},[_vm._m(1)]):_c('div',{staticClass:"card card_new"},[_c('div',{staticClass:"card-body"},[_c('a',{attrs:{"href":'/patron/restaurant/make-order/'+_vm.uuid},on:{"click":_vm.setPreorder}},[_vm._m(2)])])])]):_vm._e()]):_c('div',[_vm._m(3),_vm._v(" "),(_vm.preorderstatus !== null)?_c('div',[(_vm.preorderstatus === 1)?_c('div',{staticClass:"card card_new discard"},[_vm._m(4)]):_c('div',{staticClass:"card card_new"},[_c('div',{staticClass:"card-body"},[_c('a',{attrs:{"href":'/patron/restaurant/make-order/'+_vm.uuid},on:{"click":_vm.setPreorder}},[_vm._m(5)])])])]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"content ower_logo"},[_c('h5',[_vm._v("Powered by")]),_vm._v(" "),_c('img',{attrs:{"src":'../img/frontend/firefly-final-logo.png',"alt":""}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pickup"},[_c('i',{staticClass:"fas fa-stopwatch"}),_vm._v(" "),_c('h5',[_vm._v("Order for pick up now")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pickup"},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" "),_c('h5',[_vm._v("Preorder for pickup later")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pickup"},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" "),_c('h5',[_vm._v("Preorder for pickup later")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card_new discard",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pickup"},[_c('i',{staticClass:"fas fa-stopwatch"}),_vm._v(" "),_c('h5',[_vm._v("Order for pick up now")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"pickup"},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" "),_c('h5',[_vm._v("Preorder for pickup later")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pickup"},[_c('i',{staticClass:"far fa-clock"}),_vm._v(" "),_c('h5',[_vm._v("Preorder for pickup later")])])
}]

export { render, staticRenderFns }