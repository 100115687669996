<template>
<div class="row">
  <div v-if="loading"></div>
  <div class="col-12 col-md-6 col-lg-4 col-xl-3"  v-for="(item,index) in rtables">

    <div class="table-card rtablecard" :id="`card-tbl-id-${item.id}`">
      <span class="loader">
      <span class="content"><i class="fa fa-spinner fa-spin"></i> Please wait...</span>
      </span>
      <div class="user-headeading-booking clearfix">
        <div class="">
          <div>

            <div v-if="item.employees.length > 0">

              <div v-for="emp in item.employees" class=" pl-2">
                <div class="" v-if="emp.user">
                  <template v-for="empval in emp.restraurant_employee_attributes" >
                    <template v-if="empval.name == 'profile_photo'">
                      <img v-if="empval.pivot.value!=''" :src="'/storage/employee_pictures/'+empval.pivot.value" alt="waiter-img">
                      <img v-if="empval.pivot.value==''" src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" alt="waiter-img">
                    </template>
                  </template>
                  <a class="font-weight-bold" >{{emp.user.name}}</a>
                </div>
                <div v-else><span class="text-warning">Not Assigned</span></div>
              </div>
            </div>
            <div v-if="item.employees.length == 0">
              <div>
              <span class="text-warning">Not Assigned.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="outer-food-details tablelistboxht">
        <div class="row">
          <div class="col-sm-12 col-12">
            <div class="order-name">
              <p>Table name:  {{item.name}}</p>
            </div>
          </div>
          <div class="col-sm-12 col-12">
            <div class="order-name">
              <p>Seats: {{item.capacity}}</p>
            </div>
          </div>
          <div class="col-sm-12 col-12 text-center">
            <div class="" >
              <img :src="'/storage/QRCODE/'+item.qrcodefilename" alt="table-qr-img" width="100" height="100">
            </div>
          </div>

        </div>
      </div>


      <div class="outer-btn-edit-close">
        <div class="row">
          <div class="col-sm-4 col-4 innterbtn-edit" style="padding-right: 0px;">
            <a title="Download QR" :href="'/storage/QRCODE/'+item.qrcodefilename" download><button class="btn" ><i
                class="align-middle  fa fa-download" ></i></button></a>
          </div>
          <div class="col-sm-4 col-4 innterbtn-edit">
            <button class="btn" @click="edit(item,index)"><i
                class="align-middle  far fa-fw fa-edit" ></i>
              Edit</button>
          </div>
          <div class="col-sm-4 col-4 innterbtn-edit"
            style="padding-left: 0px;">

            <!-- <button class="btn" @click="deleteTable(item)">
            <i class="align-middle  far fa-fw fa-window-close"></i>
              Delete</button> -->
              <button class="btn" @click="deleteTable(item)">
              <i class="fa fa-times" style="font-size:16px"></i></button>


          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- add-table -->
  <div class="col-12 col-md-6 col-lg-4 col-xl-3">
    <div class="table-card plus-card">
      <div><i class="align-middle mr-2 fas fa-fw fa-plus"  @click="openAddTableModal()"></i></div>
    </div>
  </div>
  <div class="modal fade" id="addTblModalPrimary" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title add-employee-popup">Add Table</h5>
          <button type="button" class="close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form method="POST" id="addtablefrm-form" name="addtablefrm" @submit.prevent="submit" data-parsley-validate >
        <div class="m-5">
          <input type="text" name="form_field" class="form-poup"
            placeholder="Table Name" v-model="fields.name" required >
          <input type="text" name="form_field" class="form-poup"
              placeholder="Seats" v-model="fields.capacity" data-parsley-type="number" min="1" required>
          <label>Select Server <span class="text-warning" v-if="waiter_loading">Loading...</span></label>
          <select class="form-poup" v-if="!waiter_loading" v-model="fields.server_id" >
                <option value="" selected>-- Please Select --</option>
                <option :value="serveritem.id" v-for="serveritem in server_list">
                  {{serveritem.user.name}} ({{serveritem.user.email}})
                </option>
          </select>
          <button @click.prevent="add()" class="btn add-emp-btn" id="save_tbl">Save</button>

        </div>
        <div class="row">
          <div v-if="errors" class="text-danger col-12">
            <div v-for="(v, k) in errors" :key="k">
              <p v-for="error in v" :key="error" class="text-sm">
                {{ error }}
              </p>
            </div>
          </div>
          <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
            <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
              {{ problem_in_save_error }}
            </p>
          </div>
        </div>

        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="editTblModalPrimary" tabindex="-1" role="dialog"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title add-employee-popup">Edit Table</h5>
          <button type="button" class="close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form method="POST" id="edittablefrm-form" name="edittablefrm" @submit.prevent="submit" data-parsley-validate >
        <div class="m-5">
          <label for="">Table Name</label>
          <input type="text" name="form_field" class="form-poup"
            placeholder="Table Name" v-model="editfields.name" required >
          <label for="">Seats</label>
          <input type="text" name="form_field" class="form-poup"
              placeholder="Seats" v-model="editfields.capacity"   required>
          <label>Select Server <span class="text-warning" v-if="waiter_loading">Loading...</span></label>
          <select class="form-poup" v-if="!waiter_loading" v-model="editfields.server_id" >
                <option value="" selected>-- Please Select --</option>
                <option v-if="hasemp" value="free-table">Free Table</option>
                <option :value="serveritem.id" v-for="serveritem in server_list">
                  {{serveritem.user.name}} ({{serveritem.user.email}})
                </option>
          </select>
          <button @click.prevent="update()" class="btn add-emp-btn" id="update_tbl">Save</button>

        </div>
        <div class="row">
          <div v-if="update_errors" class="text-danger col-12 text-center">
            <div v-for="(v, k) in update_errors" :key="k">
              <p v-for="error in v" :key="error" class="text-sm">
                {{ error }}
              </p>
            </div>
          </div>
          <div v-if="problem_in_update_error" class="text-danger col-12 text-danger text-center">
            <p v-if="problem_in_update_error" :key="problem_in_update_error" class="text-sm">
              {{ problem_in_update_error }}
            </p>
          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>

    export default {
    props:['restaurant_id'],
    data() {
        return {
            rtables: {},
            fields: {
            name:'',
            capacity:'',
            restaurant_id:'',
            server_id:''
            },
            editfields:{
            name:'',
            capacity:'',
            id:'',
            server_id:'',
            restaurant_id:''
            },
            message:'',
            errors: {},
            iserrors: false,
            success: false,
            isLoading: false,
            loading:false,
            editindex:'',
            problem_in_save_error:'',
            problem_in_update_error : '',
            update_errors:{},
            server_list:{},
            waiter_loading:false,
            hasemp:false
        }
    },

    created: function () {
      axios.get('/owner/restaurant_table/gettables').then(response => {
      this.rtables = response.data.restaurantTables;
      this.fields.restaurant_id = this.restaurant_id;
      }).catch(error => {

      });


    },
    methods:{

    add() {
    let vm = this;
    $('#addtablefrm-form').parsley().whenValidate({

    }).done(function() {
    vm.submit();
    });

    },
    openAddTableModal(table_id=null){
      this.errors = {};
      this.problem_in_save_error = '';
      this.fields.name = '';
      this.fields.capacity = '';
      this.fields.server_id = '';

      $('#addTblModalPrimary').modal('show');
      this.getEmployees(table_id);
    },
    getEmployees(table_id=null){


      this.waiter_loading = true;
      let postData = new FormData();
      postData.append("type", 'Server');
      postData.append("table_id", table_id);
      axios.post('/owner/restaurant_employee/get-unused-employees',postData).then(response => {
        this.waiter_loading = false;
      this.server_list = response.data.data.restaurantEmployees;
      }).catch(error => {
        this.waiter_loading = false;
      });
    },
    submit() {
    let vm = this;
      vm.success = false;
      vm.errors = {};
      $('#save_tbl').prop('disabled',true);
      axios.post('/owner/restaurant_table/add', vm.fields).then(response => {
        vm.fields = {}; //Clear input fields.
        vm.success = true;
        $("#addTblModalPrimary .close").click();

        this.rtables.push(response.data.response.restaurantTables);
        $('#save_tbl').prop('disabled',false);
        toastr.success('Table added successfully.');
        this.fields.restaurant_id = response.data.response.restaurant_id;
      }).catch(error => {
        $('#save_tbl').prop('disabled',false);
        console.log(error.response.data.response.message);
        if (error.response.status === 400) {
          vm.problem_in_save_error = error.response.data.response.message || {};
        }else{
          vm.errors = error.response.data.response.message || {};
        }
      });

    },

    edit: function (item,index) {
      let vm = this;
      this.server_list = {};
      this.waiter_loading = true;

      this.update_errors = {};
      this.problem_in_update_error = '';

      this.getEmployees(item.id);
      $('#editTblModalPrimary').modal('show');
      vm.editfields.name = item.name;
      vm.editfields.capacity = item.capacity;
      vm.editfields.id = item.id;
      vm.editfields.restaurant_id = this.restaurant_id;
      vm.editindex = index;
      if(item.employees.length > 0){
        vm.editfields.server_id = item.employees[0].id;
        vm.hasemp = true;
      }

      //console.log(item.employees.length);
    },
    update: function (item) {
    let vm = this;
    $('#edittablefrm-form').parsley().whenValidate({

    }).done(function() {
    vm.submit_update(item);
    });
    },

    submit_update: function (item) {
    let vm = this;
    $('#update_tbl').prop('disabled',true);
      vm.success = false;
      vm.errors = {};
      axios.post('/owner/restaurant_table/update', vm.editfields).then(response => {
        vm.fields = {}; //Clear input fields.
        vm.success = true;
        $("#editTblModalPrimary .close").click();
        this.fields.restaurant_id = response.data.response.restaurantTables.restaurant_id;
        $('#card-tbl-id-'+response.data.response.restaurantTables.id).addClass('loading-spin');

        setTimeout( () => {
          this.rtables.splice(this.editindex, 1, response.data.response.restaurantTables);
          $('#card-tbl-id-'+response.data.response.restaurantTables.id).removeClass('loading-spin');
        }, 2000);
        $('#update_tbl').prop('disabled',false);
        toastr.success('Table updated successfully.');
      }).catch(error => {
        $('#update_tbl').prop('disabled',false);
        //console.log(error.response.data.errors);
        if (error.response.status === 400) {
          vm.problem_in_update_error = error.response.data.response.message || {};
        }else{
          vm.update_errors = error.response.data.errors || {};
        }
      });

    },

    deleteTable: function (item) {
      if (item) {
      if(confirm("Do you really want to delete?")){
          axios.post('/owner/restaurant_table/destroy', item).then(response => {
            console.log("check")
            console.log(response.data.data.message)
            if(response.data.data.message){
                toastr.error(response.data.data.message);
              }else{
                 this.rtables.splice(this.rtables.indexOf(item), 1);
                 toastr.success('Table deleted successfully.');
              }


            
          }).catch(error => {
            console.log(error.response.data.errors);
            console.log(error.response.status);
            if (error.response.status === 422) {
              vm.errors = error.response.data.errors || {};
            }else{

            }
          });
      }
      }
    }

    },

    computed: {
    }

    }
</script>
