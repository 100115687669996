<template>
    <div>
      <div class="calender_sec" style="float: left;width: 100%;border-top: 1px solid #ccc;">
                    <div class="content">
                        <h3><i style="color: #ff5802;" class="align-middle fas fa-angle-left float-left go-back-btn" id="go-back-btn"></i> {{firstevent.name}} 
                        <span>
                         <!--<i class="align-middle fa fa-heart heart-red float-right"></i>-->
                         <!-- <i class="align-middle far fa-heart float-right"></i> -->
                        </span>
                        <span v-if="userid">
                         <i v-if="firstevent.likes && firstevent.likes.user_id == userId && firstevent.likes.status == 1"  class="align-middle fa fa-heart heart-red float-right" v-on:click="toggleLike(firstevent.id,userid)"></i>
                        <i v-else class="align-middle far fa-heart float-right" v-on:click="toggleLike(firstevent.id,userid)"></i>
                        </span>
                        </h3>
                    </div>
                 
                       <img :src="'/storage/event_picture/thumbnail/'+firstevent.photos" alt="" />
                    <div class="content nme_title">
                        <div class="cst_evt">
                            <h5><i class="align-middle fas fa-map-marker-alt "></i> {{firstevent.address}}</h5>
                            <span>{{firstevent.address}} 
                            <img style="float: right; width: 10%;" :src="'../../img/frontend/icons/direction.png'" alt="" />
                           
                            </span>
                            <!--<span>{{firstevent.address}} <i class="align-middle fas fa-location-arrow" v-on:click="openMap(firstevent.address)"></i></span>-->
                            <p><i class="align-middle fas fa-clock"></i> {{convertedStartTime(firstevent.start_time)}} - {{convertedEndTime(firstevent.end_time)}}</p> 
                        </div>
                    </div>
                    <div class="content">
                        <div class="cst_event_title">
                            <h4>Merchants</h4>
                        </div>
                        <div class="att_card">
                            
                            <div v-for="(item, index) in restaurantdata" class="card card_new">
                               
                                <a :href="'/customer-order/'+item.id+'/'+firstevent.id">
                                <div class="card-body bdy_new"> 
                                    <div class=" evt_img"> 
                                        <img :src="'/storage/restaurant_logo/'+item.restaurant_logo" />
                                    </div>
                                    <div class="att_tlt">
                                        <h5>{{item.restaurant_name}}</h5>

                                        <p v-if="item.start_time && item.end_time"><i class="align-middle fas fa-clock"></i> Today {{item.start_time}} - {{item.end_time}} <i class="align-middle far fa-heart float-right"></i></p> 
                                        <!-- <span>Seafood</span> -->
                                    </div>
                                </div>
                                </a>
                            </div>

                            

                        </div>
                    </div>
                </div>  
</div>
</template>

<script>
export default {
    props: ['firstevent','userid','restaurantdata'],   
    data() {
        return {
          //firstevent: this.firstevent,
          status:false,
          userId:this.userid,
        }
      },
    methods: {
        currentDate(item) {
            const date = new Date(item);
            return date.toLocaleDateString("en-US", {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric"
            });
        },
        convertedStartTime(time) {
            const [hours, minutes] = time.split(":");
            const d = new Date();
            d.setHours(hours);
            d.setMinutes(minutes);
            return d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        },
        convertedEndTime(time) {
            const [hours, minutes] = time.split(":");
            const d = new Date();
            d.setHours(hours);
            d.setMinutes(minutes);
            return d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        },
        openMap(address) {
            const mapsUrl = "https://www.google.com/maps?q=" + encodeURIComponent(address);
            window.open(mapsUrl, "_blank");
        },
         toggleLike(itemid,userid) {
        let formData = new FormData();
        formData.append("id", itemid);
        formData.append("userid", userid);
        axios.post('/like-event-userend',formData).then(response => {
           setTimeout(function() {
            location.reload();
            }, 300);
        }).catch(error => {
            alert('Login first.')
          console.log(error);
        });
        }
    }
};
</script>
