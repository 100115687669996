<template>
  <div>
      <div class="blast_wrap" style="padding:15px;" v-if="blastmenus.length > 0">
          <!-- start -->
          <div v-for="(item, index) in blastmenus">
          <div class="row blast_wrap_menu ">
              <div class="col-md-9">
                <div class="sp_menu_name">
                  <h5>{{ item.blast_text }}</h5>
                  <p>Blast Ends <strong>{{ item.end_date }}</strong></p>
                  <p><span><i class="far fa-envelope"></i>  {{ item.email_count }} emails </span> <span><i class='fas fa-mobile-alt'></i> {{ item.phone_text_count }} text messages</span></p>
              </div>
              <div class="amt_day">
                  
                  <h3>$ {{parseFloat(item.amount_to_pay).toFixed(2)}}</h3>
              </div>     
              </div>
              <div class="col-md-3">
              <div class="sp_btn" v-if="isEndDatePassed(item.end_date)">
                  <button class="btn completed" type="button">Completed</button>
                  <!-- <button class="btn edit" type="button" disabled @click.prevent="EditblastMenu(item.collection_for)"><i class="align-middle fas fa-pencil-alt"></i> Edit </button>  -->
              </div>  
              <div class="sp_btn" v-else>
                  <button class="btn pending" type="button">Pending</button>
                  <!-- <button class="btn edit" type="button" @click.prevent="EditblastMenu(item.collection_for)"><i class="align-middle fas fa-pencil-alt"></i> Edit </button>  -->
              </div> 
              </div>
          </div>
         
      </div>
          <!-- Ends -->
      </div>
      <div v-else>
          <div class="special_no_menu">No Blast menu have been added</div>
      </div>
       <!-- Blast MOdel -->
       <div class="modal fade" id="blastModel123" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
              <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 580px;">
                <form method="POST" id="blastform" name="blastform" @submit.prevent="submit_blast_form" data-parsley-validate >
                      <div class="modal-content" id="card-reward-id">
                      
                        <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="modal-body">
                          <div class="newblast">
                              <h6>Edit the Blast</h6>
                              <p>Your account will be charged on your Blast start date. One message per type per day.</p>
                          </div>                             
                          
                          <div class="blastlabel">
                            <p>Blast Text <span>(240 characters max)</span></p>
                            <input type="text" name="firstName" v-model="edit_discount_fields.title" class="form-poup mb-2" required>
                          </div>
                          <div class="BlastType">
                            <p>Blast Type</p>
                            <select class="form-poup"  required v-model="edit_discount_fields.mailtype">
                              <option selected>Text and Email</option>
                            </select>
                          </div>
                        </div>      
                        <hr style="border: 1px solid #f6f6f6;width: 100%;margin: 0;" />
                            <div class="modal-body">
                              <div class="bookeventlabel">
                                  <p>Menu special or event for your blast</p>
                                 <select class="form-poup"  required v-model="edit_discount_fields.reaminingdays">
                                    <option :value="edit_discount_fields.reaminingdays" v-html="getFormattedOptionText"></option>
                                  </select>
                              </div>
                            </div>
                          <hr style="border: 1px solid #f6f6f6;width: 100%;margin: 0;" />
                            <div class="modal-body">  
                              <div class="Menu_wrap ">
                                <div class="menu_name_wrap ">
                                  <div class="row" style="padding: 0px 10px;">
                                    <div class="col-md-6">
                                      <div class="sp_menu_name">
                                        <h5>Duration of Blast</h5>
                                        <p>Number of days you want messages sent</p>
                                      </div>    
                                    </div>

                                    <!-- <div class="col-md-3">
                                      <div class="startday">
                                        <select name="country" class="form-control" v-model="edit_discount_fields.valid_from">
                                          <option>{{ edit_discount_fields.valid_from }}</option>
                                        </select>
                                      </div>
                                      
                                    </div>
                                    <div class="col-md-3">
                                      <div class="startday">
                                        <select name="country" class="form-control" v-model="edit_discount_fields.valid_to">
                                          <option :value="edit_discount_fields.valid_to"></option>
                                        </select>
                                      </div>
                                    </div> -->
                                     <div class="col-md-3">
                                          <label class="treat-label">Start Date</label>
                                          <input type="date"   id="valid_from" v-model="edit_discount_fields.valid_from" placeholder="11/02/2020" class="form-poup-shift-type" required>
                                      <!-- <b-form-datepicker id="valid_from" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields.valid_from" class="form-poup-shift-type" required></b-form-datepicker> -->
                                      </div>
                                       <div class="col-md-3">
                                          <label class="treat-label">End Date</label>
                                          <input type="date"  id="valid_to" v-model="edit_discount_fields.valid_to" placeholder="11/31/2020" class="form-poup-shift-type" required>
                                          <!-- <b-form-datepicker id="valid_to" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields.valid_to" class="form-poup-shift-type" required></b-form-datepicker> -->
                                      </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          <hr style="border: 1px solid #f6f6f6;width: 100%;margin: 0;" />
                            <div class="modal-body"> 
                              <div class="preyday">
                                <h6>Blast Details</h6>
                                <p>Your blast will only go to customers that have purchased from you. </p>
                              </div> 
                              <div class="prey_list">
                                <div class="col-md-12">
                                  <div class="bl_details">
                                    <div class="msg_email">
                                      <p><i class="far fa-envelope"></i><span>  {{ edit_discount_fields.emailcount }} emails </span></p>
                                    </div>
                                    <div class="msg_email">
                                      <p><i class='fas fa-mobile-alt'></i><span>{{ edit_discount_fields.phonecount }} text messages</span></p>
                                    </div>
                                    <div class="msg_email">
                                      <h3>$5.00 x  2 day(s)</h3>
                                    </div>
                                  </div>  
                                </div>
                              </div>
                              <div class="prey_list tlt_sec">
                                <div class="col-md-12">
                                  <div class="bl_details">
                                    <div class="msg_email tlt_smg">
                                      <p><span>{{edit_discount_fields.emailcount + edit_discount_fields.phonecount}} total messages</span></p>
                                    </div>
                                   
                                    <div class="msg_email">
                                      <h3>$ {{edit_discount_fields.amount_to_pay}}</h3>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                              <div class="agree_check">
                                <input type="checkbox" id="terms_and_service" name="terms_and_service" v-model="edit_discount_fields.terms_and_service">
                                <label for="vehicle1">I agree to Fireflyy Blasts <span @click="openLinkInNewTab">Terms of Service</span></label><br>
                              </div>
                                <div class="evnt_btns">
                                  <div v-if="isLoading" class="loader-overlay123">
                                   <span class="loader123" >
                                    <span class="content"><i style="color: #ff5802;" class="fa fa-spinner fa-spin"></i> Please wait...</span>
                                  </span>
                                </div>
                                  <div class="innterbtn-edit cancel_event">
                                      <button type="button" data-dismiss="modal" aria-label="Close"> Cancel</button>
                                  </div>
                                  <div class="Evt_btn">
                                      <button type="submit" class="btn add-emp-btn float-right" id="submit-blast" @click.prevent="submit_blast_validate()">Save Changes</button>
                                  </div>
                                </div>
                                <div class="row text-center">
                                <div v-if="errors" class="text-danger col-12">
                                  <div v-for="(v, k) in errors" :key="k">
                                    <p v-for="error in v" :key="error" class="text-sm">
                                      {{ error }}
                                    </p>
                                  </div>
                                </div>
                                <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                                  <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                                    {{ problem_in_save_error }}
                                  </p>
                                </div>
                              </div>
                            </div>
                      </div>
              </form>
              </div>
            </div>
              <!-- blast popup end -->
  </div>
</template>
<script>
export default {
  data() {
      return {
          blastmenus:{},
          isLoading: false,
          errors: {},
          problem_in_save_error:'',
          edit_discount_fields: {
            menu_item_ids: [],
            title: '',
            valid_from: '',
            valid_to: '',
            treat: '',
            menu_id:'',
            type:'',
            sel_menu_item_ids:[],
            active:'',
            collection_for:'',
            reaminingdays:'',
            userdetails: {},
            emailcount: '',
            terms_and_service:0,
            amount_to_pay: 10.00,
            phonecount: '',
            mailtype: 'Text and Email'
          }
      }
  },
  computed: {
    getFormattedOptionText() {
      return `<p>${this.edit_discount_fields.title} <br /><span>${this.edit_discount_fields.reaminingdays}</span></p>`;
    }
  },
  mounted(){
    this.get_blast();
  },
  methods:{
    openLinkInNewTab() {
      const url = '/owner/manage_treats_rewards/policy';
        window.open(url, '_blank');
      },
      isEndDatePassed(endDate) {
          const currentDate = new Date();
          const parsedEndDate = new Date(endDate);

          // Extract year, month, and day components from the dates
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth();
          const currentDay = currentDate.getDate();
          const parsedEndYear = parsedEndDate.getFullYear();
          const parsedEndMonth = parsedEndDate.getMonth();
          const parsedEndDay = parsedEndDate.getDate();

          // Compare only the dates
          return (
              currentYear > parsedEndYear ||
              (currentYear === parsedEndYear && currentMonth > parsedEndMonth) ||
              (currentYear === parsedEndYear && currentMonth === parsedEndMonth && currentDay > parsedEndDay)
          );
      },
      get_blast(){
      axios.get('/owner/manage_treats_rewards/get_blast').then(response => {
      this.blastmenus = response.data.response.blast;
      }).catch(error => {

      });
    },
    EditblastMenu(udId){
      $('#blastModel123').modal('show');
        let formData = new FormData();
        formData.append("collection_for", udId);
        axios.post('/owner/manage_treats_rewards/edit_blast', formData).then(response => {
          console.log('/owner/manage_treats_rewards/edit_blast');
          console.log(response);
          this.edit_discount_fields.collection_for = response.data.response.blast.collection_for;
          this.edit_discount_fields.title = response.data.response.blast.blast_text;
          this.edit_discount_fields.valid_from = response.data.response.blast.start_date;
          this.edit_discount_fields.valid_to = response.data.response.blast.end_date;
          this.edit_discount_fields.emailcount = response.data.response.blast.email_count;
          this.edit_discount_fields.phonecount = response.data.response.blast.phone_text_count;
          this.edit_discount_fields.terms_and_service = response.data.response.blast.terms_and_service;
          this.edit_discount_fields.amount_to_pay = response.data.response.blast.amount_to_pay;
          this.edit_discount_fields.reaminingdays = response.data.response.blast.blast_menu;
          this.get_blast();
          
        }).catch(error => {
        });
    },
  }
}
</script>