<template>
<form id="ccfrm" method="post"  @submit.prevent="make_payment">
         <div class="form-errors"></div>
            <div class="inner-form-credit-card">
              <!-- <div class="row">
                <div class="col-sm-12">
                 <div class="form-group">
                    <label for="cc-billing_zipcode" class="control-label">Billing zipcode</label>
                    <input id="cc-billing_zipcode" type="text" class="input-lg form-control cc-cvc new-card-elements-cls" v-model="cardfields.billing_zipcode" autocomplete="off"  data-parsley-required-message="Please enter billing zipcode" data-parsley-validation-threshold="1" data-parsley-trigger="keyup" @click="handleNewCardElements">
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col-sm-12">
                <div class="iframe-card-details">
                  <div class="form-group">
                    <label for="cc-billing_zipcode" class="control-label">Billing zip code</label>
                    <input id="cc-billing_zipcode" type="text" class="input-lg form-control cc-cvc new-card-elements-cls" style="border:1px solid #ced4da" v-model="cardfields.billing_zipcode" autocomplete="off"  data-parsley-required-message="Please enter billing zip code" data-parsley-validation-threshold="1" data-parsley-trigger="keyup" @click="handleNewCardElements">
                    <label>CARD NUMBER<div id="card-number-element-guest"></div></label>
                    <label>CARD EXPIRY<div id="card-expiration-element-guest"></div></label>
                    <label>CARD CVC<div id="card-cvv-element-guest"></div></label>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div class="credit-card-btn-footer">
              <div class="row">
                <div class="col-sm-6">
                  <button class="btn" type="button" onclick="closewidget('creditcard')">Cancel</button>
                </div>
                <div class="col-sm-6">
                  <button @click.prevent="validate_payment()" id="makepaymentbtn" class="bottom-fix-pay-btn btn mt-3 btn-save">Pay</button>
                </div>
              </div>
            </div>
          </form>
</template>
<script>
export default {
  props: ['restaurant_id','qrtokenid'],
  data() {
      return {
        showOrderCompleted:false,
        isCartLoading:true,
        lastOrderId:'',
        cardfields:{
          cardNumber:'',
          cardExpiry:'',
          cardCvv:'',
          cardPostal:'12345',
          fname:'',
          lname:'',
          email:'',
          billing_zipcode:''
        },
        validation_errors:{},
        error:'',
        errors:{},
        savedcarderror:'',
        is_enable_cvv:false,
        platform_fee_label:'',
        connaccid:'',
        paymntkeyval:'',
        tilledobj: "",
        tilledform:"",
        payment_intent_client_secret:'',
        ordid:'',
        payment_method_id:'',
        sandbox_mode_status:''
      }
  },
  components:{
    // 'order-completed-page': orderCompletedPage,
    // 'guest-popup': guestPopup,
  },
  async mounted() {
      $('#makepaymentbtn').prop('disabled',true)

      let postData = new FormData();
      postData.append("restaurant_id", this.restaurant_id);
      await axios.post('/api/restaurant-settings',postData).then(response => {
        this.platform_fee_label = response.data.data.platform_fee_label;
        this.connaccid = response.data.data.accountId;
        this.paymntkeyval = response.data.data.tilled_p_key;
        this.sandbox_mode_status = response.data.data.sandbox_mode_status;
      });

      var account_id = this.connaccid;
      var pk_PUBLISHABLE_KEY = this.paymntkeyval;
      const tilledAccountId = account_id;
      //console.log("tilledAccountId",tilledAccountId);
      if(this.sandbox_mode_status == true){
        this.tilledobj = new Tilled(
          pk_PUBLISHABLE_KEY,
          tilledAccountId,
          { sandbox: this.sandbox_mode_status }

          );
      }else{
        this.tilledobj = new Tilled(
          pk_PUBLISHABLE_KEY,
          tilledAccountId
          );
      }
        this.tilledform =  await this.tilledobj.form({
          payment_method_type: 'card',
        });

        // Optional styling of the fields
        const fieldOptions = {
          styles: {
            base: {
              fontFamily: 'Helvetica Neue, Arial, sans-serif',
              color: '#304166',
              fontWeight: '400',
              fontSize: '16px',
            },
            invalid: {
              ':hover': {
                textDecoration: 'underline dotted red',
              }
            },
            valid: {
              color: '#00BDA5',
            },
          },
        };

        const cardNumberFieldGst = this.tilledform.createField('cardNumber', fieldOptions).inject('#card-number-element-guest');
        this.tilledform.createField('cardExpiry', { ...fieldOptions, placeholder: 'MM/YY' }).inject('#card-expiration-element-guest');
        this.tilledform.createField('cardCvv', fieldOptions).inject('#card-cvv-element-guest');
        this.tilledform.build();

        cardNumberFieldGst.on('ready', (evt) => {
          $('#makepaymentbtn').prop('disabled',false)
        });


  },
  created: function () {
  },
  methods:{

    setUpStripCustomerCards(){
      let postData = new FormData();
      axios.post('/patron/card-list',postData).then(response => {
        if(response.data.response.card_list.length){
          this.saved_card_list = response.data.response.card_list;
          $('#savedcard-loader').hide();
          this.is_saved_card_list = true;
        }else{
          $('#savedcard-loader').hide();
          this.is_saved_card_list = false;
        }

      }).catch(error => {
        this.savedcarderror = error.response.data.message || {};
        this.is_saved_card_list = false;
        $('#savedcard-loader').hide();
      });
    },
    handleCvv(ccid){
      $(".card-cvv-cls").removeAttr("required");
      $('.card-cvv-cls').hide();
      $('#card-cvv-id-'+ccid).show();
      $('.new-card-elements-cls').each(function(){
        $(this).val("");
      });
      this.cardfields.cardNumber = '';
      this.cardfields.cardExpiry = '';
      this.cardfields.cardCvv = '';
    //   this.cardfields.fname = '';
    //   this.cardfields.lname = '';
    //   this.cardfields.email = '';
      this.cardfields.cardPostal = '12345';
      this.save_card_check = false;
      $('#cardsaveid').prop('checked', false);
    },
    handleNewCardElements(){
      $('.form-check-input-radio').prop('checked', false);
      $('.card-cvv-cls').each(function(){
        $(this).val("");
        $(this).hide();
      });
      this.cardfields.selectedcheckedcard = '';
      this.cardfields.selectedcheckedcardcvv = '';
    },
    validate_payment(){
      let vm = this;
      vm.make_payment();
    },

    make_payment(){
          let vm = this;

            $('#makepaymentbtn').prop( "disabled", true );
            $('#makepaymentbtn').text("Wait...");
            this.tilledobj.createPaymentMethod({
              form: vm.tilledform,
              billing_details: {
                // name: vm.cardfields.fname+ +vm.cardfields.lname, // required
                address: {
                  country: 'US', // required
                   zip: vm.cardfields.billing_zipcode,
                  // state: vm.loginUser.state,
                  // city:  vm.loginUser.city,
                  // street: vm.loginUser.billing_address,
                },
                // email: vm.cardfields.email
              },
              }).then((paymentpm) => {
                let formData = new FormData();
                formData.append("pmdata", JSON.stringify(paymentpm));
                formData.append("restaurant_id", vm.restaurant_id);
                formData.append("user_id", 0);
                formData.append("tip_perc", vm.tipval);
                formData.append("points", 0);
                // formData.append("first_name", vm.cardfields.fname);
                // formData.append("last_name", vm.cardfields.lname);
                // formData.append("name", vm.cardfields.fname+ +vm.cardfields.lname);
                // formData.append("email", vm.cardfields.email);
                formData.append("method_name", 'createPaymentMethod');
                if($('#cardsaveid').is(':checked')){
                  formData.append("save_card_check", 'yes');
                }else{
                  formData.append("save_card_check", 'no');
                }
                formData.append("qrtoken_id", this.qrtokenid);
                axios.post('/patron/new-create-tilled-paymentintent',formData).then(response => {
                  console.log("payment_intent_client_secret",response);
                  vm.payment_intent_client_secret = response.data.data.payment_client_secret;
                  vm.ordid = response.data.data.ord_id;
                  vm.payment_method_id = response.data.data.payment_method_id;
                  this.tilledobj.confirmPayment(vm.payment_intent_client_secret,{
                      payment_method: vm.payment_method_id
                    })
                    .then((payment) => {
                        console.log("pp paymentmethodrs ==",payment);
                        let payResData = new FormData();
                        if($('#cardsaveid').is(':checked')){
                          payResData.append("save_card_check", 'yes');
                        }else{
                          payResData.append("save_card_check", 'no');
                        }
                        payResData.append("method_name", 'confirmPayment');
                        payResData.append("pdata", JSON.stringify(payment));
                        payResData.append("order_id", vm.ordid);
                        payResData.append("restaurant_id", vm.restaurant_id);
                        payResData.append("user_id", 0);
                        payResData.append("tip_perc", vm.tipval);
                        // payResData.append("first_name", vm.cardfields.fname);
                        // payResData.append("last_name", vm.cardfields.lname);
                        // payResData.append("name", vm.cardfields.fname+ +vm.cardfields.lname);
                        // payResData.append("email", vm.cardfields.email);
                        payResData.append("qrtoken_id", vm.qrtokenid);
                        axios.post('/patron/new-save-order-payment',payResData).then(response => {
                          console.log("payment res ==", response);
                         // $('#confirmCartCount').text('0');
                          //vm.showOrderCompleted = true;
                         // vm.lastOrderId = response.data.data;

                          if(response.data.message == 'success'){
                            window.location.href = "/customer/thank-you";
                          }
                        }).catch(error => {
                          console.log("payment res  errr==", error.response.data.data);
                          if(error.response.data.code == 422){
                            vm.error = error.response.data.data.last_payment_error;
                          }
                          $('#makepaymentbtn').prop( "disabled", false );
                          $('#makepaymentbtn').text("Place Order");
                        })
                      },
                      (err) => {
                          console.log("err pp ==",err);
                          vm.error = err.message;
                          $('#makepaymentbtn').prop( "disabled", false );
                          $('#makepaymentbtn').text("Place Order");

                          let paymentResData = new FormData();
                          paymentResData.append("method_name", 'confirmPayment');
                          paymentResData.append("order_id", vm.ordid);
                          paymentResData.append("restaurant_id", vm.restaurant_id);
                          paymentResData.append("user_id", 0);
                          paymentResData.append("payment_res", JSON.stringify(err));
                          paymentResData.append("payment_res_type", 'error');
                          paymentResData.append("qrtoken_id", vm.qrtokenid);
                          axios.post('/api/save-paymentGateway-response',paymentResData).then(response => {
                          });
                      },
                    );
                }).catch(error => {
                  vm.error = "something went wrong";
                  $('#makepaymentbtn').prop( "disabled", false );
                  $('#makepaymentbtn').text("Place Order");
                });
              },
              (err) => {
                console.log("333payment res  errr==", err);
                vm.error = err.message;
                $('#makepaymentbtn').prop( "disabled", false );
                $('#makepaymentbtn').text("Place Order");
                let paymentResData = new FormData();
                paymentResData.append("method_name", 'createPaymentMethod');
                paymentResData.append("order_id", vm.ordid);
                paymentResData.append("restaurant_id", vm.restaurant_id);
                paymentResData.append("user_id", 0);
                paymentResData.append("payment_res", JSON.stringify(err));
                paymentResData.append("payment_res_type", 'error');
                paymentResData.append("qrtoken_id", vm.qrtokenid);
                axios.post('/api/save-paymentGateway-response',paymentResData).then(response => {
                });
              }
            );

        },
        callCartMenu(){
          location.reload();
        }
  }
}


</script>
