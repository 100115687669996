<template>
  <div class="container">


    <div >
      <div v-if="load_order_receipt" class="d-flex justify-content-center">
        <!-- <div class="patron-loader spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div> -->
      </div>
      <div class="" v-else>
        <span>{{noreceipttext}}</span>
      </div>
      <div  class="row ">
        <div class="col-md-12" v-if="receiptdetailfound">

          <div class="receipt-yours-screen text-center">
            <h2>Thanks, {{userdetail.firstname}} {{userdetail.last_name}}!</h2>
            <p>Your order number is:{{receiptdetail.id}}
            </P>
            <p>
              <span class="receivcpytxt" style="font-weight:bold;">You will receive a text when your order is ready.</span>
            </p>
            <p><span class="receivcpytxt" style="font-weight:bold;">Transaction # {{receiptdetail.payment_gateway_order_id}}</span></p>

            </div>
            <div class="receiptamtdetail">

                <div v-for="(item,index) in receiptdetail.order_items" class="receipt_item">
                  <div class="row">
                    <div class="col-sm-7 col-7">
                      <div class="receipt_name">
                        <span>{{item.quantity}}</span>
                        <p>{{item.name}}
                          <span  style="color: #ff5802;float: left;width: 100%;font-size:12px">{{item.other_data}}</span>
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-5 col-5" style="padding-left: 0px;">
                      <div class="item-price"><span>$ {{item.quantity * item.price}}</span>
                    </div>
                  </div>
                </div>
              </div>


            <div class="col-lg-12">
              <div class="cart-items-amt-list ">
                <div class="clearfix menu-list-recepit-list">
                  <h4 class="float-left">Subtotal</h4>
                  <span class="float-right">${{parseFloat(subtotal,2).toFixed(2)}}</span>
                </div>
                <div v-if="is_discount" class="clearfix menu-list-recepit-list">
                   <h4 class="float-left" style="width: 72%;"> Discount Applied: {{discounttitle}} </h4>
                   <span class="float-right"><strong style="color: #019f01;">-${{discount_amt}}</strong></span>
                </div>
                <div class="clearfix menu-list-recepit-list">
                  <h4 class="float-left">Tip</h4>
                  <span class="float-right">${{receiptdetail.tip.toFixed(2)}}</span>
                </div>
                <div class="clearfix menu-list-recepit-list">
                  <h4 class="float-left">Taxes & Fees</h4>
                  <span class="float-right">${{receiptdetail.tax.toFixed(2)}}</span>
                </div>
                <div class="clearfix menu-list-recepit-list">
                  <h4 class="float-left">{{platform_fee_label}}</h4>
                  <!-- <span class="float-right">${{fields.final_tax}}</span> -->
                  <span class="float-right">${{receiptdetail.application_fee.toFixed(2)}}</span>
                </div>
                <!-- <div v-if="is_discount" class="clearfix menu-list-recepit-list ">
                  <h4 class="float-left discounttxt">Discount Applied: {{discounttitle}}</h4>

                  <span class="float-right" style="color: #019f01;">${{discount_amt}}</span>
                </div> -->


                <div class="clearfix menu-list-recepit-list total_recipt">
                  <h4 class="float-left">Total Paid</h4>
                  <!-- <span class="float-right">${{fields.final_tax}}</span> -->
                  <span class="float-right">${{receiptdetail.final_amount_received.toFixed(2)}}</span>
                  <br/>
                  <p>{{payment_detail.card_brand}} ****{{payment_detail.last4}}</p></h4>
                </div>

              </div>
            </div>

            </div>


        </div>

    </div>
          <div v-if="receiptdetailfound == true" class="download_btn clearfix">
            <!-- <a href="#" @click.prevent="downloadorderreceipt(receiptfile)">DOWNLOAD RECEIPT</a> -->
            <div class="download_receipt_btn" @click.prevent="downloadorderreceipt(receiptfile)">DOWNLOAD RECEIPT</div>
          </div>
          <div v-else class="download_btn clearfix" style="margin-top:5px;">
             <div class="">There is no receipt generated.</div>
             <!-- <div class="">LOGIN INTO YOUR ACCOUNT</div> -->
          </div>
        
          <div class="new-total-pay-tip" v-if="receiptdetailfound">
            <div class="row" >
              <div class="col-md-12" style="padding-bottom:10px">
                <div class="top-tip-sec-bottom frontend-cust-rating" >
                  <div class="clearfix" v-if="!ratingstatus">
                    <span class="float-left">Please rate your service</span>
                   </div>
                   <!-- <div class="rating_star_border" v-if="ratingstatus && enableratingcomment">
                     <star-rating :rating="submittedrating" :read-only="true"  :active-color="['#fff','#fff']" inactive-color="#ccc" :increment="1" :star-size="25" :rounded-corners="true"  :show-rating="false" >
                     </star-rating>
                   </div>

                    <div class="" v-if="!ratingstatus">
                      <star-rating :animate="true" active-color="#fff" inactive-color="#ccc" :increment="1" :star-size="25" :rounded-corners="true"  :show-rating="false" @rating-selected="setRating" >
                      </star-rating>
                    </div> -->

                    <div class="rating_star_border" v-if="ratingstatus && enableratingcomment">
                      <star-rating :rating="submittedrating" :read-only="true"  :active-color="['#ff5802','#ff5802']" inactive-color="#ccc" :increment="1" :star-size="25" :rounded-corners="true"  :show-rating="false" >
                      </star-rating>
                    </div>

                     <div class="" v-if="!ratingstatus">
                       <star-rating :animate="true" active-color="#ff5802" inactive-color="#ccc" :increment="1" :star-size="25" :rounded-corners="true"  :show-rating="false" @rating-selected="setRating" >
                       </star-rating>
                     </div>

                </div>
              </div>
            </div>
            <div class="row" id="ratingtextsection" v-if="enableratingcomment">
              <div class="col-md-12" style="padding:0 20px">
                <div class="input-group">
                  <input type="text" class="input-lg form-control cc-cvc new-card-elements-cls" placeholder="Tell us more..."  aria-describedby="basic-addon2" id="ratingtxtinfo">
                  <div class="input-group-append">
                    <button class="btn sendratingbtn" type="button" @click="saveratingcomment();"><i class="fa fa-paper-plane" aria-hidden="true" style="color:#fff"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <p style="text-align: center;padding: 10px;font-weight: bold;margin-bottom: 0px;">
              {{ratingtxt}}
            </p>
          </div>
    </div>
  </div>
</template>

<script>
    export default {
    props: ['restaurant_id','auth_user','currenttab','country_id','qrtokenid','table_id','restauranttype'],
    data() {
        return {
          load_order_receipt:true,
          receiptdetailfound:false,
          discounttitle:'',
          receiptdetail:[],
          userdetail:[],
          payment_detail:[],
          subtotal:0,
          restaurant_rating: 0,
          platform_fee_label:'...',
          noreceipttext:'Loading...',
          receiptfile:'',
          ratingstatus:false,
          ratingtxt:'',
          is_discount:false,
          discounttitle:'',
          discount_amt:0,
          settruefalse:false,
          submittedrating:0,
          submittedratingid:null,
          enableratingcomment:false,
          orderidv:null
        }
    },
    components:{
    },
    created: function () {
      this.get_restaurant_settings();
      this.getreceipt();
    },
    mounted() {
      let vm = this;
    },
    methods:{
      get_restaurant_settings(){
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        axios.post('/api/restaurant-settings',postData).then(response => {
          this.platform_fee_label = response.data.data.platform_fee_label;
        });
      },
      getreceipt(){
        this.payment_detail = [];
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        postData.append("qrtoken_id", this.qrtokenid);
        console.log(`qrtoken_id = ${this.qrtokenid} and restaurant_id = ${this.restaurant_id}`);
        axios.post('/api/get-customer-order-receipt',postData).then(response => {
          if(response.data.data.order_detail != null){
            this.orderidv = response.data.data.order_detail.id;
            this.receiptdetailfound = true;
            this.load_order_receipt = false;
            this.noreceipttext = '';
            this.receiptdetail = response.data.data.order_detail;
            this.userdetail = JSON.parse(this.receiptdetail.guest_user_details);
            this.payment_detail = response.data.data.payment_detail;
            this.subtotal = response.data.data.subtotal;
            if(this.receiptdetail.discount_applied_amount > 0){
              this.discounttitle = this.receiptdetail.discount_detail ;
              this.is_discount = true;
              this.discount_amt = this.receiptdetail.discount_applied_amount;
            }else{
              this.discounttitle = '';
              this.is_discount = false;
              this.discount_amt = 0;
            }
            this.receiptfile = response.data.data.orderreceiptname;
            this.ratingstatus = response.data.data.ratingstatus;
            this.submittedrating = response.data.data.submittedrating;
          }else{
            this.noreceipttext = 'Please place your order first.';
            this.load_order_receipt = false;
            this.userdetail = []
          }
        });
      },

      setRating: function(restaurant_rating){
        this.restaurant_rating=restaurant_rating;
        let postData = new FormData();
        postData.append('onlyrating',1);
        postData.append("start_rating", this.restaurant_rating);
        postData.append("restaurant_id", this.restaurant_id);
        postData.append("qrtoken_id", this.qrtokenid);
        postData.append("order_id", this.receiptdetail.id);
        postData.append("user_detail", this.userdetail);
        postData.append("vibe_question_id", '');
        postData.append("collected_points",'');
        axios.post('/patron/save-customer-starrating',postData).then(response => {
          this.submittedrating = this.restaurant_rating;
          this.settruefalse = true;
          this.ratingstatus = true;
          this.enableratingcomment = true;
          this.ratingtxt = 'Thanks for the rating.';
        });
      },
      downloadorderreceipt(file) {
      //  '/customer/downloadorderreceipt/'.this.orderidv;
        //  window.open(window.location.origin+"/customer/downloadorderreceipt/" + file, "_blank");
          window.open(window.location.origin+  '/customer/downloadorderreceipt/'+this.orderidv);
      },
      saveratingcomment(){
        let ratingtxtinfo = $('#ratingtxtinfo').val();
        if(ratingtxtinfo == ''){
          alert("Please enter your comment");
          return false;
        }
        let postcommentData = new FormData();
        postcommentData.append('onlyrating',0);
        postcommentData.append("restaurant_id", this.restaurant_id);
        postcommentData.append("qrtoken_id", this.qrtokenid);
        postcommentData.append("order_id", this.receiptdetail.id);
        postcommentData.append("rating_comments",ratingtxtinfo);
        axios.post('/patron/save-customer-starrating',postcommentData).then(response => {
          $('#ratingtxtinfo').val('');
          this.enableratingcomment = false;
        });


      }
    },

}

</script>
