<template>
  <div class="paymentpagesection" id="paymentpagesection">
    <div class="your-order-list-inner clearfix" style="margin-bottom: 0">
      <h3 class="float-left">Payment Info</h3>
            
      <span class="float-right your_order_add_btn" @click="callMenuTab"
        ><i class="fas fa-pencil-alt"></i> EDIT ORDER</span
      >
    </div>
    <div class="card-body payment_info_fscrl">
      <div class="row">
        <form
          method="POST"
          id="frmpaymentbasicinfo"
          name="frmpaymentbasicinfo"
          v-show="show_basic_info_form"
        >
          <div class="col-md-12">
            <div class="form-group cart_label">
              <label for="cc-fname" class="control-label">First Name</label>
              <input
                id="cc-fname"
                type="text"
                autocomplete="off"
                placeholder="First Name"
                data-parsley-required-message="Please enter first name"
                required
                class="input-lg form-control cc-cvc new-card-elements-cls"
                v-model="cardfields.fname"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group cart_label">
              <label for="cc-lname" class="control-label">Last Name</label>
              <input
                id="cc-lname"
                type="text"
                autocomplete="off"
                placeholder="Last Name"
                data-parsley-required-message="Please enter last name"
                required
                class="input-lg form-control cc-cvc new-card-elements-cls"
                v-model="cardfields.lname"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group cart_label">
              <label for="cc-email" class="control-label">Email Address</label>
              <input
                id="cc-email"
                type="email"
                autocomplete="off"
                placeholder="example@gmail.com"
                data-parsley-required-message="Please enter email address"
                class="input-lg form-control cc-cvc new-card-elements-cls"
                v-model="cardfields.email"
              />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group cart_label">
              <label for="cc-phnnum" class="control-label">Phone Number</label>
              <input
                id="cc-phnnum"
                type="text"
                autocomplete="off"
                data-parsley-required-message="Please enter phone number"
                required
                class="input-lg form-control cc-cvc new-card-elements-cls"
                v-model="cardfields.phone"
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group cart_label">
              <label for="cc-billing_zipcode" class="control-label"
                >Billing zip code</label
              >
              <input
                id="cc-billing_zipcode"
                type="text"
                autocomplete="off"
                data-parsley-required-message="Please enter billing zipcode"
                required="required"
                class="input-lg form-control cc-cvc new-card-elements-cls"
                v-model="cardfields.billing_zipcode"
              />
            </div>
          </div>
        </form>
        <div class="col-md-12">
          <div class="col-md-12" v-show="show_payment_buttons">
            <div class="form-group cart_label">
              <div class="btn payment_mode_btn" id="apple_pay_btn"></div>
            </div>
            <div class="form-group apple_btn">
              <button
                class="btn payment_mode_btn float-unset"
                id="card_pay_btn"
                @click="validate_payment_mode"
              >
                Card Pay
              </button>
            </div>

          


          </div>
        </div>

        <form
          method="POST"
          id="frmpaymentpagesection"
          name="frmpaymentpagesection"
          v-show="display_card_details_form"
        >
          <div
            class="col-md-12 iframe-card-details card_details_new"
            id="tilledccelements"
            style="display: none"
          >
            <label
              >Card Number
              <div id="card-number-element-guest"></div
            ></label>
            <label class="col-md-6 Cexpiry"
              >Expiration
              <div id="card-expiration-element-guest"></div
            ></label>
            <label class="col-md-6 cardCVC"
              >CVC
              <div id="card-cvv-element-guest"></div
            ></label>
          </div>
        </form>
        <div class="text-danger mt-2" id="showPaymentErr">
          <div
            v-if="validation_errors"
            class="text-danger text-danger text-center"
          >
            <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
              {{ v[0] }}
            </p>
          </div>
          <div v-if="error" class="text-danger text-danger text-center">
            <p v-if="error" :key="error" class="text-sm">
              {{ error }}
            </p>
          </div>
          <div v-if="errors" class="text-danger text-danger text-center">
            <div v-for="(v, k) in errors" :key="k">
              <p v-for="error in v" :key="error" class="text-sm">
                {{ error }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new-total-pay-tip">
      <div class="row">
        <div class="col-md-12">
          <div class="top-tip-sec-bottom">
            <div class="clearfix">
              <span class="float-left">Total</span>
              <span class="float-right"
                >$ {{ parseFloat(finalcarttotal).toFixed(2) }}</span
              >
            </div>
          </div>
          <div>
            
            <button
              @click.prevent="validate_info()"
              type="button"
              id="makepaymentbtn"
              class="bottom-fix-pay-btn btn"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import orderCompletedPage from "./OrderCompletedPageComponent";
export default {
  props: [
    "restaurant_id",
    "loginUser",
    "qrtokenid",
    "tipval",
    "connaccidv",
    "paymntkeyvalv",
    "enableguestpay",
    "finalcarttotal",
    "currenttab",
    "selectedTime",
    "selectedItem",
    "tiptype",
  ],
  data() {
    return {
      showOrderCompleted: false,
      isCartLoading: true,
      lastOrderId: "",
      cardfields: {
        cardNumber: "",
        cardExpiry: "",
        cardCvv: "",
        cardPostal: "12345",
        fname: "",
        lname: "",
        phone: "",
        email: "",
        billing_zipcode: "",
      },
      validation_errors: {},
      error: "",
      errors: {},
      savedcarderror: "",
      is_enable_cvv: false,
      platform_fee_label: "",
      connaccid: "",
      paymntkeyval: "",
      tilledobj: "",
      tilledform: "",
      payment_intent_client_secret: "",
      ordid: "",
      payment_method_id: "",
      sandbox_mode_status: "",
      show_payment_buttons: false,
      display_card_details_form: false,
      show_basic_info_form: true,
      loggedUserId: this.loginUser ? this.loginUser.id : 0,
      payment_mode: "",
      final_amount: 0,
    };
  },
  components: {},
  async mounted() {
    console.log('SelelectedTime = ', this.selectedTime);
    console.log('SelelectedItem = ', this.selectedItem);
    let vm = this;
    $("#makepaymentbtn").prop("disabled", true);
    let postData = new FormData();
    postData.append("restaurant_id", this.restaurant_id);
    await axios.post("/api/restaurant-settings", postData).then((response) => {
      this.platform_fee_label = response.data.data.platform_fee_label;
      this.connaccid = response.data.data.accountId;
      this.paymntkeyval = response.data.data.tilled_p_key;
      this.sandbox_mode_status = response.data.data.sandbox_mode_status;
    });

    var account_id = this.connaccid;
    var pk_PUBLISHABLE_KEY = this.paymntkeyval;
    const tilledAccountId = account_id;
    //const sandbox_mode_status = "{{ Config::get('tilledpayment.sandbox_mode_status') }}";
    //alert(this.sandbox_mode_status)
    if (this.sandbox_mode_status == true) {
      this.tilledobj = new Tilled(pk_PUBLISHABLE_KEY, tilledAccountId, {
        sandbox: this.sandbox_mode_status,
      });
    } else {
      this.tilledobj = new Tilled(pk_PUBLISHABLE_KEY, tilledAccountId);
    }

    this.tilledform = await this.tilledobj.form({
      payment_method_type: "card",
    });

    // Optional styling of the fields
    const fieldOptions = {
      styles: {
        base: {
          fontFamily: "Helvetica Neue, Arial, sans-serif",
          color: "#304166",
          fontWeight: "400",
          fontSize: "16px",
        },
        invalid: {
          ":hover": {
            textDecoration: "underline dotted red",
          },
          color: "#dc3545",
        },
        valid: {
          color: "#495057",
        },
      },
    };

    const cardNumberField = this.tilledform
      .createField("cardNumber", fieldOptions)
      .inject("#card-number-element-guest");
    this.tilledform
      .createField("cardExpiry", fieldOptions)
      .inject("#card-expiration-element-guest");
    this.tilledform
      .createField("cardCvv", fieldOptions)
      .inject("#card-cvv-element-guest");

    /**
     *  Apple Pay Logic Start
     */
     
    let response = await this.apple_payment();
	if (response && response.data && response.data.data && response.data.data.payment_client_secret !== undefined && response.data.data.payment_client_secret !== null) {
				
			vm.payment_intent_client_secret = response.data.data.payment_client_secret;
			vm.ordid = response.data.data.ord_id;
			vm.payment_method_id = response.data.data.payment_method_id;
			vm.final_amount = response.data.data.final_amount;
			const paymentRequest = this.tilledobj.paymentRequest({
			  total: {
				label: "Apple Payment",
				amount: vm.final_amount,
			  },
			});

			var prButton = this.tilledform.createField("paymentRequestButton", {
			  paymentRequest: paymentRequest,
			});

			let apple_pay_enabled = await paymentRequest.canMakePayment();
			if (apple_pay_enabled) {
			  prButton.inject("#apple_pay_btn");
			}

			paymentRequest.on("paymentmethod", (ev) => {
			  let paymentMethod = ev.paymentMethod;
			  vm.payment_method_id = paymentMethod.id;
			  vm.tilledobj
				.confirmPayment(vm.payment_intent_client_secret, {
				  payment_method: vm.payment_method_id,
				})
				.then(
				  (paymentIntent) => {
					// The payment intent confirmation occurred, but the actual charge may still have failed. Check
					if (
					  paymentIntent.status === "succeeded" ||
					  paymentIntent.status === "processing"
					) {
					  ev.complete("success");
					  window.alert("Successul payment");

					  let payResData = new FormData();
					  if ($("#cardsaveid").is(":checked")) {
						payResData.append("save_card_check", "yes");
					  } else {
						payResData.append("save_card_check", "no");
					  }
					  payResData.append("method_name", "confirmPayment");
					  payResData.append("pdata", JSON.stringify(paymentIntent));
					  payResData.append("order_id", vm.ordid);
					  payResData.append("restaurant_id", vm.restaurant_id);
					  payResData.append("user_id", 0);
					  payResData.append("tip_perc", vm.tipval);
					  payResData.append("tiptype", vm.tiptype);
					  payResData.append("first_name", vm.cardfields.fname);
					  payResData.append("last_name", vm.cardfields.lname);
					  payResData.append("phone", vm.cardfields.phone);
					  payResData.append(
						"name",
						vm.cardfields.fname + +vm.cardfields.lname
					  );
					  payResData.append("email", vm.cardfields.email);
					  payResData.append("qrtoken_id", vm.qrtokenid);
					  payResData.append("order_type", "normal");
					  payResData.append("payment_type", "apple_pay");
            console.log('/patron/save-order-payment === ', payResData);
					  axios
						.post("/patron/save-order-payment", payResData)
						.then((response) => {
             
						  $("#confirmCartCount").text("0");
						  $("#cartCount").text("0");
						  vm.showOrderCompleted = true;
						  vm.lastOrderId = response.data.data;

						  vm.callReceiptTab();
						  vm.$emit("reloadmenus");
						  vm.$emit("reloadreviewordercnt");

						  const successorder = vm.tilledform.teardown();
						  // Callback
						  vm.tilledform.teardown((success) => {
							console.log("fields deleted");
						  });

						  //return false;
						})
						.catch((error) => {
						  if (error.response.data.code == 422) {
							vm.error = error.response.data.data.last_payment_error;
						  }
						  $("#makepaymentbtn").prop("disabled", false);
						  $("#makepaymentbtn").text("Place Order");
						});
					} else {
					  ev.complete("fail");
					  const errMsg = paymentIntent.last_payment_error?.message;
					  alert("Payment failed: " + errMsg);
					}
				  },
				  (err) => {
					ev.complete("fail");

					vm.error = err.message;
					$("#makepaymentbtn").prop("disabled", false);
					$("#makepaymentbtn").text("Place Order");

					let paymentResData = new FormData();
					paymentResData.append("method_name", "confirmPayment");
					paymentResData.append("order_id", vm.ordid);
					paymentResData.append("restaurant_id", vm.restaurant_id);
					paymentResData.append("user_id", 0);
					paymentResData.append("payment_res", JSON.stringify(err));
					paymentResData.append("payment_res_type", "error");
					paymentResData.append("qrtoken_id", vm.qrtokenid);
					paymentResData.append("order_type", "normal");
					paymentResData.append("tip_perc", vm.tipval);
					paymentResData.append("tiptype", vm.tiptype);
					paymentResData.append("first_name", vm.cardfields.fname);
					paymentResData.append("last_name", vm.cardfields.lname);
					paymentResData.append("phone", vm.cardfields.phone);
					payResData.append("payment_type", "apple_pay");
					paymentResData.append(
					  "name",
					  vm.cardfields.fname + +vm.cardfields.lname
					);
					paymentResData.append("email", vm.cardfields.email);
					axios
					  .post("/api/save-paymentGateway-response", paymentResData)
					  .then((response) => {});
				  }
				);
			});

	}

    
     /*
     *  Apple Pay Logic End
     */

    await this.tilledform.build();
    $("#tilledccelements").show();
    this.tilledform.on("validation", (event) => {
      if (event.field) {
        event.field.element.classList.remove("success_tilledinput");
        event.field.element.classList.remove("error_tilledinput");

        if (event.field.valid) {
          event.field.element.classList.add("success_tilledinput");
        } else {
          event.field.element.classList.add("error_tilledinput");
        }
      }
    });
    $("#makepaymentbtn").prop("disabled", false);
  },
  created: function () {},
  methods: {
    callMenuTab() {
      this.$emit("callmenusTabfromcart", "menu");
      $('.new-tablist-tabs a[href="#menu"]').trigger("click");
    },
    callReceiptTab() {
      this.$emit("callreceiptTabfromcart", "receipt");
      $('.new-tablist-tabs a[href="#receipt"]').trigger("click");
    },
    validate_payment() {
      let vm = this;
      let currentqrcode = window.location.pathname.split("/").pop();
      let postDataw = new FormData();
      postDataw.append("restaurant_id", vm.restaurant_id);
      axios.post("/api/restaurant-settings", postDataw).then((response) => {
        if (response.data.data.delaydetail != null) {
          window.location.href = "/restaurant/home/" + currentqrcode;
        }
      });

      $("#frmpaymentpagesection")
        .parsley()
        .whenValidate({})
        .done(function () {
          vm.make_payment();
        });
    },

    validate_info() {
      let vm = this;
      if (vm.payment_mode !== "" && vm.payment_mode == "card_pay_btn") {
        $("#makepaymentbtn").prop("disabled", false);
        this.validate_payment();
      } else if (vm.payment_mode == "") {
        $("#frmpaymentbasicinfo")
          .parsley()
          .whenValidate({})
          .done(function () {
            vm.show_basic_info_form = false;
            $("#makepaymentbtn").prop("disabled", true);
            vm.show_payment_buttons = true;
          });
      }
    },
    make_payment() {
      console.log("Inside make_payment >> ");
      let vm = this;
      $("#makepaymentbtn").prop("disabled", true);
      $("#makepaymentbtn").text("Please Wait...");
      vm.tilledobj
        .createPaymentMethod({
          form: vm.tilledform,
          billing_details: {
            name: vm.cardfields.fname + " " + vm.cardfields.lname, // required
            address: {
              country: "US", // required
              zip: vm.cardfields.billing_zipcode,
            },

            //email: vm.cardfields.email
          },
        })
        .then(
          (paymentpm) => {
            let formData = new FormData();
            formData.append("pmdata", JSON.stringify(paymentpm));
            formData.append("restaurant_id", vm.restaurant_id);
            if(vm.selectedItem && vm.selectedItem !=''){
                  formData.append("selectedTime", vm.selectedTime);
                  formData.append("selecteditem_eventid", vm.selectedItem.id);
                  formData.append("selecteditem_startdate", vm.selectedItem.start_date);
                 
              }
            formData.append("user_id", vm.loggedUserId);
            formData.append("tip_perc", vm.tipval);
            formData.append("tiptype", vm.tiptype);
            formData.append("points", 0);
            formData.append("first_name", vm.cardfields.fname);
            formData.append("last_name", vm.cardfields.lname);
            formData.append("phone", vm.cardfields.phone);
            formData.append("name", vm.cardfields.fname + +vm.cardfields.lname);
            formData.append("email", vm.cardfields.email);
            formData.append("order_type", "normal");
            formData.append("method_name", "createPaymentMethod");
            if ($("#cardsaveid").is(":checked")) {
              formData.append("save_card_check", "yes");
            } else {
              formData.append("save_card_check", "no");
            }
            formData.append("qrtoken_id", vm.qrtokenid);
            axios.post("/patron/create-tilled-paymentintent", formData).then((response) => {
                vm.payment_intent_client_secret =
                  response.data.data.payment_client_secret;
                vm.ordid = response.data.data.ord_id;
                vm.payment_method_id = response.data.data.payment_method_id;
                this.tilledobj
                  .confirmPayment(vm.payment_intent_client_secret, {
                    payment_method: vm.payment_method_id,
                  })
                  .then(
                    (payment) => {
                      let payResData = new FormData();
                      if ($("#cardsaveid").is(":checked")) {
                        payResData.append("save_card_check", "yes");
                      } else {
                        payResData.append("save_card_check", "no");
                      }
                      payResData.append("method_name", "confirmPayment");
                      payResData.append("pdata", JSON.stringify(payment));
                      payResData.append("order_id", vm.ordid);
                      payResData.append("restaurant_id", vm.restaurant_id);
                      payResData.append("user_id", vm.loggedUserId);
                      payResData.append("tip_perc", vm.tipval);
                      payResData.append("tiptype", vm.tiptype);
                      payResData.append("first_name", vm.cardfields.fname);
                      payResData.append("last_name", vm.cardfields.lname);
                      payResData.append("phone", vm.cardfields.phone);
                      payResData.append(
                        "name",
                        vm.cardfields.fname + +vm.cardfields.lname
                      );
                      payResData.append("email", vm.cardfields.email);
                      payResData.append("qrtoken_id", vm.qrtokenid);
                      payResData.append("order_type", "normal");
                      payResData.append("payment_type", "card_pay");
                      axios
                        .post("/patron/save-order-payment", payResData)
                        .then((response) => {
                          if(localStorage.getItem('item')) {
                            localStorage.removeItem('item');
                            localStorage.removeItem('selectedTime');
                          }
                          if(localStorage.getItem('preorder')) {
                            localStorage.removeItem('preorder');
                          }
                          $("#confirmCartCount").text("0");
                          $("#cartCount").text("0");
                         
                          vm.lastOrderId = response.data.data;
                         // Check order status in the backend
                         axios.post('/patron/check-order-status', { order_id: vm.lastOrderId })
                          .then((statusResponse) => {
                              if (statusResponse.data.data.status === 'success') {
                                  vm.showOrderCompleted = true;
                                  vm.callReceiptTab();
                              }else{
                                alert('Payment was not successful, hence the order is not completed.');
                              } 
                          })
                          .catch((statusError) => {
                              console.error('Failed to check order status', statusError);
                          });

                          vm.$emit("reloadmenus");
                          vm.$emit("reloadreviewordercnt");

                          const successorder = vm.tilledform.teardown();
                          // Callback
                          vm.tilledform.teardown((success) => {
                            console.log("fields deleted");
                          });

                          //return false;
                        })
                        .catch((error) => {
                          if (error.response.data.code == 422) {
                            vm.error =
                              error.response.data.data.last_payment_error;
                          }
                          $("#makepaymentbtn").prop("disabled", false);
                          $("#makepaymentbtn").text("Place Order");
                        });
                    },
                    (err) => {
                      vm.error = err.message;
                      $("#makepaymentbtn").prop("disabled", false);
                      $("#makepaymentbtn").text("Place Order");

                      let paymentResData = new FormData();
                      paymentResData.append("method_name", "confirmPayment");
                      paymentResData.append("order_id", vm.ordid);
                      paymentResData.append("restaurant_id", vm.restaurant_id);
                      paymentResData.append("user_id", 0);
                      paymentResData.append("payment_res", JSON.stringify(err));
                      paymentResData.append("payment_res_type", "error");
                      paymentResData.append("qrtoken_id", vm.qrtokenid);
                      paymentResData.append("order_type", "normal");
                      paymentResData.append("tip_perc", vm.tipval);
                      paymentResData.append("tiptype", vm.tiptype);
                      paymentResData.append("first_name", vm.cardfields.fname);
                      paymentResData.append("last_name", vm.cardfields.lname);
                      paymentResData.append("phone", vm.cardfields.phone);
                      payResData.append("payment_type", "card_pay");
                      paymentResData.append(
                        "name",
                        vm.cardfields.fname + +vm.cardfields.lname
                      );
                      paymentResData.append("email", vm.cardfields.email);
                      axios
                        .post(
                          "/api/save-paymentGateway-response",
                          paymentResData
                        )
                        .then((response) => {});
                    }
                  );
              })
              .catch((error) => {
                vm.error = "something went wrong";
                $("#makepaymentbtn").prop("disabled", false);
                $("#makepaymentbtn").text("Place Order");
              });
          },
          (err) => {
            vm.error = err.message;
            $("#makepaymentbtn").prop("disabled", false);
            $("#makepaymentbtn").text("Place Order");
            let paymentResData = new FormData();
            paymentResData.append("method_name", "createPaymentMethod");
            paymentResData.append("order_id", "0");
            paymentResData.append("restaurant_id", vm.restaurant_id);
            paymentResData.append("user_id", 0);
            paymentResData.append("payment_res", JSON.stringify(err));
            paymentResData.append("payment_res_type", "error");
            paymentResData.append("qrtoken_id", vm.qrtokenid);
            paymentResData.append("order_type", "normal");
            axios
              .post("/api/save-paymentGateway-response", paymentResData)
              .then((response) => {});
          }
        );
    },

    validate_payment_mode(event) {
      event.preventDefault();
      let vm = this;
      let mode = event.target.id;
      vm.payment_mode = mode;
      console.log(event.target.id);
      if (mode == "card_pay_btn") {
        vm.display_card_details_form = true;
        vm.show_payment_buttons = false;
        $("#makepaymentbtn").prop("disabled", false);
        $("#makepaymentbtn").text("COMPLETE ORDER");
      }
      if (mode == "apple_pay_btn") {
        vm.display_card_details_form = false;
        // vm.show_payment_buttons = false;
        vm.process_apple_pay();
      }
    },

    async apple_payment() {
      let vm = this;
      let formData = new FormData();
      //   formData.append("pmdata", JSON.stringify(paymentpm));
      formData.append("restaurant_id", vm.restaurant_id);
      formData.append("user_id", 0);
      formData.append("tip_perc", vm.tipval);
      formData.append("tiptype", vm.tiptype);
      formData.append("points", 0);
      formData.append("first_name", vm.cardfields.fname);
      formData.append("last_name", vm.cardfields.lname);
      formData.append("phone", vm.cardfields.phone);
      formData.append("name", vm.cardfields.fname + +vm.cardfields.lname);
      formData.append("email", vm.cardfields.email);
      formData.append("order_type", "normal");
      formData.append("method_name", "createPaymentMethod");
      if ($("#cardsaveid").is(":checked")) {
        formData.append("save_card_check", "yes");
      } else {
        formData.append("save_card_check", "no");
      }
      formData.append("qrtoken_id", vm.qrtokenid);
      let response = await axios.post("/patron/create-tilled-paymentintent",
        formData
      );
      return new Promise(function (resolve, reject) {
        resolve(response);
      });
    },
  },
};
</script>
