<template>
  <div class="row">
      <div class="col-lg-12">
          <div class="card card-body flex-fill w-100">
              <div class="modal-header add-schedule-model-header-treat">
                  <h5 class="modal-title add-treat-rewards">Treat & Rewards</h5>
              </div>
              <form>
                  <div >

                      <div class="clearfix table-card-title-reward">
                          <p class="card-title mb-0 float-left assign-rewards-points">Use treats to reward customers.</p>
                      </div>
                      <!-- <div class="shift-type rewardcard"  id="card-reward-id">
                        <span class="loader" >
                        <span class="content"><i class="fa fa-spinner fa-spin"></i> Please wait...</span>
                        </span>
                        <form method="POST" id="addreward-form" name="addrewardfrm" @submit.prevent="submit_reward" data-parsley-validate >
                          <div class="row">

                              <div class="col-sm-6 col-12">
                                  <label class="customer-servey">Customer Survey</label>
                                  <p class="customer-survey">Reward customers when they answer your survey.</p>
                              </div>

                              <div class="col-sm-2 col-5">
                                  <label>Points</label>
                                  <input type="text" class="form-poup-shift-type" v-model="fields.points" placeholder="65" data-parsley-type="digits" required>
                              </div>
                              <div class="col-sm-2 col-5">
                                <p class="newequallto">=</p>
                                  <label>Dollars Off</label>
                                  <input type="text" class="form-poup-shift-type" v-model="fields.amount" placeholder="1.00" data-parsley-type="number" required>
                              </div>
                              <div class="col-sm-2 col-12">
                                <button @click.prevent="add_reward()" id="save_reward" class="btn btn-save-rewards-treat">Save</button>
                              </div>

                          </div>
                          </form>
                      </div> -->
                      <div v-for="(item,index) in menus" >
                        <div class="clearfix table-card-title-head-menu " >
                            <h5 class="card-title mb-0 float-left table-card-menu">{{item.title}}</h5>
                            <a  class="mb-0 float-right" data-toggle="modal"
                              data-target="#addDiscount" @click.prevent="getMenuItems({menu_id:item.id})"><i class="align-middle fas fa-fw fa-plus fa-plus-rewards" ></i></a>
                        </div>
                        <div class="shift-type"  v-for="(itemd,indexd) in item.restaurant_discounts">
                            <div class="row " >

                                <div class="col-sm-8 col-12">
                                    <label class="add-item-food-sandwich">{{itemd.title}}</label>
                                </div>
                                <div class="col-sm-2 col-6" v-if="itemd.active">
                                    <span class="btn active-btn-popup" >Active</span>
                                </div>
                                <div class="col-sm-2 col-6" v-if="!itemd.active">
                                    <span class="btn inactive-btn-popup" >Inactive</span>
                                </div>
                                <div class="col-sm-2 col-6 ">
                                    <div class="edit-sec-user-pencil-edit"><a @click.prevent="edit_discount(itemd.collection_for)" ><i class="fas fa-pencil-alt"></i></a></div>
                                </div>
                            </div>
                        </div>

                      </div>
                  </div>
              </form>
          </div>
          <!-- add popip -->
          <div class="modal fade" id="addDiscount" tabindex="-1" role="dialog" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div class="modal-content">
                      <div class="modal-header add-schedule-model-header">
                          <button @click="close_popup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <form method="POST" id="adddiscount-form" name="adddiscountfrm" @submit.prevent="submit_discount" data-parsley-validate >
                          <div class="m-4">
                              <div class="row">
                                <div class="col-md-12">
                                    <div class="clearfix edit-treat-rewards">
                                        <h3 class="float-left">Add Treat & Reward</h3>
                                    </div>
                                </div>
                                  <div class="col-md-12">
                                      <div class="treat-itle">
                                          <label>Treat & Reward Title </label>
                                          <input type="text" v-model="discount_fields.title" class="form-poup-shift-type" placeholder="$1.00 Off any appetizer" required="required">
                                      </div>
                                      <div class="row">
                                          <div class="col-md-8">
                                              <div class="item-descount">

                                                  <label>Select item or category to discount</label>
                                                  <treeselect v-model="discount_fields.menu_item_ids" :multiple="true" :options="menu_items" :flat="true" :auto-deselect-descendants="true"  :disable-branch-nodes="true" placeholder="Open the menu item" noOptionsText="No items available" :show-count="true"  required/>

                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-5 col-12">
                                      <div class="treat-amount">
                                          <label>Treat Amount</label>
                                      </div>
                                      <div class="row">
                                          <div class="col-md-12">
                                              <input type="radio" name="discount_type" v-model="discount_fields.type" value="amount" class="" > <label>Dollars Off</label>
                                              <input type="radio" name="discount_type" v-model="discount_fields.type" value="percentage" class="" > <label>Percentage</label>
                                              <input type="text"  v-model="discount_fields.treat" placeholder="1.00" class="form-poup-shift-type" data-parsley-type='number' required>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-2 col-12"></div>
                                  <div class="col-md-5 col-12">
                                      <div class="treat-amount">
                                          <label>Treat Duration</label>
                                      </div>
                                      <div class="row">
                                          <div class="col-md-6">
                                              <label class="treat-label">Start Date</label>
                                              <b-form-datepicker :min="current_date" id="valid_from" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="discount_fields.valid_from" class="form-poup-shift-type" required></b-form-datepicker>
                                              <!-- <input type="date" :min="current_date" id="valid_from" v-model="discount_fields.valid_from" placeholder="11/02/2020" class="form-poup-shift-type" required> -->
                                          </div>
                                          <div class="col-md-6">
                                              <label class="treat-label">End Date</label>
                                              <b-form-datepicker :min="current_date" id="valid_to" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="discount_fields.valid_to" class="form-poup-shift-type" required></b-form-datepicker>
                                              <!-- <input type="date" :min="current_date" id="valid_to" v-model="discount_fields.valid_to" placeholder="11/31/2020" class="form-poup-shift-type date" required> -->
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                    <label class="checkbox-container">Active
                                      <input type="checkbox" name="discount_status" v-model="discount_fields.active" value="1" class=""/>
                                      <span class="checkmark"></span>
                                    </label>
                                </div>
                              </div>
                              <div class="clearfix main-btn-sec-shift">
                                  <button class="btn save-shift-btn float-right" id="save_discount" @click.prevent="add_discount()">Save</button>
                              </div>
                          </div>

                          <div class="row text-center">
                            <div v-if="errors" class="text-danger col-12">
                              <div v-for="(v, k) in errors" :key="k">
                                <p v-for="error in v" :key="error" class="text-sm">
                                  {{ error }}
                                </p>
                              </div>
                            </div>
                            <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                              <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                                {{ problem_in_save_error }}
                              </p>
                            </div>
                          </div>

                      </form>
                  </div>
              </div>

          </div>
          
          <!-- edit popup -->
          <div class="modal fade" id="editDiscount" tabindex="-1" role="dialog" aria-hidden="true" >
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div class="modal-content">
                      <div class="modal-header add-schedule-model-header">
                          <button @click="close_edit_popup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <form method="POST" id="editdiscount-form" name="editdiscountfrm" @submit.prevent="submit_update_discount" data-parsley-validate >
                          <div class="m-4">
                            <div class="row">
                              <div class="col-md-12">
                                  <div class="clearfix edit-treat-rewards">
                                      <h3 class="float-left">Edit Treat & Reward</h3>
                                  </div>
                              </div>
                                <div class="col-md-12">
                                    <div class="treat-itle">
                                        <label>Treat & Reward Title </label>
                                        <input type="text" v-model="edit_discount_fields.title" class="form-poup-shift-type" placeholder="$1.00 Off any appetizer" required="required">
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="item-descount">

                                                <label>Select item or category to discount</label>
                                                <treeselect v-model="edit_discount_fields.sel_menu_item_ids" :multiple="true" :options="menu_items" :flat="true" :auto-deselect-descendants="true"  :disable-branch-nodes="true"  placeholder="Open the menu item" noOptionsText="No items available" :show-count="true"  required/>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 col-12">
                                    <div class="treat-amount">
                                        <label>Treat Amount</label>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <input type="radio" name="discount_type" v-model="edit_discount_fields.type" value="amount" class="" > <label>Dollars Off</label>
                                            <input type="radio" name="discount_type" v-model="edit_discount_fields.type" value="percentage" class="" > <label>Percentage</label>
                                            <input type="text"  v-model="edit_discount_fields.treat" placeholder="1.00" class="form-poup-shift-type" data-parsley-type='number' required>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 col-12"></div>
                                <div class="col-md-5 col-12">
                                    <div class="treat-amount">
                                        <label>Treat Duration</label>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="treat-label">Start Date</label>
                                            <!-- <input type="date"   id="valid_from" v-model="edit_discount_fields.valid_from" placeholder="11/02/2020" class="form-poup-shift-type" required> -->
                                        <b-form-datepicker id="valid_from" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields.valid_from" class="form-poup-shift-type" required></b-form-datepicker>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="treat-label">End Date</label>
                                            <!-- <input type="date"  id="valid_to" v-model="edit_discount_fields.valid_to" placeholder="11/31/2020" class="form-poup-shift-type" required> -->
                                            <b-form-datepicker id="valid_to" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields.valid_to" class="form-poup-shift-type" required></b-form-datepicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label class="checkbox-container">Active
                                       <input type="checkbox"  name="discount_status" v-model="edit_discount_fields.active" value="1" class=""/>
                                      <span class="checkmark"></span>
                                    </label>
                                </div>

                            </div>
                              <div class="clearfix main-btn-sec-shift">
                                  <button class="remove-shift-btn mb-0 float-left" id="del_discount" @click.prevent="delete_discount()">REMOVE</button>
                                  <button class="btn save-shift-btn float-right" id="update_discount" @click.prevent="update_discount()">Save Changes</button>

                              </div>
                          </div>

                          <div class="row text-center">
                            <div v-if="errors" class="text-danger col-12">
                              <div v-for="(v, k) in errors" :key="k">
                                <p v-for="error in v" :key="error" class="text-sm">
                                  {{ error }}
                                </p>
                              </div>
                            </div>
                            <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                              <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                                {{ problem_in_save_error }}
                              </p>
                            </div>
                          </div>
                      </form>
                  </div>
              </div>

          </div>
      </div>
  </div>
  
</template>

<script>

    export default {
    props:['current_date'],
    data() {
        return {
            fields: {
              points:'',
              amount:'',
              reward_id:''
            },
            reward:{},
            errors: {},
            iserrors: false,
            success: false,
            isLoading: false,
            loading:false,
            problem_in_save_error:'',
            menu_categories:{},
            menu_items:[],
            menus:{},
            discount_fields: {
              menu_item_ids: [],
              title: '',
              valid_from: '',
              valid_to: '',
              treat: '',
              menu_id:'',
              type:'amount',
              active:false
            },
            edit_discount_fields: {
              menu_item_ids: [],
              title: '',
              valid_from: '',
              valid_to: '',
              treat: '',
              menu_id:'',
              type:'',
              sel_menu_item_ids:[],
              active:'',
              collection_for:''
            }
        }
    },
    created: function () {
      this.get_reward();
      this.get_menus();
    },
    methods:{
      get_reward(){
        axios.get('/owner/manage_treats_rewards/get_reward').then(response => {
        this.reward = response.data.response.reward;
        if(response.data.response.reward != null){
          this.fields.points = response.data.response.reward.points;
          this.fields.amount = response.data.response.reward.amount;
          this.fields.reward_id = response.data.response.reward.id;
        }
        }).catch(error => {

        });
      },
      get_menus(){
        axios.get('/owner/menus/get-restaurant-menus').then(response => {
        this.menus = response.data.response.menus;

        }).catch(error => {

        });
      },
      add_reward() {
        let vm = this;
        $('#addreward-form').parsley().whenValidate({

        }).done(function() {
        vm.submit_reward();
        });

      },
      submit_reward() {
        let vm = this;
        $('#save_reward').prop('disabled',true);
          vm.success = false;
          vm.errors = {};
          axios.post('/owner/manage_treats_rewards/add_reward', vm.fields).then(response => {
            vm.success = true;
            $('#card-reward-id').addClass('loading-spin');
            setTimeout( () => {
              this.fields.points = response.data.response.restaurantReward.points;
              this.fields.amount = response.data.response.restaurantReward.amount;
              this.fields.reward_id = response.data.response.restaurantReward.id;
              $('#card-reward-id').removeClass('loading-spin');
              toastr.success('Reward saved successfully.');
              $('#save_reward').prop('disabled',false);
            }, 5000);

          }).catch(error => {
            $('#save_reward').prop('disabled',false);
            if (error.response.status === 400) {
              vm.problem_in_save_error = error.response.data.response.message || {};
            }
            else if (error.response.status === 422) {
              vm.errors = error.response.data.errors;
            }else{
              vm.errors = error.response.data.response.message || {};
            }
          });
      },
      getMenuItems(rdata){
        let formData = new FormData();
        formData.append("menu_id", rdata['menu_id']);
        formData.append("collection_for", rdata['collection_for']);


        this.menu_items = [];
        this.menu_item_ids = [];
        this.discount_fields.menu_id = rdata['menu_id'];
        axios.post('/owner/manage_treats_rewards/get_r_menu_categories',formData).then(response => {
          this.menu_items = response.data.response.item_options;
          console.log(this.menu_items);
           this.discount_fields.menu_id = rdata['menu_id'];
        }).catch(error => {
          console.log(error);
        });

      },
      
      add_discount() {
        let vm = this;
        $('#adddiscount-form').parsley().whenValidate({

        }).done(function() {
        vm.submit_discount();
        });

      },
      submit_discount() {
        let vm = this;
          $('#save_discount').prop('disabled',true);
          vm.success = false;
          vm.errors = {};
          axios.post('/owner/manage_treats_rewards/add_discount', vm.discount_fields).then(response => {
            vm.success = true;
            vm.discount_fields = {}
            vm.discount_fields.type = 'amount';
            $("#addDiscount .close").click();
            toastr.success('Treat added successfully.');
            this.get_menus();
            $('#save_discount').prop('disabled',false);
          }).catch(error => {
            $('#save_discount').prop('disabled',false);
            if (error.response.status === 400) {
              vm.problem_in_save_error = error.response.data.response.message || {};
            }
            else if (error.response.status === 422) {
              vm.errors = error.response.data.errors;
            }else{
              vm.errors = error.response.data.response.message || {};
            }
          });
      },
      edit_discount(udId){
        $('#editDiscount').modal('show');


          let formData = new FormData();

          formData.append("collection_for", udId);
          axios.post('/owner/manage_treats_rewards/get_discount', formData).then(response => {
            this.edit_discount_fields.collection_for = response.data.response.discountRow.collection_for;
            this.edit_discount_fields.menu_id = response.data.response.discountRow.menu_id;
            this.edit_discount_fields.title = response.data.response.discountRow.title;
            this.edit_discount_fields.valid_from = response.data.response.discountRow.valid_from;
            this.edit_discount_fields.valid_to = response.data.response.discountRow.valid_to;
            this.edit_discount_fields.type = response.data.response.discountRow.type;
            this.edit_discount_fields.treat = response.data.response.discountRow.treat;
            this.edit_discount_fields.sel_menu_item_ids = response.data.response.menu_item_ids;
            this.edit_discount_fields.active = response.data.response.discountRow.active;
            let dataformenu =
            { collection_for: response.data.response.discountRow.collection_for,menu_id:response.data.response.discountRow.menu_id}
        ;

            this.getMenuItems(dataformenu);
          }).catch(error => {
          });
      },
      update_discount() {
        let vm = this;
        $('#editdiscount-form').parsley().whenValidate({

        }).done(function() {
        vm.submit_update_discount();
        });

      },
      submit_update_discount() {
        $('#update_discount').prop('disabled',true);
        axios.post('/owner/manage_treats_rewards/update_discount', this.edit_discount_fields).then(response => {

          setTimeout( () => {
            $('#editDiscount').modal('hide');
            this.get_menus();
            toastr.success('Treat updated successfully.');
            $('#update_discount').prop('disabled',false);
            this.edit_discount_fields = {};
          }, 3000);


        }).catch(error => {
          $('#update_discount').prop('disabled',false);
          if (error.response.status === 400) {
            this.problem_in_save_error = error.response.data.response.message || {};
          }
          else if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }else{
            this.errors = error.response.data.response.message || {};
          }
        });
      },
      delete_discount(){

        $('#del_discount').prop('disabled',true);
        let formData = new FormData();

        formData.append("collection_for", this.edit_discount_fields.collection_for);
        axios.post('/owner/manage_treats_rewards/delete_discount',formData ).then(response => {

          setTimeout( () => {
            $('#editDiscount').modal('hide');
            this.get_menus();
            toastr.success('Treat deleted successfully.');
            $('#del_discount').prop('disabled',false);
            this.edit_discount_fields = {};
          }, 7000);


        }).catch(error => {
          $('#del_discount').prop('disabled',false);
          if (error.response.status === 400) {
            vm.problem_in_save_error = error.response.data.response.message || {};
          }
          else if (error.response.status === 422) {
            vm.errors = error.response.data.errors;
          }else{
            vm.errors = error.response.data.response.message || {};
          }
        });
      },

      close_popup(){
        $('#adddiscount-form').parsley().reset();
      },
      close_edit_popup(){
        $('#editdiscount-form').parsley().reset();
      }
      
  }


    }
</script>
