<template>

  <div class="">
    <div class="row">
        <div class="new_top_order_btn">
          <div class="sub_title">
              <p>{{inprogress}} In progress</p>
          </div>

          <!-- <div class="order-toast-top-center" id="order-toast-top-center" style="display:block">
            <span><i class="align-middle  far fa-fw fa-clock" style="color:#ff5802 !important"></i>New order has been placed!</span>
          </div> -->
          <div class="top_both_btn" >
            <div id="order-toast-top-center" style="display:none" class="innterbtn-edit neworderalert"><i class="align-middle  far fa-fw fa-clock" style="color:#ff5802 !important"></i> New order has been placed!
            </div>
            <div class="innterbtn-edit delay_btn">
              <button v-if="dely" class="btn order_completed_btn" type="button" data-toggle="modal"  data-target="#setdelay"><i class="align-middle  far fa-fw fa-clock"></i> Resume Orders</button>
              <button v-else class="btn" type="button" data-toggle="modal"  data-target="#setdelay"><i class="align-middle  far fa-fw fa-clock"></i> Set Delay</button>
            </div>
            <div class="neworderbtn">
              <button class="btn add-btn-order" type="button" @click="onsiteToGo()" >
                <i class="align-middle fa fa-plus" style="float: left;padding: 3px;"></i> New order</button>

             </div>
          </div>
        </div>
      </div>

    <!-- <div class="card card-body flex-fill w-100"> -->
      <div class="clearfix">
        <!-- <h5 class="card-title mb-0 float-left">From Orders</h5>
        <a href="orders" class="view-schedule-link mb-0 float-right">View All</a> -->
      </div>

      <div class="row">

    <!----New card design ---->

    <div class="row" style="width:100%;">
      <div v-if="noOrrderFound" style=" display: contents;">
           <div class="col-md-12 mb-3 d-flex align-items-stretch">
            <div class="" style="width: 100%;height: 300px;">
              <div class=" d-flex flex-column">
                <span class="empty_order_text" style="position: absolute;font-size:20px;font-weight:300;top:47%;left: 32%;">No orders have been placed.</span>
            </div>
          </div>
        </div>
      </div>

      <span v-if="loadorder" class="empty_order_text" style="position: absolute;font-size:20px;font-weight:300;top:47%;left: 32%;">Loading...</span>



      <div v-for="(item, index) in foodtruckorders" class="col-md-6 mb-3 d-flex align-items-stretch" style="padding-left:0;">


          <div class="card" style="width:100%; border:2px solid #ff5802;">
              <div class="card-body d-flex flex-column">
                <div class="user-headeading-booking clearfix">
                    <div class="float-left">
                      <div>
                      
                       <div v-if="item.guest_user_details && JSON.parse(item.guest_user_details) !== null">
                          <span class="orderno">{{ JSON.parse(item.guest_user_details).firstname }} {{ JSON.parse(item.guest_user_details).last_name }}</span>
                      </div>


                      </div>
                    </div>
                    <div class="float-right table-number">
                      <span style="background-color: #9f9e9e;">{{item.id}}</span>
                    </div>
                </div>
                <hr>

                  <div v-for="(citem,cindex) in item.order_items" class="row">
                    <div class="col-sm-7 col-7">
                      <div class="order-name">
                          <span>{{ citem.quantity }}</span>
                          <p>{{citem.name}}
                            <br>
                            <span  style="cursor: pointer;color:#ff5802;font-size: 12px;"  v-if="citem.other_data">{{ (citem.other_data) }}</span>
                          </p>
                      </div>
                    </div>
                    <div class="col-sm-5 col-5" style="padding-left: 0;">
                      <div class="item-price">
                          <span>$ {{parseFloat(citem.quantity * citem.price).toFixed(2)}}</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="item.discount_applied_amount > 0" class="row">
                   <div class="col-sm-7 col-7">
                     <div class="order-name tax_amt">
                     <span></span>
                         <p>Discount: {{item.discount_detail}}</p>
                     </div>
                   </div>
                   <div class="col-sm-5 col-5" style="padding-left: 0;">
                     <div class="item-price">
                         <span>$ {{item.discount_applied_amount}}</span>
                     </div>
                   </div>
                 </div>

                  <div class="row">
                    <div class="col-sm-7 col-7">
                      <div class="order-name tax_amt">
                      <span></span>
                          <p>Taxes and Fees</p>
                      </div>
                    </div>
                    <div class="col-sm-5 col-5" style="padding-left: 0;">
                      <div class="item-price">
                          <span>$ {{parseFloat(item.tax).toFixed(2)}}</span>
                      </div>
                    </div>
                  </div>

                   <div class="row">
                    <div class="col-sm-7 col-7">
                      <div class="order-name tax_amt">
                      <span></span>
                          <p>Tip</p>
                      </div>
                    </div>
                    <div class="col-sm-5 col-5" style="padding-left: 0;">
                      <div class="item-price">
                          <span v-if="item.tip > 0">$ {{item.tip.toFixed(2)}}</span>
                          <span v-else>$ 0.00</span>
                      </div>
                    </div>
                  </div>

                <div  class="mt-auto align-self-start" style="width: 100%;">
                 
                    <div class="pick_time" v-if="item.foodtruck_pickup_date_time">
                      <div class="float-left left-timing pick_tm">
                          <span style=""><i class="align-middle  far fa-fw fa-clock"></i> Pick Up Date Time</span>
                      </div>
                      <div class="float-right pick_tm">
                        
                          <span>
                            <!-- {{item.foodtruck_pickup_date_time}} -->
                            {{formattedDateTime(item.foodtruck_pickup_date_time)}}
                            </span>
                      </div>
                    </div>

                  <div class="total-timing-price clearfix" style="border-bottom: 1px solid #ccc; background-color: #fff;margin-left: 0; margin-right: 0; padding-right:0;">
                    <div class="float-left left-timing total-price-number">
                        <span style="">Total Paid</span>
                    </div>
                    <div class="float-right total-price-number">
                        <span>$ {{parseFloat(item.order_amount).toFixed(2)}}</span>
                    </div>
                  </div>
                  <div class="outer-btn-edit-close">
                    <div class="row" >
                        <div v-if="item.guest_user_details && JSON.parse(item.guest_user_details) !== null" class="col-sm-6 col-6 innterbtn-edit">
                          <button  :id="`btn_${item.id}`" class="btn" @click="NotifyClient(item.order_items,item.id)">
                             <span :id="`notify_btn_${item.id}`"></span>
                            <i class="align-middle  far fa-fw fa-clock"></i> Order Ready</button>
                        </div>
                        <div class="col-sm-6 col-6 innterbtn-edit"
                          style="padding-left: 0px;">
                          <button class="btn order_completed_btn" :id="`orderbtn_${item.id}`" @click.prevent="opencloseoutSeatsModal(item,item.id)"> <span :id="`notify_orderbtn_${item.id}`"></span> Order Complete</button>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

    </div>

    <!----End New card design ---->

<div class="modal fade" id="addNewMenuItemModal" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
          <div class="modal-dialog modal-lg modal-dialog-centered" role="document" >
            <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="m-4 tab-pane handeld-popup-relative active">
                <div class="nav handhelds-menus poup-nav-hanhelds-menu">
                  <div class="nav-item active">
                    <a class="nav-link">Alcoholic </a>
                  </div>
                  <div class="nav-item">
                    <a class="nav-link">Non-Alcoholic </a>
                  </div>
                  <div class="nav-item">
                    <a class="nav-link">Eggs </a>
                  </div>
                  <div class="nav-item">
                    <a class="nav-link">Drink </a>
                  </div>
                  <div class="nav-item menu-list-handheld" @click="showAllMenus">
                    <a class="nav-link" id="final_draft"><i class="fas fa-align-justify"></i></a>
                  </div>
                </div>
                <div class="onclick-show-menu-handeld " id="mobile_toggle_nav" style="display: none;">
                  <ul class="nav flex-column">
                    <div class="close-menu" id="close_toggle_nav" @click="close_all_menu_nav">&times;</div>
                    <li class="nav-item" v-for="(item,index) in menu_categories" @click="showMenuItems($event,item.id)">
                      <a class="nav-link " :class="{ active: (activeTab == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                    </li>
                  </ul>
                </div>
                <div class="outer-menu-list-price">
                  <h3>Alcoholic</h3>
                  <!-- menu-1 -->
                    <div v-if="load_menu_items" class="d-flex justify-content-center">
                      <div class="patron-loader spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div class="menu-list-price">
                      <div class="clearfix">
                        <h4 class="float-left">Tropical Fizz</h4>
                          <span class="float-right">$10.00</span>
                      </div>
                      <p>Tropical Fizz</p>
                    </div>
                  <div v-if="menu_items.length == 0" class="empty_menu empty_msg">No menu items found.</div>
                </div>
              </div>
            </div>
          </div>
        </div>


    <!-- model set delay start -->
      <div class="modal fade" id="setdelay" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 380px;">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
                  <!--<div class="modal-header">
                    <h4 class="modal-title" style="color: #fff;">Set delay</h4>
                  </div>-->
                  <div class="modal-body">
                    <div class="text-center delay_title"><i class="align-middle  far fa-fw fa-clock" style="font-size: 50px;color: #ccc;"></i>
                      <h4>How long do you want to delay ordering?</h4>
                    </div>

                      <div class="m-2">



                        <select required class="form-poup" v-model="dely_time">
                          <option v-if="dely.status == 1" value=""><b>{{dely.dely_time}} minutes</b></option>
                          <option v-if="dely.status == 2" value=""><b>{{dely.dely_time}}</b></option>
                          <option v-else value="" selected>Select</option>
                          <option value="1" >1 minutes</option>
                          <option value="10" >10 minutes</option>
                          <option value="20">20 minutes</option>
                          <option value="30">30 minutes</option>
                          <option value="40">40 minutes</option>
                          <option value="50">50 minutes</option>
                          <option value="60">60 minutes</option>
                          <option value="closed">Closed</option>
                        </select>

                        <div class="text-center delay_title" style="margin-bottom: 20px;">
                          <h6>Let your customers know why you're delayed and when to expect ordering to resume</h6>
                        </div>

                        <textarea v-model="dely_message" rows="5" style="height:auto;margin-bottom: 30px; resize: none;" class="form-control" placeholder="ex. We've recevied so many order we are delaying ordering for 10 minutes. we will be ready to serve you shortly"></textarea>

                        <div v-if="dely">
                           <div class="innterbtn-edit cancel_btn_order">
                              <button id="resumebtn" @click="resumeOrder(dely)" style="color: #fff !important;width: 156px;background: #28a745 !important;" type="button" data-toggle="modal" data-target="#" class="btn">
                              <i class="align-middle  far fa-fw fa-clock"></i> <span id="notify_resume"></span> Resume Orders</button>
                        </div>
                        <div class="set_delay">
                            <button style="background-color: #f6f6f6;width: 166px;font-size: 15px;padding: 10px !important;color: #00000075;" id="extendOrder" type="submit" class="btn add-emp-btn float-right" @click="extendOrder(dely)"> <span id="notify_extendOrder"></span> Extend Delay</button>
                        </div>
                        </div>
                        <div v-else>
                          <div class="innterbtn-edit cancel_btn_order">
                          <button type="button" data-dismiss="modal" class="btn" @click="hideSetDelyModal()">
                            <i class="align-middle  far fa-fw fa-clock"></i> Cancel</button>
                        </div>
                        <div class="set_delay">
                            <button type="submit" @click.prevent="OrderdelyFunction" id="save_que" class="btn add-emp-btn float-right"> <span id="notify_orderdely"></span> Set Delay</button>
                        </div>
                        </div>

                      </div>
                      <div class="row text-center">
                        <div class="text-danger col-12"></div> <!---->
                      </div>

                  </div>
              </div>
            </div>
          </div>
     <!-- model set delay end -->
      <!-- model order confirmation start -->

      <div class="modal fade" id="orderconfirmation" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 380px;">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
                  <!--<div class="modal-header">
                    <h4 class="modal-title" style="color: #fff;">Set delay</h4>
                  </div>-->
                  <div class="modal-body">
                    <div class="text-center order_close_title"><i class="align-middle  far fa-fw fa-clock" style="font-size: 50px;color: #ccc;    margin-bottom: 30px;"></i>
                      <h5>Please confirm that <span> {{orderComplete.clientname}} Order#{{orderComplete.orderid}} </span> received <span>{{orderComplete.totalcartitems}} </span>items?</h5>

                    </div>

                      <div class="m-2">
                      <div class="order_close">
                            <button class="btn add-emp-btn" @click="OrderCompleteClickFunction(orderComplete.cartdata,orderComplete.index)">Yes, close this order</button>
                        </div>
                        <div class="innterbtn-edit">
                          <button type="button"  data-dismiss="modal" class="btn">
                            No, keep order open</button>
                        </div>

                      </div>

                  </div>
              </div>
            </div>
          </div>
     <!-- model order confirmation end -->






      <!-- edit order modal -->
      <div
        class="modal fade"
        id="editCurrOrderPrimary"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header padding-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="m-3">
              <div class="header-add-seat">
                <div class="new-left-sec-with-avtar">
                  <span >{{editorderfields.seat_id}}</span>
                </div>
                <div class="inprogress-btn">
                  <button>In Progress</button>
                </div>
              </div>

                <!-- tabs01 -->
                <!-- tabs02 -->
                  <div class="clearfix second-tab-left-right">
                    <h3 class="float-left">Order</h3>
                    <!-- <a class="float-right ml-2" :data-table-order-id="editorderfields.table_id" @click.prevent="addSeat($event)">+ ADD SEAT</a> -->
                     <a class="float-right ml-2"  @click.prevent="addNewMenuItem(editorderfields.seatsarray)" title="Add More Items"
                                ><i class="fas fa-plus"></i>Add More Items</a>
                  </div>

                  <div class="new-seat-table-collection">
                    <div class="new-seat-table-section">
                      <div class="order-name-edit-outer" :id="`seat-pop-card-id-${seatitem.id}`"  v-for="(seatitem, index_s) in editorderfields.seatsarray.cart_items">


                          <!-- <div  v-for="(edcitem,edcindex) in seatitem.cart_items"> -->
                            <div class="clearfix top-order-name-inner" style="border: none;">
                              <div class="float-left">

                                <h3>{{seatitem.menuitem.title}}</h3>
                                <!-- <p>{{edcitem.menuitem.description}}</p> -->
                                <p v-if="seatitem.other_data<20" >{{seatitem.other_data}}</p>
                                <p v-else>{{ (seatitem.other_data).substring(0,8)+".." }}</p>


                              </div>
                              <div v-if="seatitem.cancelled == 0" class="float-right d-flex order-name-edit-price">
                                <div class="price-total">$ {{parseFloat(seatitem.quantity * seatitem.menuitem.price).toFixed(2)}}</div>
                                <a  @click.prevent="editItem(seatitem,index_s)" title="Update"
                                  ><i class="fas fa-pencil-alt"></i
                                ></a>
                                <!-- <a  @click.prevent="removeItem(edcitem.id,seatitem.cart_items,edcindex,index_s)" title="Remove"
                                  ><i class="fas fa-trash"></i
                                ></a> -->
                              </div>

                            </div>
                          <!-- </div> -->
                          <div>

                          </div>
                        </div>

                        <!-- enddddddddddddddddddddddd -->

                    </div>
                  </div>

            </div>
             <div class="taxes-and-fee-price">

                  </div>
          </div>
        </div>
      </div>
      <!-- CLOSE OUT NEW MODAL START -->
      <div
        class="modal fade"
        id="CloseOutModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header padding-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="m-3">
              <div class="header-add-seat">
                <div class="new-left-sec-with-avtar">
                  <span >{{closeout.seat_name}}</span>
                </div>
                <div class="inprogress-btn">
                  <button>In Progress</button>
                </div>
              </div>

                <!-- tabs01 -->
                <!-- tabs02 -->
                  <div class="clearfix second-tab-left-right">
                        <h3 class="float-left">Select all items to close out ?</h3>
                        <div class="float-right">
                        <input type="checkbox" class="checkbox-outlook-input" name="" @click="selectAllSeats($event)"/><label class="all-seats-outlook"> All Items</label>

                      </div>
                  </div>


                  <div class="new-seat-table-collection">

                    <!-- start loop -->
                    <div v-for="(seatitem,seatinx) in closeout.seatsdata.cart_items" class="new-seat-table-section">
                        <div class="order-name-edit-outer">
                          <div class="clearfix top-order-name-inner" style="display:none">
                            <span class="float-left">
                             <!-- <input class="checkbox-outlook-input seatselcls" type="checkbox" /> -->
                             <input class="checkbox-outlook-input seatselcls" type="checkbox" :id="`card-seat-id-${seatitem.id}`" name="asdsd" v-model="closeout.selecteddeats" :value="seatitem.id+'-'+seatinx"/>

                            </span>

                          </div>

                          <div>
                            <div class="clearfix top-order-name-inner" style="border: none;">
                                  <div class="float-left">
                                    <h3>{{seatitem.menuitem.title}}</h3>
                                      <p>{{seatitem.menuitem.description}}</p>
                                  </div>
                                  <div class="float-right d-flex order-name-edit-price">
                                    <div class="price-total">$ {{parseFloat(seatitem.menuitem.price * seatitem.quantity).toFixed(2)}}</div>
                                  </div>
                            </div>
                          </div>
                          <div>

                          </div>
                        </div>

                    </div>
                    <!-- end loop -->
                  </div>

            </div>
             <div class="taxes-and-fee-price">
                    <div class="taxes-and-fee-buttons">
                      <div class="clearfix">
                        <!-- <button class="float-left btn cancel-btn">Cancel</button> -->
                        <div class="float-right">
                          <button class="btn close-out" @click.prevent="validateCloseOutSeat()"><img src="/img/frontend/close-icon.PNG" />Close Out</button>
                          <!-- <button class="btn close-out" @click.prevent="opencloseoutSeatsModal(editorderfields.seatsarray)"><img src="/img/frontend/close-icon.PNG" />Close Out</button> -->
                        </div>
                      </div>
                    </div>




                  </div>
          </div>
        </div>
      </div>
      <!-- CLOSE OUT NEW MODAL END -->
      <!-- edit menu modal -->
      <div class="modal fade" id="editMenuItem" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <form method="POST" id="editiorderfrm" name="editiorderfrm" @submit.prevent="saveEditItem" >
            <div class="order_menu_item">
              <h4>${{edit_menu.menu_item.price}}</h4>
              <h4>{{edit_menu.menu_item.title}}</h4>
              <p>
                {{edit_menu.menu_item.description}}
              </p>
              <p v-if="edit_menu.menu_item.inventory == 0" style="color:red;font-weignt:500">Out of Stock !</p>
              <label>Special Requests?</label>
              <textarea placeholder="What are your requests?" class="form-poup" v-model="edit_menu.menu_item.specialrequest" ></textarea>
              <div class="qty-text-sec" v-if="edit_menu.menu_item.inventory > 0">
                <div class="row">
                  <div class="col-2">
                    <span>Qty</span>
                  </div>
                  <div class="col-5">
                    <input type="text" id="qty" class="qtyform-poup" v-model="edit_menu.menu_item.quantity" min="0"  required=""
                     data-parsley-trigger="keyup" data-parsley-type="digits">
                  </div>
                  <div class="col-5 button-service">
                    <span ><i class="fa fa-minus"  v-on:click="decrease"></i></span>
                    <span><i class="fa fa-plus"    v-on:click="increase"></i></span>
                  </div>
                </div>
              </div>
              <div>
                <button @click.prevent="validateEditItem()" class="btn add-btn-order" id="edit_item" >Update</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>


      <!-- view item modal-->
      <div class="modal fade" id="viewItem" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <form method="POST" id="addiorderfrm" name="addiorderfrm" @submit.prevent="addOrderToSeat" >
            <div class="order_menu_item">
             <img style="width: 570px;" v-if="new_menu_item.image"  :src="'/storage/menu_item_images/original/'+new_menu_item.image" :alt="new_menu_item.title">
              <h4>${{new_menu_item.price}}</h4>
              <h4>{{new_menu_item.title}}</h4>
              <p>
                {{new_menu_item.description}}
              </p>
              <label>Special Requests?</label>
              <textarea placeholder="What are your requests?" class="form-poup" v-model="new_menu_item.specialrequest" ></textarea>
              <div class="qty-text-sec">
                <div class="row">
                  <div class="col-2">
                    <span>Qty</span>
                  </div>
                  <div class="col-5">
                    <input type="text" id="qty" class="qtyform-poup" v-model="new_menu_item.quantity" min="1"  required=""
                    data-parsley-validation-threshold="1" data-parsley-trigger="keyup" data-parsley-type="digits">
                  </div>
                  <div class="col-5 button-service">
                    <span ><i class="fa fa-minus"  v-on:click="decrease_new"></i></span>
                    <span><i class="fa fa-plus"    v-on:click="increase_new"></i></span>
                  </div>
                </div>
              </div>
              <div>
                <button @click.prevent="ValidateSeatOrder()" class="btn add-btn-order" id="add_order" >Add to order</button>
                <!-- <a data-toggle="modal" data-target="#signInModalPrimary" class="btn add-signin-btn signin-up-btn"  v-if="!isloggedin">Sign in/ Create Account</a> -->
              </div>
            </div>
            <div class="mb-2 text-center">

                <div v-if="validation_errors" class="text-danger text-danger text-center">
                  <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                      {{v[0]}}
                  </p>
                </div>
                <div v-if="error" class="text-danger text-danger text-center">
                  <p v-if="error" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
                <div v-if="errors" class="text-danger text-danger text-center">
                  <div v-for="(v, k) in errors" :key="k">
                    <p v-for="error in v" :key="error" class="text-sm">
                      {{ error }}
                    </p>
                  </div>
                </div>

            </div>

            </form>
          </div>
        </div>
      </div>
      <!-- model list of special request start -->

      <div class="modal fade" id="specialrequest_model" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-header" style="background-color: #4256ce;">
                <h4 class="modal-title" style="color: #fff;">Special Requests:</h4></div>
            <div class="modal-body" v-if="sprequest">
              <ul class="list-group">
                  <li class="list-group-item">{{sprequest}}</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- model list of special request end -->

      <!--close out popup-->


    </div>
  </div>
</template>

<script>

    export default {
    props: ['restaurant_id'],
    data() {
        return {
          orderslength:0,
          incomplete_order_count:0,
          inprogress:0,
          sp_request:[],
          sprequest:'',
          sprequest_data:[],
          activeTabInline:null,
          activeTab: null,
          activeMenuTitle:null,
          load_menu_items:false,
          menu_items:{},
          menu_categories:[],
          orders:[],
          foodtruck_orders:[],
          cartitemtotal:'',
          orderitemtotal:'',
          editorderfields:{
            seatsarray:[],
            seat_id:'',
            tabletol:'',
            cartItms:'',
            specialrequest:''
          },
          edit_menu:{
            menu_item:{
              title:'',
              description:'',
              price:'',
              quantity:1,
              user_id:'',
              menu_id:'',
              inventory:'',

            },
            menu_item_arry:'',
            cartItms:'',
            menuItmInx:'',

          },
          add_menu_to_seat:{
            seat_id:'',
            table_id:'',
            tbl_qr_str:'',
            seat_token_id:''
          },
          new_menu_item:{
            title:'',
            description:'',
            price:'',
            quantity:1,
            user_id:'',
            image:'',
            specialrequest:''
          },
          orderComplete:{
            cartdata:{},
            index:'',
            orderid:'',
            clientname:'',
            totalcartitems:''
          },
          validation_errors:{},
          error:'',
          errors:{},
          closeout:{
            seatsdata:{},
            selecteddeats:[],
            seat_order_total:'',
            seat_name:''
          },
          selectedseatid:'',
          dely_time:'',
          dely_message:'',
          message:'',
           dely:{},
           delyId:'',
          stindex:'',
          foodtruckorders:[],
          loadorder:true,
          noOrrderFound:'',
          timer:'',
          setting:'',
          setdelaylist: ['10', '20', '30', '40', '50', '60', 'Closed'],
        }
    },

    created: function () {
      //this.getDelyEndTimeAndDestroy();
      this.getRestaurantCurrentOrders();
      this.formattedDateTime();
      //this.getSR();
    },
    mounted() {
      let vm = this;


      Echo.channel('order-placed.'+vm.restaurant_id)
      .listen('OrderPlaced', (e) => {
            vm.getRestaurantCurrentOrders();
            //let newOrderAlert = new Audio('../sound/neworderalert.mp3');
            //newOrderAlert.play();
            //newOrderAlert.currentTime = 0;
            $('#order-toast-top-center').show().delay( 5000 ).hide(0);

            /*toastr.options = {
              'positionClass': 'order-toast-top-center',
              "debug": false,
              "timeOut": 5000,
              "extendedTimeOut": 0,
              "tapToDismiss": true,
              "onclick": true,
              "closeOnHover": false
            };
            toastr.success('New order has been placed!');*/

      });
    },
    methods:{
       formattedDateTime(date_time){
        const momentObj = moment(date_time);
        return momentObj.format('YYYY-MM-DD hh:mm A');
     },
     getDelayCounter(endtime, id,ctime) {
          let fdate = endtime;
          let futiretime = new Date(fdate).getTime();
          let currenttime = new Date().getTime()

		        //let futiretime = fdate;
           //let currenttime = ctime;

          let delaytimer = futiretime - currenttime;
          // console.log("delaytimer",delaytimer)
          if (delaytimer <= 0) {
            window.clearInterval(this.timer);
            console.log("timer expired");
             let formData = new FormData();
              formData.append("id",id);
              axios.post('/owner/orders/deleteorderdely',formData).then(response => {
                if(response.data.response.status == 1){

                  this.getRestaurantCurrentOrders();
                }
            });

          } else {

          }

      },
      deletedelay(delayid) {
          let formData = new FormData();
          formData.append("id",delayid);
          axios.post('/owner/orders/deleteorderdely',formData).then(response => {
              if(response.data.response.status == 1){
                this.getRestaurantCurrentOrders();
              }
          });
       },
      getRestaurantCurrentOrders(){
        axios.post('/owner/orders/current_orders').then(response => {
          this.loadorder = false;
          this.orders = response.data.response.table_list.table_rows;
          this.foodtruckorders = response.data.response.foodtruckorders;
          //console.log(this.foodtruckorders);
          this.orderslength = response.data.response.totl_tbls;
          this.incomplete_order_count = response.data.response.incomplete_order_count;
          this.inprogress = response.data.response.inprogress;
          if(response.data.response.dely == null){
            this.dely = false;
            this.delyId = 0;
          }else{
            let vm  = this;
            this.dely = response.data.response.dely;
            this.delyId = response.data.response.dely.id;
            if(response.data.response.dely.status == 1){
              this.timer =  setTimeout(() => {vm.deletedelay(response.data.response.dely.id);}, response.data.response.delaytimeleft);
              // this.timer = setInterval(function() {
              //     vm.getDelayCounter(response.data.response.dely.dely_time_end,response.data.response.dely.id,response.data.response.currenttime);
              //   }, 1000)
            }

          }


          //console.log('this.dely');
          //console.log(this.dely);


          if(this.foodtruckorders.length > 0){
            this.noOrrderFound = false;
          }else{
            this.noOrrderFound = true;
          }
          // if(this.incomplete_order_count == 0){
          //   $('.empty_order_text').text('No Orders ...');
          // }
        }).catch(error => {

        });
      },

      getRecentScanTableData(tid,rid){
        let formData = new FormData();
        formData.append("table_id", tid);
        formData.append("restaurant_id", rid);
        axios.post('/owner/orders/get_scan_table_detail',formData).then(response => {
          //console.log(response.data.response.table_row);
          this.orders.push(response.data.response.table_row);
          this.orderslength = this.orderslength + 1;
          if(this.orderslength == 0){
            $('.empty_order_text').text('No orders have been placed.');
          }
        }).catch(error => {

        });
      },
      closeouttable(qrid,qindex){
        if(confirm("Do you really want to close out?")){
          let formData = new FormData();
          formData.append("id", qrid);
          formData.append("restaurant_id", this.restaurant_id);
          axios.post('/owner/orders/close_out_scanned_table',formData).then(response => {


            Vue.delete(this.orders, qindex);
          }).catch(error => {
            if (error.response.status === 400) {
              alert(error.response.data.response.message);
            }else{
              alert("Something went wrong!");
            }
          });
        }

      },
      editOrderModal: function (itemc,indexc) {

        let vm = this;
        $('#editCurrOrderPrimary').modal('show');
        vm.editorderfields.seatsarray = itemc;
        vm.editorderfields.seat_id = itemc.seat;
        vm.editorderfields.tabletol = itemc.seat_order_total;
        vm.editorderfields.specialrequest = itemc.other_data;
      },
      addSeat: function (event) {
        let vm  = this;
        let tblid = event.target.getAttribute('data-table-order-id');
        let formData = new FormData();
        formData.append("table_id", tblid);
        formData.append("restaurant_id", vm.restaurant_id);
        axios.post('/owner/orders/add_new_seat_in_table',formData).then(response => {
          vm.editorderfields.seatsarray.push(response.data.response);
          toastr.success('Seat added successfully.');
        }).catch(error => {

        });
      },
      removeItem(itmId,cartItms,menuItm,stindex){
        let vm  = this;
        if(confirm("Do you really want to remove?")){
          axios.delete('/owner/orders/remove-cart-item/'+itmId).then(response => {
            Vue.delete(cartItms, menuItm);
            vm.getRestaurantCurrentOrders();

            vm.editorderfields.seatsarray[stindex].seat_order_total = response.data.response.seat_order_total;
          }).catch(error => {
          });
        }
      },
      addNewMenuItem(seatitemtr){
        let vm = this;
        console.log('seatitemtr');
        console.log(seatitemtr);
        $('#addNewMenuItemModal').modal('show');
        let formData = new FormData();
        formData.append("type", 'all');
        formData.append("restaurant_id", vm.restaurant_id);
        axios.post('/owner/orders/get-menu-categories',formData).then(response => {
          vm.menu_categories = response.data.response.categories;
          vm.activeTab = response.data.response.categories[0].id;
          vm.activeTabInline = response.data.response.categories[0].id;
          vm.activeMenuTitle = response.data.response.categories[0].title;

          formData.append("menu_category_id", response.data.response.categories[0].id);
          axios.post('/owner/orders/get-restaurant-menu-items',formData).then(response => {
            //console.log(response);
              vm.menu_items = response.data.response.menu_items;
              vm.selectedseatid = seatitemtr.id;
              // vm.stindex = stindex;
          });
          //console.log(response.data.response.categories);
        }).catch(error => {

        });
        vm.add_menu_to_seat = {};
        vm.add_menu_to_seat.seat_id = seatitemtr.seat;
        vm.add_menu_to_seat.tbl_qr_str = seatitemtr.token;
        vm.add_menu_to_seat.table_id = seatitemtr.table_id;
        vm.add_menu_to_seat.seat_token_id = seatitemtr.id;
      },
     editItem(itmmenu,stindex){
        let vm = this;
        $('#editMenuItem').modal('show');
        vm.edit_menu.menu_item.title = itmmenu.menuitem.title;
        vm.edit_menu.menu_item.description = itmmenu.menuitem.description;
        vm.edit_menu.menu_item.price = itmmenu.menuitem.price;
        vm.edit_menu.menu_item.quantity = itmmenu.quantity;
        vm.edit_menu.menu_item.id = itmmenu.id;
        vm.edit_menu.menu_item.menu_id = itmmenu.menuitem.id;
        vm.edit_menu.menu_item.inventory = itmmenu.menuitem.inventory;
        //vm.edit_menu.menu_item_arry = itmmenu;
        vm.edit_menu.cartItms = itmmenu;
        vm.edit_menu.menuItmInx = stindex;
        vm.edit_menu.menu_item.specialrequest = itmmenu.other_data;
        vm.selectedseatid = itmmenu.id;
        vm.stindex = stindex;
        vm.seatqrtoken = itmmenu.qr_token_id;
      },
      validateEditItem(itmmenu){
        let vm = this;
        $('#editiorderfrm').parsley().whenValidate({

        }).done(function() {
          vm.saveEditItem();
        });
      },
      saveEditItem(){
        let vm = this;

        //alert(vm.seatqrtoken);
        vm.validation_errors = {};
        vm.error = '';
        $('#edit_item').prop('disabled',true);
        if(vm.edit_menu.menu_item.quantity > 0){
          let formData = new FormData();
          formData.append("u_menu_item", JSON.stringify(vm.edit_menu.menu_item));
          formData.append("restaurant_id", vm.restaurant_id);
          formData.append("qrtoken_id", vm.seatqrtoken);
          axios.post('/owner/orders/new-edit-seat-menuitem',formData).then(response => {
            vm.editorderfields.seatsarray.cart_items[vm.edit_menu.menuItmInx] = response.data.response.cart_updated_item;
           vm.editorderfields.tabletol = response.data.response.seat_order_total;
           $('#edit_item').prop('disabled',false);
            $('#editMenuItem').modal('hide');
            vm.getRestaurantCurrentOrders();
            vm.editorderfields.seatsarray[vm.stindex].seat_order_total = response.data.response.seat_order_total;
          }).catch(error => {
          });
        }else{
          this.removeItem(vm.edit_menu.menu_item.id,vm.edit_menu.cartItms,vm.edit_menu.menuItmInx,vm.stindex);
          $("#editMenuItem .close").click();
        }

      },
      increase: function() {
        let vm = this;
        vm.edit_menu.menu_item.quantity++;
      },
      decrease:  function() {
        let vm = this;
        if (vm.edit_menu.menu_item.quantity > 0) {
            vm.edit_menu.menu_item.quantity--;
        }

      },

      showMenuItems: function (event,menucatid) {

        this.load_menu_items = true;
        this.menu_items = {};
        this.activeMenuTitle = event.target.getAttribute('data-menu');
        this.activeTab = menucatid;
        this.activeTabInline = menucatid;
        let menuData = new FormData();
        menuData.append("menu_category_id", menucatid);
        axios.post('/owner/orders/get-restaurant-menu-items',menuData).then(response => {
          this.load_menu_items = false;
          this.menu_items = response.data.response.menu_items;
          $('#close_toggle_nav').click();
        }).catch(error => {
        });
      },
      showMenuItem: function (item) {
        console.log('showMenuItem-1')
        console.log(item)
        let vm = this;
        $('#viewItem').modal('show');
        vm.new_menu_item.title = item.title;
        vm.new_menu_item.description = item.description;
        vm.new_menu_item.price = item.price;
        vm.new_menu_item.id = item.id;
        vm.new_menu_item.image = item.images;
        vm.new_menu_item.specialrequest = '';
      },
      showAllMenus(){

      },
      close_all_menu_nav(){

      },
      ValidateSeatOrder() {
        let vm = this;
        $('#addiorderfrm').parsley().whenValidate({

        }).done(function() {
          vm.addOrderToSeat();
        });
      },
      addOrderToSeat: function () {
        let vm = this;
        vm.validation_errors = {};
        vm.error = '';
        $('#add_order').prop('disabled',true);
        let menuData = new FormData();
        menuData.append("menu_item_id", vm.new_menu_item.id);
        menuData.append("quantity", vm.new_menu_item.quantity);
        menuData.append("specialrequest", vm.new_menu_item.specialrequest);
        menuData.append("seat_id", vm.selectedseatid);
        menuData.append("qrtoken_id", vm.selectedseatid);
        axios.post('/owner/orders/add-menu-to-seat',menuData).then(response => {
          //console.log(response.data.response.item);

          $('#viewItem').modal('hide');
          $('#add_order').prop('disabled',false);
          //vm.new_menu_item = {};
          vm.new_menu_item.quantity = 1;
          vm.editorderfields.seatsarray[vm.stindex].cart_items.push(response.data.response.item);

          if(vm.editorderfields.seatsarray[vm.stindex].seat_order_total > 0){
            vm.editorderfields.seatsarray[vm.stindex].seat_order_total = vm.editorderfields.seatsarray[vm.stindex].seat_order_total + (response.data.response.item.menuitem.price * response.data.response.item.quantity);
          }else{
            vm.editorderfields.seatsarray[vm.stindex].seat_order_total = 0 + (response.data.response.item.menuitem.price * response.data.response.item.quantity);
          }

        }).catch(error => {
        });

      },
      increase_new: function() {
        let vm = this;
        vm.new_menu_item.quantity++;
      },
      decrease_new:  function() {
        let vm = this;
        if (vm.new_menu_item.quantity > 1) {
            vm.new_menu_item.quantity--;
        }

      },

      opencloseoutSeatsModal(sitem, sindex){
        let user = JSON.parse(sitem.guest_user_details);
        this.orderComplete.orderid = sitem.id;
        this.orderComplete.clientname = user.firstname+' '+user.last_name;
        //this.orderComplete.totalcartitems = sitem.order_items.length;
        this.orderComplete.totalcartitems = sitem.orderitemsQuantity;
        this.orderComplete.cartdata = sitem.order_items;
        this.orderComplete.index = sindex;
        $('#orderconfirmation').modal('show');
      },
      validateCloseOutSeat(itmmenu){
        let vm = this;
        vm.closeoutSelSeats();
      },
      closeoutSelSeats(){
        console.log('this.closeout');
        console.log(this.closeout);
        let formData = new FormData();
        formData.append("seat_id", this.closeout.seat_name);
        formData.append("cart_ids", this.closeout.selecteddeats);
        formData.append("restaurant_id", this.restaurant_id);
        axios.post('/owner/orders/new_close_out_table_seats',formData).then(response => {
          // $('#closeoutSeats').modal('hide');
          $('#CloseOutModal').modal('hide');
          console.log('response.data.response.order_id');
          console.log(response.data.response.order_id);
          if(response.data.response.order_id){
            window.location.href = "/owner/onsite_togo_orders/make-current-payment/"+response.data.response.order_id;
          }
          let getseats = this.closeout.selecteddeats;
          axios.post('/owner/orders/current_orders').then(response => {
            this.orders = response.data.response.table_list.table_rows;
            this.orderslength = response.data.response.totl_tbls;
            if(this.orderslength == 0){
              location.reload();
            }
          }).catch(error => {

          });

          for(let ar=0; ar < getseats.length; ar++){
            console.log(getseats[ar]);
            let get_indx_val = getseats[ar].split("-");
            console.log( get_indx_val[1]);
            //$('#seat-pop-card-id-'+get_indx_val[0]).hide();
            Vue.delete(this.editorderfields.seatsarray, get_indx_val[1]);
            Vue.delete(this.closeout.seatsdata, get_indx_val[1]);
          }

        }).catch(error => {
          if (error.response.status === 400) {
            alert(error.response.data.response.message);
          }else{
            alert("Something went wrong!");
          }
        });
      },
      selectAllSeats(ele) {
         var checkboxes = document.getElementsByClassName('seatselcls');
         if (ele.target.checked) {
             for (var i = 0; i < checkboxes.length; i++) {
                 if (checkboxes[i].type == 'checkbox') {
                     this.closeout.selecteddeats.push(checkboxes[i].value);
                     checkboxes[i].checked = true;
                 }
             }
         } else {
             for (var i = 0; i < checkboxes.length; i++) {
                 if (checkboxes[i].type == 'checkbox') {
                     this.closeout.selecteddeats = [];
                     checkboxes[i].checked = false;
                 }
             }
         }
         console.log('checkboxes');
         console.log(this.closeout.selecteddeats);
     },
     padTime(num) {
        return ("0"+num).slice(-2);
      },
      calltablescantimer(scanDate,elmId){

          let vm = this;
          setInterval(function(){
            let startTime = moment(scanDate);
            let endTime = moment();
            let seconds = endTime.diff(startTime, 'seconds');


            let minutes = Math.floor(seconds / 60);
            seconds = seconds%60;
            let hours = Math.floor(minutes/60)
            minutes = minutes%60;

            let time = vm.padTime(hours)+':'+vm.padTime(minutes)+':'+vm.padTime(seconds);
              $("#"+elmId).html(time);
            }, 1000);

      },
      getSpecialRequest_old(data){
        let arr = Array();
        data.cart_items.forEach((v, i) =>{
           if(v.other_data){
                arr.push(v.other_data);
              }
            this.sp_request = arr;
        });

      },
       NotifyClientDone(item_row_id,cindex){
        console.log('NotifyClientDone >>');
        console.log(item_row_id);
        console.log(cindex);
      $('#notify_btn_'+cindex).prop('disabled',true);

      let formData = new FormData();
      formData.append("item_id", item_row_id);
      formData.append("c_order_id", cindex);
      axios.post('/owner/orders/prepared_menu_item',formData).then(response => {
        this.message = response.data.response.message;
        // if(response.data.response.phoneavailable == 0){
        //   this.message = 'Phone number not provided by customer';
        // }
        // if(response.data.response.phoneavailable == 1){
        //   this.message = 'Order ready message sent';
        // }

        if(this.message){
          $('#notify_btn_'+cindex).removeClass('spinner-border spinner-border-sm');
          alert(this.message);
        }
        //$('#notify_btn_'+cindex).addClass('spinner-border spinner-border-sm');

        setTimeout( () => {
          $('#notify_btn_'+cindex).removeClass('spinner-border spinner-border-sm');
          $('#btn_'+cindex).removeClass('text-muted');
          this.getRestaurantCurrentOrders();
        }, 5000);

      $('#notify_btn_'+cindex).prop('disabled',false);
      }).catch(error => {
       $('#notify_btn_'+cindex).prop('disabled',false);
      });
    },
      NotifyClient(menuitemssrr,cindex){
      console.log('NotifyClient >>');
      console.log(menuitemssrr);
      console.log(cindex);
        $('#btn_'+cindex).addClass('text-muted');
        $('#notify_btn_'+cindex).addClass('spinner-border spinner-border-sm');
      let menu_item_list_ids=[];
       $.each(menuitemssrr, function(key, value) {
         menu_item_list_ids.push(value.cart_item_id);
       });
       console.log('menu_item_list_ids');
       console.log(menu_item_list_ids);


       this.NotifyClientDone(menu_item_list_ids,cindex);

     },
      OrderCompleteFunction(item_row_id,cindex){
       $('#notify_orderbtn_'+cindex).prop('disabled',true);
      $('#orderconfirmation').modal('hide');
      let formData = new FormData();
      formData.append("item_id", item_row_id);
      formData.append("c_order_id", cindex);
      axios.post('/owner/orders/ordercomplete_menu_item',formData).then(response => {
        //this.closeCard = response.data.response.closeCard;
        // $('#notify_orderbtn_'+cindex).addClass('spinner-border spinner-border-sm');

        setTimeout( () => {
          $('#notify_orderbtn_'+cindex).removeClass('spinner-border spinner-border-sm');
          $('#orderbtn_'+cindex).removeClass('text-muted');
          $('#orderbtn_'+cindex).prop('disabled',false);

          toastr.options = {
            "debug": false,
            "positionClass": "toast-bottom-right",
            "onclick": null,
            "fadeIn": 300,
            "fadeOut": 1000,
            "timeOut": 2000,
            "extendedTimeOut": 1000
          };

          this.getRestaurantCurrentOrders();
          toastr.success('Order Completed successfully.');

        },2000);

      $('#notify_orderbtn_'+cindex).prop('disabled',false);
      }).catch(error => {
       $('#notify_orderbtn_'+cindex).prop('disabled',false);
      });
    },
      OrderCompleteClickFunction(menuitemssrr,cindex){
      $('#orderbtn_'+cindex).addClass('text-muted');
      $('#orderbtn_'+cindex).prop('disabled',true);
       $('#notify_orderbtn_'+cindex).addClass('spinner-border spinner-border-sm');
      let menu_item_list_ids=[];
       $.each(menuitemssrr, function(key, value) {
         menu_item_list_ids.push(value.cart_item_id);
       });
       this.OrderCompleteFunction(menu_item_list_ids,cindex);

     },
      onsiteToGo(){
         window.location.href = "/owner/onsite_togo_orders/add";
      },

      OrderdelyFunction(){
        if(this.dely_time == '')
       {
        alert('Select Time to set dely.')
       }else{
        $('#notify_orderdely').prop('disabled',false);
        $('#notify_orderdely').addClass('spinner-border spinner-border-sm');
        let formData = new FormData();
        formData.append("dely_time",this.dely_time);
        formData.append("dely_message", this.dely_message);
         axios.post('/owner/orders/orderdely',formData).then(response => {
          this.delyData = response.data.response.data;

          this.dely_time = '';
          this.dely_message = '';
          clearTimeout(this.timer);
          //this.getDelyEndTimeAndDestroy();
            setTimeout( () => {
            $('#notify_orderdely').removeClass('spinner-border spinner-border-sm');
            this.getRestaurantCurrentOrders();
            }, 500);
           $("#setdelay").modal('hide');
          }).catch(error => {
            $('#notify_orderdely').prop('disabled',false);
          });
       }
      },
      resumeOrder(dely){
       $('#resumebtn').prop('disabled',false);
       $('#notify_resume').addClass('spinner-border spinner-border-sm');
       let formData = new FormData();
        formData.append("dely_id",dely.id);
        formData.append("dely_time",dely.dely_time);
        formData.append("dely_by",dely.dely_by);
        axios.post('/owner/orders/order_resume',formData).then(response => {
          this.dely='';
            setTimeout( () => {
            $('#notify_resume').removeClass('spinner-border spinner-border-sm');
            this.getRestaurantCurrentOrders();
            }, 2000);
          $("#setdelay").modal('hide');
          }).catch(error => {
            $('#resumebtn').prop('disabled',false);
          });
      },
      extendOrder(dely){
         if(this.dely_time == '')
          {
            alert('Select Time to extend dely.')
          }else{
         $('#extendOrder').prop('disabled',false);
         $('#notify_extendOrder').addClass('spinner-border spinner-border-sm');
         let formData = new FormData();
         formData.append("dely_id",dely.id);
         formData.append("dely_status",dely.status);
         formData.append("dely_time",this.dely_time);
         formData.append("dely_message",this.dely_message);
         formData.append("dely_by",dely.dely_by);
          axios.post('/owner/orders/order_extend',formData).then(response => {
             let vm  = this;
             //window.clearInterval(vm.timer);
             clearTimeout(vm.timer);
            setTimeout( () => {
            $('#notify_extendOrder').removeClass('spinner-border spinner-border-sm');
              this.getRestaurantCurrentOrders();
            }, 500);
          $("#setdelay").modal('hide');
          }).catch(error => {
            $('#extendOrder').prop('disabled',false);
          });
        }
      },

       getSpecialRequest(item){
        $('#specialrequest_model').modal('show');
        this.sprequest = item.other_data;
      },
       hideSetDelyModal(){
        $("#setdelay").modal('hide');
      },
    },

    computed: {
    }

    }
</script>
