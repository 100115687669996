<template>
  <div class="row">
    <div class="col-md-4">
      <div class="onsite-price">
        <h3>Scanned</h3>
        <span><a style="color:#000000;" href="/owner/orders">{{onsiteorders}}</a></span>
      </div>
    </div>
    <div class="col-md-4" >
      <div class="onsite-price">
        <h3>Onsite ToGo</h3>
        <span><a style="color:#000000;" href="/owner/orders">{{onsitetogoorders}}</a></span>
      </div>
    </div>
    

  </div>

</template>

<script>
export default {
props: ['restaurant_id'],
data() {
    return {
      onsiteorders:0,
      onsitetogoorders:0,
      barorders:0,
      isOnsiteLoading:'',
    }
},

created: function () {
  this.getalltypeorder();
},
mounted() {
  let vm = this;
  Echo.channel('item-confirmed-in-cart.'+vm.restaurant_id)
  .listen('ConfirmCartItem', (e) => {
        vm.getalltypeorder();
  });
},
methods:{
  getalltypeorder(){
    let vm = this;
    let formData = new FormData();
    formData.append("restaurant_id", vm.restaurant_id);
    axios.post('/owner/orders/get-all-type-order-count',formData).then(response => {
      console.log(response.data.response);
      vm.onsiteorders = response.data.response.order_count;
      vm.onsitetogoorders = response.data.response.togo_order_count;
      vm.barorders = response.data.response.bar_order_count;
      vm.isOnsiteLoading = response.data.response.type;

    }).catch(error => {
    });
  }
},

computed: {
}

}
</script>
