<template>
  <div class="row">
    <!-- Current Tables -->
    <div class="col-lg-12 foodtruckmenucls">
      <div class="card-body-menu flex-fill w-100">
          <div class="clearfix order-card-title-active-menu menu_drp_btn">

            <a  class="mb-0 float-right add_menu_btn" data-toggle="modal" data-target="#addMenuPrimary">
              <i class="align-middle fas fa-fw fa-plus addmenuicon" ></i>Add Menu
            </a>
            <div class="col-12 col-sm-12 col-md-4 col-xl-4 mb-0 float-right margin-right menu_list">
              <select style="float: right;width: 43%; height:46px !important;" class="form-poup" @change="showMenuAndCategory($event)">
                <option value="">Select</option>
                <option :value="item.id" :selected="activeTab == item.id" v-for="item in menus">
                  {{item.title}}
                </option>
              </select>
               <span style="font-size: 16px;" class="mb-0 float-right margin-right activemenu">Menus</span>
            </div>
          </div>
        <!-- Nav tabs -->
        <div class="row" v-if="menudetailfound">
          <div class="col-sm-12">
            <div class=" flex-fill w-100">

              <!-- Nav tabs -->
              <ul class="nav new-tablist-menu new-tablist-tabs" role="tablist" :id="`someList-${catfields.id}`">
                <li class="nav-item" >
                  <p class="nav-link"  >{{menudetail.title}}
                    <i class="align-middle fas fa-fw fa-edit parent-cat" @click="getCategories(menudetail)" style="cursor: pointer;font-size: 16px;margin-right: 20px;"></i>
                    <button v-if="menudetail.is_active_menu == 0" type="button" @click="activate_menu(menudetail.id)" class="mr-l activate_menu_btn">Activate</button>
                    <button v-if="menudetail.is_active_menu == 1" type="button" class="mr-l activated_menu_btn">Activated</button>
                  </p>

                </li>
              </ul>
              <div class="col-lg-6" v-if="catfields.id">
                <form method="POST" id="cat-form" name="catform" @submit.prevent="submit_addcat_Item" >
                  <input type="hidden" name="form_field" class="form-control" placeholder="Title" v-model="catfields.id" required>
                  <input type="text" name="form_field" class="form-control" placeholder="Title" v-model="catfields.title" required>
                  <button type="button" aria-label="Close" @click="closeCatForm()" class="close cat-btn-close"><span aria-hidden="true">×</span></button>
                  <button @click.prevent="removecat()" class="btn btn-primary btn-sm cat-btn" id="remove_cat">Remove</button>
                  <button @click.prevent="addcat_item()" class="btn btn-primary btn-sm cat-btn" id="save_cat">Save</button>
                </form>
                </div>
                <div class="categoriesmenubtn">
                    <a class="mb-0 float-right categoriesmenubtnfull" data-toggle="modal" data-target="#addCategoryPrimary">
                    <i class="align-middle fas fa-fw fa-plus addmenuicon "></i>Add New Categories</a>
                  </div>

              <!-- Nav tabs -->
              <div class="tab-content">

                <!-- tab-one -->
                <div v-if="categories.length == 0" class="empty_menu empty_msg">Category not available for this menu.</div>
                <div id="Lunch" class="tab-pane active" v-for="(itemcat,mindex) in categories">

                  <div>
                    <div class="clearfix table-card-title-head-menu">
                      <h5 class="card-title mb-0 float-left table-card-menu" :id="`card-category-title-id-${itemcat.id}`">{{itemcat.title}}
                      </h5>
                      <a v-if="itemcat.is_editable == 1" class="ml-2 " @click="edit_category(itemcat,mindex)">
                        <i class="align-middle fas fa-fw fa-edit" ></i></a>


                      <a  class="text-white add-tables-menu mb-0 float-right"  @click="openMenuPopup(itemcat.id,mindex,itemcat.menu.title)">+ Add
                        item</a>

                    </div>
                    <div class="row">
                      <!-- table1 -->
                      <div v-if="itemcat.menuitems.length == 0" class="empty_menu_items empty_msg"><div class="col-md-12">Menu items not available for this category.</div></div>
                      <div v-else class="col-12 col-md-6 col-lg-6 col-xl-4" v-for="(mitem,index)  in itemcat.menuitems">
                        <draggable ghost-class="ghost" tag="div" :animation="200" :group="'status'" @start="drag=true" @end="onEnd()">
                        <div class="table-card-menu-name sortorder" :data-order="`${mitem.id}`" :class="{ badreview: (mitem.inventory == 0) ? true : false }"  @click="edit_item(mindex,mitem,index,itemcat.menu.title)" :id="`card-menu-id-${mitem.id}`">
                          <span class="loader">
                          <span class="content"><i class="fa fa-spinner fa-spin"></i> Please wait...</span>
                          </span>
                          <div class="headeading-menu clearfix"  >
                            <div class="float-left">
                              <a>{{mitem.title}}</a>

                            </div>
                            <div class="float-right table-menu">
                              <span>${{parseFloat(mitem.price).toFixed(2)}}</span>
                            </div>
                          </div>
                          <div class="menu-item-name">
                            <p>{{mitem.description}}</p>
                          </div>
                          <div class="headeading-menu-rating clearfix">

                            <div class="float-right table-menu-total-review">
                              <span>{{mitem.inventory}}</span>
                            </div>
                          </div>
                        </div>
                        </draggable>
                      </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="modal fade" id="addCategoryPrimary" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title add-employee-popup">Add Category</h5>
              <button type="button" class="close" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form method="POST" id="addcatfrm-form" name="addcatfrm" @submit.prevent="submit" >
            <div class="m-5">

              <!-- <select class="form-poup" v-model="fields.menu_id" required>
                <option value="">Select</option>
                <option :value="item.id" v-for="item in menus">
                  {{item.title}}
                </option>
              </select> -->
              <input type="hidden" v-model="fields.menu_id" name="fields.menu_id" />
               <label for="">Title</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Title" v-model="fields.title" required>
               <label for="">Description</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Description" v-model="fields.description"  >
              <!-- <label for="">Order</label>
             <input type="text" name="form_field" class="form-poup"  v-model="fields.sortorder" > -->
              <div class="clearfix">
                <button @click.prevent="add()" class="btn add-btn float-right" id="save_cat">Save</button>
              </div>
            </div>
            <div class="row text-center">
              <div v-if="errors" class="text-danger col-12">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                  {{ problem_in_save_error }}
                </p>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>

      <div class="modal fade" id="addMenuPrimary" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title add-employee-popup">Add Menu</h5>
              <button type="button" class="close" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form method="POST" id="addmenufrm-form" name="addmenufrm" @submit.prevent="submit_menu" >
            <div class="m-5">
              <label for="">Title</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Title" v-model="menufields.title" required>
              <label for="">Description</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Description" v-model="menufields.description"  >
              <div class="clearfix">
                <button @click.prevent="add_menu()" class="btn add-btn float-right" id="save_menu">Save</button>
              </div>
            </div>
            <div class="row text-center">
              <div v-if="errors" class="text-danger col-12">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                  {{ problem_in_save_error }}
                </p>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>

      <div class="modal fade" id="addMenuItemPrimary" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title add-employee-popup">Add Item</h5>
              <button type="button" class="close" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form method="POST" id="additemfrm-form" name="additemfrm" @submit.prevent="submit_item">
            <div class="m-5">
              <label for="">Title</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Title" v-model="itemfields.title" required>
              <label for="">Description</label>
               <input type="text" name="form_field" class="form-poup" placeholder="Description" v-model="itemfields.description">
              <label for="">Price</label>
              <input  name="form_field" class="form-poup" placeholder="Price" v-model="itemfields.price" type="number" step="0.01" required data-parsley-error-message="Please enter valid price">
              <label for="">Inventory</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Inventory" v-model="itemfields.inventory" data-parsley-type="digits" required>
              <label for="">Upload Image</label>
              <input type="file" name="image" class="form-poup" accept=".png,.jpg,.jpeg" @change="onFileChange" >
              <div v-if="errorMessage" class="text-danger">{{ errorMessage }}</div>

              <div class="clearfix">
                <button @click.prevent="add_item()" class="btn add-btn float-right" id="save_item">Save</button>
              </div>
            </div>
            <div class="row text-center">
              <div v-if="errors" class="text-danger col-12">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                  {{ problem_in_save_error }}
                </p>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal fade" id="addMenuItemEditPrimary" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title add-employee-popup">Edit Item</h5>
              <button type="button" class="close" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form method="POST" id="additemeditfrm-form" name="additemeditfrm" @submit.prevent="submit_update_item" >
            <div class="m-5">
              <label for="">Title</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Title" v-model="itemeditfields.title" required>
              <label for="">Description</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Description" v-model="itemeditfields.description"  required>
              <label for="">Price</label>
              <input name="form_field" class="form-poup" placeholder="Price" v-model="itemeditfields.price" type="number" step="0.01" required data-parsley-error-message="Please enter valid price">
              <label for="">Inventory</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Inventory" v-model="itemeditfields.inventory" data-parsley-type="digits" required >
              <label for="">Upload Image</label>
              <input type="file" name="newimage" class="form-poup" @change="onEditFileChange">

              <label v-if="itemeditfields.image" for="">Image</label><br>
               <img v-if="itemeditfields.image"  :src="'/storage/menu_item_images/thumbnail/'+itemeditfields.image" :alt="itemeditfields.imagefile">

              <div class="clearfix">
                <button @click.prevent="delete_menu_item" :data-menuitem-id="menu_item_id"  class="btn remove-btn float-left" >Remove</button>
                <button @click.prevent="update_item()" class="btn add-btn float-right save-changes-btn" id="edit_item">Save Changes</button>
              </div>
            </div>
            <div class="row text-center">
              <div v-if="errors" class="text-danger col-12">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                  {{ problem_in_save_error }}
                </p>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>


      <div class="modal fade" id="editCategoryPrimary" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title add-employee-popup">Update Category</h5>
              <button type="button" class="close" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form method="POST" id="editcategory-form" name="editcategory-form" @submit.prevent="submit_update_category" >
            <div class="m-5">
              <label for="">Title</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Title" v-model="updatecategoryfields.title" required>
              <label for="">Description</label>
              <input type="text" name="form_field" class="form-poup" placeholder="Description" v-model="updatecategoryfields.description"  >
              <!-- <label for="">Order</label>
             <input type="text" name="form_field" class="form-poup"  v-model="updatecategoryfields.sortorder" > -->
              <div class="clearfix">
                <button @click.prevent="delete_category" :data-cat-id="cat_id_v"  class="btn remove-btn float-left" >Remove</button>
                <button @click.prevent="update_category()" class="btn add-btn float-right" id="edit_category">Save</button>
              </div>
            </div>
            <div class="row text-center">
              <div v-if="errors" class="text-danger col-12">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
              </div>
              <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                  {{ problem_in_save_error }}
                </p>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    data() {
        return {
            menudetailfound:false,
            message:'',
            categories:{},
            menus:{},
            fields: {
            title:'',
            description:'',
            menu_id:'',
            sortorder:''
            },
            catfields: {
            title:'',
            id:''
            },
            menufields: {
            title:'',
            description:'',
            },
            itemfields: {
              title:'',
              description:'',
              menu_category_id:'',
              price:'',
              inventory:'',
              id:'',
              catindex:'',
              item_type:'',
              item_serve_from:'',
              imagefile:''

            },
            itemeditfields:{
              title:'',
              description:'',
              menuitemid:'',
              price:'',
              inventory:'',
              item_type:'',
              image:'',
              newimage:'',
              item_serve_from:''
            },
            updatecategoryfields:{
              title:'',
              description:'',
              id:'',
              sortorder:''
            },
            errors: {},
            errorMessage:'',
            iserrors: false,
            success: false,
            isLoading: false,
            loading:false,
            problem_in_save_error:'',
            activeTab: null,
            itemeditindex:'',
            menu_item_id:'',
            ecat_index:'',
            cat_id_v:'',
            cat_index_id:'',
            menuitemtype:[{title: "Alcoholic"}, {title: "Non-Alcoholic"}],
            menuitemservefrom:[{title: "Bar"}, {title: "Kitchen"}],
            menudetail:[]

        }
    },
    
    created: function () {
      let vm = this;
      axios.get('/owner/menus/get-restaurant-menus').then(response => {
      vm.menus = response.data.response.menus;
      $.each(vm.menus, function(keymenu, valuemenu) {
        if(valuemenu.is_active_menu == 1){
          vm.activemenu_id = valuemenu.id;
          vm.showCategories(valuemenu.id);
        }else{
          vm.activemenu_id = null;
        }
      });
      }).catch(error => {
      });

    },
    mounted: function() {

    },
  components:{
            draggable,
        },
    methods:{
      onEnd(){
        var item_order = [];
         $('.sortorder').each(function() {
          item_order.push($(this).attr("data-order"));
        });
        console.log(item_order);
       
        axios.post('/owner/menus/update-display-order',item_order).then(response => {
             this.showCategories(this.fields.menu_id);
            //  location.reload();
        })
        
         
      },
      add() {
        let vm = this;
        $('#addcatfrm-form').parsley().whenValidate({

        }).done(function() {
        vm.submit();
        });

      },
      submit() {
        let vm = this;
        $('#save_cat').prop('disabled',true);
          vm.success = false;
          vm.errors = {};
          axios.post('/owner/menus/add-restaurant-category', vm.fields).then(response => {


            $("#addCategoryPrimary .close").click();
            toastr.success('Category added successfully.');
            $('#save_cat').prop('disabled',false);
            this.showCategories(vm.fields.menu_id);
            //vm.categories.push(response.data.response.category);
            vm.fields.title = ''; //Clear input fields.
            vm.fields.description = ''; //Clear input fields.
            vm.fields.menu_id = ''; //Clear input fields.
            vm.success = true;
          }).catch(error => {
            $('#save_cat').prop('disabled',false);
          });
      },
      add_menu() {
        let vm = this;
        $('#addmenufrm-form').parsley().whenValidate({

        }).done(function() {
          vm.submit_menu();
        });

      },
      submit_menu() {
        let vm = this;
        $('#save_menu').prop('disabled',true);
          vm.success = false;
          vm.errors = {};
          axios.post('/owner/menus/add-restaurant-menu', vm.menufields).then(response => {
            vm.menufields.title = ''; //Clear input fields.
            vm.menufields.description = ''; //Clear input fields.
            vm.success = true;

            $("#addMenuPrimary .close").click();
            toastr.success('Menu added successfully.');
            $('#save_menu').prop('disabled',false);

            this.menus.push(response.data.response.menu);

          }).catch(error => {
            $('#save_menu').prop('disabled',false);
            if (error.response.status === 422) {
              vm.errors = error.response.data.errors || {};
            }else{
              vm.problem_in_save_error = error.response.data.response.message || {};
            }
          });
      },
      showMenuAndCategory(ev){
        console.log(ev.target.value);
        this.categories = {};
          this.activeTab = ev.target.value;

          axios.get('/owner/menus/get-categories-by-menu/'+ev.target.value).then(response => {
            this.categories = response.data.response.categories;
            this.menudetail = response.data.response.menu;
            this.menudetailfound = true;
            this.fields.menu_id = this.menudetail.id;
          }).catch(error => {
          });
      },
      activate_menu(menuidv){
        let postDatamenu = new FormData();
        postDatamenu.append("id",menuidv);
        axios.post('/owner/menus/makemenuactive',postDatamenu).then(res => {
          this.menudetail.is_active_menu = 1;
        });
      },
      showCategories(rmenuid){
      this.categories = {};
        this.activeTab = rmenuid;
        this.activemenu_id = rmenuid;
        axios.get('/owner/menus/get-categories-by-menu/'+rmenuid).then(response => {
          this.categories = response.data.response.categories;
          this.menudetailfound = true;
          this.menudetail = response.data.response.menu;
          this.fields.menu_id = this.menudetail.id;
        }).catch(error => {
        });
      },
      getCategories(item){
        this.catfields.id = item.id;
        this.catfields.title = item.title;
      },
      closeCatForm(){
        this.catfields.id = '';
        this.catfields.title = '';
      },
      openMenuPopup(menucatid,catindex,menutitle){
        let vm = this;

        $("#addMenuItemPrimary").modal('show');

        vm.itemfields.menu_category_id = menucatid;
        vm.itemfields.catindex = catindex;
        $('#addMenuItemPrimary').on('shown.bs.modal', function (event) {

        });
      },
      // onFileChange(e) {
      // var files = e.target.files || e.dataTransfer.files;
      // if (!files.length)
      //   return;
      // this.createImage(files[0]);
      // },
      onFileChange(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        const file = files[0];
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!validTypes.includes(file.type)) {
          // Display an error message
          this.errorMessage = 'Please select a valid image file (PNG, JPG, JPEG).';
          return;
        }

        // Clear any previous error message
        this.errorMessage = '';

        // Proceed with creating the image
        this.createImage(file);
      },
      createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.itemfields.imagefile = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onEditFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createEditImage(files[0]);
      },
      createEditImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.itemeditfields.newimage = e.target.result;
      };
      reader.readAsDataURL(file);
    },
     pageRefresh(){
        window.location.reload();
      },
     removecat(){
      let vm = this;
        $('#save_cart').prop('disabled',true);
        $('#remove_cart').prop('disabled',true);
         axios.post('/owner/menus/removecat-menu', vm.catfields).then(response => {

            if(response.data.response.message){
                //toastr.error(response.data.response.message);
                alert(response.data.response.message)
              }else{
                 toastr.success("Category deleted successfully.");
              }
            // $('#someList-'+response.data.response.menu_category).attr(response.data.response.menu_category);
            $('#save_cat').prop('disabled',false);
            $('#remove_cat').prop('disabled',false);
            vm.catfields.id='';
             this.pageRefresh();
            //setTimeout(this.pageRefresh(), 15000);

          }).catch(error => {
            $('#save_cat').prop('disabled',false);
            $('#remove_cat').prop('disabled',false);
             toastr.error(error);
          });

      },
      // ------------------------------------------------
      addcat_item() {
        let vm = this;
        $('#cat-form').parsley().whenValidate({
        }).done(function() {
          vm.submit_addcat_Item();
        });
      },
      submit_addcat_Item() {
        let vm = this;
        $('#save_cart').prop('disabled',true);
        $('#remove_cart').prop('disabled',true);
          axios.post('/owner/menus/updatecat-menu', vm.catfields).then(response => {

            if(response.data.response.message){
                toastr.error(response.data.response.message);
              }else{
                 toastr.success("Menu title is updated successfully.");
              }
            // $('#someList-'+response.data.response.menu_category).attr(response.data.response.menu_category);
            $('#save_cat').prop('disabled',false);
            $('#remove_cat').prop('disabled',false);
            vm.catfields.id='';
            this.pageRefresh();

          }).catch(error => {
            $('#save_item').prop('disabled',false);
             toastr.error(error);

          });
      },
      // ---------------------------------------------
      add_item() {
        let vm = this;
        $('#additemfrm-form').parsley().whenValidate({

        }).done(function() {
          if (!vm.errorMessage) { 
              vm.submit_item(); 
            }
        });
      },
      submit_item() {
        let vm = this;
        $('#save_item').prop('disabled',true);
          vm.success = false;
          vm.errors = {};
          axios.post('/owner/menus/add-menu-item', vm.itemfields).then(response => {
            $("#addMenuItemPrimary .close").click();
            toastr.success('Menu Item added successfully.');
            $('#save_item').prop('disabled',false);
            vm.categories[vm.itemfields.catindex]['menuitems'].push(response.data.response.menu_item);
            vm.itemfields.title='';
            vm.itemfields.description='';
            vm.itemfields.menu_category_id='';
            vm.itemfields.price='';
            vm.itemfields.inventory='';
            vm.itemfields.id='';
            vm.itemfields.catindex='';

            vm.success = true;

          }).catch(error => {
            $('#save_item').prop('disabled',false);
            if (error.response.status === 422) {
              vm.errors = error.response.data.errors || {};
            }else{
              vm.problem_in_save_error = error.response.data.response.message || {};
            }
          });
      },
      edit_item: function (catindex,item,index,menutitle) {
        let vm = this;
        $('#addMenuItemEditPrimary').modal('show');
        $('#addMenuItemEditPrimary').on('shown.bs.modal', function (event) {

        });
        vm.itemeditfields.title = item.title;
        vm.itemeditfields.image = item.images;
        vm.itemeditfields.price = item.price;
        vm.itemeditfields.description = item.description;
        vm.itemeditfields.inventory = item.inventory;
        vm.itemeditfields.id = item.id;
        vm.itemeditindex = index;
        vm.menu_item_id = item.id;
        vm.ecat_index = catindex;

      },
      update_item: function (item) {
      let vm = this;
      $('#additemeditfrm-form').parsley().whenValidate({

      }).done(function() {
      vm.submit_update_item(item);
      });
      },
      submit_update_item: function (item) {
      let vm = this;
      $('#edit_item').prop('disabled',true);
        vm.success = false;
        vm.errors = {};
        axios.post('/owner/menus/update-menu-item', vm.itemeditfields).then(response => {
           vm.itemeditfields = {}; //Clear input fields.
           $('#addMenuItemEditPrimary').modal('hide');
          vm.success = true;
          $("#addMenuItemEditPrimary .close").click();
          $('#card-menu-id-'+response.data.response.menu_item.id).addClass('loading-spin');

          setTimeout( () => {
            this.categories[vm.ecat_index]['menuitems'].splice(this.itemeditindex, 1, response.data.response.menu_item);
            $('#card-menu-id-'+response.data.response.menu_item.id).removeClass('loading-spin');
            vm.itemeditfields.title = '';
            vm.itemeditfields.price = '';
            vm.itemeditfields.description = '';
            vm.itemeditfields.inventory = '';
            vm.itemeditfields.id = '';
            vm.itemeditindex = '';
            vm.menu_item_id = '';
            vm.ecat_index = '';

            toastr.success('Menu Item updated successfully.');

          }, 2000);
          $('#edit_item').prop('disabled',false);


        }).catch(error => {
          $('#edit_item').prop('disabled',false);
          if (error.response.status === 400) {
            vm.problem_in_update_error = error.response.data.response.message || {};
          }else{
            vm.update_errors = error.response.data.errors || {};
          }
        });

      },
      delete_menu_item: function (event,item) {
        let vm = this;
        var id = event.target.getAttribute('data-menuitem-id');
        if (id) {
        if(confirm("Do you really want to delete?")){
          let formData = new FormData();

          formData.append("id", id);
            axios.post('/owner/menus/destroy-menu-item', formData).then(response => {
              $("#addMenuItemEditPrimary .close").click();
              if(response.data.response.message){
                toastr.error(response.data.response.message);
              }else{
                 toastr.success("Menu item is deleted successfully.");
                Vue.delete(vm.categories[vm.ecat_index]['menuitems'],vm.itemeditindex);
              }



              //vm.categories[vm.ecat_index]['menuitems'].splice(vm.categories[vm.ecat_index]['menuitems'].indexOf(response.data.response.menu_item), 1);

            }).catch(error => {
              //  if (error.response.status === 500) {
              //   toastr.error(response.message);
              //  }
            });
        }
        }
      },
      edit_category: function (item,mindex) {
        let vm = this;
        $('#editCategoryPrimary').modal('show');
        vm.updatecategoryfields.title = item.title;
        vm.updatecategoryfields.description = item.description;
        vm.updatecategoryfields.sortorder = item.sortorder;
        vm.updatecategoryfields.id = item.id;
        vm.cat_id_v = item.id;
        vm.cat_index_id = mindex;
      },
      update_category: function () {
      let vm = this;
      $('#editcategory-form').parsley().whenValidate({

      }).done(function() {
      vm.submit_update_category();
      });
      },
      submit_update_category: function () {
      let vm = this;
      $('#edit_category').prop('disabled',true);
        vm.success = false;
        vm.errors = {};
        axios.post('/owner/menus/update-category', vm.updatecategoryfields).then(response => {
          $('#card-category-title-id-'+vm.updatecategoryfields.id).text(vm.updatecategoryfields.title);

          $("#editCategoryPrimary .close").click();
          toastr.success('Category updated successfully.');
          $('#edit_category').prop('disabled',false);
          this.showCategories(response.data.response.menu_category.menu_id);
          vm.success = true;

          vm.updatecategoryfields.title = '';
          vm.updatecategoryfields.description = '';
          vm.cat_index_id = '';
          vm.cat_id_v = '';
          vm.updatecategoryfields.id = '';

        }).catch(error => {
          $('#edit_category').prop('disabled',false);
          if (error.response.status === 400) {
            vm.problem_in_save_error = error.response.data.response.message || {};
          }
          else if (error.response.status === 422) {
            vm.errors = error.response.data.errors;
          }else{
            vm.errors = error.response.data.response.message || {};
          }
        });

      },
      delete_category: function () {
        let vm = this;
        var id = event.target.getAttribute('data-cat-id');

        if (id) {
        if(confirm("Do you really want to delete?")){
          let formData = new FormData();

          formData.append("id", id);
            axios.post('/owner/menus/destroy-category', formData).then(response => {
              $("#editCategoryPrimary .close").click();
              if(response.data.response.message){
                toastr.error(response.data.response.message);
              }else{
                 toastr.success('Category deleted successfully.');
                Vue.delete(this.categories, vm.cat_index_id);
              }

            }).catch(error => {

            });
        }
        }
      },

  },
 }


</script>
