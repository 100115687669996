<template>
    <div>
      <form method="POST" id="blastformTwo" name="blastformTwo" @submit.prevent="submit_blast_form_two" data-parsley-validate >
                      <div class="modal-content" id="card-reward-id">
                      
                        <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="modal-body">
                          <div class="newblast">
                              <h6>New Blast</h6>
                              <p>Your account will be charged on your Blast start date. One message per type per day.</p>
                          </div>                             
                          
                          <div class="blastlabel">
                            <p>Blast Text <span>(240 characters max)</span></p>
                            <input type="text" name="firstName" v-model="edit_discount_fields_two.title" class="form-poup mb-2" required>
                          </div>
                          <div class="BlastType">
                            <p>Blast Type</p>
                            <select class="form-poup"  required v-model="edit_discount_fields_two.mailtype">
                              <option selected>Text and Email</option>
                            </select>
                          </div>
                        </div>      
                        <hr style="border: 1px solid #f6f6f6;width: 100%;margin: 0;" />
                              <div class="modal-body">
                                <div class="bookeventlabel">
                                    <p>Menu special or event for your blast</p>
                                    <input type="radio" id="typeoflist1" name="typeoflist" value="1" v-model="edit_discount_fields_two.selectedType">
                                    <label for="typeoflist1">Menu Special</label> &nbsp;&nbsp;&nbsp;
                                    <input type="radio" id="typeoflist2" name="typeoflist" value="2" v-model="edit_discount_fields_two.selectedType">
                                    <label for="typeoflist2">Event</label>
                                  <select class="form-poup"  required v-model="edit_discount_fields_two.id" id="selectOptions">
                                    <option v-for="(option, index) in dynamicOptions" :value="option.value" :key="index">{{ option.label }}</option>                                    </select>
                                </div>
                              </div>
                          <hr style="border: 1px solid #f6f6f6;width: 100%;margin: 0;" />
                            <div class="modal-body">  
                              <div class="Menu_wrap ">
                                <div class="menu_name_wrap ">
                                  <div class="row" style="padding: 0px 10px;">
                                    <div class="col-md-6">
                                      <div class="sp_menu_name">
                                        <h5>Duration of Blast</h5>
                                        <p>Number of days you want messages sent</p>
                                      </div>    
                                    </div>

                                    
                                     <div class="col-md-3">
                                          <label class="treat-label">Start Date</label>
                                          <!-- <input type="date"   id="valid_from" v-model="edit_discount_fields.valid_from" placeholder="11/02/2020" class="form-poup-shift-type" required> -->
                                      <b-form-datepicker id="valid_from" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields_two.valid_from" class="form-poup-shift-type" required></b-form-datepicker>
                                      </div>
                                       <div class="col-md-3">
                                          <label class="treat-label">End Date</label>
                                          <!-- <input type="date"  id="valid_to" v-model="edit_discount_fields.valid_to" placeholder="11/31/2020" class="form-poup-shift-type" required> -->
                                          <b-form-datepicker id="valid_to" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields_two.valid_to" class="form-poup-shift-type" required></b-form-datepicker>
                                      </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          <hr style="border: 1px solid #f6f6f6;width: 100%;margin: 0;" />
                            <div class="modal-body"> 
                              <div class="preyday">
                                <h6>Blast Details</h6>
                                <p>Your blast will only go to customers that have purchased from you. </p>
                              </div> 
                              <div class="prey_list">
                                <div class="col-md-12">
                                  <div class="bl_details">
                                    <div class="msg_email">
                                      <p><i class="far fa-envelope"></i><span>  {{ edit_discount_fields_two.emailcount }} emails </span></p>
                                    </div>
                                    <div class="msg_email">
                                      <p><i class='fas fa-mobile-alt'></i><span>{{ edit_discount_fields_two.phonecount }} text messages</span></p>
                                    </div>
                                    <div class="msg_email">
                                      <h3>$5.00 x  {{edit_discount_fields_two.days}} day(s)</h3>
                                    </div>
                                  </div>  
                                </div>
                              </div>
                              <div class="prey_list tlt_sec">
                                <div class="col-md-12">
                                  <div class="bl_details">
                                    <div class="msg_email tlt_smg">
                                      <p><span>{{edit_discount_fields_two.emailcount + edit_discount_fields_two.phonecount}} total messages</span></p>
                                    </div>
                                   
                                    <div class="msg_email">
                                      <h3>$ {{edit_discount_fields_two.amount_to_pay}}</h3>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                              <div class="agree_check">
                                <input type="checkbox" id="terms_and_service" name="terms_and_service" v-model="edit_discount_fields_two.terms_and_service">
                                <label for="vehicle1">I agree to Fireflyy Blasts <span @click="openLinkInNewTab">Terms of Service</span></label><br>
                              </div>
                                <div class="evnt_btns">
                                  <div v-if="isLoading" class="loader-overlay123">
                                   <span class="loader123" >
                                    <span class="content"><i style="color: #ff5802;" class="fa fa-spinner fa-spin"></i> Please wait...</span>
                                  </span>
                                </div>
                                  <div class="innterbtn-edit cancel_event">
                                      <button type="button" data-dismiss="modal" aria-label="Close"> Cancel</button>
                                  </div>
                                  <div class="Evt_btn">
                                      <button type="submit" class="btn add-emp-btn float-right" id="submit-blast" @click.prevent="submit_blast_validate_two()">Start Blast</button>
                                  </div>
                                </div>
                                <div class="row text-center">
                                <div v-if="errors" class="text-danger col-12" style="margin-top: 20px;">
                                  <div v-for="(v, k) in errors" :key="k">
                                    <p v-for="error in v" :key="error" class="text-sm" style="margin-bottom: 6px;">
                                      {{ error }}
                                    </p>
                                  </div>
                                </div>
                                <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                                  <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                                    {{ problem_in_save_error }}
                                  </p>
                                </div>
                              </div>
                            </div>
                      </div>
              </form>
    </div>
  </template>
  
  <script>
  
    export default {
    props:['current_date'],
    data() {
        return {
            fields: {
              points:'',
              amount:'',
              reward_id:''
            },
            reward:{},
            errors: {},
            blastStatus: true,
            iserrors: false,
            success: false,
            isLoading: false,
            loading:false,
            problem_in_save_error:'',
            menu_categories:{},
            menu_items:[],
            menus:{},
            menu_id:'',
            discount_fields: {
              menu_item_ids: [],
              title: '',
              valid_from: '',
              valid_to: '',
              treat: '',
              menu_id:'',
              type:'amount',
              active:false
            },
            selectoptionData: [],
            selectEventoptionData: [],
            edit_discount_fields_two: {
              menu_item_ids: [],
              days: '',
              title: '',
              valid_from: '',
              valid_to: '',
              // treat: '',
              id:'',
              // menu_id:'',
              type:'',
              // sel_menu_item_ids:[],
              active:'',
              // collection_for:'',
              reaminingdays:'',
              userdetails: {},
              emailcount: '',
              terms_and_service:0,
              amount_to_pay: 0,
              phonecount: '',
              selectedType: "1",
              selectedOption: "",
              mailtype: 'Text and Email'
            },
        }
    },
    watch: {
      'edit_discount_fields_two.valid_from': 'updateDaysAndAmountTwo',
      'edit_discount_fields_two.valid_to': 'updateDaysAndAmountTwo',
    },
    computed: {
      dynamicOptions() {
        if(this.edit_discount_fields_two.selectedType === "1") {
          return Object.values(this.menus).flatMap(item => (
            item.restaurant_discounts.map(discountItem => ({
              value: discountItem.id,
              label: discountItem.title,
            }))
          ));
        }else if(this.edit_discount_fields_two.selectedType === "2") {
          return Object.values(this.selectEventoptionData).flatMap(item => ({
            value: item.event.id, 
            label: item.event.name,
          }));
        }
  
        return [];
  
      },
      getFormattedOptionText() {
        return `<p>${this.edit_discount_fields.title} <br /><span>${this.edit_discount_fields.reaminingdays}</span></p>`;
      },
    },
    // created() {
    //   this.get_menus();
    //   this.getMenuItems();
    // },
    mounted(){
      this.get_menus();
      this.getmarketplaceevents();
      this.getMenuItems();
    },
    methods:{
      openLinkInNewTab() {
      const url = '/owner/manage_treats_rewards/policy';
        window.open(url, '_blank');
      },
      updateDaysAndAmountTwo() {
        const validFrom = new Date(this.edit_discount_fields_two.valid_from);
        const validTo = new Date(this.edit_discount_fields_two.valid_to);
  
        if (!isNaN(validFrom) && !isNaN(validTo)) {
          const timeDiff = Math.abs(validTo - validFrom);
          const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  
          this.edit_discount_fields_two.days = daysDiff;
          this.edit_discount_fields_two.reaminingdays = daysDiff + ' day' + (daysDiff !== 1 ? 's' : '') + ' remaining Expires ' + this.edit_discount_fields_two.valid_to;
          this.edit_discount_fields_two.amount_to_pay = daysDiff * 5;
        } else {
          // Handle invalid date input
          this.edit_discount_fields_two.days = null;
          this.edit_discount_fields_two.amount_to_pay = null;
        }
      },
      displayRemainingDays(validFrom, validTo) {
      const currentDate = new Date();
      const startDate = new Date(validFrom);
      const endDate = new Date(validTo);
      const daysDiff = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
  
      let blastStatus = true;
  
      if (daysDiff < 0) {
        blastStatus = false;
        return 'Expired at ' + validTo;
      } else if (daysDiff === 0) {
        blastStatus = true;
        return '1 day remaining Expires ' + validTo;
      } else {
        blastStatus = true;
        return daysDiff + ' day' + (daysDiff !== 1 ? 's' : '') + ' remaining Expires ' + validTo;
      }
    },
    getmarketplaceevents(){
         axios.get('/owner/get-event-data').then(response => {
          this.selectEventoptionData = response.data.data;
          console.log('this.selectEventoptionData');
          console.log(this.selectEventoptionData);
          }).catch(error => {
            console.log(error);
          });
        },
      get_menus(){
        axios.get('/owner/menus/get-restaurant-menus').then(response => {
          console.log('/owner/menus/get-restaurant-menus');
          console.log(response);
        this.menus = response.data.response.menus;
        this.edit_discount_fields_two.emailcount = response.data.response.emailcount;
        this.edit_discount_fields_two.phonecount = response.data.response.phonecount;
        this.edit_discount_fields_two.userdetails = response.data.response.emailphonelist;
        }).catch(error => {
          console.error('Error fetching menus:', error);
        });
      },
      getMenuItems(){
        axios.get('/owner/manage_treats_rewards/new_get_r_menu_categories').then(response => {
          this.menu_items = response.data.response.item_options;
          this.discount_fields.menu_id = response.data.response.menu_id;
          console.log('getMenuItems');
        console.log(this.menu_items);
  
        }).catch(error => {
          console.log(error);
        });
  
      },
      
      add_discount() {
        let vm = this;
        $('#adddiscount-form123').parsley().whenValidate({
  
        }).done(function() {
        vm.submit_discount();
        });
  
      },
      submit_discount() {
        let vm = this;
          $('#save_discount').prop('disabled',true);
          vm.success = false;
          vm.errors = {};
          axios.post('/owner/manage_treats_rewards/add_discount', vm.discount_fields).then(response => {
            vm.success = true;
            vm.discount_fields = {}
            vm.discount_fields.type = 'amount';
            $("#addDiscount123 .close").click();
            toastr.success('Special Menu added successfully.');
            setTimeout( () => {
              vm.get_menus();
              location.reload();
             }, 3000);
            $('#save_discount').prop('disabled',false);
          }).catch(error => {
            $('#save_discount').prop('disabled',false);
            if (error.response.status === 400) {
              vm.problem_in_save_error = error.response.data.response.message || {};
            }
            else if (error.response.status === 422) {
              vm.errors = error.response.data.errors;
            }else{
              vm.errors = error.response.data.response.message || {};
            }
          });
      },
      submit_blast_validate_two() {
        if (!this.edit_discount_fields_two.terms_and_service) {
        toastr.error('Please agree to the Terms of Service before submitting.');
        return;
        }
        let vm = this;
        $('#blastformTwo').parsley().whenValidate({
  
        }).done(function() {
        vm.submit_blast_form_two();
        });
      },
      submit_blast_form_two(){
        axios.post('/owner/manage_treats_rewards/add_blast_menu', this.edit_discount_fields_two).then(response => {
              if(response.data.status == 422){
                  toastr.error(response.data.message)
                  setTimeout(() => {
                  window.location.href = '/owner/specialmenu';
                  }, 1000);
              }else{
                  this.resdata = response.data.response.resdata;
                  window.location.href = '/owner/manage_treats_rewards/make-blast-payment?blast=' + this.resdata;
              }
              
          }).catch(error => {
          $('#submit-blast').prop('disabled',false);
          if (error.response.status === 400) {
            this.problem_in_save_error = error.response.data.response.message || {};
          }
          else if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }else{
            this.errors = error.response.data.response.message || {};
          }
          setTimeout( () => {
            $('#blastModel').modal('hide')
            location.reload();
            this.edit_discount_fields = {};
          }, 3000);
        });
      },
      close_popup(){
        $('#blastformTwo').parsley().reset();
      },
      close_edit_popup(){
        $('#editdiscount-form').parsley().reset();
      }
      
  }
  
  
    }
  </script>