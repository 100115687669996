<template>
  <div  class="col-12 col-md-6 col-lg-6 col-xl-4">
    <div class="history-table-card">
      <div class="user-headeading-togo-history clearfix" style="background-color:#bdc0c5d9;">
        <div class="float-left">
          <div><a href="#" style="color:#000;">Bar</a>
          </div>
        </div>
      </div>
       <div v-if="bar_menus.length > 0">
        <div v-if="closeCard == 0" class="outer-food-details-history">
          <div v-for="(item_c,index_c) in bar_menus" class="row" :class="{ 'blank': item_c.prepared == 0, 'history-green': item_c.prepared == 1,'history-red': item_c.cancelled == 1 }">
            <div class="col-sm-6 col-6"  style="padding-right: 0;">
              <div class="order-name">
                <span>{{item_c.quantity}}</span>
                <p>{{item_c.menuitem.title}}
                  <br>
                  <span title="click to see special request" style="cursor: pointer;color:#ff5802;font-size: 12px;" @click="getspecialRequests(item_c)" v-if="item_c.other_data && item_c.prepared == 0">{{ (item_c.other_data).substring(0,8)+"..." }}</span>
                </p>
              </div>
            </div>
            <div class="col-sm-3 col-3" style="padding-right: 0;">
              <div class="item-price-history">
                <span :id="'kttimer_onsite_clk_'+item_c.id">{{calltablescantimer_togo(item_c.created_at,'kttimer_onsite_clk_'+item_c.id)}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-3" style="padding-left: 0;">
              <div v-if="item_c.prepared == 0" class="history-check">
                <button :id="`kitchen_togo_done_btn_${item_c.id}`" class="btn kitchen_done_btn" @click.prevent="mark_togo_menu_done(item_c.id)">Done</button>
              </div>
            </div>
          </div>
        </div>
        
       <div class="total-timing-price-history clearfix">
          <div class="float-right total-price-number-hostory" v-if="closeCard == 0">
            <button class="btn kitchen_complete_btn" id="complete_togo_menus" @click="preparedAllToGoOrderMenus(bar_menus)">Complete</button>
          </div>
          <div class="total-price-number-hostory" v-else>
           <p style="text-align:left"> No Order Yet...</p>
            <!-- <button class="btn kitchen_completed_btn" ><i class="fa fa-check" aria-hidden="true"></i> Completed</button> -->
          </div>
        </div>


      </div>
  </div>
   <!-- model list of special request start -->

      <div class="modal fade" id="specialrequest_models3" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-header" style="background-color: #4256ce;">
                <h4 class="modal-title" style="color: #fff;">Special Requests:</h4></div>
            <div class="modal-body" v-if="sprequest">
              <ul class="list-group">
                  <li class="list-group-item">{{sprequest}}</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- model list of special request end -->
</div>
</template>

<script>
export default {
  data() {
    return {
       sprequest:'',
      bar_menus:{},
      bar_menus_prepared:{},
      bar_menus_cancelled:{},

      bar_menus_length:'',
      closeCard:'',
      preparedbar_menus_length:'',
      cancelledbar_menus_length:'',
      bar_menus_confirmed:1
    };
  },
  created: function () {
    this.barOrdersInKitchen();
  },
  methods: {
    barOrdersInKitchen(){
      axios.post('/owner/orders/bar-menus-kitchen').then(response => {
        this.bar_menus = response.data.response.bar_menus;
        this.closeCard = response.data.response.closeCard;
               
      }).catch(error => {

      });
    },
    calltablescantimer_togo(scanDate,elmId){
         let vm = this;
         setInterval(function(){
           let startTime = moment(scanDate);
           let endTime = moment();
           let minutes = endTime.diff(startTime, 'minutes');
           let time = minutes;
             $("#"+elmId).html(time+'m');
           }, 1000);
    },
    mark_togo_menu_done(item_row_id){
      $('#kitchen_togo_done_btn_'+item_row_id).prop('disabled',true);
      let formData = new FormData();
      formData.append("item_id", item_row_id);
      axios.post('/owner/orders/prepared_barmenu_item',formData).then(response => {
        $('#menu-emp-id').addClass('loading-spin');
        setTimeout( () => {
          $('#menu-emp-id').removeClass('loading-spin');
          this.barOrdersInKitchen();
        }, 5000);
        $('#kitchen_togo_done_btn_'+item_row_id).prop('disabled',false);
      }).catch(error => {
        $('#kitchen_togo_done_btn_'+item_row_id).prop('disabled',false);
      });
    },
    preparedAllToGoOrderMenus(menuitemssrr){
      console.log('menuitemssrr')
      console.log(menuitemssrr)
      $('#complete_togo_menus').prop('disabled',true);
      let menu_item_list_ids=[];
      $.each(menuitemssrr, function(key, value) {
        menu_item_list_ids.push(value.id);
      });
      console.log('menu_item_list_ids')
      console.log(menu_item_list_ids)
      this.mark_togo_menu_done(menu_item_list_ids);
    },
      getspecialRequests(item){
        $('#specialrequest_models3').modal('show');
        this.sprequest = item.other_data;
      } ,
  }
};
</script>
