<template>
    <div>
        <div class="calender_main_wrap">          
            <div class="calender_sec" id="first" style="border-left: 1px solid #ccc;">
                <h4>{{firstmitems.name}}
                  
                    <div v-if="firstmitems.likes !== null && firstmitems.likes.status == 1">
                      <i v-if="!likedItems.includes(firstmitems.id)" class="align-middle far fa-heart float-right" v-on:click="toggleLike(firstmitems.id)"></i>
                      <i v-else class="align-middle fa fa-heart heart-red float-right" v-on:click="toggleLike(firstmitems.id)"></i>
                   </div>
                   <div v-else>
                      <i v-if="!likedItems.includes(firstmitems.id)" class="align-middle far fa-heart float-right" v-on:click="toggleLike(firstmitems.id)"></i>
                      <i v-else class="align-middle fa fa-heart heart-red float-right" v-on:click="toggleLike(firstmitems.id)"></i>
                   </div>
                </h4>
                    <img :src="'/storage/event_picture/thumbnail/'+firstmitems.photos" alt="" />
                <div class="evt_add">
                    <h5><i class="align-middle fas fa-map-marker-alt "></i> {{firstmitems.name}}</h5>
                    <span>{{firstmitems.address}} <i class="align-middle fas fa-location-arrow" v-on:click="openMap(firstmitems.address)"></i></span>
                    <p><i class="align-middle fas fa-clock"></i> {{convertedStartTime(firstmitems.start_time)}} - {{convertedEndTime(firstmitems.end_time)}}</p> 
                    <p style="float: left;width: 7%; margin-top:0;"><i class="align-middle fas fa-users"></i></p>
                    <p style="float: left;width: 56%;margin-top:0;">{{firstmitems.estimated_attendance}} people expected permit required</p>
                    
                </div>
                <div class="fee">
                    <h4>${{firstmitems.booking_fee}} Booking Fee</h4>
                   
                    <div v-if="checkbookdata">
                      <div v-if="checkbookdata.paid == 0">
                        <button class="btn add-btn-order" data-toggle="modal" data-target="#bookevents" type="button" style="margin: 0 !important;width: 100%;margin-top: 21px !important;float: left;">
                          Book Event
                        </button>
                      </div>
                    </div>
                    <div v-else>
                      <div>
                        <button class="btn add-btn-order" data-toggle="modal" data-target="#bookevents" type="button" style="margin: 0 !important;width: 100%;margin-top: 21px !important;float: left;">
                          Book Event
                        </button>
                      </div>
                    </div>

                
                    <div class="att_tle">
                        <h4 style="padding-left: 0;margin-bottom: 10px;">Merchants Attending</h4>
                    </div>
                    <div class="att_card">
                        <merchants-attending-component :ids="firstmitems.id"></merchants-attending-component>
                    </div>
                </div>  
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="bookevents" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
                    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 380px;">
                       <form @submit.prevent="BookEvent">
                        <div class="modal-content">
                            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true" @click="closeModel()">&times;</span>
                            </button>
                                <div class="modal-body">
                                <div class="evtlt">
                                    <h6>Book Event</h6>
                                </div>
                                <div class="bookeventlabel">
                                    <p>Are you permitted to work in <span> {{firstmitems.address}}</span></p>
                                </div>

                                <div>
                                    <select class="form-poup" name="first_dropdown" v-model="catfields.first_dropdown">
                                    <option>No</option>
                                    <option>Yes</option>
                                    
                                    </select>
                                </div>


                                <div class="bookeventlabel">
                                    <p>Are you offering pre-ordering?</p>
                                </div>

                                <div>
                                    <select class="form-poup" name="second_dropdown" v-model="catfields.second_dropdown">
                                    <option selected>Yes</option>
                                    <option>No</option>
                                    </select>
                                </div>

                                    <div class="bookeventlabel">
                                        <p>Booking Fee</p>
                                    </div>
                                    <div class="bookeventlabel">
                                         <h4>${{ bookingFee }}</h4>
                                    </div>
                                    <div class="bookeventlabel" v-if="cards.length > 0">
                                        <p>Select the card</p>
                                    </div>
                                    <select class="form-control cardlisthead" id="selectId" name="selectedcard" v-if="cards.length > 0" v-model="selectedCardId">
                                      <option v-for="c in cards" :value="c.id" class="cardlist" :selected="c.id === cards[0].id">
                                        card brand:{{ c.card_type }},card number: xxxx - xxxx - xxxx - {{ c.card_number }}, expiry: {{ c.card_expiration }}
                                      </option>
                                    </select>
                                    <div class="evnt_btns">
                                        <div class="innterbtn-edit cancel_event">
                                            <button type="button" @click="closeModel()"> Cancel</button>
                                        </div>
                                        <div class="Evt_btn">
                                            <!-- <a class="btn add-emp-btn float-right" :href="'/owner/onsite_togo_orders/make-payment/'+eventId">Book Event</a> -->
                                            <button type="submit" class="btn add-emp-btn float-right"> Book Event</button>
                                        </div>
                                    </div>

                                </div>
                            
                                <div class="row text-center">
                                    <div class="text-danger col-12"></div> 
                                </div>

                            </div>
                        </form>
                        </div>
                    </div>
        <!-- end -->
    </div>
</template>
<script>
export default {
    props: ['firstmitems','cards'],
     data(){
      return {
        catfields: {
          first_dropdown:'Yes',
          second_dropdown:'No',
        },
        selectedCardId: null,
        status:'',
         eventId:'',
         bookingFee: 0,
         resdata:'',
         checkbookdata:[],
         likedItems: [],
      }
  },
  created() {
          this.bookingFee = this.firstmitems.booking_fee;
          this.eventId = this.firstmitems.id;
          const likedItems = localStorage.getItem('likedItems');
          this.likedItems = likedItems ? JSON.parse(likedItems) : [];

          if (this.cards.length > 0) {
                this.selectedCardId = this.cards[0].id;
          }
    },
    watch:{
    firstmitems: {
    immediate: true,
    handler(newValue) {
      this.bookingFee = newValue.booking_fee;
      this.eventId = newValue.id;
      this.CheckBookEvent(this.eventId);
    }
  }
  },
  mounted(){
    // const vm = this;
    // vm.eventId = this.firstmitems.id;
    // vm.CheckBookEvent(vm.eventId);
  },
 methods: {
    currentDate(item){
      const date = new Date(item);
      return date.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    },
    convertedStartTime(time) {
      const [hours, minutes] = time.split(":");
      const d = new Date();
      d.setHours(hours);
      d.setMinutes(minutes);
      return d.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
    },
    convertedEndTime(time) {
      const [hours, minutes] = time.split(":");
      const d = new Date();
      d.setHours(hours);
      d.setMinutes(minutes);
      return d.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
    },
     openMap(address) {
            const mapsUrl = "https://www.google.com/maps?q=" + encodeURIComponent(address);
            window.open(mapsUrl, "_blank");
    },
     toggleLike(itemid) {
        let formData = new FormData();
        formData.append("id", itemid);
        axios.post('/owner/like-event',formData).then(response => {
          if(response.data.status == 1){
           this.likedItems.push(itemid);
            localStorage.setItem('likedItems', JSON.stringify(this.likedItems));
           toastr.success('Event is added into favorite list successfully.');
          }else{
            const index = this.likedItems.indexOf(itemid);
            if (index > -1) {
              this.likedItems.splice(index, 1);
            }
            localStorage.setItem('likedItems', JSON.stringify(this.likedItems));
            toastr.success('Event is removed from favorite list successfully.');
            
          }
          this.$forceUpdate();
        }).catch(error => {
          console.log(error);
        });
        },
    CheckBookEvent(itemid) {
      console.log('CheckBookEvent = '+itemid);
        let formData = new FormData();
        formData.append("id", itemid);
        axios.post('/owner/check-booked-event',formData).then(response => {
          console.log('check-booked-event');
          console.log(response.data.checkbookedevent);
          this.checkbookdata = response.data.checkbookedevent;
        }).catch(error => {
          console.log(error);
        });
        },
    BookEvent(){
        let vm = this;
        let formData = new FormData();
        formData.append("firstdropdown",vm.catfields.first_dropdown);
        formData.append("seconddropdown",vm.catfields.second_dropdown);
        formData.append("booking_fee",vm.bookingFee);
        formData.append("selectedCardId",vm.selectedCardId);
        formData.append("eventId",vm.eventId);
        // axios.post('/owner/book-eventmarket',formData) make-event-payment
        // axios.get('/owner/book-eventmarket?firstdropdown=' + vm.catfields.first_dropdown + '&seconddropdown=' + vm.catfields.second_dropdown+ '&eventId=' + vmId)
            axios.post('/owner/book-eventmarket',formData).then(response => {
                if(response.data.status == 422){
                    toastr.error(response.data.message)
                   setTimeout(() => {
                    window.location.href = '/owner/restaurantevent';
                   }, 1000);
                }else{
                    this.resdata = response.data.data;
                    window.location.href = '/owner/make-event-payment?event=' + this.resdata;
                }
            
        }).catch(error => {
          console.log(error);
        });
    },
    closeModel(){
    $('#bookevents').modal('hide');
  },
  },

}
</script>
