<template>
  <div class="container">


 
     <!-- Model start -->
      <div class="modal-dialog modal-dialog-centered" id="guestOrderSucess" role="document">
    <div class="modal-content">
      <button type="button" class="close handheld-close-popup" data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="model-body">
          <div class="row">
      <div class="col-md-12">
        <div class="congrates-popup-acount-created">
          <span><i class="fas fa-check-circle" style="font-size: 80px;text-center:center;margin-top: 32px;"></i></span>
          <h2>Your order has been sent to the kitchen.</h2>
          <h3 style="text-align: center;margin: 37px;">Your order number is :  {{OrderId}}</h3>
        </div>
      </div>
   </div>
      </div>

    </div>
  </div>
     <!-- Model end -->








  </div>

</template>

<script>

    export default {

    props: ['restaurant_id','auth_user','OrderId'],
    data() {
        return {
          OrderId:this.OrderId,

        }
    },

    methods:{
      order_completed_call(){
        alert("fjshfsdj");
      }
    }
}

</script>
