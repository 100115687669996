<template>
  <div class="col-lg-12">
    <div class="card card-body flex-fill w-100">
      <div class="clearfix">
        <h5 class="card-title mb-0 float-left">Current Orders</h5>
        <a href="orders" class="view-schedule-link mb-0 float-right">View All</a>
      </div>
      <div class="row">
        <!-- table start -->
        <div v-if="orderslength == 0" class="col-12 col-md-6 col-lg-4 col-xl-3">
          <div class="card card-block d-flex table-card no-order" >
            <div class="card-body align-items-center d-flex justify-content-center empty_order_text">
              Loading...
            </div>
          </div>
        </div>

        <div v-for="(item, index) in orders" class="row" style="width: 100%;">
           <div v-for="(sitem, index) in item.seats" class="col-12 col-md-6 col-lg-4 col-xl-3" v-if="sitem.cart_items.length > 0">
            <div class="table-card c-table-card" :id="`table_card_${sitem.id}`" style="width: 230px;height: 358px !important;">
            <div class="user-headeading-booking clearfix">
              <div class="float-left">
                
                <div v-if="(sitem.order.guest_user_details != '')">
                  <span class="orderno" v-if="order = JSON.parse(sitem.order.guest_user_details)">{{order.firstname}} {{order.last_name}} :</span>
                </div>
                <div v-if="(sitem.order.user_id > 0)">
                  <span class="orderno" >{{sitem.order.orderuser.first_name}} {{sitem.order.orderuser.last_name}} :</span>
                </div>
                
              </div>
               <div class="float-right table-number">
                <span :class="`table-c-${sitem.order.id}`">{{sitem.order.id}}</span>
              </div>
           </div>
           <hr>
           <div v-if="sitem.cart_items.length > 0 " class="" style="padding:10px;height: 188px !important;overflow-y: scroll;overflow-x: hidden;">
                <div v-for="(citem,cindex) in sitem.cart_items" class="row">
                      <div class="col-sm-7 col-7">
                        <div class="order-name">
                          <span>{{ citem.quantity }}</span>
                          <p>{{citem.menuitem.title}}
                            <br>
                            <span title="click to see special request" style="cursor: pointer;color:#ff5802;font-size: 12px;" @click="getSpecialRequest(citem)" v-if="citem.other_data">{{ (citem.other_data).substring(0,8)+"..." }}</span>

                          </p>

                        </div>
                      </div>
                      <div class="col-sm-5 col-5" style="padding-left: 0;">
                        <div class="item-price">
                          <span>$ {{parseFloat(citem.quantity * citem.menuitem.price).toFixed(2)}}</span>
                        </div>
                      </div>
                    </div>
              </div>
             </div>

              <div  class="mt-2" style="margin-top: -6.5rem!important;width: 230px;">
              <div  class="total-timing-price clearfix">
                <div class="float-left left-timing">
                  <i class="align-middle  far fa-fw fa-clock" ></i><a :id="'timer_clk_'+sitem.id">
                    {{calltablescantimer(sitem.created_at,'timer_clk_'+sitem.id)}}
                  </a>
                </div>
                <div v-if="item.carttotalp > 0 " class="float-right total-price-number">
                  <span>$ {{parseFloat(sitem.seat_order_total).toFixed(2)}}</span>
                </div>
              </div>
              <div class="outer-btn-edit-close">
                <div class="row">
                  <div  class="col-sm-6 col-6 innterbtn-edit">
                   
                    <div v-if="sitem.order.kitchen_status == null">
                      <button :id="`notify_btn_${sitem.id}`" class="btn" @click="NotifyClient(sitem.cart_items,sitem.id)"><i
                        class="align-middle  far fa-fw fa-bell"></i>
                      Notify</button>
                    </div>
                    <div v-else>
                      <button class="btn" disabled><i
                        class="align-middle  far fa-fw fa-bell"></i>
                      Notified</button>
                    </div>
                    <!-- <button v-if="item.carttotalp > 0 " class="btn" @click="editOrderModal(sitem,index)"><i
                        class="align-middle  far fa-fw fa-edit"></i>
                      Edit</button> -->
                  </div>
                  <div class="col-sm-6 col-6 innterbtn-edit"
                    style="padding-left: 0px;">
                    <button class="btn" @click.prevent="opencloseoutSeatsModal(sitem)"><i
                        class="align-middle  far fa-fw fa-window-close"></i>
                      Close Out</button>
                  </div>
                </div>
              </div>
            </div>
            </div>
      </div>
      <!-- table end -->
      </div>
      <!-- edit order modal -->
      <div
        class="modal fade"
        id="editCurrOrderPrimary"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header padding-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="m-3">
              <div class="header-add-seat">
                <div class="new-left-sec-with-avtar">
                  <span >{{editorderfields.seat_id}}</span>
                </div>
                <div class="inprogress-btn">
                  <button>In Progress</button>
                </div>
              </div>

                <!-- tabs01 -->
                <!-- tabs02 -->
                  <div class="clearfix second-tab-left-right">
                    <h3 class="float-left">Order</h3>
                    <!-- <a class="float-right ml-2" :data-table-order-id="editorderfields.table_id" @click.prevent="addSeat($event)">+ ADD SEAT</a> -->
                     <a class="float-right ml-2"  @click.prevent="addNewMenuItem(editorderfields.seatsarray)" title="Add More Items"
                                ><i class="fas fa-plus"></i>Add More Items</a>
                  </div>

                  <div class="new-seat-table-collection">
                    <div class="new-seat-table-section">
                      <div class="order-name-edit-outer" :id="`seat-pop-card-id-${seatitem.id}`"  v-for="(seatitem, index_s) in editorderfields.seatsarray.cart_items">

                          <!-- starttttttttttttt -->

                          <!-- <div class="clearfix top-order-name-inner">

                            <div class="float-right">
                              <a  @click.prevent="addNewMenuItem(seatitem,index_s)" title="Update"
                                ><i class="fas fa-plus"></i
                              ></a>
                            </div>
                          </div> -->

                          <!-- <div  v-for="(edcitem,edcindex) in seatitem.cart_items"> -->
                            <div class="clearfix top-order-name-inner" style="border: none;">
                              <div class="float-left">

                                <h3>{{seatitem.menuitem.title}}</h3>
                                <!-- <p>{{edcitem.menuitem.description}}</p> -->
                                <p v-if="seatitem.other_data<20" >{{seatitem.other_data}}</p>
                                <p v-else>{{ (seatitem.other_data).substring(0,8)+".." }}</p>


                              </div>
                              <div v-if="seatitem.cancelled == 0" class="float-right d-flex order-name-edit-price">
                                <div class="price-total">$ {{parseFloat(seatitem.quantity * seatitem.menuitem.price).toFixed(2)}}</div>
                                <a  @click.prevent="editItem(seatitem,index_s)" title="Update"
                                  ><i class="fas fa-pencil-alt"></i
                                ></a>
                                <!-- <a  @click.prevent="removeItem(edcitem.id,seatitem.cart_items,edcindex,index_s)" title="Remove"
                                  ><i class="fas fa-trash"></i
                                ></a> -->
                              </div>

                            </div>
                          <!-- </div> -->
                          <div>

                          </div>
                        </div>

                        <!-- enddddddddddddddddddddddd -->

                    </div>
                  </div>

            </div>
             <div class="taxes-and-fee-price">

                  </div>
          </div>
        </div>
      </div>
      <!-- CLOSE OUT NEW MODAL START -->
      <div
        class="modal fade"
        id="CloseOutModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header padding-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="m-3">
              <div class="header-add-seat">
                <div class="new-left-sec-with-avtar">
                  <span >{{closeout.seat_name}}</span>
                </div>
                <div class="inprogress-btn">
                  <button>In Progress</button>
                </div>
              </div>

                <!-- tabs01 -->
                <!-- tabs02 -->
                  <div class="clearfix second-tab-left-right">
                        <h3 class="float-left">Select all items to close out ?</h3>
                        <div class="float-right">
                        <input type="checkbox" class="checkbox-outlook-input" name="" @click="selectAllSeats($event)"/><label class="all-seats-outlook"> All Items</label>

                      </div>
                  </div>


                  <div class="new-seat-table-collection">

                    <!-- start loop -->
                    <div v-for="(seatitem,seatinx) in closeout.seatsdata.cart_items" class="new-seat-table-section">
                        <div class="order-name-edit-outer">
                          <div class="clearfix top-order-name-inner" style="display: none;">
                            <span class="float-left">
                             <!-- <input class="checkbox-outlook-input seatselcls" type="checkbox" /> -->
                             <input class="checkbox-outlook-input seatselcls" type="checkbox" :id="`card-seat-id-${seatitem.id}`" name="asdsd" v-model="closeout.selecteddeats" :value="seatitem.id+'-'+seatinx"/>

                            </span>

                          </div>

                          <div>
                            <div class="clearfix top-order-name-inner" style="border: none;">
                                  <div class="float-left">
                                    <h3>{{seatitem.menuitem.title}}</h3>
                                      <p>{{seatitem.menuitem.description}}</p>
                                  </div>
                                  <div class="float-right d-flex order-name-edit-price">
                                    <div class="price-total">$ {{parseFloat(seatitem.menuitem.price * seatitem.quantity).toFixed(2)}}</div>
                                  </div>
                            </div>
                          </div>
                          <div>

                          </div>
                        </div>

                    </div>
                    <!-- end loop -->
                  </div>

            </div>
             <div class="taxes-and-fee-price">
                    <div class="taxes-and-fee-buttons">
                      <div class="clearfix">
                        <!-- <button class="float-left btn cancel-btn">Cancel</button> -->
                        <div class="float-right">
                          <button class="btn close-out" @click.prevent="validateCloseOutSeat()"><img src="/img/frontend/close-icon.PNG" />Close Out</button>
                          <!-- <button class="btn close-out" @click.prevent="opencloseoutSeatsModal(editorderfields.seatsarray)"><img src="/img/frontend/close-icon.PNG" />Close Out</button> -->
                        </div>
                      </div>
                    </div>




                  </div>
          </div>
        </div>
      </div>
      <!-- CLOSE OUT NEW MODAL END -->
      <!-- edit menu modal -->
      <div class="modal fade" id="editMenuItem" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <form method="POST" id="editiorderfrm" name="editiorderfrm" @submit.prevent="saveEditItem" >
            <div class="order_menu_item">
              <h4>${{edit_menu.menu_item.price}}</h4>
              <h4>{{edit_menu.menu_item.title}}</h4>
              <p>
                {{edit_menu.menu_item.description}}
              </p>
              <p v-if="edit_menu.menu_item.inventory == 0" style="color:red;font-weignt:500">Out of Stock !</p>
              <label>Special Requests?</label>
              <textarea placeholder="What are your requests?" class="form-poup" v-model="edit_menu.menu_item.specialrequest" ></textarea>
              <div class="qty-text-sec" v-if="edit_menu.menu_item.inventory > 0">
                <div class="row">
                  <div class="col-2">
                    <span>Qty</span>
                  </div>
                  <div class="col-5">
                    <input type="text" id="qty" class="qtyform-poup" v-model="edit_menu.menu_item.quantity" min="0"  required=""
                     data-parsley-trigger="keyup" data-parsley-type="digits">
                  </div>
                  <div class="col-5 button-service">
                    <span ><i class="fa fa-minus"  v-on:click="decrease"></i></span>
                    <span><i class="fa fa-plus"    v-on:click="increase"></i></span>
                  </div>
                </div>
              </div>
              <div>
                <button @click.prevent="validateEditItem()" class="btn add-btn-order" id="edit_item" >Update</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </div>
      <!-- add new menu modal -->
      <div class="modal fade" id="addNewMenuItemModal" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document" >
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="m-4 tab-pane handeld-popup-relative active">
                <div class="nav handhelds-menus poup-nav-hanhelds-menu">
                    <div class="nav-item" v-for="(item,index) in menu_categories.slice(0,5)"  @click="showMenuItems($event,item.id)">
                      <a class="nav-link" :class="{ active: (activeTabInline == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                    </div>
                    <div class="nav-item menu-list-handheld" @click="showAllMenus">
                      <a class="nav-link" id="final_draft"><i class="fas fa-align-justify"></i></a>
                    </div>
                </div>
                <div class="onclick-show-menu-handeld " id="mobile_toggle_nav" style="display: none;">
                  <ul class="nav flex-column">
                    <div class="close-menu" id="close_toggle_nav" @click="close_all_menu_nav">&times;</div>
                    <li class="nav-item" v-for="(item,index) in menu_categories" @click="showMenuItems($event,item.id)">
                      <a class="nav-link " :class="{ active: (activeTab == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                    </li>
                  </ul>
                </div>


                <div class="outer-menu-list-price">
                  <h3>{{activeMenuTitle}}</h3>
                  <!-- menu-1 -->

                  <div v-if="load_menu_items" class="d-flex justify-content-center">
                    <div class="patron-loader spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                  </div>


                  <div class="menu-list-price" v-for="(item,index) in menu_items" v-if="item.inventory > 0"  @click="showMenuItem(item)">
                    <div class="clearfix" >
                      <img style="width: 70px;height:60px; float:left" v-if="item.images"  :src="'/storage/menu_item_images/thumbnail/'+item.images" :alt="item.title">
                        <h4 class="float-left" style="margin-left:10px;">{{item.title}}</h4>
                        <br> <p style="margin-left:80px !important;">{{item.description}}</p>
                        <span class="float-right">${{item.price}}</span>
                    </div>

                  </div>
                  <div v-if="menu_items.length == 0" class="empty_menu empty_msg">No menu items found.</div>

                </div>

              </div>
            </div>
          </div>
      </div>

      <!-- view item modal-->
      <div class="modal fade" id="viewItem" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <form method="POST" id="addiorderfrm" name="addiorderfrm" @submit.prevent="addOrderToSeat" >
            <div class="order_menu_item">
             <img style="width: 570px;" v-if="new_menu_item.image"  :src="'/storage/menu_item_images/original/'+new_menu_item.image" :alt="new_menu_item.title">
              <h4>${{new_menu_item.price}}</h4>
              <h4>{{new_menu_item.title}}</h4>
              <p>
                {{new_menu_item.description}}
              </p>
              <label>Special Requests?</label>
              <textarea placeholder="What are your requests?" class="form-poup" v-model="new_menu_item.specialrequest" ></textarea>
              <div class="qty-text-sec">
                <div class="row">
                  <div class="col-2">
                    <span>Qty</span>
                  </div>
                  <div class="col-5">
                    <input type="text" id="qty" class="qtyform-poup" v-model="new_menu_item.quantity" min="1"  required=""
                    data-parsley-validation-threshold="1" data-parsley-trigger="keyup" data-parsley-type="digits">
                  </div>
                  <div class="col-5 button-service">
                    <span ><i class="fa fa-minus"  v-on:click="decrease_new"></i></span>
                    <span><i class="fa fa-plus"    v-on:click="increase_new"></i></span>
                  </div>
                </div>
              </div>
              <div>
                <button @click.prevent="ValidateSeatOrder()" class="btn add-btn-order" id="add_order" >Add to order</button>
                <!-- <a data-toggle="modal" data-target="#signInModalPrimary" class="btn add-signin-btn signin-up-btn"  v-if="!isloggedin">Sign in/ Create Account</a> -->
              </div>
            </div>
            <div class="mb-2 text-center">

                <div v-if="validation_errors" class="text-danger text-danger text-center">
                  <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                      {{v[0]}}
                  </p>
                </div>
                <div v-if="error" class="text-danger text-danger text-center">
                  <p v-if="error" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
                <div v-if="errors" class="text-danger text-danger text-center">
                  <div v-for="(v, k) in errors" :key="k">
                    <p v-for="error in v" :key="error" class="text-sm">
                      {{ error }}
                    </p>
                  </div>
                </div>

            </div>

            </form>
          </div>
        </div>
      </div>
      <!-- model list of special request start -->

      <div class="modal fade" id="specialrequest_model" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-header" style="background-color: #4256ce;">
                <h4 class="modal-title" style="color: #fff;">Special Requests:</h4></div>
            <div class="modal-body" v-if="sprequest">
              <ul class="list-group">
                  <li class="list-group-item">{{sprequest}}</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- model list of special request end -->

      <!--close out popup-->


    </div>
  </div>
</template>

<script>

    export default {
    props: ['restaurant_id'],
    data() {
        return {
          orderslength:0,
          sp_request:[],
          sprequest:'',
          sprequest_data:[],
          activeTabInline:null,
          activeTab: null,
          activeMenuTitle:null,
          load_menu_items:false,
          menu_items:{},
          menu_categories:[],
          orders:[],
          foodtruck_orders:[],
          cartitemtotal:'',
          orderitemtotal:'',
          editorderfields:{
            seatsarray:[],
            seat_id:'',
            tabletol:'',
            cartItms:'',
            specialrequest:''
          },
          edit_menu:{
            menu_item:{
              title:'',
              description:'',
              price:'',
              quantity:1,
              user_id:'',
              menu_id:'',
              inventory:'',

            },
            menu_item_arry:'',
            cartItms:'',
            menuItmInx:'',

          },
          add_menu_to_seat:{
            seat_id:'',
            table_id:'',
            tbl_qr_str:'',
            seat_token_id:''
          },
          new_menu_item:{
            title:'',
            description:'',
            price:'',
            quantity:1,
            user_id:'',
            image:'',
            specialrequest:''
          },
          validation_errors:{},
          error:'',
          errors:{},
          closeout:{
            seatsdata:{},
            selecteddeats:[],
            seat_order_total:'',
            seat_name:''
          },
          selectedseatid:'',
          stindex:''
        }
    },

    created: function () {
      this.getRestaurantCurrentOrders();
      //this.getSR();
    },
    mounted() {
      //alert("sjhjsd");
      let vm = this;
      Echo.channel('table-qr-scan.'+vm.restaurant_id)
      .listen('TableOccupied', (e) => {
        //alert("kshfksjfhsjd");
        vm.getRestaurantCurrentOrders();

      });
      // Echo.channel('item-added-in-cart.'+vm.restaurant_id)
      // .listen('ItemAddedToCart', (e) => {
      //       vm.getRestaurantCurrentOrders();
      // });
      Echo.channel('item-confirmed-in-cart.'+vm.restaurant_id)
      .listen('ConfirmCartItem', (e) => {

      //  alert("3333");
            vm.getRestaurantCurrentOrders();
      });
      Echo.channel('order-placed.'+vm.restaurant_id)
      .listen('OrderPlaced', (e) => {
            vm.getRestaurantCurrentOrders();
      });
    },
    methods:{

      getRestaurantCurrentOrders(){

        axios.post('/owner/orders/current_orders').then(response => {
          this.orders = response.data.response.table_list.table_rows;
            var len = Object.keys(this.orders);
            var i = len[0];
            this.foodtruck_orders = this.orders[i];

          this.orderslength = response.data.response.totl_tbls;
          if(this.orderslength == 0){
            $('.empty_order_text').text('No Order Found...');
          }
        }).catch(error => {

        });
      },
      getRecentScanTableData(tid,rid){
        let formData = new FormData();
        formData.append("table_id", tid);
        formData.append("restaurant_id", rid);
        axios.post('/owner/orders/get_scan_table_detail',formData).then(response => {
          //console.log(response.data.response.table_row);
          this.orders.push(response.data.response.table_row);
          this.orderslength = this.orderslength + 1;
          if(this.orderslength == 0){
            $('.empty_order_text').text('No Order Found...');
          }
        }).catch(error => {

        });
      },
      closeouttable(qrid,qindex){
        if(confirm("Do you really want to close out?")){
          let formData = new FormData();
          formData.append("id", qrid);
          formData.append("restaurant_id", this.restaurant_id);
          axios.post('/owner/orders/close_out_scanned_table',formData).then(response => {


            Vue.delete(this.orders, qindex);
          }).catch(error => {
            if (error.response.status === 400) {
              alert(error.response.data.response.message);
            }else{
              alert("Something went wrong!");
            }
          });
        }

      },
      editOrderModal: function (itemc,indexc) {

        let vm = this;
        $('#editCurrOrderPrimary').modal('show');
        vm.editorderfields.seatsarray = itemc;
        vm.editorderfields.seat_id = itemc.seat;
        vm.editorderfields.tabletol = itemc.seat_order_total;
        vm.editorderfields.specialrequest = itemc.other_data;
      },
      addSeat: function (event) {
        let vm  = this;
        let tblid = event.target.getAttribute('data-table-order-id');
        let formData = new FormData();
        formData.append("table_id", tblid);
        formData.append("restaurant_id", vm.restaurant_id);
        axios.post('/owner/orders/add_new_seat_in_table',formData).then(response => {
          vm.editorderfields.seatsarray.push(response.data.response);
          toastr.success('Seat added successfully.');
        }).catch(error => {

        });
      },
      removeItem(itmId,cartItms,menuItm,stindex){
        let vm  = this;
        if(confirm("Do you really want to remove?")){
          axios.delete('/owner/orders/remove-cart-item/'+itmId).then(response => {
            Vue.delete(cartItms, menuItm);
            vm.getRestaurantCurrentOrders();

            vm.editorderfields.seatsarray[stindex].seat_order_total = response.data.response.seat_order_total;

          }).catch(error => {
          });
        }
      },
      addNewMenuItem(seatitemtr){
        let vm = this;
        console.log('seatitemtr');
        console.log(seatitemtr);
        $('#addNewMenuItemModal').modal('show');
        let formData = new FormData();
        formData.append("type", 'all');
        formData.append("restaurant_id", vm.restaurant_id);
        axios.post('/owner/orders/get-menu-categories',formData).then(response => {
          vm.menu_categories = response.data.response.categories;
          vm.activeTab = response.data.response.categories[0].id;
          vm.activeTabInline = response.data.response.categories[0].id;
          vm.activeMenuTitle = response.data.response.categories[0].title;

          formData.append("menu_category_id", response.data.response.categories[0].id);
          axios.post('/owner/orders/get-restaurant-menu-items',formData).then(response => {
            //console.log(response);
              vm.menu_items = response.data.response.menu_items;
              vm.selectedseatid = seatitemtr.id;
              // vm.stindex = stindex;
          });
          //console.log(response.data.response.categories);
        }).catch(error => {

        });
        vm.add_menu_to_seat = {};
        vm.add_menu_to_seat.seat_id = seatitemtr.seat;
        vm.add_menu_to_seat.tbl_qr_str = seatitemtr.token;
        vm.add_menu_to_seat.table_id = seatitemtr.table_id;
        vm.add_menu_to_seat.seat_token_id = seatitemtr.id;
      },
     editItem(itmmenu,stindex){
       console.log("itmmenu",itmmenu);
       console.log("stindex",stindex);
        let vm = this;
        $('#editMenuItem').modal('show');
        vm.edit_menu.menu_item.title = itmmenu.menuitem.title;
        vm.edit_menu.menu_item.description = itmmenu.menuitem.description;
        vm.edit_menu.menu_item.price = itmmenu.menuitem.price;
        vm.edit_menu.menu_item.quantity = itmmenu.quantity;
        vm.edit_menu.menu_item.id = itmmenu.id;
        vm.edit_menu.menu_item.menu_id = itmmenu.menuitem.id;
        vm.edit_menu.menu_item.inventory = itmmenu.menuitem.inventory;
        //vm.edit_menu.menu_item_arry = itmmenu;
        vm.edit_menu.cartItms = itmmenu;
        vm.edit_menu.menuItmInx = stindex;
        vm.edit_menu.menu_item.specialrequest = itmmenu.other_data;
        vm.selectedseatid = itmmenu.id;
        vm.stindex = stindex;
        vm.seatqrtoken = itmmenu.qr_token_id;
      },
      validateEditItem(itmmenu){
        let vm = this;
        $('#editiorderfrm').parsley().whenValidate({

        }).done(function() {
          vm.saveEditItem();
        });
      },
      saveEditItem(){
        let vm = this;
        vm.validation_errors = {};
        vm.error = '';
        $('#edit_item').prop('disabled',true);
        if(vm.edit_menu.menu_item.quantity > 0){
          let formData = new FormData();
          formData.append("u_menu_item", JSON.stringify(vm.edit_menu.menu_item));
          formData.append("restaurant_id", vm.restaurant_id);
          formData.append("qrtoken_id", vm.seatqrtoken);
          axios.post('/owner/orders/new-edit-seat-menuitem',formData).then(response => {
            vm.editorderfields.seatsarray.cart_items[vm.edit_menu.menuItmInx] = response.data.response.cart_updated_item;
           vm.editorderfields.tabletol = response.data.response.seat_order_total;
           $('#edit_item').prop('disabled',false);
            $('#editMenuItem').modal('hide');
            vm.getRestaurantCurrentOrders();
            vm.editorderfields.seatsarray[vm.stindex].seat_order_total = response.data.response.seat_order_total;
              $('#edit_item').prop('disabled',false);
          }).catch(error => {
          });
        }else{
          this.removeItem(vm.edit_menu.menu_item.id,vm.edit_menu.cartItms,vm.edit_menu.menuItmInx,vm.stindex);
          $("#editMenuItem .close").click();
          $('#edit_item').prop('disabled',false);
        }

      },
      increase: function() {
        let vm = this;
        vm.edit_menu.menu_item.quantity++;
      },
      decrease:  function() {
        let vm = this;
        if (vm.edit_menu.menu_item.quantity > 0) {
            vm.edit_menu.menu_item.quantity--;
        }

      },

      showMenuItems: function (event,menucatid) {

        this.load_menu_items = true;
        this.menu_items = {};
        this.activeMenuTitle = event.target.getAttribute('data-menu');
        this.activeTab = menucatid;
        this.activeTabInline = menucatid;
        let menuData = new FormData();
        menuData.append("menu_category_id", menucatid);
        axios.post('/owner/orders/get-restaurant-menu-items',menuData).then(response => {
          this.load_menu_items = false;
          this.menu_items = response.data.response.menu_items;
          $('#close_toggle_nav').click();
        }).catch(error => {
        });
      },
      showMenuItem: function (item) {
        console.log('showMenuItem-1')
        console.log(item)
        let vm = this;
        $('#viewItem').modal('show');
        vm.new_menu_item.title = item.title;
        vm.new_menu_item.description = item.description;
        vm.new_menu_item.price = item.price;
        vm.new_menu_item.id = item.id;
        vm.new_menu_item.image = item.images;
        vm.new_menu_item.specialrequest = '';
      },
      showAllMenus(){

      },
      close_all_menu_nav(){

      },
      ValidateSeatOrder() {
        let vm = this;
        $('#addiorderfrm').parsley().whenValidate({

        }).done(function() {
          vm.addOrderToSeat();
        });
      },
      addOrderToSeat: function () {
        let vm = this;
        vm.validation_errors = {};
        vm.error = '';
        $('#add_order').prop('disabled',true);
        let menuData = new FormData();
        menuData.append("menu_item_id", vm.new_menu_item.id);
        menuData.append("quantity", vm.new_menu_item.quantity);
        menuData.append("specialrequest", vm.new_menu_item.specialrequest);
        menuData.append("seat_id", vm.selectedseatid);
        menuData.append("qrtoken_id", vm.selectedseatid);
        axios.post('/owner/orders/add-menu-to-seat',menuData).then(response => {
          //console.log(response.data.response.item);

          $('#viewItem').modal('hide');
          $('#add_order').prop('disabled',false);
          //vm.new_menu_item = {};
          vm.new_menu_item.quantity = 1;
        //  console.log("hhh",vm.editorderfields.seatsarray[vm.stindex]);
          vm.editorderfields.seatsarray.cart_items.push(response.data.response.item);
          //vm.editorderfields.seatsarray.cart_items.push(response.data.response.item);

          if(vm.editorderfields.seatsarray[vm.stindex].seat_order_total > 0){
            vm.editorderfields.seatsarray[vm.stindex].seat_order_total = vm.editorderfields.seatsarray[vm.stindex].seat_order_total + (response.data.response.item.menuitem.price * response.data.response.item.quantity);
          }else{
            vm.editorderfields.seatsarray[vm.stindex].seat_order_total = 0 + (response.data.response.item.menuitem.price * response.data.response.item.quantity);
          }

        }).catch(error => {
        });

      },
      increase_new: function() {
        let vm = this;
        vm.new_menu_item.quantity++;
      },
      decrease_new:  function() {
        let vm = this;
        if (vm.new_menu_item.quantity > 1) {
            vm.new_menu_item.quantity--;
        }

      },

      opencloseoutSeatsModal(seatsdata){
        $('#CloseOutModal').modal('show');
        // $('#closeoutSeats').modal('show');
        this.closeout.seatsdata = seatsdata;
        this.closeout.seat_order_total = seatsdata.seat_order_total;
        this.closeout.seat_name = seatsdata.seat;



      },
      validateCloseOutSeat(itmmenu){
        let vm = this;
        vm.closeoutSelSeats();
      },
      closeoutSelSeats(){
        let formData = new FormData();
        formData.append("seat_id", this.closeout.seat_name);
        formData.append("cart_ids", this.closeout.selecteddeats);
        formData.append("restaurant_id", this.restaurant_id);
        axios.post('/owner/orders/new_close_out_table_seats',formData).then(response => {
          // $('#closeoutSeats').modal('hide');
          $('#CloseOutModal').modal('hide');
          if(response.data.response.order_id){
            window.location.href = "/owner/onsite_togo_orders/make-current-payment/"+response.data.response.order_id;
          }
          let getseats = this.closeout.selecteddeats;
          axios.post('/owner/orders/current_orders').then(response => {
            this.orders = response.data.response.table_list.table_rows;
            this.orderslength = response.data.response.totl_tbls;
            if(this.orderslength == 0){
              location.reload();
            }
          }).catch(error => {

          });

          for(let ar=0; ar < getseats.length; ar++){
            console.log(getseats[ar]);
            let get_indx_val = getseats[ar].split("-");
            console.log( get_indx_val[1]);
            //$('#seat-pop-card-id-'+get_indx_val[0]).hide();
            Vue.delete(this.editorderfields.seatsarray, get_indx_val[1]);
            Vue.delete(this.closeout.seatsdata, get_indx_val[1]);
          }

        }).catch(error => {
          if (error.response.status === 400) {
            alert(error.response.data.response.message);
          }else{
            alert("Something went wrong!");
          }
        });
      },
      selectAllSeats(ele) {
         var checkboxes = document.getElementsByClassName('seatselcls');
         if (ele.target.checked) {
             for (var i = 0; i < checkboxes.length; i++) {
                 if (checkboxes[i].type == 'checkbox') {
                     this.closeout.selecteddeats.push(checkboxes[i].value);
                     checkboxes[i].checked = true;
                 }
             }
         } else {
             for (var i = 0; i < checkboxes.length; i++) {
                 if (checkboxes[i].type == 'checkbox') {
                     this.closeout.selecteddeats = [];
                     checkboxes[i].checked = false;
                 }
             }
         }
         console.log('checkboxes');
         console.log(this.closeout.selecteddeats);
     },
     padTime(num) {
        return ("0"+num).slice(-2);
      },
      calltablescantimer(scanDate,elmId){

          let vm = this;
          setInterval(function(){
            let startTime = moment(scanDate);
            let endTime = moment();
            let seconds = endTime.diff(startTime, 'seconds');


            let minutes = Math.floor(seconds / 60);
            seconds = seconds%60;
            let hours = Math.floor(minutes/60)
            minutes = minutes%60;

            let time = vm.padTime(hours)+':'+vm.padTime(minutes)+':'+vm.padTime(seconds);
              $("#"+elmId).html(time);
            }, 1000);

      },
      getSpecialRequest_old(data){
        let arr = Array();
        data.cart_items.forEach((v, i) =>{
           if(v.other_data){
                arr.push(v.other_data);
              }
            this.sp_request = arr;
        });

      },
      NotifyClientDone(item_row_id,cindex){
      $('#notify_btn_'+cindex).prop('disabled',true);
      let formData = new FormData();
      formData.append("item_id", item_row_id);
      axios.post('/owner/orders/prepared_menu_item',formData).then(response => {
        $('#table_card_'+cindex).addClass('loading-spin');
        setTimeout( () => {
          $('#table_card_'+cindex).removeClass('loading-spin');
          this.getRestaurantCurrentOrders();
        }, 5000);

        $('#notify_btn_'+cindex).prop('disabled',false);
      }).catch(error => {
       $('#notify_btn_'+cindex).prop('disabled',false);
      });
    },
      NotifyClient(menuitemssrr,cindex){
      let menu_item_list_ids=[];
       $.each(menuitemssrr, function(key, value) {
         menu_item_list_ids.push(value.id);
       });
     
       this.NotifyClientDone(menu_item_list_ids,cindex);
     },
      getAllSR(item){
        $('#specialrequest_model').modal('show');
        this.sprequest_data = item.cart_items;
        console.log(this.sprequest_data)
      },

       getSpecialRequest(item){
        $('#specialrequest_model').modal('show');
        this.sprequest = item.other_data;
      }
    },

    computed: {
    }

    }
</script>
