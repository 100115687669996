<template>
  <div class="container">
    <div  class="row">
      <div class="col-md-12" v-if="!showOrderCompleted">
          <div class="receipt-yours-screen">
            <h2>Payment details</h2>
            <div class="card-stripe-section">
              <div id="card-element"></div>
                  <div class="card">
                    <div v-if="is_saved_card_list">
                      <div class="card-header bg-savedCard">
                          <div class="row">
                              <div class="col-md-6"> <span>USE SAVED CARDS</span> </div>
                          </div>
                      </div>
                      <div  class="card-body" style="">
                        <div id="savedcard-loader" class="spinner-border text-warning" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <div class="form-group" v-for="(item,index) in saved_card_list">
                          <input  name="savedcardcls" type="radio" class="form-check-input-radio" v-model="cardfields.selectedcheckedcard" :value="item.id" >
                          <label for="cc-number" class="control-label">•••• •••• •••• {{ item.card.last4 }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="card-header bg-newCard">
                      <div class="row">
                        <div class="col-md-6">
                          <span>ENTER NEW CARD</span>
                        </div>
                      </div>
                    </div>
                    <div class="iframe-card-details" id="tilledccelementsp" style="display:none">
                      <label >CARD NUMBER<div  id="card-number-element"></div></label>
                      <label >CARD EXPIRY<div  id="card-expiration-element"></div></label>
                      <label >CARD CVC<div  id="card-cvv-element"></div></label>
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" id="cardsaveid" v-model="save_card_check" style="margin-top: 5px !important;">
                        <strong class="terms-policy-text">Save card for future use</strong>
                      </label>
                    </div>
                  </div>
                </div>
                <button @click.prevent="validate_payment()" id="makepaymentbtn" class="bottom-fix-pay-btn btn mt-3">Place Order</button>

                <div class="text-danger mt-2" id="showPaymentErr">
                  <div v-if="validation_errors" class="text-danger text-danger text-center">
                    <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                        {{v[0]}}
                    </p>
                  </div>
                  <div v-if="error" class="text-danger text-danger text-center">
                    <p v-if="error" :key="error" class="text-sm">
                      {{ error }}
                    </p>
                  </div>
                  <div v-if="errors" class="text-danger text-danger text-center">
                    <div v-for="(v, k) in errors" :key="k">
                      <p v-for="error in v" :key="error" class="text-sm">
                        {{ error }}
                      </p>
                    </div>
                  </div>
                </div>
            </div>
      </div>
      <div class="col-md-12" v-if="showOrderCompleted">
        <order-completed-page v-on:enableallheadtabs="enableAllHeadeTabsCall">
        </order-completed-page>
      </div>
    </div>
  </div>


</template>
<script type="text/javascript">

import orderCompletedPage from  './OrderCompletedPageComponent';

export default {
  props: ['restaurant_id','auth_user','tipval','connaccidv','paymntkeyvalv','qrtokenid'],
  data() {
      return {
        showOrderCompleted:false,
        saved_card_list:{},
        save_card_check:false,
        is_saved_card_list:true,
        user_id:'',
        cardfields:{
          cardNumber:'',
          cardExpiry:'',
          cardCvv:'',
          cardPostal:'',
          selectedcheckedcard:'',
          selectedcheckedcardcvv:'',
        },
        validation_errors:{},
        error:'',
        errors:{},
        savedcarderror:'',
        loginUser:this.auth_user,
        is_enable_cvv:false,
        tilledobj: "",
        tilledform:"",
        payment_intent_client_secret:'',
        ordid:'',
        payment_method_id:''
      }
  },
  components:{
    'order-completed-page': orderCompletedPage
  },
  async mounted() {
          $('#makepaymentbtn').prop('disabled',true)
          var account_id = this.connaccidv;
          var pk_PUBLISHABLE_KEY = this.paymntkeyvalv;
          const tilledAccountId = account_id;

          this.tilledobj = new Tilled(
            pk_PUBLISHABLE_KEY,
            tilledAccountId,
                { sandbox: "{{ Config::get('tilledpayment.sandbox_mode_status') }}" }
            );

          this.tilledform =  await this.tilledobj.form({
            payment_method_type: 'card',
          });

          // Optional styling of the fields
          const fieldOptions = {
            styles: {
              base: {
                fontFamily: 'Helvetica Neue, Arial, sans-serif',
                color: '#304166',
                fontWeight: '400',
                fontSize: '16px',
              },
              invalid: {
                ':hover': {
                  textDecoration: 'underline dotted red',
                },
                color: '#dc3545',
              },
              valid: {
                color: '#495057',
              }
            }

          };

        //  const cardNumberField = form.createField('cardNumber', fieldOptions);

          const cardNumberField =  this.tilledform.createField('cardNumber', fieldOptions).inject('#card-number-element');
          this.tilledform.createField('cardExpiry', { ...fieldOptions, placeholder: 'MM/YY' }).inject('#card-expiration-element');
          this.tilledform.createField('cardCvv', fieldOptions).inject('#card-cvv-element');
          await this.tilledform.build();
          $('#tilledccelementsp').show();

          this.tilledform.on('validation', (event) => {
            if (event.field) {
              event.field.element.classList.remove('success_tilledinput');
              event.field.element.classList.remove('error_tilledinput');

              if (event.field.valid) {
                event.field.element.classList.add('success_tilledinput');
              } else {
                event.field.element.classList.add('error_tilledinput');
              }
            }

          });
          cardNumberField.on('ready', (evt) => {
            $('#makepaymentbtn').prop('disabled',false)
          });
          cardNumberField.on('change', (evt) => {
            if($("input[name='savedcardcls']").is(':checked')) {
              $("input[name='savedcardcls']").prop('checked', false);
              $("input[name='savedcardcls']").val('');
            }
          });
  },
  created: function () {
      axios.post('/patron/check-patron-auth-status').then(response => {
        if(response.data.response.user != ''){
          this.loginUser = response.data.response.user;
        }
      }).catch(error => {
      });
      this.fetchSavedCards();
  },
  methods:{
    enableAllHeadeTabsCall(){
      this.$emit('ordercompletepageev');
    },
    make_payment(){
          let vm = this;

          //let ucity = this.auth_user.usercity.city;
          //let ustate = this.auth_user.userstate.abv;
          //console.log(ustate);
          //return false;


          if(this.cardfields.selectedcheckedcard !=''){
            $('#makepaymentbtn').prop( "disabled", true );
            $('#makepaymentbtn').text("Please Wait...");
            vm.payment_method_id = this.cardfields.selectedcheckedcard;
            let formData = new FormData();
            formData.append("restaurant_id", vm.restaurant_id);
            formData.append("user_id", vm.loginUser.id);
            formData.append("tip_perc", vm.tipval);
            formData.append("points", 0);
            formData.append("save_card_check", 'no');
            formData.append("qrtoken_id", vm.qrtokenid);

            axios.post('/patron/create-tilled-paymentintent',formData).then(response => {
              vm.payment_intent_client_secret = response.data.data.payment_client_secret;
              vm.ordid = response.data.data.ord_id;
              this.tilledobj.confirmPayment(vm.payment_intent_client_secret,{
                    payment_method: vm.payment_method_id,
              }).then(
                (payment) => {
                  console.log("success used saved card ==",payment);
                  let payResData = new FormData();
                  if($('#cardsaveid').is(':checked')){
                    payResData.append("save_card_check", 'yes');
                  }else{
                    payResData.append("save_card_check", 'no');
                  }
                  payResData.append("pdata", JSON.stringify(payment));
                  payResData.append("order_id", vm.ordid);
                  payResData.append("restaurant_id", vm.restaurant_id);
                  payResData.append("user_id", vm.loginUser.id);
                  payResData.append("tip_perc", vm.tipval);
                  payResData.append("method_name", 'confirmPayment');
                  payResData.append("qrtoken_id", vm.qrtokenid);

                  axios.post('/patron/save-order-payment',payResData).then(response => {
                    console.log("payment res ==", response);
                    $('#cartCount').text('0');
                    $('#confirmCartCount').text('0');
                    vm.showOrderCompleted = true;
                    $('#ratingtab').css("pointer-events", "none");
                  }).catch(error => {
                    console.log("payment res  errr==", error.response.data.data);
                    if(error.response.data.code == 422){
                      vm.error = error.response.data.data.last_payment_error;
                    }
                    $('#makepaymentbtn').prop( "disabled", false );
                    $('#makepaymentbtn').text("Place Order");
                  })
                },
                (err) => {
                  console.log("err used saved card ==",err);
                  vm.error = err.message;
                  $('#makepaymentbtn').prop( "disabled", false );
                  $('#makepaymentbtn').text("Place Order");
                  let paymentResData = new FormData();
                  paymentResData.append("method_name", 'confirmPayment');
                  paymentResData.append("order_id", vm.ordid);
                  paymentResData.append("restaurant_id", vm.restaurant_id);
                  paymentResData.append("user_id", vm.loginUser.id);
                  paymentResData.append("payment_res", JSON.stringify(err));
                  paymentResData.append("payment_res_type", 'error');
                  paymentResData.append("qrtoken_id", vm.qrtokenid);
                  axios.post('/api/save-paymentGateway-response',paymentResData).then(response => {
                  });
                },
            );
          }).catch(error => {
            vm.error = "something went wrong";
            $('#makepaymentbtn').prop( "disabled", false );
            $('#makepaymentbtn').text("Place Order");
          });
          }else{
            $('#makepaymentbtn').prop( "disabled", true );
            $('#makepaymentbtn').text("Please Wait...");
            this.tilledobj.createPaymentMethod({
              form: vm.tilledform,
              billing_details: {
                name: vm.loginUser.name, // required
                address: {
                  country: 'US', // required
                  zip: vm.loginUser.billing_zipcode,
                  //state:vm.auth_user.userstate.abv,
                  //city:vm.auth_user.usercity.city,
                  street: vm.loginUser.billing_address,
                },
                email: vm.loginUser.email
              },
              }).then((paymentpm) => {
                let formData = new FormData();
                formData.append("pmdata", JSON.stringify(paymentpm));
                formData.append("restaurant_id", vm.restaurant_id);
                formData.append("user_id", vm.loginUser.id);
                formData.append("tip_perc", vm.tipval);
                formData.append("points", 0);
                formData.append("order_id", vm.ordid);
                formData.append("method_name", 'createPaymentMethod');
                formData.append("qrtoken_id", vm.qrtokenid);
                if($('#cardsaveid').is(':checked')){
                  formData.append("save_card_check", 'yes');
                }else{
                  formData.append("save_card_check", 'no');
                }
                axios.post('/patron/create-tilled-paymentintent',formData).then(response => {
                  console.log("payment_intent_client_secret",response);
                  vm.payment_intent_client_secret = response.data.data.payment_client_secret;
                  vm.ordid = response.data.data.ord_id;
                  vm.payment_method_id = response.data.data.payment_method_id;
                  this.tilledobj.confirmPayment(vm.payment_intent_client_secret,{
                      payment_method: vm.payment_method_id
                    })
                    .then((payment) => {
                        console.log("pp paymentmethodrs ==",payment);
                        let payResData = new FormData();
                        if($('#cardsaveid').is(':checked')){
                          payResData.append("save_card_check", 'yes');
                        }else{
                          payResData.append("save_card_check", 'no');
                        }
                        payResData.append("method_name", 'confirmPayment');
                        payResData.append("pdata", JSON.stringify(payment));
                        payResData.append("order_id", vm.ordid);
                        payResData.append("restaurant_id", vm.restaurant_id);
                        payResData.append("user_id", vm.loginUser.id);
                        payResData.append("tip_perc", vm.tipval);
                        payResData.append("qrtoken_id", vm.qrtokenid);
                        axios.post('/patron/save-order-payment',payResData).then(response => {
                          console.log("payment res ==", response);
                          $('#cartCount').text('0');
                          vm.showOrderCompleted = true;
                          $('#ratingtab').css("pointer-events", "none");
                        }).catch(error => {
                          console.log("payment res  errr==", error.response.data.data);
                          if(error.response.data.code == 422){
                            vm.error = error.response.data.data.last_payment_error;
                          }
                          $('#makepaymentbtn').prop( "disabled", false );
                          $('#makepaymentbtn').text("Place Order");
                        })
                      },
                      (err) => {
                          console.log("err pp ==",err);
                          vm.error = err.message;
                          $('#makepaymentbtn').prop( "disabled", false );
                          $('#makepaymentbtn').text("Place Order");

                          let paymentResData = new FormData();
                          paymentResData.append("method_name", 'confirmPayment');
                          paymentResData.append("order_id", vm.ordid);
                          paymentResData.append("restaurant_id", vm.restaurant_id);
                          paymentResData.append("user_id", vm.loginUser.id);
                          paymentResData.append("payment_res", JSON.stringify(err));
                          paymentResData.append("payment_res_type", 'error');
                          paymentResData.append("qrtoken_id", vm.qrtokenid);
                          axios.post('/api/save-paymentGateway-response',paymentResData).then(response => {
                          });
                      },
                    );
                }).catch(error => {
                  vm.error = "something went wrong";
                  $('#makepaymentbtn').prop( "disabled", false );
                  $('#makepaymentbtn').text("Place Order");
                });
              },
              (err) => {
                console.log("333payment res  errr==", err);
                vm.error = err.message;
                $('#makepaymentbtn').prop( "disabled", false );
                $('#makepaymentbtn').text("Place Order");
                let paymentResData = new FormData();
                paymentResData.append("method_name", 'createPaymentMethod');
                paymentResData.append("order_id","0");
                paymentResData.append("restaurant_id", vm.restaurant_id);
                paymentResData.append("user_id", vm.loginUser.id);
                paymentResData.append("payment_res", JSON.stringify(err));
                paymentResData.append("payment_res_type", 'error');
                axios.post('/api/save-paymentGateway-response',paymentResData).then(response => {
                });
              }
            );
          }
        },

    fetchSavedCards(){
      let postData = new FormData();
      postData.append("user_id", this.loginUser.id);
      postData.append("restaurant_id", this.restaurant_id);
      axios.post('/patron/card-list',postData).then(response => {
          if(response.data.response.tilled_card_list.total  > 0){
              this.saved_card_list = response.data.response.tilled_card_list.items;
              console.log("saved_card_list",this.saved_card_list);
              $('#savedcard-loader').hide();
              this.is_saved_card_list = true;
          }else{
            $('#savedcard-loader').hide();
            this.is_saved_card_list = false;
          }
      }).catch(error => {
        //alert("err");
        this.savedcarderror = error.response.data.message || {};
        this.is_saved_card_list = false;
        $('#savedcard-loader').hide();
      });
    },
    validate_payment(){
      let vm = this;
      vm.make_payment();
    },

  }
}


</script>
