<template>
  <div id="allorders" class="container tab-pane active">
    <div class="clearfix table-card-title-head">
      <h5 class="card-title mb-0 float-left table-card-title">
        <strong>{{accupiedtbl}} of {{tottable}}</strong> Occupied&nbsp;
      </h5>
    </div>

    <div class="row">
      <!-- table1 -->
      <div v-if="orderslength == 0" class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card card-block d-flex table-card no-order" >
          <div class="card-body align-items-center d-flex justify-content-center empty_order_text">
            Loading...
          </div>
        </div>
      </div>
      <div v-for="(item, index) in orders" class="col-12 col-md-6 col-lg-6 col-xl-4" >
        <div class="table-card c-table-card">
          <div class="user-headeading-booking clearfix">
            <div class="float-left">
              <div v-if="item.rtable.employees.length > 0">
                <div v-for="emp in item.rtable.employees" class=" pl-2">
                    <template v-for="empval in emp.restraurant_employee_attributes" >
                      <template v-if="empval.name == 'profile_photo'">
                        <img v-if="empval.pivot.value!=''" :src="'/storage/employee_pictures/'+empval.pivot.value" alt="">
                        <img v-if="empval.pivot.value==''" src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" alt="">
                      </template>
                    </template>
                    <a href="#">{{emp.user.name}}</a>
                </div>
              </div>
            </div>
            <div class="float-right table-number">
              <span :class="`table-c-${item.tblloop}`">{{item.rtable.name}}</span>
            </div>
          </div>
          <hr>
          <div class="" style="height:200px; overflow-y:auto !important">
            <div class="outer-food-details">
              <div class="" v-for="(sitem,sindex) in item.seats">
                <span class="badge badge-secondary">Seat {{sitem.seat}}</span><hr/>
                <div v-if="sitem.cart_items.length > 0 " class="">
                  <div v-for="(citem,cindex) in sitem.cart_items" class="row">
                    <div class="col-sm-7 col-7">
                      <div class="order-name">
                        <span>{{ citem.quantity }}</span>
                        <p>{{citem.menuitem.title}}
                          <br>
                           <span title="click to see special request" style="cursor: pointer;color:#ff5802;font-size: 12px;" @click="getspecialRequests(citem)" v-if="citem.other_data">{{ (citem.other_data).substring(0,8)+"..." }}</span>
                        </p>

                      </div>
                    </div>
                    <div class="col-sm-5 col-5" style="padding-left: 0;">
                      <div class="item-price">
                        <!-- <span>$ {{citem.quantity * citem.menuitem.price}}</span> -->
                        <span>$ {{parseFloat(citem.quantity * citem.menuitem.price).toFixed(2)}}</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>



          </div>
          <!-- buttons-->

          <div  class="mt-2">
            <div  class="total-timing-price clearfix">
              <div class="float-left left-timing">
                <i class="align-middle  far fa-fw fa-clock" ></i><a :id="'timer_clk_'+item.id">
                  {{calltablescantimer(item.created_at,'timer_clk_'+item.id)}}
                </a>
              </div>
              <div v-if="item.carttotalp > 0 " class="float-right total-price-number">
                <span>$ {{parseFloat(item.carttotalp).toFixed(2)}}</span>
              </div>
            </div>
            <div class="outer-btn-edit-close">
              <div class="row">
                <div  class="col-sm-6 col-6 innterbtn-edit">
                  <button v-if="item.carttotalp > 0 " class="btn" @click="editOrderModal(item,index)"><i
                      class="align-middle  far fa-fw fa-edit"></i>
                    Edit</button>
                </div>
                <div class="col-sm-6 col-6 innterbtn-edit"
                  style="padding-left: 0px;">
                  <button class="btn" @click.prevent="opencloseoutSeatsModal(item.seats)"><i
                      class="align-middle  far fa-fw fa-window-close"></i>
                    Close Out</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="editCurrOrderPrimary"
      tabindex="-1"
      role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
        <div class="modal-header padding-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="Occupied_add_list">
          <div class="m-3">
            <div class="header-add-seat">
              <div class="new-left-sec-with-avtar">
                <span >{{editorderfields.table_name}}</span>
                <img :src="editorderfields.emp_photo" />
                <h2>{{editorderfields.emp_name}}</h2>
              </div>
              <div class="inprogress-btn">
                <button>In Progress</button>
              </div>
            </div>

              <!-- tabs01 -->
              <!-- tabs02 -->
                <div class="clearfix second-tab-left-right">
                  <h3 class="float-left">Order</h3>
                  <a class="float-right ml-2" :data-table-order-id="editorderfields.table_id" @click.prevent="addSeat($event)">+ ADD SEAT</a>
                </div>

                <div class="new-seat-table-collection">
                  <div class="new-seat-table-section">

                      <div class="order-name-edit-outer"  v-for="(seatitem, index_s) in editorderfields.seatsarray">
                        <div class="clearfix top-order-name-inner">
                          <span class="float-left">Seat {{seatitem.seat}}</span>
                          <div class="float-right">
                            <a  @click.prevent="addNewMenuItem(seatitem,index_s)" title="Update"
                              ><i class="fas fa-plus"></i
                            ></a>
                          </div>
                        </div>

                        <div  v-for="(edcitem,edcindex) in seatitem.cart_items">
                          <div class="clearfix top-order-name-inner" style="border: none;">
                            <div class="float-left">
                              <h3> {{edcitem.menuitem.title}}</h3>
                              <!-- <p>{{edcitem.menuitem.description}}</p> -->
                              <p v-if="edcitem.other_data<20" >{{edcitem.other_data}}</p>
                                <p v-else>{{ (edcitem.other_data).substring(0,8)+".." }}</p>
                            </div>
                            <div v-if="edcitem.cancelled == 0" class="float-right d-flex order-name-edit-price">
                              <!-- <div class="price-total">$ {{edcitem.quantity * edcitem.menuitem.price}}</div> -->
                              <div class="price-total">$ {{parseFloat(edcitem.quantity * edcitem.menuitem.price).toFixed(2)}}</div>
                              <a  @click.prevent="editItem(edcitem,seatitem.cart_items,edcindex,seatitem.id,index_s)" title="Update"
                                ><i class="fas fa-pencil-alt"></i
                              ></a>
                              <!-- <a  @click.prevent="removeItem(edcitem.id,seatitem.cart_items,edcindex,index_s)" title="Remove"
                                ><i class="fas fa-trash"></i
                              ></a> -->
                            </div>

                          </div>
                        </div>
                        <div>
                          <div class="clearfix top-order-name-inner-bottom">
                            <div class="float-left">
                              <h3>Total</h3>
                            </div>
                            <div class="float-right order-name-edit-price-bottom">
                              <!-- <div class="price-total">$ {{seatitem.seat_order_total}}</div> -->
                              <div class="price-total">$ {{parseFloat(seatitem.seat_order_total).toFixed(2)}}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                  </div>
                </div>

          </div>
           <div class="taxes-and-fee-price">
                  <div class="taxes-and-fee-price-inner">
                    <div class="clearfix">
                      <h3 class="float-left">Taxes & Fees</h3>
                      <span class="float-right">${{editorderfields.taxamt}}</span>
                    </div>
                  </div>
                  <div class="taxes-and-fee-price-inner">
                    <div class="clearfix">
                      <h3 class="float-left">Service Charge</h3>
                      <span class="float-right" style="color: #00981b">${{editorderfields.platformfee}}</span>
                    </div>
                  </div>
                  <div class="taxes-and-fee-price-inner-total">
                    <div class="clearfix">
                      <h3 class="float-left">Total</h3>
                      <!-- <span class="float-right">${{editorderfields.tabletol}}</span> -->
                      <span class="float-right">${{parseFloat(editorderfields.tabletol).toFixed(2)}}</span>
                    </div>
                  </div>

                  <div class="taxes-and-fee-buttons">
                    <div class="clearfix">
                      <!-- <button class="float-left btn cancel-btn">Cancel</button> -->
                      <div class="float-right">

                        <button class="btn close-out" @click.prevent="opencloseoutSeatsModal(editorderfields.seatsarray)"><img src="/img/frontend/close-icon.PNG" />Close Out</button>
                      </div>
                    </div>
                  </div>

                </div>
        </div>
        </div>
      </div>
    </div>
    <!-- CLOSE OUT NEW MODAL START -->
      <div
        class="modal fade"
        id="CloseOutModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header padding-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="m-3">
              <div class="header-add-seat">
                <div class="new-left-sec-with-avtar">
                  <span >{{closeout.seat_name}}</span>
                  <!-- <img :src="editorderfields.emp_photo" /> -->
                  <!-- <h2>Emp Name</h2> -->
                </div>
                <div class="inprogress-btn">
                  <button>In Progress</button>
                </div>
              </div>

                <!-- tabs01 -->
                <!-- tabs02 -->
                  <div class="clearfix second-tab-left-right">
                    <h3 class="float-left">Select seats to close out ?</h3>
                    <div class="float-right">
                     <input type="checkbox" class="checkbox-outlook-input" name="" @click="selectAllSeats($event)"/><label class="all-seats-outlook"> All Seats</label>
                   </div>
                    </div>


                  <div class="new-seat-table-collection">

                    <!-- start loop -->
                    <div v-for="(seatitem,seatinx) in closeout.seatsdata" class="new-seat-table-section">
                        <div class="order-name-edit-outer">
                          <div class="clearfix top-order-name-inner">
                            <span class="float-left">
                             <!-- <input class="checkbox-outlook-input seatselcls" type="checkbox" /> -->
                             <input class="checkbox-outlook-input seatselcls" type="checkbox" :id="`card-seat-id-${seatitem.id}`" name="asdsd" v-model="closeout.selecteddeats" :value="seatitem.id+'-'+seatinx"/>
                              Seat {{seatitem.seat}}
                            </span>

                          </div>

                          <div>
                            <div v-for="row in seatitem.cart_items" class="clearfix top-order-name-inner" style="border: none;">
                                  <div class="float-left">
                                    <h3>{{row.menuitem.title}}</h3>
                                      <p>{{row.menuitem.description}}</p>
                                  </div>
                                  <div class="float-right d-flex order-name-edit-price">
                                    <div class="price-total">$ {{parseFloat(row.menuitem.price * row.quantity).toFixed(2)}}</div>
                                  </div>
                            </div>
                          </div>
                          <div>
                            <div class="clearfix top-order-name-inner-bottom">
                              <div class="float-left">
                                <h3>Total</h3>
                              </div>
                              <div class="float-right order-name-edit-price-bottom">
                                <div class="price-total">$ {{seatitem.seat_order_total}}</div>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>
                    <!-- end loop -->
                  </div>

            </div>
             <div class="taxes-and-fee-price">
                    <div class="taxes-and-fee-buttons">
                      <div class="clearfix">
                        <!-- <button class="float-left btn cancel-btn">Cancel</button> -->
                        <div class="float-right">
                          <button class="btn close-out" @click.prevent="validateCloseOutSeat()"><img src="/img/frontend/close-icon.PNG" />Close Out</button>
                          <!-- <button class="btn close-out" @click.prevent="opencloseoutSeatsModal(editorderfields.seatsarray)"><img src="/img/frontend/close-icon.PNG" />Close Out</button> -->
                        </div>
                      </div>
                    </div>




                  </div>
          </div>
        </div>
      </div>
      <!-- CLOSE OUT NEW MODAL END -->
    <!-- edit menu modal -->
    <div class="modal fade" id="editMenuItem" tabindex="-1" role="dialog"
      aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          <form method="POST" id="editiorderfrm" name="editiorderfrm" @submit.prevent="saveEditItem" >
          <div class="order_menu_item">
            <!-- <h4>${{edit_menu.menu_item.price}}</h4> -->
            <h4>${{parseFloat(edit_menu.menu_item.price).toFixed(2)}}</h4>
            <h4>{{edit_menu.menu_item.title}}</h4>
            <p>
              {{edit_menu.menu_item.description}}
            </p>
            <p v-if="edit_menu.menu_item.inventory == 0" style="color:red;font-weignt:500">Out of Stock !</p>
            <label>Special Requests?</label>
            <textarea placeholder="What are your requests?" class="form-poup" v-model="edit_menu.menu_item.specialrequest" ></textarea>
            <div class="qty-text-sec" v-if="edit_menu.menu_item.inventory > 0">
              <div class="row">
                <div class="col-2">
                  <span>Qty</span>
                </div>
                <div class="col-5">
                  <input type="text" id="qty" class="qtyform-poup" v-model="edit_menu.menu_item.quantity" min="0"  required=""
                   data-parsley-trigger="keyup" data-parsley-type="digits">
                </div>
                <div class="col-5 button-service">
                  <span ><i class="fa fa-minus"  v-on:click="decrease"></i></span>
                  <span><i class="fa fa-plus"    v-on:click="increase"></i></span>
                </div>
              </div>
            </div>
            <div>
              <button @click.prevent="validateEditItem()" class="btn add-btn-order" id="edit_item" >Update</button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add new menu modal -->
    <div class="modal fade" id="addNewMenuItemModal" tabindex="-1" role="dialog"
      aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="m-4 tab-pane handeld-popup-relative active">
              <div class="nav handhelds-menus  poup-nav-hanhelds-menu">
                  <div class="nav-item" v-for="(item,index) in menu_categories.slice(0,5)"  @click="showMenuItems($event,item.id)">
                    <a class="nav-link" :class="{ active: (activeTabInline == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                  </div>
                  <div class="nav-item menu-list-handheld" @click="showAllMenus">
                    <a class="nav-link" id="final_draft"><i class="fas fa-align-justify"></i></a>
                  </div>
              </div>
              <div class="onclick-show-menu-handeld onclick_menu " id="mobile_toggle_nav" style="display: none;">
                <ul class="nav flex-column">
                  <div class="close-menu" id="close_toggle_nav" @click="close_all_menu_nav">&times;</div>
                  <li class="nav-item" v-for="(item,index) in menu_categories" @click="showMenuItems($event,item.id)">
                    <a class="nav-link " :class="{ active: (activeTab == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                  </li>
                </ul>
              </div>


              <div class="outer-menu-list-price resturant_add_list">
                <h3>{{activeMenuTitle}}</h3>
                <!-- menu-1 -->

                <div v-if="load_menu_items" class="d-flex justify-content-center">
                  <div class="patron-loader spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                </div>


                <div class="menu-list-price" v-for="(item,index) in menu_items" v-if="item.inventory > 0"  @click="showMenuItem(item)">
                  <div class="clearfix" >
                    <img style="width: 70px;height:60px; float:left" v-if="item.images"  :src="'/storage/menu_item_images/thumbnail/'+item.images" :alt="item.title">
                      <h4 class="float-left" style="margin-left:10px;">{{item.title}}</h4>
                      <br><p style="margin-left:80px !important;">{{item.description}}</p>
                      <span class="float-right">${{parseFloat(item.price).toFixed(2)}}</span>
                  </div>

                </div>
                <div v-if="menu_items.length == 0" class="empty_menu empty_msg">No menu items found.</div>

              </div>

            </div>
          </div>
        </div>
    </div>

    <!-- view item modal-->
    <div class="modal fade" id="viewItem" tabindex="-1" role="dialog"
      aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          <form method="POST" id="addiorderfrm" name="addiorderfrm" @submit.prevent="addOrderToSeat" >
          <div class="order_menu_item">
           <img style="width: 570px;" v-if="new_menu_item.image"  :src="'/storage/menu_item_images/original/'+new_menu_item.image" :alt="new_menu_item.title">
            <h4>${{parseFloat(new_menu_item.price).toFixed(2)}}</h4>
            <h4>{{new_menu_item.title}}</h4>
            <p>
              {{new_menu_item.description}}
            </p>
            <label>Special Requests?</label>
            <textarea placeholder="What are your requests?" class="form-poup" v-model="new_menu_item.specialrequest" ></textarea>
            <div class="qty-text-sec">
              <div class="row">
                <div class="col-2">
                  <span>Qty</span>
                </div>
                <div class="col-5">
                  <input type="text" id="qty" class="qtyform-poup" v-model="new_menu_item.quantity" min="1"  required=""
                  data-parsley-validation-threshold="1" data-parsley-trigger="keyup" data-parsley-type="digits">
                </div>
                <div class="col-5 button-service">
                  <span ><i class="fa fa-minus"  v-on:click="decrease_new"></i></span>
                  <span><i class="fa fa-plus"    v-on:click="increase_new"></i></span>
                </div>
              </div>
            </div>
            <div>
              <button @click.prevent="ValidateSeatOrder()" class="btn add-btn-order" id="add_order" >Add to order</button>
              <!-- <a data-toggle="modal" data-target="#signInModalPrimary" class="btn add-signin-btn signin-up-btn"  v-if="!isloggedin">Sign in/ Create Account</a> -->
            </div>
          </div>
          <div class="mb-2 text-center">

              <div v-if="validation_errors" class="text-danger text-danger text-center">
                <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                    {{v[0]}}
                </p>
              </div>
              <div v-if="error" class="text-danger text-danger text-center">
                <p v-if="error" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>
              <div v-if="errors" class="text-danger text-danger text-center">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
              </div>

          </div>

          </form>
        </div>
      </div>
    </div>
 <!-- model list of special request start -->

      <div class="modal fade" id="specialrequest_models" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-header" style="background-color: #4256ce;">
                <h4 class="modal-title" style="color: #fff;">Special Requests:</h4></div>
            <div class="modal-body" v-if="sprequest">
              <ul class="list-group">
                  <li class="list-group-item">{{sprequest}}</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- model list of special request end -->
    <!--close out popup-->
    <div class="modal fade" id="closeoutSeats" tabindex="-1" role="dialog"
      aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          <form method="POST" id="closeseatfrm" name="closeseatfrm" @submit.prevent="closeoutSeats"  data-parsley-validate >
            <div class="m-4">
              <h5 class="modal-title add-employee-popup mb-2">Which Seats are you closing out?</h5>
              <div class="outlook-employee-poup">
                  <input type="checkbox" class="checkbox-outlook-input" name="" @click="selectAllSeats($event)"/><label class="all-seats-outlook"> All Seats</label>
                  <ul>
                    <li v-for="(seatitem,seatinx) in closeout.seatsdata" >
                      <input class="checkbox-outlook-input seatselcls" type="checkbox" :id="`card-seat-id-${seatitem.id}`" name="asdsd" v-model="closeout.selecteddeats" :value="seatitem.id+'-'+seatinx"/>
                      <label class="d-flex" style="position: relative; top: -2px;"><div class="outlook-seat-pop">Seat {{seatitem.seat}}:</div> <span class="seat-outlook-price" v-if="seatitem.cart_items.length > 0 "> {{seatitem.cart_items.length}} Item(s) - ${{seatitem.seat_order_total}}</span> </label>
                    </li>
                  </ul>
              </div>
              <div class="taxes-and-fee-buttons">
                <div class="clearfix">
                  <div class="">
                    <button class="btn close-out" @click.prevent="validateCloseOutSeat()"><img src="/img/frontend/close-icon.PNG" />Close Out</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
props: ['restaurant_id','tottable'],
data() {
    return {
      orderslength:0,
      activeTabInline:null,
      sp_request:[],
      sprequest_data:[],
      sprequest:'',
      activeTab: null,
      activeMenuTitle:null,
      load_menu_items:false,
      menu_items:{},
      menu_categories:[],
      orders:[],
      cartitemtotal:'',
      orderitemtotal:'',
      editorderfields:{
        emp_name:'',
        emp_photo:'',
        table_name:'',
        seatsarray:'',
        table_id:'',
        tabletol:'',
        specialrequest:'',
        taxamt:'0.00',
        platformfee:'0.00'
      },
      edit_menu:{
        menu_item:{
          title:'',
          description:'',
          price:'',
          quantity:1,
          menu_id:'',
          inventory:'',
          user_id:'',

        },
        menu_item_arry:'',
        cartItms:'',
        menuItmInx:'',

      },
      add_menu_to_seat:{
        seat_id:'',
        table_id:'',
        tbl_qr_str:'',
        seat_token_id:''
      },
      new_menu_item:{
        title:'',
        description:'',
        price:'',
        quantity:1,
        image:'',
        user_id:'',
        specialrequest:''
      },
      validation_errors:{},
      error:'',
      errors:{},
      closeout:{
        seatsdata:{},
        selecteddeats:[],
        seat_order_total:'',
        seat_name:''
      },
      selectedseatid:'',
      stindex:'',
      accupiedtbl:0,
      abcnew:''

    }
},

created: function () {
  this.getRestaurantCurrentOrders();
},
mounted() {
  let vm = this;
  Echo.channel('table-qr-scan.'+vm.restaurant_id)
  .listen('TableOccupied', (e) => {
    vm.getRestaurantCurrentOrders();
    vm.accupiedtbl = vm.accupiedtbl + 1;

  });
  Echo.channel('item-confirmed-in-cart.'+vm.restaurant_id)
  .listen('ConfirmCartItem', (e) => {
        vm.getRestaurantCurrentOrders();
  });
  Echo.channel('order-placed.'+vm.restaurant_id)
  .listen('OrderPlaced', (e) => {
        vm.getRestaurantCurrentOrders();
  });
  Echo.channel('item-or-seatitem-changes.'+vm.restaurant_id)
  .listen('CartItemOrSeatUpdated', (e) => {

        vm.getRestaurantCurrentOrders();
  });
},
methods:{



  getRestaurantCurrentOrders(){
    axios.post('/owner/orders/current_orders').then(response => {
      this.orders = response.data.response.table_list.table_rows;
      this.orderslength = response.data.response.totl_tbls;
      console.log("orderslength");
      console.log(this.orderslength);
      if(this.orderslength == 0){
        $('.empty_order_text').text('No Order Found...');
      }
      this.accupiedtbl = response.data.response.totl_tbls;
    }).catch(error => {

    });
  },
  getRecentScanTableData(tid,rid){
    let formData = new FormData();
    formData.append("table_id", tid);
    formData.append("restaurant_id", rid);
    axios.post('/owner/orders/get_scan_table_detail',formData).then(response => {
      //console.log(response.data.response.table_row);
      this.orders.push(response.data.response.table_row);
      this.orderslength = this.orderslength + 1;
      if(this.orderslength == 0){
        $('.empty_order_text').text('No Order Found...');
      }
    }).catch(error => {

    });
  },
  closeouttable(qrid,qindex){
    if(confirm("Do you really want to close out?")){
      let formData = new FormData();
      formData.append("id", qrid);
      formData.append("restaurant_id", this.restaurant_id);
      axios.post('/owner/orders/close_out_scanned_table',formData).then(response => {


        Vue.delete(this.orders, qindex);
      }).catch(error => {
        if (error.response.status === 400) {
          alert(error.response.data.response.message);
        }else{
          alert("Something went wrong!");
        }
      });
    }

  },
  editOrderModal: function (itemc,indexc) {
    let vm = this;
    let profile_photo_val;

    $('#editCurrOrderPrimary').modal('show');
    itemc.rtable.employees.forEach((v,i)=>{
      v.restraurant_employee_attributes.forEach((v1,i)=>{
        if(v1.name == 'profile_photo'){
          if(v1.pivot.value == '' || v1.pivot.value == null){
            profile_photo_val = 'https://gravatar.com/avatar/20abecc84972ab4aaec8d19a11e17b46?s=80&d=mp';
          }else{
            profile_photo_val = '/storage/employee_pictures/'+v1.pivot.value;
          }

        }
      })
      vm.editorderfields.emp_name = v.user.name;
      vm.editorderfields.emp_photo = profile_photo_val;
    });
    vm.editorderfields.table_name = itemc.rtable.name;
    vm.editorderfields.seatsarray = itemc.seats;
    vm.editorderfields.table_id   = itemc.table_id;
    vm.editorderfields.tabletol = itemc.carttotalp;
    vm.editorderfields.specialrequest = itemc.other_data;
    vm.editorderfields.taxamt = itemc.taxamt;
    vm.editorderfields.platformfee = itemc.platformfee;

  },
  addSeat: function (event) {
    let vm  = this;
    let tblid = event.target.getAttribute('data-table-order-id');
    let formData = new FormData();
    formData.append("table_id", tblid);
    formData.append("restaurant_id", vm.restaurant_id);
    axios.post('/owner/orders/add_new_seat_in_table',formData).then(response => {
      vm.editorderfields.seatsarray.push(response.data.response);
      toastr.success('Seat added successfully.');
    }).catch(error => {

    });
  },
  removeItem(itmId,cartItms,menuItm,stindex){
    let vm  = this;
    if(confirm("Do you really want to remove?")){
      axios.delete('/owner/orders/remove-cart-item/'+itmId).then(response => {
        Vue.delete(cartItms, menuItm);
        vm.getRestaurantCurrentOrders();

        vm.editorderfields.seatsarray[stindex].seat_order_total = response.data.response.seat_order_total;
      }).catch(error => {
      });
    }
  },
  addNewMenuItem(seatitemtr,stindex){
    let vm = this;
    //console.log(seatitemtr);
    $('#addNewMenuItemModal').modal('show');
    let formData = new FormData();
    formData.append("type", 'all');
    formData.append("restaurant_id", vm.restaurant_id);
    axios.post('/owner/orders/get-menu-categories',formData).then(response => {
      vm.menu_categories = response.data.response.categories;
      vm.activeTab = response.data.response.categories[0].id;
      vm.activeTabInline = response.data.response.categories[0].id;
      vm.activeMenuTitle = response.data.response.categories[0].title;

      formData.append("menu_category_id", response.data.response.categories[0].id);
      axios.post('/owner/orders/get-restaurant-menu-items',formData).then(response => {

          vm.menu_items = response.data.response.menu_items;
          vm.selectedseatid = seatitemtr.id;
          vm.stindex = stindex;
      });
      //console.log(response.data.response.categories);
    }).catch(error => {

    });
    vm.add_menu_to_seat = {};
    vm.add_menu_to_seat.seat_id = seatitemtr.seat;
    vm.add_menu_to_seat.tbl_qr_str = seatitemtr.token;
    vm.add_menu_to_seat.table_id = seatitemtr.table_id;
    vm.add_menu_to_seat.seat_token_id = seatitemtr.id;
  },
  editItem(itmmenu,cartItms,menuItmInx,seatitemtrid,stindex){
    let vm = this;
    $('#editMenuItem').modal('show');
    vm.edit_menu.menu_item.title = itmmenu.menuitem.title;
    vm.edit_menu.menu_item.description = itmmenu.menuitem.description;
    vm.edit_menu.menu_item.price = itmmenu.menuitem.price;
    vm.edit_menu.menu_item.quantity = itmmenu.quantity;
    vm.edit_menu.menu_item.id = itmmenu.id;
     vm.edit_menu.menu_item.menu_id = itmmenu.menuitem.id;
    vm.edit_menu.menu_item.inventory = itmmenu.menuitem.inventory;
    //vm.edit_menu.menu_item_arry = itmmenu;
    vm.edit_menu.cartItms = cartItms;
    vm.edit_menu.menuItmInx = menuItmInx;
    vm.edit_menu.menu_item.specialrequest = itmmenu.other_data;

    vm.selectedseatid = seatitemtrid;
    vm.stindex = stindex;
    vm.edit_menu.menu_item.qr_token_id = itmmenu.qr_token_id;
  },
  validateEditItem(itmmenu){
    let vm = this;
    $('#editiorderfrm').parsley().whenValidate({

    }).done(function() {
      vm.saveEditItem();
    });
  },
  saveEditItem(){
    let vm = this;
    vm.validation_errors = {};
    vm.error = '';
    $('#edit_item').prop('disabled',true);
    if(vm.edit_menu.menu_item.quantity > 0){
      let formData = new FormData();
      formData.append("u_menu_item", JSON.stringify(vm.edit_menu.menu_item));
      formData.append("restaurant_id", vm.restaurant_id);
      formData.append("qrtoken_id", vm.edit_menu.menu_item.qr_token_id);
      axios.post('/owner/orders/edit-seat-menuitem',formData).then(response => {
        vm.edit_menu.cartItms[vm.edit_menu.menuItmInx] = response.data.response.cart_updated_item;
        $('#edit_item').prop('disabled',false);
        $('#editMenuItem').modal('hide');
        vm.getRestaurantCurrentOrders();

        vm.editorderfields.seatsarray[vm.stindex].seat_order_total = response.data.response.seat_order_total;
      }).catch(error => {
      });
    }else{
      this.removeItem(vm.edit_menu.menu_item.id,vm.edit_menu.cartItms,vm.edit_menu.menuItmInx,vm.stindex);
      $("#editMenuItem .close").click();
    }

  },
  increase: function() {
    let vm = this;
    vm.edit_menu.menu_item.quantity++;
  },
  decrease:  function() {
    let vm = this;
    if (vm.edit_menu.menu_item.quantity > 0) {
        vm.edit_menu.menu_item.quantity--;
    }

  },

  showMenuItems: function (event,menucatid) {
    this.load_menu_items = true;
    this.menu_items = {};
    this.activeMenuTitle = event.target.getAttribute('data-menu');
    this.activeTab = menucatid;
    this.activeTabInline = menucatid;
    let menuData = new FormData();
    menuData.append("menu_category_id", menucatid);
    axios.post('/owner/orders/get-restaurant-menu-items',menuData).then(response => {
      this.load_menu_items = false;
      this.menu_items = response.data.response.menu_items;
      $('#close_toggle_nav').click();
    }).catch(error => {
    });
  },
  showMenuItem: function (item) {
    let vm = this;
    $('#viewItem').modal('show');
    vm.new_menu_item.title = item.title;
    vm.new_menu_item.description = item.description;
    vm.new_menu_item.price = item.price;
    vm.new_menu_item.id = item.id;
    vm.new_menu_item.image = item.images;
    vm.new_menu_item.specialrequest = '';
  },
  showAllMenus(){

  },
  close_all_menu_nav(){

  },
  ValidateSeatOrder() {
    let vm = this;
    $('#addiorderfrm').parsley().whenValidate({

    }).done(function() {
      vm.addOrderToSeat();
    });
  },
  addOrderToSeat: function () {
    let vm = this;
    vm.validation_errors = {};
    vm.error = '';
    $('#add_order').prop('disabled',true);
    let menuData = new FormData();
    menuData.append("menu_item_id", vm.new_menu_item.id);
    menuData.append("quantity", vm.new_menu_item.quantity);
    menuData.append("specialrequest", vm.new_menu_item.specialrequest);
    menuData.append("seat_id", vm.selectedseatid);
    menuData.append("qrtoken_id", vm.selectedseatid);
    axios.post('/owner/orders/add-menu-to-seat',menuData).then(response => {
      //console.log(response.data.response.item);
      $('#viewItem').modal('hide');
      $('#add_order').prop('disabled',false);
      //vm.new_menu_item = {};

      vm.new_menu_item.quantity = 1;
      vm.editorderfields.seatsarray[vm.stindex].cart_items.push(response.data.response.item);

      if(vm.editorderfields.seatsarray[vm.stindex].seat_order_total > 0){
        vm.editorderfields.seatsarray[vm.stindex].seat_order_total = vm.editorderfields.seatsarray[vm.stindex].seat_order_total + (response.data.response.item.menuitem.price * response.data.response.item.quantity);
      }else{
        vm.editorderfields.seatsarray[vm.stindex].seat_order_total = 0 + (response.data.response.item.menuitem.price * response.data.response.item.quantity);
      }



    }).catch(error => {
    });

  },
  increase_new: function() {
    let vm = this;
    vm.new_menu_item.quantity++;
  },
  decrease_new:  function() {
    let vm = this;
    if (vm.new_menu_item.quantity > 1) {
        vm.new_menu_item.quantity--;
    }
  },
  opencloseoutSeatsModal(seatsdata){
    //console.log(seatsdata);
    // $('#closeoutSeats').modal('show');
    $('#CloseOutModal').modal('show');
    this.closeout.seatsdata = seatsdata;
     seatsdata.forEach((v, i) =>{
          this.closeout.seat_order_total = v.seat_order_total;
          this.closeout.seat_name = v.rtable.name;

        });

  },
  validateCloseOutSeat(itmmenu){
    let vm = this;
    $('#closeseatfrm').parsley().whenValidate({

    }).done(function() {
      vm.closeoutSelSeats();
    });
  },
  closeoutSelSeats(){
    console.log(this.closeout);
    let formData = new FormData();
    formData.append("seat_ids", this.closeout.selecteddeats);
    formData.append("restaurant_id", this.restaurant_id);
    axios.post('/owner/orders/close_out_table_seats',formData).then(response => {
      // $('#closeoutSeats').modal('hide');
       $('#CloseOutModal').modal('hide');
          if(response.data.response.order_id){
            window.location.href = "/owner/onsite_togo_orders/make-current-payment/"+response.data.response.order_id;
          }
      let getseats = this.closeout.selecteddeats;
      //console.log(getseats);


      axios.post('/owner/orders/current_orders').then(response => {
        this.orders = response.data.response.table_list.table_rows;
        this.orderslength = response.data.response.totl_tbls;
        console.log("orderslength");
        console.log(this.orderslength);
        if(this.orderslength == 0){
          location.reload();
        }
      }).catch(error => {

      });

      for(let ar=0; ar < getseats.length; ar++){
        console.log(getseats[ar]);
        let get_indx_val = getseats[ar].split("-");
        console.log( get_indx_val[1]);
        //$('#seat-pop-card-id-'+get_indx_val[0]).hide();
        Vue.delete(this.editorderfields.seatsarray, get_indx_val[1]);
        Vue.delete(this.closeout.seatsdata, get_indx_val[1]);
      }

    }).catch(error => {
      if (error.response.status === 400) {
        alert(error.response.data.response.message);
      }else{
        alert("Something went wrong!");
      }
    });
  },
  selectAllSeats(ele) {
     var checkboxes = document.getElementsByClassName('seatselcls');
     if (ele.target.checked) {
         for (var i = 0; i < checkboxes.length; i++) {
             if (checkboxes[i].type == 'checkbox') {
                 this.closeout.selecteddeats.push(checkboxes[i].value);
                 checkboxes[i].checked = true;
             }
         }
     } else {
         for (var i = 0; i < checkboxes.length; i++) {
             if (checkboxes[i].type == 'checkbox') {
                 this.closeout.selecteddeats = [];
                 checkboxes[i].checked = false;
             }
         }
     }
 },
 padTime(num) {
    return ("0"+num).slice(-2);
  },
  calltablescantimer(scanDate,elmId){

      let vm = this;
      setInterval(function(){
        let startTime = moment(scanDate);
        let endTime = moment();
        let seconds = endTime.diff(startTime, 'seconds');


        let minutes = Math.floor(seconds / 60);
        seconds = seconds%60;
        let hours = Math.floor(minutes/60)
        minutes = minutes%60;

        let time = vm.padTime(hours)+':'+vm.padTime(minutes)+':'+vm.padTime(seconds);
          $("#"+elmId).html(time);
        }, 1000);

  },
  getSpecialRequest_old(data){
        let arr = Array();
        data.cart_items.forEach((v, i) =>{
           if(v.other_data){
                arr.push(v.other_data);
              }
            this.sp_request = arr;
        });

  },
  getspecialRequests(item){
        $('#specialrequest_models').modal('show');
        this.sprequest = item.other_data;
      } ,
    getAllSR_old(item){
          $('#specialrequest_model').modal('show');
          this.sprequest_data = item.cart_items;
          console.log(this.sprequest_data)
        }

},

filters: {
},

computed: {
}

}
</script>
