<template>
  <div class="patron-vibe-que  flex-fill w-100">

    <div>

        <h3>You have {{total_points}} points!</h3>
        <p v-if="total_points == 0">
          Earns points by letting {{restaurant_name}} know how they are doing.
        </p>
        <div class="" v-if="totalvcount==0 && total_redeem_points == ''">

          <!-- <p v-if="total_points > 0">
            Your are now able to redeem rewards as you collect points!
          </p>
          <div v-if="total_points > 0" class="service-sec-rounded" >
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <i class="fas fa-gift" aria-hidden="true" style="font-size:28px;color:#ff5802"></i>
                <span class="font-weight-bold">$1 off</span> any Handheld Sandwich or Wraps during lunch.
              </li>
              <li class="list-group-item" @click="redeem_reward_points()">REDEEEM</li>
            </ul>
          </div> -->
        </div>

      <div class="service-sec-rounded" v-for="(item,index) in vibequestions">
        <h2>{{item.title}}</h2>
        <span>{{item.points}}pts</span>
        <div class="total-rating-star-services" v-if="item.type == 'rating'">

          <div class="star-total-rating">
            <star-rating @rating-selected ="submitRating($event,item)" :show-rating="false"></star-rating>
          </div>
        </div>

        <div class="" :id="`vibe-card-main-id-${item.id}`" v-if="item.type == 'options' && item.vibe_options.length > 0" >
          <div class="clearfix yes-price-tag" :id="`vibe-card-id-${itemo.id}`" v-for="(itemo,indexo) in item.vibe_options" @click="submitOption(itemo.id,item)" >
            <h5 class="float-left" >
              <img v-if="itemo.icon_name!=''"  :src="'/img/frontend/vibe_que_icons/'+itemo.icon_name" :alt="itemo.icon_name">
              {{itemo.option_text}}
            </h5>
          </div>
        </div>

      </div>


    </div>

  </div>
</template>
<script src="{{asset('js/frontend/parsley.js')}}" type="text/javascript"></script>
<script>
    import StarRating from 'vue-star-rating'
    export default {
    props: ['restaurant_id','auth_user','qrtokenid'],
    data() {
        return {
            activeTab: null,
            message:'',
            vibequestions:[],
            activeRatingTab: null,
            activeRatingTitle:null,
            load_ratings:false,
            isloggedin:false,
            restaurant_name:'',
            total_points:0,
            totalvcount:'',
            vibeRewardPoints:0,
            total_redeem_points:'',
            textEntries: [
            'You just earned',
            'You just racked up another',
            'Boom! another'
          ]
        }
    },
    components: {
      StarRating
    },
    created: function () {
      if(this.auth_user != null){
        this.isloggedin = true;
      }
      console.log(this.isloggedin);
      let postData = new FormData();
      postData.append("restaurant_id", this.restaurant_id);
      postData.append("qrtoken_id", this.qrtokenid);
      if(this.auth_user != null){
        postData.append("user_id", this.auth_user.id);
      }

      axios.post('/patron/get-vibe-questions',postData).then(response => {
        this.vibequestions = response.data.response.vibeQuestionList;
        this.restaurant_name = response.data.response.restaurant_name;
        this.total_points = response.data.response.total_points;
        this.totalvcount = this.vibequestions.length;
        this.vibeRewardPoints = response.data.response.vibeRewardRecord.points;
        this.total_redeem_points = response.data.response.total_redeem_points;
        this.total_collected_points = response.data.response.total_collected_points;
      }).catch(error => {
      });
    },
    methods:{
      submitRating: function (ev,item) {
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        if(this.auth_user != null){
          postData.append("user_id", this.auth_user.id);
        }

        postData.append("vibe_question_id", item.id);
        postData.append("start_rating", ev);
        postData.append("collected_points", item.points);
        postData.append("qrtoken_id", this.qrtokenid);
        axios.post('/patron/submit-vibe-question',postData).then(response => {
          toastr.success('You just earned '+item.points+' pts for rating your service!');
          this.vibequestions.splice(this.vibequestions.indexOf(item), 1);
          this.total_points = parseInt(this.total_points) + parseInt(item.points);
          this.totalvcount = this.totalvcount - 1;
        }).catch(error => {
        });
      },
      submitOption: function (OptId,item) {
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        if(this.auth_user != null){
          postData.append("user_id", this.auth_user.id);
        }
        postData.append("qrtoken_id", this.qrtokenid);

        $('#vibe-card-id-'+OptId).append('<i class="fas fa-check float-right right-check-mrk"></i>');
        $('#vibe-card-id-'+OptId).addClass('highlight-vibe-right-mark');
        //return false;
        //$('#vibe-card-main-id-'+item.id).css("pointer-events", "none");


        postData.append("vibe_question_id", item.id);
        postData.append("vibe_question_option_id", OptId);
        postData.append("collected_points", item.points);
        axios.post('/patron/submit-vibe-question',postData).then(response => {
          let msgstr = this.textEntries[Math.floor(Math.random()  * this.textEntries.length)]+" "+item.points+' pts!';
          console.log(msgstr);
          $('.yes-price-tag').removeClass('highlight-vibe-right-mark');
          $('.right-check-mrk').remove();
          toastr.success(msgstr);
          //toastr.success('You just earned '+item.points+' pts!');
          this.total_points = this.total_points + item.points;
          this.vibequestions.splice(this.vibequestions.indexOf(item), 1);

          this.totalvcount = this.totalvcount - 1;

        }).catch(error => {
        });
      },
      redeem_reward_points(){
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        postData.append("qrtoken_id", this.qrtokenid);
        if(this.auth_user != null){
          postData.append("user_id", this.auth_user.id);
        }



        axios.post('/patron/redeem-points',postData).then(response => {
          console.log(response);

          // toastr.success('You just earned '+item.points+' pts!');
          // this.total_points = this.total_points + item.points;
          // this.vibequestions.splice(this.vibequestions.indexOf(item), 1);
          //
          // this.totalvcount = this.totalvcount - 1;

        }).catch(error => {
        });
      }
    },
  mounted: function() {

  }
}

</script>
