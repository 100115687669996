<template>
  <div  class="col-12 col-md-6 col-lg-6 col-xl-4">
    <div class="history-table-card">
      <div class="user-headeading-togo-history clearfix">
        <div class="float-left">
          <div><a href="#">To Go</a>
          </div>
        </div>
      </div>
      <div v-if="togo_menus_confirmed.length > 0 || togo_menus_prepared.length > 0 || togo_menus_cancelled.length > 0">
        <div v-if="togo_menus_confirmed.length > 0" class="outer-food-details-history">
          <div v-for="(item_c,index_c) in togo_menus_confirmed" class="row">
            <div class="col-sm-6 col-6"  style="padding-right: 0;">
              <div class="order-name">
                <span>{{item_c.quantity}}</span>
                <p>{{item_c.menuitem.title}}</p>
              </div>
            </div>
            <div class="col-sm-3 col-3" style="padding-right: 0;">
              <div class="item-price-history">
                <span :id="'kttimer_onsite_clk_'+item_c.id">{{calltablescantimer_togo(item_c.created_at,'kttimer_onsite_clk_'+item_c.id)}}</span>
              </div>
            </div>
            <div class="col-sm-3 col-3" style="padding-left: 0;">
              <div class="history-check">
                <button :id="`kitchen_togo_done_btn_${item_c.id}`" class="btn kitchen_done_btn" @click.prevent="mark_togo_menu_done(item_c.id)">Done</button>
              </div>
            </div>
          </div>
        </div>
        <!-- gree-color -->
        <div  v-if="togo_menus_prepared.length > 0" class="outer-food-details-history-green">
          <div v-for="(item_p,index_p) in togo_menus_prepared"  class="row">
            <div class="col-sm-7 col-7"  style="padding-right: 0;">
              <div class="order-name-green">
                <span>{{item_p.quantity}}</span>
                <p>{{item_p.menuitem.title}}</p>
              </div>
            </div>
            <div class="col-sm-3 col-3" style="padding-right: 0;">
              <div class="item-price-history-green">
                <span>{{item_p.menuReceivedAndPreparedTime}}m</span>
              </div>
            </div>
            <div class="col-sm-2 col-2" style="padding-left: 0;">
              <div class="prepared-check">
                <i class="fa fa-check" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <!-- gree-red -->
        <div v-if="togo_menus_cancelled.length > 0" class="outer-food-details-history-red">
          <div v-for="(item_ca,index_ca) in togo_menus_cancelled" class="row">
            <div class="col-sm-7 col-7"  style="padding-right: 0;">
              <div class="order-name-green">
                <span>{{item_ca.quantity}}</span>
                <p>{{item_ca.menuitem.title}}</p>
              </div>
            </div>
            <div class="col-sm-5 col-5" style="padding-right: 0;">
              <div class="item-price-history-green">
                <span>CANCELLED</span>
              </div>
            </div>
          </div>
        </div>
        <div class="total-timing-price-history clearfix">
          <div class="float-left left-timing tab-top-history">
            <i class="align-middle  far fa-fw fa-clock"></i><a
              >{{tot_card_avgCompletionTime_togo}}m</a>
          </div>
          <div class="float-right total-price-number-hostory" v-if="togo_menus_confirmed.length > 0">
            <button class="btn kitchen_complete_btn" id="complete_togo_menus" @click="preparedAllToGoOrderMenus(togo_menus_confirmed)">Complete</button>
          </div>
          <div class="float-right total-price-number-hostory" v-else>
            <button class="btn kitchen_completed_btn" ><i class="fa fa-check" aria-hidden="true"></i> Completed</button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="outer-food-details-history">
          <div  class="row">
            <div class="col-12" style="padding-right: 0px;">
              <div class="order-name">
                <p>No Order Yet...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['restaurant_id'],
  data() {
    return {
      togo_menus_confirmed:{},
      togo_menus_prepared:{},
      togo_menus_cancelled:{},
      tot_card_avgCompletionTime_togo:0
    };
  },

  created: function () {
    this.onsite_togo_orders();
  },
  mounted() {
    let vm = this;
    Echo.channel('onsiteto-items.'+vm.restaurant_id)
    .listen('OnsiteToGoItem', (e) => {
          vm.onsite_togo_orders();
    });
  },
  methods: {
    onsite_togo_orders(){
      let formData = new FormData();
      formData.append("restaurant_id", this.restaurant_id);
      axios.post('/owner/orders/onsite-togo-menus-kitchen',formData).then(response => {
        this.togo_menus_confirmed = response.data.response.tosietogomenus_confirmed;
        this.togo_menus_prepared = response.data.response.tosietogomenus_prepared;
        this.togo_menus_cancelled = response.data.response.tosietogomenus_cancelled;
        this.tot_card_avgCompletionTime_togo = response.data.response.tot_card_avgCompletionTime_togo;
      }).catch(error => {

      });
    },
    calltablescantimer_togo(scanDate,elmId){
         let vm = this;
         setInterval(function(){
           let startTime = moment(scanDate);
           let endTime = moment();
           let minutes = endTime.diff(startTime, 'minutes');
           let time = minutes;
             $("#"+elmId).html(time+'m');
           }, 1000);
    },
    mark_togo_menu_done(item_row_id){
      $('#kitchen_togo_done_btn_'+item_row_id).prop('disabled',true);
      let formData = new FormData();
      formData.append("item_id", item_row_id);
      axios.post('/owner/orders/prepared_menu_item',formData).then(response => {
        $('#menu-emp-id').addClass('loading-spin');
        setTimeout( () => {
          $('#menu-emp-id').removeClass('loading-spin');
          this.onsite_togo_orders();
        }, 5000);
        $('#kitchen_togo_done_btn_'+item_row_id).prop('disabled',false);
      }).catch(error => {
        $('#kitchen_togo_done_btn_'+item_row_id).prop('disabled',false);
      });
    },
    preparedAllToGoOrderMenus(menuitemssrr){
      $('#complete_togo_menus').prop('disabled',true);
      let menu_item_list_ids=[];
      $.each(menuitemssrr, function(key, value) {
        menu_item_list_ids.push(value.id);
      });
      this.mark_togo_menu_done(menu_item_list_ids);
    }
  }
};
</script>
