<template>
    <div>
        <div class="col-md-6" style=" padding: 0;"> 
          <div class="calender_main_wrap">
              <div class="calender_sec">
                    <div class="events-container"></div>
                   <div v-if="items.length > 0">
                        <div v-for="(item, index) in items" :key="item.event.id"  @click="handleSecondClick(item.event.id)" class="date_header b1">
                    <div :class="[{'dt_tlt': item.event.id === activeEventId && item.active !== '2'}, {'dt_tlt2': item.event.id !== activeEventId && item.active !== '2'},{'dt_tlt3': item.active === '2'}]">
                        <p>{{ currentDate(item.event.start_date) }}</p>
                    </div>
                    <div :class="[{'evt_dtl': item.event.id === activeEventId && item.active !== '2'}, {'evt_dtl2': item.event.id !== activeEventId && item.active !== '2'},{'evt_dtl3': item.active === '2'}]">
                            <p class="loc_name">
                                <i class="align-middle fas fa-map-marker-alt "></i>
                                {{ item.event.name }}
                            </p>
                            <p>
                            <i class="align-middle fas fa-clock"></i>
                            {{ convertedStartTime(item.event.start_time) }} -
                            {{ convertedEndTime(item.event.end_time) }}
                            </p>
                            <span>
                                
                                    <div v-if="item.event.estimated_attendance && item.event.booking_fee != null">
                                        <p style="font-weight: 600;">
                                        <i class="align-middle fas fa-users"></i>
                                        {{ item.event.estimated_attendance }}
                                        </p>
                                    </div>
                                   
                            </span>
                        </div>
                    </div>
                   </div>
                    <div v-else class="calender_main_wrap">
                        <div class="event-name">No events have been found</div>
                    </div>
                </div>
            </div>
        </div>
                <div class="col-md-6" style=" padding: 0;float: right;">
                    <event-details-component :firstevent="firstevent" v-if="loaded"></event-details-component>
                </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            firstevent: [],
            items: [],
            allItems: [],
            temp: [],
            loaded: false,
            activeEventId: null,
            isDataLoaded: false,
            highlightedDates: []
        };
    },
    mounted() {
        const vm = this;
        vm.geteventdata().then(() => {
            vm.highlightedDates = vm.allItems.map(item => item.event.start_date);
            $('.calendar').datepicker({
                inline: true,
                firstDay: 7,
                showOtherMonths: true,
                dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                beforeShowDay: vm.highlightEvent,
                onSelect: function(dateText, inst) {
                    var clickedDate = $(this).datepicker('getDate');
                    var formattedDate = $.datepicker.formatDate("yy-mm-dd", clickedDate);
                    let formData = new FormData();
                    formData.append("date", formattedDate);

                    axios.post('/owner/get-date-event', formData).then(response => {
                        // Filter events that start on the clicked date
                        let dateEvents = response.data.dateevent.filter(date => date.event !== null && date.event.start_date === formattedDate);

                        // Filter all items to find events that start before and end after the clicked date
                        let eventsFromRange = vm.allItems.filter(item => {
                            let startDate = new Date(item.event.start_date);
                            let endDate = new Date(item.event.end_date);
                            let clickedDateObj = new Date(formattedDate);
                            return startDate <= clickedDateObj && clickedDateObj <= endDate;
                        });

                        // Combine dateEvents and eventsFromRange ensuring no duplicates
                        let combinedEvents = [...dateEvents, ...eventsFromRange];
                        vm.items = combinedEvents.filter((item, index, self) => 
                            index === self.findIndex((t) => (
                                t.event.id === item.event.id
                            ))
                        );

                        console.log('vm.items = ', vm.items);

                        if (vm.items.length > 0) {
                            vm.handleSecondClick(vm.items[0].event.id);
                            vm.loaded = true;
                        } else {
                            vm.loaded = false;
                        }

                        console.log('/owner/get-date-event');
                        console.log(vm.items);
                    }).catch(error => {
                        console.log(error);
                    });
                }
            });
        });
    },
    methods: {
        geteventdata() {
            return axios.get('/owner/get-event-data').then(response => {
                this.temp = response.data.data;
                Vue.set(this, 'allItems', this.temp);
                Vue.set(this, 'items', this.temp);
                this.isDataLoaded = true;
                if (this.temp.length > 0) {
                    this.handleSecondClick(this.temp[0].event.id);
                }
                console.log('/owner/get-event-data');
                console.log(this.temp);
            }).catch(error => {
                console.log(error);
            });
        },
        highlightEvent(date) {
            if (!this.isDataLoaded) {
                return [true, '', ''];
            }
            let formattedDate = this.formatDate(date);
            let isHighlighted = this.allItems.some(item => {
                let startDate = item.event.start_date;
                let endDate = item.event.end_date;
                return startDate <= formattedDate && formattedDate <= endDate;
            });
            if (isHighlighted) {
                return [true, 'event-highlight', '•'];
            } else {
                return [true, '', ''];
            }
        },
        formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        currentDate(item) {
            const date = new Date(item);
            return date.toLocaleDateString("en-US", {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric"
            });
        },
        handleSecondClick(itemid) {
            let formData = new FormData();
            formData.append("id", itemid);
            this.activeEventId = itemid;
            axios.post("/owner/get-single-event", formData).then(response => {
                this.firstevent = response.data.firstevent;
                this.loaded = true;
            }).catch(error => {
                console.log(error);
            });
        },
        convertedStartTime(time) {
            const [hours, minutes] = time.split(":");
            const d = new Date();
            d.setHours(hours);
            d.setMinutes(minutes);
            return d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        },
        convertedEndTime(time) {
            const [hours, minutes] = time.split(":");
            const d = new Date();
            d.setHours(hours);
            d.setMinutes(minutes);
            return d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
            });
        }
    }
};
</script>

