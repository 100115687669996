<template>
<div>

  <div class=" modal-dialog modal-md modal-dialog-centered" >
    <div class="modal-content">
      <button type="button" class="close handheld-close-popup" data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <form class="patron_signin_item" method="POST" id="signinform" name="signinform"  data-parsley-validate @submit.prevent="doLogin">
        <div class="m-3">
          <h4>Sign In</h4>
          <p>Sign in with your email address or create a new account to make dinning at more places easier and faster</p>
          <div>
            <input type="email" name="form_field" v-model="form_field.email" class="form-poup"
              placeholder="Email Address" data-parsley-required-message="Please enter email address"  required>
            <input type="password" name="form_field" v-model="form_field.password" class="form-poup"
                placeholder="*******" data-parsley-required-message="Please enter password" required>
          </div>
          <div class="mb-2 text-center">

              <div v-if="validation_errors" class="text-danger">
                <div v-for="(v, k) in validation_errors" :key="k">
                    {{v[0]}}
                </div>
              </div>
              <div v-if="error" class="text-danger text-danger text-center">
                <p v-if="error" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>

          </div>
          <div class="clearfix">
            <label class="text-center d-block create-account-popup" @click="showSignUpModl" >Create new Account</label>
            <span class="text-center d-block forget-account-popup"><a href="/password/reset" target="_blank">Forgot Password</a></span>
          </div>
          <div class="clearfix">
            <button  class="btn add-signin-btn" @click.prevent="ValidateForm()" id="login_btn">Sign In</button>
          </div>
          <!-- <div class="clearfix text-center">
            <label class="">Continue as guest</label>
          </div> -->
           <div class="clearfix text-center">
             <!-- <a data-toggle="modal" @click="showGuestPayModel" href="#guestSignUpModalPrimary" class="btn btn-primary">Open modal2</a> -->
            <label class="guest-sign-up-popup" @click="showGuestPayModel" >Continue as guest</label>
          </div>

        </div>
      </form>

    </div>
  </div>

  <!-- <div   class="modal fade" id="guestSignUpModalPrimary" tabindex="-1" role="dialog" aria-hidden="true">
    <patron-guest-pay v-if="showguestpay" :showguestpay="showguestpay" :tipval="tipVal" :restaurant_id="restaurant_id" :qrtokenid="qrtokenid" :finalcarttotal="finalcarttotal" :country_id="country_id"  :tipfinval="tipfinval" :taxfinval="taxfinval"  :connaccidv="connaccidv" :paymntkeyvalv="paymntkeyvalv"></patron-guest-pay>
  </div> -->
</div>

</template>
<script src="{{asset('js/frontend/parsley.js')}}" type="text/javascript"></script>
<script>
    export default {
      props: ['connaccidv','paymntkeyvalv','restaurant_id','qrtokenid','finalcarttotal','country_id','tipfinval','taxfinval','tipVal'],
    data() {
        return {
          form_field:{
            email:'',
            password:''
          },
          problem_in_login:'',
          errors: {},
          validation_errors:{},
          error:'',
          loggedUser:'',
          showguestpay:false
        }

    },

    created: function () {
    },
    methods:{
      ValidateForm() {
        let vm = this;
        $('#signinform').parsley().whenValidate({

        }).done(function() {
          vm.doLogin();
        });
      },

      doLogin: function () {
        let vm = this;
        $('#login_btn').prop('disabled',true);
        axios.post('/patron/login',vm.form_field).then(response => {
          $('#login_btn').prop('disabled',false);
          $("#signInModalPrimaryPay .close").click();
          //location.reload();
          //console.log(response.data.response.user);
          this.loggedUser = response.data.response.user;
          vm.setUserLoginOnPay();
        }).catch(error => {
          $('#login_btn').prop('disabled',false);
          if(error.response.data.errors){
            vm.validation_errors = error.response.data.errors || {};
          }
          if(error.response.data.response.message){
            vm.error = error.response.data.response.message || {};
          }

        });
      },
      showSignUpModl : function(){
          $('#signInModalPrimaryPay').modal('hide');
          $('#signUpStepModalPrimary').modal('show');
      },
      setUserLoginOnPay(){
        this.$emit('setloginonpay',this.loggedUser);
      },
      showGuestPayModel: function(){
          this.$emit('setguestpageactive',true);
          $('#signInModalPrimaryPay').modal('hide');
         $('#guestSignUpModalPrimary').modal('show');

      },
    },
  mounted: function() {

  }
}

</script>
