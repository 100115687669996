/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap');
//require('../plugins');

//import Vue from 'vue';
window.Vue = require('vue').default;
import VueSlimScroll from 'vue-slimscroll';
Vue.use(VueSlimScroll);
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
window.toastr = require('toastr');
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import VueCountdownTimer from 'vuejs-countdown-timer';
import StarRating from 'vue-star-rating';
import { BFormDatepicker } from 'bootstrap-vue';
Vue.component('b-form-datepicker', BFormDatepicker)
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('guest-popup', require('./components/patron/GuestPopup.vue').default);
Vue.component('restaurant-signup-component', require('./components/restaurant/SignupComponent.vue').default);
Vue.component('foodtruck-signup-component', require('./components/restaurant/SignupFoodtruckComponent.vue').default);
Vue.component('recaptcha-component', require('./components/restaurant/RecaptchaComponent.vue').default);
Vue.component('restaurant-orders-component', require('./components/restaurant/OrdersComponent.vue').default);
Vue.component('restaurant-bar-orders-component', require('./components/restaurant/BarOrdersComponent.vue').default);
Vue.component('restaurant-kitchen-orders-component', require('./components/restaurant/KitchenOrdersComponent.vue').default);
Vue.component('restaurant-table-list-component', require('./components/restaurant/RestaurantTableListComponent.vue').default);
Vue.component('restaurant-employee-list-component', require('./components/restaurant/RestaurantEmployeeListComponent.vue').default);
Vue.component('restaurant-menus-component', require('./components/restaurant/RestaurantMenusComponent.vue').default);
Vue.component('foodtruck-menus-component', require('./components/restaurant/FoodtruckMenusComponent.vue').default);
Vue.component('restaurant-test1-component', require('./components/restaurant/Test1.vue').default);
Vue.component('reservation-component', require('./components/restaurant/ReservationComponent.vue').default);

Vue.component('restaurant-menus-patron-desktop-component', require('./components/patron/MenusDesktopComponent.vue').default);
Vue.component('restaurant-menus-patron-component', require('./components/patron/MenusComponent.vue').default);
Vue.component('patron-sign-in', require('./components/patron/SignInComponent.vue').default);
Vue.component('patron-sign-up', require('./components/patron/SignUpComponent.vue').default);
Vue.component('patron-guest-pay', require('./components/patron/guestSignUpComponent.vue').default);
Vue.component('vibe-questions-component',require('./components/restaurant/VibeQuestionsComponent.vue').default);
Vue.component('payment-component', require('./components/restaurant/PaymentComponent.vue').default);
Vue.component('onsitetogo-payment-component', require('./components/restaurant/OnsitetogoPaymentComponent.vue').default);
Vue.component('event-payment-component', require('./components/restaurant/EventPaymentComponent.vue').default);
Vue.component('blast-payment-component', require('./components/restaurant/BlastPaymentComponent.vue').default);
Vue.component('tilled-payment-component', require('./components/restaurant/TilledPaymentComponent.vue').default);
Vue.component('onsitetogo-tilled-payment-component', require('./components/restaurant/OnsitetogoTilledPaymentComponent.vue').default);
Vue.component('v-select', vSelect);
Vue.component('restaurant-treats-rewards-component',require('./components/restaurant/RestaurantTreatsRewardsComponent.vue').default);
Vue.component('restaurant-employee-schedule-component',require('./components/restaurant/RestaurantEmployeeScheduleComponent.vue').default);
Vue.component('Treeselect', Treeselect);
Vue.component('final-checkout-page-component', require('./components/patron/FinalCheckoutPageComponent.vue').default);
Vue.component('current-tables-component', require('./components/restaurant/RestaurantCurrentTablesComponent.vue').default);
Vue.component('restaurant-orders-count-component', require('./components/restaurant/OrdersCountComponent.vue').default);
Vue.component('restaurant-employee-attendance-component', require('./components/restaurant/RestaurantEmployeeAttendanceComponent.vue').default);
Vue.component('vue-timepicker', VueTimepicker);
Vue.use(VueCountdownTimer);
Vue.component('final-paymentpage-component', require('./components/patron/FinalPaymentPageComponent.vue').default);
Vue.component('receipt-page', require('./components/patron/ReceiptPageComponent.vue').default);

Vue.component('kitchen-orders-component', require('./components/restaurant/KitchenTabComponent.vue').default);
Vue.component('bar-kitchen-orders-component', require('./components/restaurant/BarKitchenOrdersComponent.vue').default);
Vue.component('onsite-togo-kitchen-orders-component', require('./components/restaurant/OnsiteToGoKitchenOrdersComponent.vue').default);
Vue.component('register-add-order-component', require('./components/restaurant/RegisterOrdersComponent.vue').default);
Vue.component('register-orders-component', require('./components/restaurant/RestaurantOnsiteToGoOrdersComponent.vue').default);
Vue.component('register-togo-orders-component', require('./components/restaurant/RestaurantToGoOrdersComponent.vue').default);
Vue.component('StarRating', StarRating);
//-------- FoodTruck Start--------//
Vue.component('foodtruck-current-tables-component', require('./components/restaurant/FoodtruckCurrentTablesComponent.vue').default);
Vue.component('foodtruck-services-current-tables-component', require('./components/restaurant/foodtruckServicesCurrentTablesComponent.vue').default);
Vue.component('foodtruck-bar-orders-component', require('./components/restaurant/foodtruckBarOrdersComponent.vue').default);
Vue.component('foodtruck-kitchen-orders-component', require('./components/restaurant/foodtruckKitchenOrdersComponent.vue').default);
Vue.component('foodtruck-togo-orders-component', require('./components/restaurant/foodtruckToGoOrdersComponent.vue').default);
Vue.component('onsitetogo-payment-component', require('./components/restaurant/OnsitetogoPaymentComponent.vue').default);
//-------- FoodTruck ends--------//

Vue.component('current-time-component', require('./components/CurrentTimeComponent.vue').default);
// ------------Event-------------//
Vue.component('event-component', require('./components/restaurant/EventComponent.vue').default);
Vue.component('home-event-component', require('./components/restaurant/HomeEventComponent.vue').default);
Vue.component('scan-card-component', require('./components/restaurant/ScanCardComponent.vue').default);
Vue.component('event-details-component', require('./components/restaurant/EventDetailsComponent.vue').default);
Vue.component('event-market-place-list-component', require('./components/restaurant/EventMarketPlaceComponent.vue').default);
Vue.component('event-market-place-detail-component', require('./components/restaurant/EventMarketPlaceDetailComponent.vue').default);
Vue.component('event-favorite-detail-component', require('./components/restaurant/EventFavoriteDetailComponent.vue').default);
Vue.component('favorite-event-component', require('./components/restaurant/FavoriteEventComponent.vue').default);
Vue.component('schedule-calender-component', require('./components/restaurant/ScheduleCalenderComponent.vue').default);
Vue.component('events-calender-userend-component', require('./components/restaurant/EventUserendCalenderComponent.vue').default);
Vue.component('userend-events-component', require('./components/restaurant/UserEndEventsComponent.vue').default);
Vue.component('event-dely-component', require('./components/restaurant/EventdelyComponent.vue').default);
Vue.component('eventdatetime-component', require('./components/restaurant/EventDatetimeComponent.vue').default);
Vue.component('about-foodtruck-component', require('./components/restaurant/AboutFoodtruckComponent.vue').default);
Vue.component('merchants-attending-component', require('./components/restaurant/MerchantsAttendingComponent.vue').default);

// ---------- special menu --------------//
Vue.component('specialmenu-model-component', require('./components/restaurant/SpecialmenuModelComponent.vue').default);
Vue.component('blast-model-component', require('./components/restaurant/BlastModelComponent.vue').default);
Vue.component('special-menu-component', require('./components/restaurant/SpecialMenuComponent.vue').default);
Vue.component('blast-menu-component', require('./components/restaurant/BlastMenuComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
