<template>
  <div>
      <div  class="calender_main_wrap">
          <div v-if="firstevent != ''" class="calender_sec" id="first" style="border-left: 1px solid #ccc;">
              <h4>
                  {{firstevent.event.name}}
               
                  <!-- <i v-if="firstevent.likes && firstevent.likes.status == 1"  class="align-middle fa fa-heart heart-red float-right" v-on:click="toggleLike(firstevent.id)"></i>
                  <i v-else class="align-middle far fa-heart float-right" v-on:click="toggleLike(firstevent.id)"></i>
                -->
                 
                  
              </h4>
              <img :src="'/storage/event_picture/thumbnail/'+firstevent.event.photos" alt="" />
              <div class="evt_add">
                  <h5>
                      <i class="align-middle fas fa-map-marker-alt "></i>
                      {{firstevent.event.address}}
                  </h5>
                  <span>{{firstevent.event.address}}
                      <i class="align-middle fas fa-location-arrow" v-on:click="openMap(firstevent.event.address)"></i
                  ></span>
                  <p>
                      <i class="align-middle fas fa-clock"></i>
                      {{convertedStartTime(firstevent.event.start_time)}} - {{convertedEndTime(firstevent.event.end_time)}}
                  </p>
                  <div v-if="firstevent.event.estimated_attendance">
                      <p style="float: left;width: 7%; margin-top:0;">
                          <i class="align-middle fas fa-users"></i>
                      </p>
                      <p style="float: left;width: 56%;margin-top:0;">
                          {{firstevent.event.estimated_attendance}} people expected permit required
                      </p>
                  </div>
              </div>
              <div class="fee">
                  <h4 v-if="firstevent.event.booking_fee">${{firstevent.event.booking_fee}} Booking Fee</h4>
                  <!-- <div class="pendingbtn">
                      <button class="btn btn-pending" type="button">
                          PENDING
                      </button>
                  </div> -->
                  <div v-if="firstevent.booking_fee != null">
                    <div v-if="firstevent.refunded == 0" class="bk_cncl">
                      <div v-if="firstevent.booking_status != 3">
                       <button @click.prevent="cancelBooking(firstevent.id,firstevent.event.id,firstevent.order_id)" class="btn btn-primary">
                          Cancel Booking
                      </button>
                      </div>
                   </div>
                  </div>
                  
              </div>

              <div class="att_tle" v-if="firstevent.event.booking_fee">
                  <h4>Merchants Attending</h4>
              </div>

              <div class="att_card" v-if="firstevent.event.booking_fee">
                  <merchants-attending-component :ids="firstevent.event.id" :key="firstevent.event.id"></merchants-attending-component>
                  
              </div>
          </div>
   </div>

<!-- show Refund details -->
<div class="modal fade" id="orderDetailModalPrimary" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">

        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="clearfix">
                        <div class="float-left pl-2">
                            <h5 class="modal-title add-order-new-popup">Order# {{order_detail.id}}</h5>
                            <p class=" add-order-transection-popup"> {{clientname}}</p>
                            <p class=" add-order-transection-popup">Email: {{customeremail}}</p>
                            <p v-if="customerphn" class=" add-order-transection-popup">Phone: {{customerphn}}</p>
                        </div>
                        <span class="float-right transection-popup-paid">{{paidbtntxt}}</span>
                        <p class="float-right Transaction_id_title" v-if="paymentgatewayorderid !=''">Transaction# {{paymentgatewayorderid}}</p>
                    </div>
                    <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">×</span></button>
                </div>
                    <div class="order-form-popup">
                      <div class="d-flex justify-content-center mt-2" >
                        <div class="spinner-border text-warning" role="status" id="orderdetailspin" >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div class="order_details_popup">
                            <div class="outer-form-popup-order">

                                <div  class="headeading-menu-popup-order clearfix">
                                    <div class="float-left">
                                      <p>Event Name : </p>
                                      <p>Event Address : </p>
                                      <p>Event Started : </p>
                                      <p>Event Ended : </p>
                                      </div>
                                    <div class="float-right">
                                          <p>{{firstevent.event.name}}</p>
                                          <p>{{firstevent.event.address}}</p>
                                          <p>{{convertedStartTime(firstevent.event.start_time)}}</p>
                                          <p>{{convertedEndTime(firstevent.event.end_time)}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="order_detail" class="bottom-due-taxes-order">

                        <div class="order_details_popup total_amount_number">

                          <div  v-if="order_detail.discount_applied_amount > 0" class="headeading-menu-popup-order taxes-due clearfix">
                              <div class="float-left">
                                  <p style="width:200px">Disocunt applied on menu: @{{order_detail.discount_detail}}</p>
                              </div>
                              <div class="float-right">
                                  <p>-${{order_detail.discount_applied_amount}}</p>
                              </div>
                          </div>
                            <div class="headeading-menu-popup-order taxes-due clearfix">
                                <div class="float-left">
                                    <p>Taxes & Fees</p>
                                </div>
                                <div class="float-right">
                                    <p>${{ordertax.toFixed(2)}}</p>
                                </div>
                            </div>

                            <div class="headeading-menu-popup-order taxes-due clearfix">
                                <div class="float-left">
                                    <p>Platform Fee</p>
                                </div>
                                <div class="float-right">
                                    <p>${{order_application_fee.toFixed(2)}}</p>
                                </div>
                            </div>
                            <div class="headeading-menu-popup-order taxes-due clearfix">
                              <div class="float-left">
                                    <p>Total paid</p>
                                </div>
                                <div class="float-right">
                                    <p class="paid-visa-card-popup">${{paid_order_amount.toFixed(2)}}</p>
                                </div>
                            </div>
                           
                            <div class="row" v-if="loadodetail">
                              <div class="col-6">
                                <div v-if="firstevent.paid == 1" class="refund-btn-popup-order">
                                    <button @click.prevent="refundOrderAmount(order_detail.id,firstevent.id,firstevent.event.id)" class="btn" id="refundbtnid" >Refund and Reverse</button>
                                </div>
                              </div>
                            </div>

                        </div>
                    </div>
            </div>
        </div>
      </div>
<!-- end -->
</div>
</template>

<script>
export default {
  props: ['firstevent'],   
  data() {
      return {
        //firstevent: this.firstevent,
        status:false,
        order_detail:[],
        clientname:'',
        customeremail:'',
        customerphn:'',
        keyword_search:'',
        transactionId:'',
        payment_detail:[],
        searched_data:[],
        order_refund_status:true,
        paidbtntxt:'...',
        data_found:false,
        order_tip:0,
        paid_order_amount:0,
        ordertax:0,
        order_application_fee:0,
        loadodetail:false,
        paymentgatewayorderid:''
      }
    },
  methods: {
      currentDate(item) {
          const date = new Date(item);
          return date.toLocaleDateString("en-US", {
              weekday: "long",
              month: "long",
              day: "numeric",
              year: "numeric"
          });
      },
      convertedStartTime(time) {
          const [hours, minutes] = time.split(":");
          const d = new Date();
          d.setHours(hours);
          d.setMinutes(minutes);
          return d.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true
          });
      },
      convertedEndTime(time) {
          const [hours, minutes] = time.split(":");
          const d = new Date();
          d.setHours(hours);
          d.setMinutes(minutes);
          return d.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true
          });
      },
      openMap(address) {
          const mapsUrl = "https://www.google.com/maps?q=" + encodeURIComponent(address);
          window.open(mapsUrl, "_blank");
      },
       toggleLike(itemid) {
      let formData = new FormData();
      formData.append("id", itemid);
      axios.post('/owner/like-event',formData).then(response => {
         setTimeout(function() {
          location.reload();
          }, 300);
      }).catch(error => {
        console.log(error);
      });
      },
      showOrderDetail(oid){
      this.ordertax = 0;
      this.order_application_fee = 0;
      this.clientname = '';
      this.customeremail = '';
      this.customerphn = '';
      this.loadodetail = false;
      this.paidbtntxt = "...";
      this.order_detail = [];
      this.payment_detail = [];
      this.order_tip = 0;
      this.paid_order_amount = 0;
      this.paymentgatewayorderid = '';
      $('#orderDetailModalPrimary').modal('show');
      $('#orderdetailspin').show();
      this.orderid = oid;
      let formData = new FormData();
      formData.append("id", oid);
      axios.post('/owner/order-detail',formData).then(response => {
      console.log('/owner/order-detail');
      console.log(response);
        this.order_detail = response.data.response.orderDetail;
        this.transactionId = this.order_detail.transaction_id;
        if(this.order_detail.payment_gateway_order_id != ''){
          this.paymentgatewayorderid = this.order_detail.payment_gateway_order_id;
        }else{
          this.paymentgatewayorderid = '';
        }

        if(response.data.response.orderDetail.tip > 0){
          this.order_tip = response.data.response.orderDetail.tip;
        }else{
          this.order_tip = 0;
        }
        this.ordertax =  response.data.response.orderDetail.tax;
        this.paid_order_amount = response.data.response.orderDetail.order_amount;
        this.order_application_fee = response.data.response.orderDetail.application_fee;
        let userData = (this.order_detail.guest_user_details)?JSON.parse(this.order_detail.guest_user_details):'';
        let firstName = userData.firstname?userData.firstname:'';
        let lastName = userData.last_name?userData.last_name:'';
        this.clientname = userData.name?userData.name:'';
        this.customeremail = userData.email?userData.email:'';
        this.customerphn = userData.phone?userData.phone:'';
        this.payment_detail = response.data.response.payment_detail;
        if(response.data.response.orderDetail.refund_payment !=null){
          this.order_refund_status = true;
          this.paidbtntxt = 'Refunded';
        }else{
          this.order_refund_status = false;
          this.paidbtntxt = 'Paid';
        }
        this.loadodetail = true;
        $('#orderdetailspin').hide();

      }).catch(error => {
      });
    },
      refundOrderAmount(orderid,participate_id,event_id){
      if (confirm("Are you sure you want to refund?") == true) {
        $('#refundbtnid').prop('disabled',true);
        let formData = new FormData();
        formData.append("order_id", orderid);
        formData.append("participate_id", participate_id);
        formData.append("event_id", event_id);
        axios.post('/owner/refund-paymnet-to-customer',formData).then(response => {
          console.log('/owner/refund-paymnet-to-customer');
          console.log(response);
          if(response.data.response.refund_status === 400){
              alert(response.data.response.message+', Therefore, no refund is made.');
          }else{
               alert('Refund Completed!');
          }
          $('#refundbtnid').prop('disabled',false);
          $('#orderDetailModalPrimary').modal('hide');
          location.reload();
        }).catch(error => {
          console.log(error);
          alert('Something went wrong!');
          $('#refundbtnid').prop('disabled',false);
        });
      } else {

      }

    },
  cancelBooking(participate_id,event_id,order_id){
      if (confirm("Are you sure you want to cancel ?") == true) {
        $('#refundbtnid').prop('disabled',true);
        let formData = new FormData();
        formData.append("participate_id", participate_id);
        formData.append("order_id", order_id);
        formData.append("event_id", event_id);
        axios.post('/owner/cancel-booking',formData).then(response => {
          console.log('/owner/cancel-booking');
          console.log(response);
          if(response.data.response.refund_status === 200){
              alert(response.data.response.message);
          }
          $('#refundbtnid').prop('disabled',false);
          $('#orderDetailModalPrimary').modal('hide');
          location.reload();
        }).catch(error => {
          console.log(error);
          alert('Something went wrong!');
          $('#refundbtnid').prop('disabled',false);
        });
      } else {

      }

    },
  }
};
</script>
