<template>
  <div class="card card-body new-card-body-padding flex-fill w-100">
    <div class="clearfix">
      <h5 class="card-title mb-0 float-left card-rating-font-head">Reservations
        <span>({{reservationslength}})</span>
      </h5>
      <a data-toggle="modal"
        data-target="#addReservationModalPrimary" class="add-vibe mb-0 float-right"><i
          class="align-middle fas fa-fw fa-plus"></i></a>
    </div>

    <!-- <div v-if="resIndex <= reservations.length" v-for="resIndex in reservationToShow">
    <div>{{reservations[resIndex - 1].id}} says:</div>
    </div>
    <button @click="reservationToShow += 2">show more reviews</button> -->

    <div v-if="reservationslength > 0" class="inner-sec-reservation" >
      <!-- one-user -->
      <div  class="clearfix reservation-tag" v-if="resIndex <= reservations.length" v-for="resIndex in reservationToShow">
        <h5 class="float-left left-sec-user">

          <template v-if="reservations[resIndex - 1].user != null">
            <template v-if="reservations[resIndex - 1].user.profile_photo !='' && reservations[resIndex - 1].user.profile_photo !=null">
              <img :src="'/storage/patron_profile/'+reservations[resIndex - 1].user.profile_photo" alt="">
            </template>
            <template v-else>
              <img :src="reservations[resIndex - 1].user.avatar" alt="">
            </template>
            {{reservations[resIndex - 1].user.name}}
          </template>
          <template v-else>
            <img src="https://gravatar.com/avatar/20abecc84972ab4aaec8d19a11e17b46?s=80&d=mp" alt="">{{reservations[resIndex - 1].customer_name}}
          </template>
          <div class="time-resarvation">{{reservations[resIndex - 1].reservationHumanReadableDate}} <br/>{{reservations[resIndex - 1].reservationHumanReadableTime}}</div>
        </h5>
        <div class="delete-action" style="float: right;margin-top:1em;color: #ff5802;" @click="DeleteReservation(reservations[resIndex - 1].id, resIndex)"><i class="align-middle fas fa-fw fa-trash-alt"></i></div>
        <span class="float-right left-sec-user">{{reservations[resIndex - 1].reservationHumanReadable}}
              <div class="user-count-number">
              <img src="/img/frontend/user-count.png" alt="Party">{{reservations[resIndex - 1].no_of_seats}}
            </div>
        </span>

      </div>

      <div v-if="reservationToShow < reservationslength || reservationslength > reservationToShow" class="view-reservation-all-tbn">
        <a @click="reservationToShow += 5">View All</a>
      </div>

    </div>
    <div v-else>
      <div class="clearfix yes-price-tag"><h5 class="float-left">
            No Reservations...
          </h5> </div>
    </div>


    <div class="modal fade" id="addReservationModalPrimary" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title add-employee-popup">Make Reservation</h5>
            <button type="button" class="close" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form method="POST" id="addres-form" name="addresfrm" @submit.prevent="submitReservation"  data-parsley-validate >
          <div class="m-5">
            <label>Customer Name</label>
            <input type="text" class="form-poup" v-model="fields.customer_name" placeholder="Customer Name" required>
            <label>Select Date</label>
            <b-form-datepicker :min="current_date" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="fields.r_date" class="form-poup" required></b-form-datepicker>
            <!-- <input type="date" :min="current_date" class="form-poup" v-model="fields.r_date" placeholder="07/19/2021" required> -->
            <label>Select Time</label><br/>
            <vue-timepicker hide-clear-button v-model="fields.r_time" format="H:mm"></vue-timepicker><br/>
            <small class="text-success">Format should be: HH:MM</small>
            <br/>
            <label>No. of Seats</label>
            <input type="text" class="form-poup" v-model="fields.no_of_seats" placeholder="1" required>

            <button class="btn add-emp-btn" @click.prevent="addReservation()" id="save_res">Save</button>
          </div>
          <div class="row text-center">
            <div v-if="errors" class="text-danger col-12">
              <div v-for="(v, k) in errors" :key="k">
                <p v-for="error in v" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>
            </div>
            <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
              <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                {{ problem_in_save_error }}
              </p>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

    export default {
      props: ['restaurant_id','current_date'],
      data() {
          return {
            roptions:{
                height:'200px',
                color: '#e9986f',
				        size: '10px',
                position: 'right',
                distance:'5px'
            },
            fields: {
              customer_name:'',
              reservation_id:'',
              r_time:'',
              r_date:'',
              restaurant_id:this.restaurant_id,
              no_of_seats:1
            },
            errors: {},
            iserrors: false,
            success: false,
            isLoading: false,
            loading:false,
            problem_in_save_error:'',
            reservations: {},
            reservationslength:0,
            reservationToShow:5
          }
      },

      created: function () {
        this.list();
      },
      mounted() {
        let vm = this;
        Echo.channel('make-reservation.'+vm.restaurant_id)
        .listen('makeReservation', (e) => {
            vm.reservations.unshift(e.reservation_data.reservation_data);
            vm.reservationslength = parseInt(vm.reservationslength) + 1;
        });
      },
      methods:{
        list(){
          axios.get('/owner/reservation/list').then(response => {
          this.reservations = response.data.response.list;
          this.reservationslength = this.reservations.length;
          }).catch(error => {

          });
        },
        addReservation(){
          let vm = this;
          $('#addres-form').parsley().whenValidate({

          }).done(function() {
          vm.submitReservation();
          });
        },
        submitReservation(){
          let vm = this;
          $('#save_res').prop('disabled',true);
            vm.success = false;
            vm.errors = {};
            axios.post('/owner/reservation/make-reservation', vm.fields).then(response => {
              vm.fields = {}; //Clear input fields.
              vm.fields.r_time = '';
              vm.success = true;
              $("#addReservationModalPrimary .close").click();
              toastr.success('Reservation saved successfully.');
              $('#save_res').prop('disabled',false);
            }).catch(error => {
              $('#save_res').prop('disabled',false);
              if (error.response.status === 400) {
                vm.problem_in_save_error = error.response.data.response.message || {};
              }
              else if (error.response.status === 422) {
                vm.errors = error.response.data.errors;
              }else{
                vm.errors = error.response.data.response.message || {};
              }
            });
        },
        DeleteReservation(reservation_id, index){
          if(confirm('Do you really want to delete ?')){
            let vm = this;
          vm.fields.reservation_id = reservation_id;
          $('.delete-action').prop('disabled',true);
            vm.success = false;
            vm.errors = {};
            axios.post('/owner/reservation/delete-reservation', vm.fields).then(response => {
              // vm.reservations.splice(index,1)
              vm.success = true;
              toastr.success('Reservation deleted successfully.');
              location.reload()
            }).catch(error => {
              $('#save_res').prop('disabled',false);
              if (error.response.status === 400) {
                vm.problem_in_save_error = error.response.data.response.message || {};
              }
              else if (error.response.status === 422) {
                vm.errors = error.response.data.errors;
              }else{
                vm.errors = error.response.data.response.message || {};
              }
            });

          }
        }

      }
    }

</script>
