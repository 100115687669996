<template>
  <div id="barmenu" class="container tab-pane fade">
    <div class="clearfix table-card-title-head">
      <h5 class="card-title mb-0 float-left table-card-title">
        <strong>From Tables</strong>
      </h5>
    </div>
    <div class="row bartabcard" id="bar-table-cards">
      <span class="loader" >
      <span class="content  text-center"><i class="fa fa-spinner fa-spin"></i> Please wait...</span>
      </span>
      <div v-if="orderslength == 0" class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="card card-block d-flex table-card no-order" >
          <div class="card-body align-items-center d-flex justify-content-center empty_order_text_bar">
            Loading...
          </div>
        </div>
      </div>
      <!-- table -->
      <div v-for="(item, index) in orders" class="col-12 col-md-6 col-lg-6 col-xl-4" >
        <div class="table-card c-table-card">
          <div class="user-headeading-booking clearfix">
            <div class="float-left">
              <div v-if="item.rtable.employees.length > 0">
                <div v-for="emp in item.rtable.employees" class=" pl-2">
                    <template v-for="empval in emp.restraurant_employee_attributes" >
                      <template v-if="empval.name == 'profile_photo'">
                        <img v-if="empval.pivot.value!=''" :src="'/storage/employee_pictures/'+empval.pivot.value" alt="">
                        <img v-if="empval.pivot.value==''" src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" alt="">
                      </template>
                    </template>
                    <a href="#">{{emp.user.name}}</a>
                </div>
              </div>
            </div>
            <div class="float-right table-number">
              <span :class="`table-c-${item.tblloop}`">{{item.rtable.name}}</span>
            </div>
          </div>
          <hr>
          <div class="" style="height:200px; overflow-y:auto !important">
            <div class="outer-food-details">
              <div class="" v-for="(sitem,sindex) in item.seats">
                <span class="badge badge-secondary">Seat {{sitem.seat}}</span><hr/>
                <div v-if="sitem.cart_items.length > 0 " class="">
                  <div v-for="(citem,cindex) in sitem.cart_items" class="row">
                    <div class="col-sm-7 col-7">
                      <div class="order-name">
                        <span>{{ citem.quantity }}</span>
                        <p>{{citem.menuitem.title}}
                          <br>
                            <span title="click to see special request" style="cursor: pointer;color:#ff5802;font-size: 12px;" @click="getSpecialRequest(citem)" v-if="citem.other_data">{{ (citem.other_data).substring(0,8)+"..." }}</span>
                        </p>

                      </div>
                    </div>
                    <div class="col-sm-5 col-5" style="padding-left: 0;">
                      <div class="item-price">
                        <span>$ {{citem.quantity * citem.menuitem.price}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- buttons-->
          <div  class="mt-2">
            <div  class="total-timing-price clearfix">
              <div class="float-left left-timing">
                <i class="align-middle  far fa-fw fa-clock" ></i><a :id="'bartimer_clk_'+item.id">
                  {{callbartablescantimer(item.created_at,'bartimer_clk_'+item.id)}}
                </a>
              </div>
              <div v-if="item.carttotalp > 0 " class="float-right total-price-number">
                <span>$ {{parseFloat(item.carttotalp).toFixed(2)}}</span>
              </div>
            </div>
            <div class="outer-btn-edit-close">
              <div class="row">
                <div  class="col-sm-12 ">
                  <button v-if="item.carttotalp > 0 && item.customer_menus_bar_items_not_completed.length > 0" :id="'bar_complete_btn_'+index" class="btn bar_complete_btn" @click="completeBarOrders(item.seats,index)">
                    Complete</button>
                    <button v-if="item.customer_menus_bar_items_not_completed.length == 0" class="btn bar_completed_btn" >
                      <i class="fa fa-check" aria-hidden="true"></i> Completed</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <!--end table-->
  </div>

  <div class="clearfix table-card-title-head">
      <h5 class="card-title mb-0 float-left table-card-title">
        <strong>At The Bar</strong>
      </h5>
  </div>
  <div class="row directbartabcard">
    <span class="loader" >
      <span class="content  text-center"><i class="fa fa-spinner fa-spin"></i> Please wait...</span>
    </span>
    <div v-if="directorders.length == 0" class="col-12 col-md-6 col-lg-6 col-xl-4">
      <div class="card card-block d-flex table-card no-order" >
        <div class="card-body align-items-center d-flex justify-content-center empty_order_text_bar">
          Loading...
        </div>
      </div>
    </div>
    <!-- direct tables -->
    <div v-if="directorders.length > 0" v-for="(ditem,dindex) in directorders" class="col-12 col-md-6 col-lg-6 col-xl-4" >
      <div class="table-card c-table-card">
        <div class="user-headeading-booking clearfix">
          <div class="float-left">
            {{ditem.seat_info}}
          </div>
          <div class="float-right card-number">
            <span v-if="!!ditem.card_number">**{{ ditem.card_number.slice(ditem.card_number.length - 4) }}</span>
          </div>
        </div>
        <hr>
        <div class="" style="height:200px; overflow-y:auto !important">
          <div class="outer-food-details" >
            <div v-if="ditem.bar_cart_menu_items.length > 0 " class="">
              <div v-for="(baritem,index) in ditem.bar_cart_menu_items" class="row">
                <div class="col-sm-7 col-7">
                  <div class="order-name">
                    <span>{{ baritem.quantity }}</span>
                    <p>{{baritem.menuitem.title}}</p>
                  </div>
                </div>
                <div class="col-sm-5 col-5" style="padding-left: 0;">
                  <div class="item-price">
                    <span>$ {{baritem.quantity * baritem.menuitem.price}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- buttons-->
        <div  class="mt-2">
          <div  class="total-timing-price clearfix">
            <div class="float-left left-timing">
              <i class="align-middle  far fa-fw fa-clock" ></i>
              <a :id="'dbartimer_clk_'+ditem.id">
                    {{callbartablescantimer(ditem.created_at,'dbartimer_clk_'+ditem.id)}}
              </a>
            </div>
            <div  class="float-right total-price-number">
              <span>$ {{parseFloat(ditem.seat_total).toFixed(2)}}</span>
            </div>
          </div>
          <div class="outer-btn-edit-close">
            <div class="row">
              <div class="col-sm-6 col-6 innterbtn-edit">
                <button class="btn" @click="edit_modal_at_bar_order(ditem)"><i class="align-middle  far fa-fw fa-edit"></i>Edit</button>
              </div>
              <div v-if="ditem.seat_total > 0" class="col-sm-6 col-6 innterbtn-edit" style="padding-left: 0px;">
                <button class="btn" @click="close_out_seat_at_bar(ditem.id)"><i class="align-middle  far fa-fw fa-window-close"></i>Close Out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end tables-->
    <!-- add new table at bar-->
    <div class="col-12 col-md-6 col-lg-6 col-xl-4" @click="addDirectOrder()">
      <div class="table-card plus-card">
        <div><i class="align-middle mr-2 fas fa-fw fa-plus"></i></div>
      </div>
    </div>
  </div>

  <!--modals-->
  <div class="modal fade"   id="showBarTableModal" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header padding-header">
          <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="m-3" id="addseatatbar_header">
          <div class="header-add-seat">
            <div class="new-left-sec-with-avtar">
              <span v-if="newseat_number !=''">{{newseat_number}}</span>
              <h2 class="ml-2">{{newseat_info}}</h2>
            </div>
            <div class="seat-info-btn"><button @click="setup_seat_info()">Seat Info</button></div>
          </div>
        </div>
        <div class="showSeatInfoModal" id="showSeatInfoModal">
          <div class="m-3">
            <div class="header-add-seat-info">
              <form method="POST" id="adddirectorderfrm" name="adddirectorderfrm" @submit.prevent="save_seat_info">
                <div class="row">
                  <div class="col-sm-3">
                    <label for="">Seat Number</label>
                      <input type="text" v-model="direct_order_seatinformation.seat_number" class="form-control" required>
                    </div>
                    <div class="col-sm-9">
                      <label for="">Seat name or description</label>
                      <input type="text" v-model="direct_order_seatinformation.seat_info" class="form-control" required>
                    </div>
                </div>
                <div class="row">
                  <div class="col-sm-5">
                    <label for="">Card Number</label>
                    <input type="text" v-model="direct_order_seatinformation.card_number" data-parsley-required-message="Please enter card number"
                      pattern="^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$" maxlength="16"  class="form-control" >
                  </div>
                  <div class="col-sm-3">
                    <label for="">Expiration</label>
                    <input type="text" v-model="direct_order_seatinformation.card_expiry" class="form-control"  v-on:keyup="addSlashPattern($event)" pattern="^(0[1-9]|1[0-2])\/?([0-9]{2})$" maxlength="5" data-parsley-required-message="Please enter card Expiration" >
                  </div>
                  <div class="col-sm-4">
                    <label for="">Billing Zipcode</label>
                    <input type="text" v-model="direct_order_seatinformation.billing_zipcode"
                    data-parsley-required-message="Please enter postal code" maxlength="5"
                    data-parsley-validation-threshold="1" data-parsley-trigger="keyup"
                    data-parsley-type="digits" class="form-control">
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <button type="button" name="button" class="save_seat_info_btn" @click.prevent="validateDirectOrder()" id="add_direct_order_btn">Save</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="bar-add-order-section" v-if="seatinfoid > 0">
          <div class="clearfix bar-direct-order-name-inner">
            <h3 class="float-left">Order</h3>
            <div class="float-right"><a title="Update" @click.prevent="addItemAtDirectBarOrder()"><i class="fas fa-plus"></i></a></div>
          </div>
          <div class="new-seat-table-collection">
            <div class="new-seat-table-section"  v-if="newseatitems.length > 0">
              <div v-for="(item,index) in newseatitems" class="order-name-edit-outer">
                  <div>
                    <div  class="clearfix top-order-name-inner" style="border: medium none;">
                      <div class="float-left">
                        <h3>{{item.quantity}} {{item.menuitem.title}}</h3>
                        <p>{{item.menuitem.description}}</p>
                      </div>
                      <div class="float-right d-flex order-name-edit-price">
                        <div class="price-total">$ {{item.menuitem.price * item.quantity}}</div>
                        <a title="Update" @click.prevent="edit_new_seat_item(item,newseatitems,index)"><i class="fas fa-pencil-alt"></i></a>
                        <a title="Remove" @click.prevent="remove_new_seat_item(item.id,newseatitems,index)"><i class="fas fa-trash"></i></a>
                      </div>
                   </div>
                 </div>
              </div>
            </div>
            <div class="taxes-and-fee-price" v-if="newseatitems.length > 0">
              <div class="taxes-and-fee-price-inner">
                <div class="clearfix"><h3 class="float-left">Taxes & Fees</h3>
                  <span class="float-right">${{parseFloat(taxamt).toFixed(2)}}</span>
                </div>
              </div>
              <div class="taxes-and-fee-price-inner">
                <div class="clearfix"><h3 class="float-left">Service Charge</h3>
                  <span class="float-right">${{parseFloat(platformfee).toFixed(2)}}</span>
                </div>
              </div>
              <div class="taxes-and-fee-price-inner-total">
                <div class="clearfix">
                  <h3 class="float-left">Total</h3>
                  <span class="float-right">${{parseFloat(new_seat_order_total).toFixed(2)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--seat info modal-->

  <!--edit seat items-->
  <div class="modal fade"   id="showBarTableModalEdit" tabindex="-1" role="dialog"  aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header padding-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="m-3" id="addseatatbar_header">
            <div class="header-add-seat">
              <div class="new-left-sec-with-avtar">
                <span >{{at_bar_seat_number}}</span>
                <h2 class="ml-2">{{at_bar_seat_info}}</h2>
              </div>
              <div class="seat-info-btn"><button @click="edit_seat_info()">Seat Info</button></div>
            </div>
          </div>
          <div class="showSeatInfoModal" id="showSeatInfoModalEdit" style="display:none">
            <div class="m-3">
              <div class="header-add-seat-info">
                <form method="POST" id="editdirectorderfrm" name="editdirectorderfrm" >
                  <div class="row">
                    <div class="col-sm-3">
                      <label for="">Seat Number</label>
                      <input type="text" v-model="edit_direct_order_seatinfo.seat_number" class="form-control" required>
                    </div>
                    <div class="col-sm-9">
                      <label for="">Seat name or description</label>
                      <input type="text" v-model="edit_direct_order_seatinfo.seat_info" class="form-control" required>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-5">
                      <label for="">Card Number</label>
                      <input type="text" v-model="edit_direct_order_seatinfo.card_number" data-parsley-required-message="Please enter card number"
                      pattern="^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$" maxlength="16"  class="form-control" required>
                    </div>
                    <div class="col-sm-3">
                      <label for="">Expiration</label>
                      <input type="text" v-model="edit_direct_order_seatinfo.card_expiry" class="form-control"  v-on:keyup="addSlashPattern($event)" pattern="^(0[1-9]|1[0-2])\/?([0-9]{2})$" maxlength="5" data-parsley-required-message="Please enter card Expiration" required>
                    </div>
                    <div class="col-sm-4">
                      <label for="">Billing Zipcode</label>
                      <input type="text" v-model="edit_direct_order_seatinfo.billing_zipcode"
                      data-parsley-required-message="Please enter postal code" maxlength="5"
                      data-parsley-validation-threshold="1" data-parsley-trigger="keyup"
                      data-parsley-type="digits" class="form-control">
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <button type="button" name="button" class="save_seat_info_btn" @click.prevent="validate_seat_info()" id="edit_direct_order_btn">Save</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="bar-add-order-section">
            <div class="clearfix bar-direct-order-name-inner">
              <h3 class="float-left">Order</h3>
              <div class="float-right"><a title="Update" @click.prevent="addItemAtDirectBarOrder()"><i class="fas fa-plus"></i></a></div>
            </div>
            <div class="new-seat-table-collection">
              <div class="new-seat-table-section">
              <div class="order-name-edit-outer">
                  <div>
                    <div v-for="(item,index) in seat_items_array" class="clearfix top-order-name-inner" style="border: medium none;">
                      <div class="float-left">
                        <h3>{{item.quantity}} {{item.menuitem.title}}</h3>
                        <p>Drinks</p>
                      </div>
                      <div class="float-right d-flex order-name-edit-price">
                        <div class="price-total">$ {{item.quantity * item.menuitem.price}}</div>
                        <a title="Update" @click.prevent="editItemAtBar(item,seat_items_array,index)"><i class="fas fa-pencil-alt"></i></a>
                        <a title="Remove" @click.prevent="removeItemAtBar(item.id,seat_items_array,index)"><i class="fas fa-trash"></i></a>
                      </div>
                   </div>
                 </div>
              </div>
              </div>

              <div class="taxes-and-fee-price">
                <div class="taxes-and-fee-price-inner">
                  <div class="taxes-and-fee-price-inner">
                    <div class="clearfix"><h3 class="float-left">Taxes & Fees</h3>
                      <span class="float-right">${{parseFloat(seat_items_array.taxamt).toFixed(2)}}</span>
                    </div>
                  </div>
                  <div class="taxes-and-fee-price-inner">
                    <div class="clearfix"><h3 class="float-left">Service Charge</h3>
                      <span class="float-right">${{parseFloat(seat_items_array.platformfee).toFixed(2)}}</span>
                    </div>
                  </div>

                  <div class="taxes-and-fee-price-inner-total">
                    <div class="clearfix">
                      <h3 class="float-left">Total</h3>

                      <span class="float-right">${{parseFloat(seat_items_array.seat_total).toFixed(2)}}</span>
                    </div>
                  </div>
                  <div class="taxes-and-fee-buttons">
                    <div class="clearfix">
                      <div class="">
                        <button @click="close_out_seat_at_bar(edit_seat_id)" class="btn close-out-direct-order"><img src="/img/frontend/close-icon.PNG">Close Out</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
  </div>

    <!-- view menu category list modal-->
    <div class="modal fade" id="addMenuItemAtBarModal" tabindex="-1" role="dialog"
      aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="m-4 tab-pane handeld-popup-relative active">
              <div class="nav handhelds-menus  poup-nav-hanhelds-menu">
                  <div class="nav-item" v-for="(item,index) in menu_categories.slice(0,5)"  @click="showMenuItems($event,item.id)">
                    <a class="nav-link" :class="{ active: (activeTabInline == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                  </div>
                   <div class="nav-item menu-list-handheld" @click="toggle = true">
                      <a class="nav-link" id="final_draft"><i class="fas fa-align-justify"></i></a>
                    </div>
              </div>
                <div class="onclick-show-menu-handeld onclick_menu" id="mobile_toggle_nav" v-show="toggle">
                  <ul class="nav flex-column">
                    <div class="close-menu" id="close_toggle_nav" @click="toggle = false">&times;</div>
                    <li class="nav-item" v-for="(item,index) in menu_categories" @click="showMenuItems($event,item.id)">
                      <a @click="toggle = false" class="nav-link " :class="{ active: (activeTab == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                    </li>
                  </ul>
                </div>
              <div class="outer-menu-list-price order_add_list">
                <h3>{{activeMenuTitle}}</h3>
                <div v-if="load_menu_items" class="d-flex justify-content-center">
                  <div class="patron-loader spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="menu-list-price" v-for="(item,index) in menu_items" v-if="item.inventory > 0"  @click="showMenuItem(item)">
                  <div class="clearfix" >
                   <img style="width: 70px;height:60px; float:left" v-if="item.images"  :src="'/storage/menu_item_images/thumbnail/'+item.images" :alt="item.title">
                      <h4 class="float-left" style="margin-left:10px;">{{item.title}}</h4>
                      <br> <p style="margin-left:80px !important;">{{item.description}}</p>
                      <span class="float-right">${{item.price}}</span>
                  </div>

                </div>
                <div v-if="menu_items.length == 0" class="empty_menu empty_msg">No menu items found.</div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <!-- viw menu item modal-->
    <div class="modal fade" id="viewBarItem" tabindex="-1" role="dialog"
      aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content" style="width: 595px;">
            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          <form method="POST" id="add_order_at_bar_frm" name="add_order_at_bar_frm" >
          <div class="order_menu_item">
            <img style="width: 570px;" v-if="at_bar_new_menu_item.image"  :src="'/storage/menu_item_images/original/'+at_bar_new_menu_item.image" :alt="at_bar_new_menu_item.title">
            <h4>${{at_bar_new_menu_item.price}}</h4>
            <h4>{{at_bar_new_menu_item.title}}</h4>
            <p>
              {{at_bar_new_menu_item.description}}
            </p>
            <label>Special Requests?</label>
            <textarea placeholder="What are your requests?" class="form-poup" v-model="at_bar_new_menu_item.specialrequest" ></textarea>
            <div class="qty-text-sec">
              <div class="row">
                <div class="col-2">
                  <span>Qty</span>
                </div>
                <div class="col-5">
                  <input type="text" id="qty" class="qtyform-poup" v-model="at_bar_new_menu_item.quantity" min="1"  required=""
                  data-parsley-validation-threshold="1" data-parsley-trigger="keyup" data-parsley-type="digits">
                </div>
                <div class="col-5 button-service">
                  <span ><i class="fa fa-minus"  v-on:click="decrease_new"></i></span>
                  <span><i class="fa fa-plus"    v-on:click="increase_new"></i></span>
                </div>
              </div>
            </div>
            <div>
              <button  class="btn add-btn-order" id="add_order_at_bar" @click.prevent="ValidateAtBarOrder(seatinfoid)">Add to order</button>
            </div>
          </div>
          <div class="mb-2 text-center">
              <div v-if="validation_errors" class="text-danger text-danger text-center">
                <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                    {{v[0]}}
                </p>
              </div>
              <div v-if="error" class="text-danger text-danger text-center">
                <p v-if="error" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>
              <div v-if="errors" class="text-danger text-danger text-center">
                <div v-for="(v, k) in errors" :key="k">
                  <p v-for="error in v" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
              </div>
          </div>
          </form>
        </div>
      </div>
    </div>
<!-- model list of special request start -->

      <div class="modal fade" id="specialrequest_model4" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-header" style="background-color: #4256ce;">
                <h4 class="modal-title" style="color: #fff;">Special Requests:</h4></div>
            <div class="modal-body" v-if="sprequest">
              <ul class="list-group">
                  <li class="list-group-item">{{sprequest}}</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- model list of special request end -->
    <!--edit item of new seat-->
    <div class="modal fade" id="editNewBarMenuItem" tabindex="-1" role="dialog"
      aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          <form method="POST" id="editNewBatorderfrm" name="editNewBatorderfrm" >
          <div class="order_menu_item">
            <h4>${{edit_new_bar_menu.menu_item.price}}</h4>
            <h4>{{edit_new_bar_menu.menu_item.title}}</h4>
            <p>
              {{edit_new_bar_menu.menu_item.description}}
            </p>
            <label>Special Requests?</label>
            <textarea placeholder="What are your requests?" class="form-poup" v-model="edit_new_bar_menu.menu_item.specialrequest" ></textarea>
            <div class="qty-text-sec">
              <div class="row">
                <div class="col-2">
                  <span>Qty</span>
                </div>
                <div class="col-5">
                  <input type="text" id="qty" class="qtyform-poup" v-model="edit_new_bar_menu.menu_item.quantity" min="0"  required=""
                   data-parsley-trigger="keyup" data-parsley-type="digits">
                </div>
                <div class="col-5 button-service">
                  <span ><i class="fa fa-minus"  v-on:click="decrease_new_seat"></i></span>
                  <span><i class="fa fa-plus"    v-on:click="increase_new_seat"></i></span>
                </div>
              </div>
            </div>
            <div>
              <button @click.prevent="validateBarEditItemNew()" class="btn add-btn-order" id="edit_new_bar_item" >Update</button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>

    <!-- edit menu modal -->
    <div class="modal fade" id="editBarMenuItem" tabindex="-1" role="dialog"
      aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          <form method="POST" id="editBatorderfrm" name="editBatorderfrm" @submit.prevent="saveEditBarItem" >
          <div class="order_menu_item">

            <h4>${{edit_bar_menu.menu_item.price}}</h4>
            <h4>{{edit_bar_menu.menu_item.title}}</h4>
            <p>
              {{edit_bar_menu.menu_item.description}}
            </p>
            <label>Special Requests?</label>
            <textarea placeholder="What are your requests?" class="form-poup" v-model="edit_bar_menu.menu_item.specialrequest" ></textarea>
            <div class="qty-text-sec">
              <div class="row">
                <div class="col-2">
                  <span>Qty</span>
                </div>
                <div class="col-5">
                  <input type="text" id="qty" class="qtyform-poup" v-model="edit_bar_menu.menu_item.quantity" min="0"  required=""
                   data-parsley-trigger="keyup" data-parsley-type="digits">
                </div>
                <div class="col-5 button-service">
                  <span ><i class="fa fa-minus"  v-on:click="decrease"></i></span>
                  <span><i class="fa fa-plus"    v-on:click="increase"></i></span>
                </div>
              </div>
            </div>
            <div>
              <button @click.prevent="validateBarEditItem()" class="btn add-btn-order" id="edit_bar_item" >Update</button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
    <!-- add new menu modal -->
    <div class="modal fade" id="addNewBarMenuItemModal" tabindex="-1" role="dialog"
      aria-hidden="true" data-backdrop="false">
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <button type="button" class="close handheld-close-popup" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="tab-pane handeld-popup-relative active">
              <div class="nav handhelds-menus">
                  <div class="nav-item" v-for="(item,index) in menu_categories.slice(0,5)"  @click="showBarMenuItems($event,item.id)">
                    <a class="nav-link" :class="{ active: (activeTabInline == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                  </div>
              </div>
              <div class="outer-menu-list-price">
                <h3>{{activeMenuTitle}}</h3>
                <div v-if="load_menu_items" class="d-flex justify-content-center">
                  <div class="patron-loader spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div class="menu-list-price" v-for="(item,index) in menu_items" v-if="item.inventory > 0"  @click="showBarMenuItem(item)">
                  <div class="clearfix" >
                      <h4 class="float-left">{{item.title}}</h4>
                      <span class="float-right">${{item.price}}</span>
                  </div>
                  <p>{{item.description}}</p>
                </div>
                <div v-if="menu_items.length == 0" class="empty_menu empty_msg">No menu items found.</div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  </div>
  </template>

<script>
export default {
props: ['restaurant_id'],
data() {
    return {
      toggle: false,
      orderslength:0,
      sprequest:'',
      activeTabInline:null,
      activeTab: null,
      activeMenuTitle:null,
      load_menu_items:false,
      menu_items:{},
      menu_categories:[],
      orders:[],

      cartitemtotal:'',
      orderitemtotal:'',


      edit_bar_menu:{
        menu_item:{
          title:'',
          description:'',
          price:'',
          quantity:1,
          user_id:'',

        },
        menu_item_arry:'',
        cartItms:'',
        menuItmInx:'',

      },
      edit_new_bar_menu:{
        menu_item:{
          title:'',
          description:'',
          price:'',
          quantity:1,
          user_id:'',

        },
        menu_item_arry:'',
        cartItms:'',
        menuItmInx:'',
      },

      at_bar_new_menu_item:{
        title:'',
        description:'',
        specialrequest:'',
        price:'',
        image:'',
        quantity:1,
        user_id:''
      },

      validation_errors:{},
      error:'',
      errors:{},


      direct_order_seatinfo:{
        seat_number:'',
        seat_info:'',
        card_number:'',
        card_expiry:'',
        billing_zipcode:'',
        restaurant_id:this.restaurant_id
      },
      direct_order_seatinformation:{},
      newseat_number:'',
      newseat_info:'New Seat',


      directorders:{},
      seatinfoid:'',
      seat_items_array:{},

      edit_direct_order_seatinfo:{
        seat_number:'',
        seat_info:'',
        card_number:'',
        card_expiry:'',
        billing_zipcode:'',
        restaurant_id:this.restaurant_id,
        id:''
      },

      at_bar_seat_number:'',
      at_bar_seat_info:'',
      edit_seat_id:'',
      newseatitems:[],
      new_seat_order_total:0,
      existing_seat:"no",
      taxamt:0,
      platformfee:0,
    }
},

created: function () {
  this.getRestaurantCurrentBarOrders();
  this.orders_at_bar();
},
mounted() {
  let vm = this;
  Echo.channel('table-qr-scan.'+vm.restaurant_id)
  .listen('TableOccupied', (e) => {
    vm.getRestaurantCurrentBarOrders();

  });
  Echo.channel('item-confirmed-in-cart.'+vm.restaurant_id)
  .listen('ConfirmCartItem', (e) => {
        vm.getRestaurantCurrentBarOrders();
  });
  Echo.channel('order-placed.'+vm.restaurant_id)
  .listen('OrderPlaced', (e) => {
        vm.getRestaurantCurrentBarOrders();
  });
  Echo.channel('item-or-seatitem-changes.'+vm.restaurant_id)
  .listen('CartItemOrSeatUpdated', (e) => {
        vm.getRestaurantCurrentBarOrders();
  });

},
methods:{

  getRestaurantCurrentBarOrders(){
    let formData = new FormData();
    formData.append("restaurant_id", this.restaurant_id);
    axios.post('/owner/orders/current_bar_orders',formData).then(response => {
      this.orders = response.data.response.table_list.table_rows;
      this.orderslength = response.data.response.totl_tbls;
      if(this.orderslength == 0){
        $('.empty_order_text_bar').text('No Order Found...');
      }
    }).catch(error => {

    });
  },
  getRecentScanTableData(tid,rid){
    let formData = new FormData();
    formData.append("table_id", tid);
    formData.append("restaurant_id", rid);
    axios.post('/owner/orders/get_scan_table_detail',formData).then(response => {
      this.orders.push(response.data.response.table_row);
      this.orderslength = this.orderslength + 1;
      if(this.orderslength == 0){
        $('.empty_order_text_bar').text('No Order Found...');
      }
    }).catch(error => {

    });
  },

 padbarTime(num) {
    return ("0"+num).slice(-2);
  },
callbartablescantimer(scanDate,elmId){
      let vm = this;
      setInterval(function(){
        let startTime = moment(scanDate);
        let endTime = moment();
        let seconds = endTime.diff(startTime, 'seconds');


        let minutes = Math.floor(seconds / 60);
        seconds = seconds%60;
        let hours = Math.floor(minutes/60)
        minutes = minutes%60;

        let time = vm.padbarTime(hours)+':'+vm.padbarTime(minutes)+':'+vm.padbarTime(seconds);
          $("#"+elmId).html(time);
        }, 1000);

  },
completeBarOrders(itmtbl,tblinx){
    let vm = this;
    $('#bar_complete_btn_'+tblinx).prop('disabled',true);
    let menu_item_list_ids=[];
    itmtbl.forEach((v,i)=>{
      v.cart_items.forEach((v1,i)=>{
        menu_item_list_ids.push(v1.id);
      });
    });
    let formData = new FormData();
    formData.append("bar_item_ids", menu_item_list_ids);
    formData.append("restaurant_id", this.restaurant_id);
    axios.post('/owner/orders/complete_bar_order_items',formData).then(response => {
      $('#bar-table-cards').addClass('loading-spin');

      $('#bar_complete_btn_'+tblinx).prop('disabled',false);
      setTimeout( () => {
        $('#bar-table-cards').removeClass('loading-spin');
        this.getRestaurantCurrentBarOrders();
      }, 5000);


    }).catch(error => {
      if (error.response.status === 400) {
        alert(error.response.data.response.message);
        $('#bar_complete_btn_'+tblinx).prop('disabled',false);
      }else{
        alert("Something went wrong!");
      }
    });
  },

  /* start: at bar order realted function */
  orders_at_bar(){
    let formData = new FormData();
    formData.append("restaurant_id", this.restaurant_id);
    axios.post('/owner/orders/direct_bar_order_list',formData).then(response => {
      if(response.data.response.list.seats.length > 0){
        this.directorders = response.data.response.list.seats;

      }

    }).catch(error => {

    });
  },
  addDirectOrder(){
    $('#showBarTableModal').modal('show');
    $('#showSeatInfoModal').hide();
    $('#addseatatbar_header').show();
    this.seatinfoid = '';
    this.direct_order_seatinfo = {};
    this.direct_order_seatinformation = {};
    this.direct_order_seatinfo.restaurant_id = this.restaurant_id;
    this.newseat_number = '';
    this.newseat_info = 'New Seat';
  },
  setup_seat_info(){
    $('#showSeatInfoModal').show();
    $('#addseatatbar_header').hide();
  },
  validateDirectOrder(){
    let vm = this;
    $('#adddirectorderfrm').parsley().whenValidate({

    }).done(function() {
      vm.save_seat_info();
    });
  },
  save_seat_info(){
    let vm = this;
    let formData = new FormData();
    console.log('direct_order_seatinformation')
    console.log(this.direct_order_seatinformation)

    formData.append("seatinfo",this.direct_order_seatinformation);
    formData.append("restaurant_id", this.restaurant_id);
    axios.post('/owner/orders/save_seatinfo_direct_order_at_bar',[{seatinfo:this.direct_order_seatinformation,restaurant_id:this.restaurant_id}]).then(response => {
      $('#add_direct_order_btn').prop('disabled',true);
      $('#showSeatInfoModal').hide();
       console.log('save_seatinfo_direct_order_at_bar')
       console.log(response.data.response)
      vm.newseat_number = response.data.response.seatinfo.seat_number;
      vm.newseat_info = response.data.response.seatinfo.seat_info;
      $('#addseatatbar_header').show();
      $('#add_direct_order_btn').prop('disabled',false);
      vm.seatinfoid = response.data.response.seatinfo.id;

      vm.orders_at_bar();

    }).catch(error => {
      $('#add_direct_order_btn').prop('disabled',false);
      console.log('error.response.status')
      console.log(error.response.status)
      if (error.response.status === 400) {
        alert(error.response.data.response.message);
          $('#add_direct_order_btn').prop('disabled',false);
      }else{
        alert("Something went wrong!");
      }
    });
  },
  addItemAtDirectBarOrder(){
    let vm = this;
    $('#addMenuItemAtBarModal').modal('show');
    let formData = new FormData();
    //formData.append("menu_type", 'Drinks');
    formData.append("restaurant_id", vm.restaurant_id);
    axios.post('/owner/orders/get-menu-categories',formData).then(response => {
       vm.menu_categories = response.data.response.categories;
       vm.activeTab = response.data.response.categories[0].id;
       vm.activeTabInline = response.data.response.categories[0].id;
       vm.activeMenuTitle = response.data.response.categories[0].title;
       formData.append("menu_category_id", response.data.response.categories[0].id);
       axios.post('/owner/orders/get-restaurant-menu-items',formData).then(response => {
           vm.menu_items = response.data.response.menu_items;
       });
    }).catch(error => {
    });
  },
  showMenuItems: function (event,menucatid) {
    this.load_menu_items = true;
    this.menu_items = {};
    this.activeMenuTitle = event.target.getAttribute('data-menu');
    this.activeTab = menucatid;
    this.activeTabInline = menucatid;
    let menuData = new FormData();
    menuData.append("menu_category_id", menucatid);
    axios.post('/owner/orders/get-restaurant-menu-items',menuData).then(response => {
      this.load_menu_items = false;
      this.menu_items = response.data.response.menu_items;
      $('#close_toggle_nav').click();
    }).catch(error => {
    });
  },
  showMenuItem: function (item) {

    let vm = this;
    $('#viewBarItem').modal('show');
    vm.at_bar_new_menu_item.title = item.title;
    vm.at_bar_new_menu_item.description = item.description;
    vm.at_bar_new_menu_item.price = item.price;
    vm.at_bar_new_menu_item.id = item.id;
    vm.at_bar_new_menu_item.image = item.images;
    vm.at_bar_new_menu_item.specialrequest = '';

  },
  ValidateAtBarOrder(seataddedid) {
    let vm = this;
    $('#add_order_at_bar_frm').parsley().whenValidate({

    }).done(function() {
      vm.add_order_at_bar(seataddedid);
    });
  },
  add_order_at_bar: function (seataddedid) {
    console.log('this.at_bar_new_menu_item');
    console.log(this.at_bar_new_menu_item);
    let vm = this;
    $('#add_order_at_bar').prop('disabled',true);
    let menuData = new FormData();
    menuData.append("restaurant_id", vm.restaurant_id);
    menuData.append("menu_item_id", vm.at_bar_new_menu_item.id);
    menuData.append("quantity", vm.at_bar_new_menu_item.quantity);
    menuData.append("specialrequest", this.at_bar_new_menu_item.specialrequest);
    menuData.append("seat_at_bar_id", vm.seatinfoid);
    axios.post('/owner/orders/add-order-at-bar',menuData).then(response => {
      $('#add_order_at_bar').prop('disabled',false);
      $('#viewBarItem').modal('hide');

      if(vm.existing_seat == 'yes'){
        vm.seat_items_array.push(response.data.response.added_item);
        vm.seat_items_array.seat_total = response.data.response.seat_order_total;
      }else{
        vm.at_bar_new_menu_item.specialrequest = '';
        vm.at_bar_new_menu_item.quantity = 1;
        vm.newseatitems.push(response.data.response.added_item);
        vm.new_seat_order_total = response.data.response.seat_order_total;
        vm.taxamt = response.data.response.taxamt;
        vm.platformfee = response.data.response.platformfee;
      }

      vm.orders_at_bar();
    }).catch(error => {
      $('#add_order_at_bar').prop('disabled',false);
    });

  },
  increase_new: function() {
    let vm = this;
    vm.at_bar_new_menu_item.quantity++;
  },
  decrease_new:  function() {
    let vm = this;
    if (vm.at_bar_new_menu_item.quantity > 1) {
        vm.at_bar_new_menu_item.quantity--;
    }
  },
  /*edit item of new  seat*/
  edit_new_seat_item(itmmenu,cartItms,menuItmInx){
    let vm = this;
    $('#editNewBarMenuItem').modal('show');
    vm.edit_new_bar_menu.menu_item.title = itmmenu.menuitem.title;
    vm.edit_new_bar_menu.menu_item.description = itmmenu.menuitem.description;
    vm.edit_new_bar_menu.menu_item.price = itmmenu.menuitem.price;
    vm.edit_new_bar_menu.menu_item.quantity = itmmenu.quantity;
    vm.edit_new_bar_menu.menu_item.id = itmmenu.id;

    //vm.edit_bar_menu.menuItmInx = menuItmInx;

   vm.edit_new_bar_menu.menu_item_arry = itmmenu;
   vm.edit_new_bar_menu.cartItms = cartItms;
   vm.edit_new_bar_menu.menuItmInx = menuItmInx;
  },
  increase_new_seat: function() {
    let vm = this;
    vm.edit_new_bar_menu.menu_item.quantity++;
  },
  decrease_new_seat:  function() {
    let vm = this;
    if (vm.edit_new_bar_menu.menu_item.quantity > 0) {
        vm.edit_new_bar_menu.menu_item.quantity--;
    }

  },
  validateBarEditItemNew(itmmenu){
    let vm = this;
    $('#editNewBatorderfrm').parsley().whenValidate({

    }).done(function() {
      vm.saveEditBarItemNew();
    });
  },
  saveEditBarItemNew(){
    let vm = this;
    vm.validation_errors = {};
    vm.error = '';
    $('#edit_new_bar_item').prop('disabled',true);
    if(vm.edit_new_bar_menu.menu_item.quantity > 0){
      let formData = new FormData();
      formData.append("u_menu_item", JSON.stringify(vm.edit_new_bar_menu.menu_item));
      formData.append("restaurant_id", vm.restaurant_id);
      axios.post('/owner/orders/edit-at-bar-menuitem',formData).then(response => {
        vm.newseatitems[vm.edit_new_bar_menu.menuItmInx] = response.data.response.cart_updated_item;
        $('#edit_new_bar_item').prop('disabled',false);
        $('#editNewBarMenuItem').modal('hide');
        vm.orders_at_bar();

        vm.new_seat_order_total = response.data.response.seat_order_total;
      }).catch(error => {
      });
    }else{
      this.remove_new_seat_item(vm.edit_bar_menu.menu_item.id,vm.edit_bar_menu.cartItms,vm.edit_bar_menu.menuItmInx);
      $("#editNewBarMenuItem .close").click();
    }

  },
  /*remove item of new  seat*/
  remove_new_seat_item(itmId,cartItms,menuItm){
    let vm = this;
    if(confirm("Do you really want to remove?")){
      axios.delete('/owner/orders/remove-at-bar-menuitem/'+itmId).then(response => {
        Vue.delete(cartItms, menuItm);
        vm.orders_at_bar();
        vm.new_seat_order_total = response.data.response.seat_order_total;
      }).catch(error => {
      });
    }
  },

  /*edit seat info of existing  seat*/
  edit_modal_at_bar_order(itm_array){

    let vm = this;
    vm.edit_seat_id = itm_array.id;
    console.log(vm.edit_seat_id);
    $('#showBarTableModalEdit').modal('show');
    vm.at_bar_seat_number = itm_array.seat_number;
    vm.at_bar_seat_info = itm_array.seat_info;

    vm.edit_direct_order_seatinfo.seat_number = itm_array.seat_number;
    vm.edit_direct_order_seatinfo.seat_info = itm_array.seat_info;
    vm.edit_direct_order_seatinfo.card_number = itm_array.card_number;
    vm.edit_direct_order_seatinfo.card_expiry  = itm_array.card_expiry;
    vm.edit_direct_order_seatinfo.billing_zipcode  = itm_array.billing_zipcode;
    vm.edit_direct_order_seatinfo.id  = itm_array.id;

    vm.seat_items_array = itm_array.bar_cart_menu_items;
    vm.seat_items_array.seat_total = itm_array.seat_total;

    vm.seatinfoid  = itm_array.id;
    vm.existing_seat  ="yes";
    vm.seat_items_array.taxamt = itm_array.taxamt;
    vm.seat_items_array.platformfee = itm_array.platformfee;
  },
  validate_seat_info(){
    let vm = this;
    $('#editdirectorderfrm').parsley().whenValidate({

    }).done(function() {
      vm.update_seat_info();
    });
  },
  update_seat_info(){
    $('#edit_direct_order_btn').prop('disabled',true);
    let vm = this;
    axios.post('/owner/orders/update_seatinfo_direct_order_at_bar',vm.edit_direct_order_seatinfo).then(response => {

      $('#showSeatInfoModalEdit').hide();
      vm.at_bar_seat_number = response.data.response.seatinfo.seat_number;
      vm.at_bar_seat_info = response.data.response.seatinfo.seat_info;

      $('#edit_direct_order_btn').prop('disabled',false);

    }).catch(error => {
      $('#edit_direct_order_btn').prop('disabled',false);
      if (error.response.status === 400) {
        alert(error.response.data.response.message);
          $('#edit_direct_order_btn').prop('disabled',false);
      }else{
        alert("Something went wrong!");
      }
    });
  },
  edit_seat_info(){
    $('#showSeatInfoModalEdit').show();
  },
  /*close out existing seat */
  close_out_seat_at_bar(bar_seat_id){
    let vm = this;
    window.location.href = "/owner/orders/at_bar_orders/make-payment/"+bar_seat_id;
    /*if(confirm("Do you really want to close out?")){
      let formData = new FormData();
      formData.append("id", bar_seat_id);
      formData.append("restaurant_id", this.restaurant_id);
      axios.post('/owner/orders/close-out-seat-at-bar',formData).then(response => {
        toastr.options = {
        "positionClass": "toast-bottom-right"
        };
        toastr.success('Seat Closed Out successfully.');
        $('#showBarTableModalEdit').modal('hide');
        location.reload();
      }).catch(error => {
        if (error.response.status === 400) {
          alert(error.response.data.response.message);
        }else{
          alert("Something went wrong!");
        }
      });
    }*/
  },
  /*edit item of  existing seat */
  editItemAtBar(itmmenu,cartItms,menuItmInx){
    let vm = this;
    $('#editBarMenuItem').modal('show');
    vm.edit_bar_menu.menu_item.title = itmmenu.menuitem.title;
    vm.edit_bar_menu.menu_item.description = itmmenu.menuitem.description;
    vm.edit_bar_menu.menu_item.price = itmmenu.menuitem.price;
    vm.edit_bar_menu.menu_item.quantity = itmmenu.quantity;
    vm.edit_bar_menu.menu_item.id = itmmenu.id;

   vm.edit_bar_menu.menu_item_arry = itmmenu;
   vm.edit_bar_menu.cartItms = cartItms;
   vm.edit_bar_menu.menuItmInx = menuItmInx;
  },
  validateBarEditItem(itmmenu){
    let vm = this;
    $('#editBatorderfrm').parsley().whenValidate({

    }).done(function() {
      vm.saveEditBarItem();
    });
  },
  saveEditBarItem(){
    let vm = this;
    vm.validation_errors = {};
    vm.error = '';
    $('#edit_bar_item').prop('disabled',true);
    if(vm.edit_bar_menu.menu_item.quantity > 0){
      let formData = new FormData();
      formData.append("u_menu_item", JSON.stringify(vm.edit_bar_menu.menu_item));
      formData.append("restaurant_id", vm.restaurant_id);
      axios.post('/owner/orders/edit-at-bar-menuitem',formData).then(response => {
        vm.edit_bar_menu.cartItms[vm.edit_bar_menu.menuItmInx] = response.data.response.cart_updated_item;
        $('#edit_bar_item').prop('disabled',false);
        $('#editBarMenuItem').modal('hide');
        vm.orders_at_bar();
        vm.seat_items_array.seat_total = response.data.response.seat_order_total;
      }).catch(error => {
      });
    }else{
      this.removeItemAtBar(vm.edit_bar_menu.menu_item.id,vm.edit_bar_menu.cartItms,vm.edit_bar_menu.menuItmInx);
      $("#editBarMenuItem .close").click();
    }

  },
  /*remove item of  existing seat */
  removeItemAtBar(itmId,cartItms,menuItm){
    let vm = this;
    if(confirm("Do you really want to remove?")){
      axios.delete('/owner/orders/remove-at-bar-menuitem/'+itmId).then(response => {
        Vue.delete(cartItms, menuItm);
        vm.orders_at_bar();
        vm.seat_items_array.seat_total = response.data.response.seat_order_total;
      }).catch(error => {
      });
    }
  },
  increase: function() {
    let vm = this;
    vm.edit_bar_menu.menu_item.quantity++;
  },
  decrease:  function() {
    let vm = this;
    if (vm.edit_bar_menu.menu_item.quantity > 0) {
        vm.edit_bar_menu.menu_item.quantity--;
    }
  },
  addSlashPattern(ev){
    var v = ev.target.value;
    if (v.match(/^\d{2}$/) !== null) {
        ev.target.value = v + '/';
    }
  },
  getSpecialRequest(item){
        $('#specialrequest_model4').modal('show');
        this.sprequest = item.other_data;
  }
},

filters: {
},

computed: {
}

}
</script>
