<template>
    <div>
      <div class="tab-content middle-tab-content-list">
        <!-- tab-one -->
        <div id="menu" class="tab-pane handeld-popup-relative active receipt_details" v-if="currenttab=='menu'">
                 <!--- Menu List 23-03-2023--->
                 
                    <section class="handhelds-menus sticky-nav-tabs" style=" position: fixed;width: 100%;z-index: 9;margin-left: -33px;" >
                      <div class="sticky-nav-tabs-container mbl_menu" style="top: 0px;overflow-y: hidden;"> 
                        <a class="sticky-nav-tab" v-for="(item,index) in categories" @click="showMenuItems($event,item.id)" :data-menu="item.title">{{item.title}}</a>
                        <span class="sticky-nav-tab-slider"></span>
                         
                      </div>
    
                      <div class="nav-item menu-list-handheld menu_listdropdown mobl_menu_listdropdown" style="margin:0;">
                          <a class="nav-link final_draft" id="final_draft" @click="showMenuItems1">
                            <i class="fas fa-align-justify" style="left: 0px;padding:8px 7px 9px 7px; padding-left: 0;"></i>
                          </a>
                        </div>
                        <!--<div class="nav-item menu-list-handheld menu_listdropdown mobl_menu_listdropdown" @click="showAllMenus(modalid)" style="margin:0;">
                          <a class="nav-link" id="final_draft">
                            <i class="fas fa-align-justify" style="left: 0px;padding:8px 13px 8px 0px; padding-left: 0;"></i>
                          </a>
                        </div>-->
                    </section>
                  <!--END -->
                   <!-- onclick-show-menu-handeld -->
                  <div class="onclick-show-menu-handeld mobile_toggle_nav" id="mobile_toggle_nav" style="display: none;">
                    <ul class="nav flex-column">
                      <div class="close-menu close_toggle_nav" id="close_toggle_nav" @click="close_all_menu_nav">&times;</div>
                      <li class="nav-item" v-for="(item,index) in categories" @click="showMenuItems($event,item.id)">
                        <a class="nav-link " :class="{ active: (activeTab == item.id) ? true : false }" :data-menu="`${item.title}`">{{item.title}}</a>
                      </li>
                    </ul>
                  </div>
                  <!-- onclick-show-menu-handeld -->
                  <!-- Overlay -->
                  <div id="menuoverlay">
                    <div class="w-100 d-flex justify-content-center align-items-center">
                      <div class="menuoverlayspinner">Please Wait...</div>
                    </div>
                  </div>
                  <!-- End -->
    
                    <!--- Menu List --->
                      <div class="outer-menu-list-price">
                        <section class="menu_section" v-for="(item,index) in categories" :key="item.id" :id="`tab-${item.id}`">
                            <h3>{{item.title}}</h3>
                             <div v-if="menu_cat_discounts_title !=''" class="discount-sec rounded" >
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item">
                                  <i class="fas fa-gift" aria-hidden="true" style="font-size:28px;color:#ff5802"></i>
                                  <span class="ml-2 font-weight-bold">{{menu_cat_discounts_title}}</span>
                                </li>
                              </ul>
                            </div>
                            <div v-if="load_menu_items" class="d-flex justify-content-center">
                            <div class="patron-loader spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                            <div v-for="(sitem,index) in item.menuitems" v-if="sitem.inventory > 0" class="menu-list-price menu_list_img">
                              <div class="menu_add_img" v-if="sitem.images != ''">
                                <img  v-if="sitem.images"  :src="'/storage/menu_item_images/original/'+sitem.images" :alt="sitem.title">
                                <span v-if="sitem.cart_addedmenuitem == null" class="menu_list_add_btn"><i class="fas fa-fw fa-plus" v-on:click="quickadd(sitem.id,1,sitem.inventory,sitem)"></i></span>
                                <div class="qtyadjstclsidloader">
                                  <div v-if="load_menu_icons" class="flex justify-content-center">
                                    <div class="patron-loader spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                  </div>
                                </div>
                                <span v-if="sitem.cart_addedmenuitem != null" class="menu_list_add_btn add-item-cnt count_bg qtyadjstcls" :id="`qtyadjstclsid_${sitem.id}`">
                                  <i class="fas fa-fw fa-minus minus_icon" v-on:click="quickupdate(sitem.cart_addedmenuitem,'minus',sitem.id,sitem.inventory)"></i>
                                    <p>{{sitem.cart_addedmenuitem.quantity}}</p>
                                  <i class="fas fa-fw fa-plus plus_icon" v-on:click="quickupdate(sitem.cart_addedmenuitem,'add',sitem.id,sitem.inventory)"></i></span>
                              </div>
                              <div class="menu_add_img null_img" v-else>
                                 <img  v-if="sitem.images"  :src="'/storage/menu_item_images/original/'+sitem.images" :alt="sitem.title">
                                  <span v-if="sitem.cart_addedmenuitem == null" class="menu_list_add_btn"><i class="fas fa-fw fa-plus" v-on:click="quickadd(sitem.id,1,sitem.inventory,sitem)"></i></span>
                                  <span v-if="sitem.cart_addedmenuitem != null" class="menu_list_add_btn add-item-cnt count_bg qtyadjstcls" :id="`qtyadjstclsid_${sitem.id}`" >
                                    <i class="fas fa-fw fa-minus" v-on:click="quickupdate(sitem.cart_addedmenuitem,'minus',sitem.id,sitem.inventory)"></i>
                                      {{sitem.cart_addedmenuitem.quantity}}
                                    <i class="fas fa-fw fa-plus" v-on:click="quickupdate(sitem.cart_addedmenuitem,'add',sitem.id,sitem.inventory)"></i>
                                  </span>
                              </div> 
                              <div class="menu_descri clearfix">
                                <h4 class="float-left">{{sitem.title}}</h4>
                                <br> 
                                <p>{{sitem.description}}</p>
                                <span >${{parseFloat(sitem.price).toFixed(2)}}</span>
                              </div>
                            </div>
                            <div v-if="item.length == 0" class="empty_menu empty_msg">No menu items found.</div>
                        </section>
                      </div>
                 <!---End New Menu List --->  
        </div>
        <!-- tab-one -->
    
        <div id="carttb" class="container tab-pane fade receipt_details">
          <div class="row" v-if="currenttab=='carttb'">
            <cart-desktop-list v-on:resetrevieworderbtncnt="resetReviewOrderbtnVal" v-on:reloadmenulistfromcart="refreshMenuListist" v-on:updatemenulist="updateHomeMenulist" v-on:updatecartcnt="changeCartCnt" :restaurant_id="restaurant_id" :auth_user="auth_user" :qrtokenid="qrtokenid" :table_id="table_id" v-on:callmenusTabfromcart="setCurrentTabMenu" v-on:callorderstabfromcart="setCurrentTabMenu"  v-on:callmenusTabfromrevieworder="setCurrentTabMenu" :cartcount="cartcount"  :showrevieworder="showrevieworder" :restauranttype="restauranttype" :country_id="country_id">
    
            </cart-desktop-list>
          </div>
        </div>
        <!-- tab-two -->
        <!-- tab-three -->
        <div id="receipt" class="container tab-pane fade receipt_details">
          <div class="row" v-if="currenttab=='receipt'">
            <receipt-page :restauranttype="restauranttype" :country_id="country_id" :restaurant_id="restaurant_id" :auth_user="loginUser" :cartdata="cartdata" :qrtokenid="qrtokenid" :table_id="table_id" v-on:setloginonmenu="getLoginUser" v-on:callmenusTab="setCurrentTabMenu"  v-on:disablealltabs="disableAllTabsCall" v-on:enablealltabs="enableAllTabsCall"></receipt-page>
          </div>
        </div>
        <!-- tab-three -->
      </div>
    
    
      <!-- view item modal-->
      <div class="modal fade" id="viewItem" tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <form method="POST" id="addiorderfrm" name="addiorderfrm" @submit.prevent="addToOrder" >
            <div class="order_menu_item">
              <img style="width: 570px;" v-if="menu_item.image"  :src="'/storage/menu_item_images/original/'+menu_item.image" :alt="menu_item.title">
              <h4>${{parseFloat(menu_item.price).toFixed(2)}}</h4>
              <h4>{{menu_item.title}}</h4>
              <p>
                {{menu_item.description}}
              </p>
              <label>Special Requests?</label>
              <textarea placeholder="What are your requests?" class="form-poup" v-model="menu_item.specialrequest" ></textarea>
              <div class="qty-text-sec">
                <div class="row">
                  <div class="col-2">
                    <span>Qty</span>
                  </div>
                  <div  class="col-5">
                    <input type="text" id="qty" class="qtyform-poup" v-model="menu_item.quantity"   required=""
                    data-parsley-validation-threshold="1" data-parsley-trigger="keyup" data-parsley-type="digits">
                  </div>
    
                  <div class="col-5 button-service">
                    <span ><i class="fa fa-minus"  v-on:click="decrease"></i></span>
                    <span><i class="fa fa-plus"    v-on:click="increase"></i></span>
                  </div>
                </div>
              </div>
              <div>
                <button @click.prevent="ValidateOrder()" class="btn add-btn-order" id="add_order" >Add to order</button>
                <!-- <a data-toggle="modal" data-target="#signInModalPrimary" class="btn add-signin-btn signin-up-btn"  v-if="!isloggedin">Sign in/ Create Account</a> -->
              </div>
            </div>
            <div class="mb-2 text-center">
    
                <div v-if="validation_errors" class="text-danger text-danger text-center">
                  <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                      {{v[0]}}
                  </p>
                </div>
                <div v-if="error" class="text-danger text-danger text-center">
                  <p v-if="error" :key="error" class="text-sm">
                    {{ error }}
                  </p>
                </div>
                <div v-if="errors" class="text-danger text-danger text-center">
                  <div v-for="(v, k) in errors" :key="k">
                    <p v-for="error in v" :key="error" class="text-sm">
                      {{ error }}
                    </p>
                  </div>
                </div>
    
            </div>
    
            </form>
          </div>
        </div>
      </div>
    
    
      <div v-if="restauranttype == 'foodtruck'">
        <div  v-bind:class="confirmcartcountmenu > 0 ? 'review-order-bottom-fix' : 'review-order-bottom-fix-deactiv' " v-bind:style="confirmcartcountmenu > 0 ? '' : '' "  id="reveiworderbtn"   v-if="showrevieworder && confirmcartcountmenu == 0 " >
              <a id="reveiworderbtncnt"><i class="fa fa-shopping-bag" style="padding-right: 10px; color: #fff!important;"></i> Review Order ({{confirmcartcountmenu}})</a>
    
          </div>
          <div  v-bind:class="confirmcartcountmenu > 0 ? 'review-order-bottom-fix' : 'review-order-bottom-fix-deactiv' " v-bind:style="confirmcartcountmenu > 0 ? '' : '' "  id="reveiworderbtn"   v-if="showrevieworder  && confirmcartcountmenu > 0 " @click="callcarttabmenu">
                <a id="reveiworderbtncnt"><i class="fa fa-shopping-bag" style="padding-right: 10px; color: #fff!important;"></i> Review Order ({{confirmcartcountmenu}})</a>
    
            </div>
      </div>
    </div>
    </template>
    
    <script src="{{asset('js/frontend/parsley.js')}}" type="text/javascript"></script>
    <script>
    
    class StickyNavigation {
      constructor() {
        this.currentId = null;
        this.currentTab = null;
        this.tabContainerHeight = 70;
        this.lastScroll = 0;
        let self = this;
        $('.sticky-nav-tab').click(function() { 
          self.onTabClick(event, $(this)); 
        });
        $(window).scroll(() => { this.onScroll(); });
        $(window).resize(() => { this.onResize(); });
      }
      
      onTabClick(event, element) {
        event.preventDefault();
        let scrollTop = $(element.attr('href')).offset().top - this.tabContainerHeight + 1;
        $('html, body').animate({ scrollTop: scrollTop }, 600);
      }
      
      onScroll() {
        this.checkHeaderPosition();
        this.findCurrentTabSelector();
        this.lastScroll = $(window).scrollTop();
      }
      
      onResize() {
        if(this.currentId) {
          this.setSliderCss();
        }
      }
      
      checkHeaderPosition() {
        const headerHeight = 70;
        let offset = ($('.sticky-nav-tabs').offset().top + $('.sticky-nav-tabs').height() - this.tabContainerHeight) - headerHeight;
        if($(window).scrollTop() > this.lastScroll && $(window).scrollTop() > offset) {
          $('.spa-header').addClass('spa-header--move-up');
          $('.sticky-nav-tabs-container').removeClass('sticky-nav-tabs-container--top-first');
          $('.sticky-nav-tabs-container').addClass('sticky-nav-tabs-container--top-second');
        } 
        else if($(window).scrollTop() < this.lastScroll && $(window).scrollTop() > offset) {
          $('.spa-header').removeClass('spa-header--move-up');
          $('.sticky-nav-tabs-container').removeClass('sticky-nav-tabs-container--top-second');
          $('.sticky-nav-tabs-container').addClass('sticky-nav-tabs-container--top-first');
        }
    
      }
    
      findCurrentTabSelector(element) {
        let newCurrentId;
        let newCurrentTab;
        let self = this;
        $('.sticky-nav-tab').each(function() {
          let id = $(this).attr('href');
          let offsetTop = $(id).offset().top - self.tabContainerHeight;
          let offsetBottom = $(id).offset().top + $(id).height() - self.tabContainerHeight;
          if($(window).scrollTop() > offsetTop && $(window).scrollTop() < offsetBottom) {
            newCurrentId = id;
            newCurrentTab = $(this);
          }
        });
        if(this.currentId != newCurrentId || this.currentId === null) {
          this.currentId = newCurrentId;
          this.currentTab = newCurrentTab;
          this.setSliderCss();
        }
      }
      
      setSliderCss() {
        let width = 0;
        let left = 0;
        if(this.currentTab){
          width = this.currentTab.css('width');
          left = this.currentTab.offset().left;
        }
        $('.sticky-nav-tab-slider').css('width', width);
        $('.sticky-nav-tab-slider').css('left', left);
      }
      
    }
    
    
    new StickyNavigation();
    
    
    
    
    
    
    
    // var _gaq = _gaq || [];
    //   _gaq.push(['_setAccount', 'UA-36251023-1']);
    //   _gaq.push(['_setDomainName', 'jqueryscript.net']);
    //   _gaq.push(['_trackPageview']);
    
    //   (function() {
    //     var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
    //     ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
    //     var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
    //   })();
    
        import VibeQuestions from './VibeQuestionsComponent';
        import CartDesktoptList from './CartListDesktopComponent';
        import CheckoutPage from  './CheckoutPageComponent';
        import OrderCompletedPage from  './OrderCompletedPageComponent';
        import ReceiptPage from  './ReceiptPageComponent';
        export default {
        props: ['restaurant_id','auth_user','currenttab','country_id','menu_type','qrtokenid','table_id','confirmcartcount','restauranttype','cartcount','showrevieworder'],
        data() {
            return {
                screenWidth: 0,
                screenHeight: 0,
                isCartLoading:false,
                activeTab: null,
                activeTabInline:null,
                message:'',
                categories:[],
                menu_items:{},
                activeMenuTab: null,
                activeMenuTitle:null,
                load_menu_items:false,
                menu_item:{
                  title:'',
                  description:'',
                  price:'',
                  quantity:1,
                  user_id:'',
                  image:'',
                  specialrequest:'',
                  menu_category_id:'',
                  restaurant_id:this.restaurant_id
                },
                isloggedin:false,
                is_proceed:false,
                cartdata:{},
                validation_errors:{},
                error:'',
                errors:{},
                order_completed:false,
                loginUser:this.auth_user,
                menu_cat_discounts_title:'',
                cartcountnew:this.cartcount,
                addedmenuqty:1,
                confirmcartcountmenu:this.confirmcartcount,
                load_menu_icons:false,
                update_cart_item:{
                  title:'',
                  description:'',
                  price:'',
                  quantity:1,
                  user_id:'',
                  menuItmInx:''
              },
            }
        },
        components:{
          'vibe-questions': VibeQuestions,
          'cart-desktop-list': CartDesktoptList,
          'checkout-page':CheckoutPage,
          'order-completed-page':OrderCompletedPage,
          'receipt-page':ReceiptPage,
        },
        created: function () {
          let currentqrcode = window.location.pathname.split("/").pop();
          let postDataw = new FormData();
          postDataw.append("restaurant_id",this.restaurant_id);
          axios.post('/api/restaurant-settings',postDataw).then(response => {
            if(response.data.data.delaydetail != null){
              //location.reload();
              window.location.href = "/restaurant/home/"+currentqrcode;
            }
          });
          //this.list();
          this.menu_cat_discounts_title = '';
          if(this.auth_user != null){
            this.isloggedin = true;
            this.menu_item.user_id = this.auth_user.id;
          }
    
          let postData = new FormData();
          postData.append("restaurant_id", this.restaurant_id);
          postData.append("menu_type", this.menu_type);
          postData.append("qrtoken_id", this.qrtokenid);
        postData.append("order_type", 'normal');
          axios.post('/patron/get-restaurant-categories',postData).then(response => {
            this.categories = response.data.response.categories;
            this.activeTab = response.data.response.categories[0].id;
            this.activeTabInline = response.data.response.categories[0].id;
            this.activeMenuTitle = response.data.response.categories[0].title;
    
            postData.append("menu_category_id", response.data.response.categories[0].id);
            axios.post('/patron/get-restaurant-menu-items',postData).then(response => {
                this.menu_items = response.data.response.menu_items;
                if(response.data.response.discounts.menu_cat_discounts != null){
                  this.menu_cat_discounts_title = response.data.response.discounts.menu_cat_discounts.title;
                }
            });
    
          }).catch(error => {
          });
    
          /*let postData1 = new FormData();
          if(this.auth_user != null){
            postData1.append("user_id", this.auth_user.id);
          }
    
          postData1.append("restaurant_id", this.restaurant_id);
          postData1.append("confirmed", 1);
          postData1.append("table_id", this.table_id);
          postData1.append("order_type", 'normal');
          postData1.append("qrtoken_id", this.qrtokenid);
          axios.post('/patron/cart-list',postData1).then(response => {
    
            if(response.data.data.cart_list.length){
              $('#ratingtab').css("pointer-events", "");
              this.cartcountnew = response.data.data.cartItemCount;
              this.confirmcartcountmenu = response.data.data.cartItemCount;
              $('#cartCount').text(this.cartcountnew);
            }
          }).catch(error => {
          });*/
    
        },
        mounted() {
          let vm = this;
        },
        methods:{
          resetReviewOrderbtnVal(){
            this.confirmcartcountmenu = 0;
          },
          refreshMenuListist(){
            this.menuslist();
          },
          async menuslist(){
            let currentqrcode = window.location.pathname.split("/").pop();
            let postDataw = new FormData();
            postDataw.append("restaurant_id",this.restaurant_id);
            axios.post('/api/restaurant-settings',postDataw).then(response => {
              if(response.data.data.delaydetail != null){
                //location.reload();
                window.location.href = "/restaurant/home/"+currentqrcode;
              }
            });
            let postData = new FormData();
            postData.append("restaurant_id", this.restaurant_id);
            postData.append("menu_type", this.menu_type);
            postData.append("qrtoken_id", this.qrtokenid);
            postData.append("order_type", 'normal');
    
             axios.post('/patron/get-restaurant-categories',postData).then(response => {
              this.categories = response.data.response.categories;
    
              if(this.activeTab > 0){
                  postData.append("menu_category_id", this.activeTab);
    
              }else{
                this.activeTab = response.data.response.categories[0].id;
                this.activeTabInline = response.data.response.categories[0].id;
                this.activeMenuTitle = response.data.response.categories[0].title;
                postData.append("menu_category_id", response.data.response.categories[0].id);
              }
    
    
    
               axios.post('/patron/get-restaurant-menu-items',postData).then(response => {
                  this.menu_items = response.data.response.menu_items;
                  if(response.data.response.discounts.menu_cat_discounts != null){
                    this.menu_cat_discounts_title = response.data.response.discounts.menu_cat_discounts.title;
                  }
              });
    
            }).catch(error => {
            });
          },
          async list(){
            let postData = new FormData();
            if(this.auth_user != null){
              this.isloggedin = true;
              postData.append("user_id", this.auth_user.id);
            }
            postData.append("restaurant_id", this.restaurant_id);
            postData.append("confirmed", 1);
            postData.append("qrtoken_id", this.qrtokenid);
            postData.append("order_type", 'normal');
            await axios.post('/patron/cart-list',postData).then(response => {
              //this.cartcount = response.data.data.cart_list.length;
              //this.confirmcartcountmenu = response.data.data.cart_list.length;
              $('#cartCount').text(response.data.data.cartItemCount);
              this.confirmcartcountmenu = response.data.data.cartItemCount;
            });
          },
          showMenuItems1(){
            $('.mobile_toggle_nav').show();
          },
          showMenuItems: function (event,menucatid) {
            console.log(`menucatid = ${menucatid}`);
            this.menu_cat_discounts_title = '';
            this.load_menu_items = true;
            this.menu_items = {};
            this.activeMenuTitle = event.target.getAttribute('data-menu');
            this.activeTab = menucatid;
            this.activeTabInline = menucatid;
            let menuData = new FormData();
            menuData.append("menu_category_id", menucatid);
            menuData.append("qrtoken_id", this.qrtokenid);
            menuData.append("restaurant_id", this.restaurant_id);
        menuData.append("order_type", 'normal');
            axios.post('/patron/get-restaurant-menu-items',menuData).then(response => {
              this.load_menu_items = false;
              this.menu_items = response.data.response.menu_items;
              if(response.data.response.discounts.menu_cat_discounts != null){
                this.menu_cat_discounts_title = response.data.response.discounts.menu_cat_discounts.title;
              }
    
              $('.close_toggle_nav').click();
    
            }).catch(error => {
            });
          },
      
          showMenuItem: function (item) {
            let vm = this;
            $('#viewItem').modal('show');
            vm.menu_item.title = item.title;
            vm.menu_item.description = item.description;
            vm.menu_item.price = item.price;
            vm.menu_item.id = item.id;
            vm.menu_item.image = item.images;
            vm.menu_item.specialrequest = '';
            vm.menu_item.menu_category_id = item.menu_category_id;
            vm.menu_item.inventory = item.inventory;
            if(item.cart_addedmenuitem != null){
              vm.menu_item.quantity = item.cart_addedmenuitem.quantity;
            }else{
              vm.menu_item.quantity = 1
            }
          },
          ValidateOrder() {
            let vm = this;
            $('#addiorderfrm').parsley().whenValidate({
    
            }).done(function() {
              vm.addToOrder();
            });
          },
          async addToOrder() {
            let postDataw = new FormData();
            postDataw.append("restaurant_id",this.restaurant_id);
            axios.post('/api/restaurant-settings',postDataw).then(response => {
              if(response.data.data.delaydetail != null){
                location.reload();
              }
            });
            let vm = this;
         // return false;
            vm.validation_errors = {};
            vm.error = '';
            $('#add_order').prop('disabled',true);
            $('#add_order').text("Please Wait...");
    
        /*if(vm.menu_item.quantity > vm.menu_item.inventory){
          alert('The quantity is higher than the maximum allowed quantity');
          $('#add_order').prop('disabled',false);
          $('#add_order').text("Add to order");
          return false;
        }*/
    
            vm.menu_item.qrtoken_id = vm.qrtokenid;
            await axios.post('/patron/add-item-to-cart',vm.menu_item).then(response => {
              $("#viewItem .close").click();
              if(response.data.data.new == 1){
                //let cartCount = $('#cartCount').text();
                //let newcartCount = parseInt(cartCount)+1;
                //$('#cartCount').text(newcartCount);
                //this.cartcountnew = newcartCount;
                //vm.confirmcartcountmenu = newcartCount;
                let cartCount = response.data.data.cartTotal;
                let newcartCount = cartCount
                $('#cartCount').text(cartCount);
                this.cartcountnew = cartCount
                vm.confirmcartcountmenu = cartCount;
    
              }else{
                let cartCount = response.data.data.cartTotal;
                let newcartCount = cartCount
                $('#cartCount').text(cartCount);
                this.cartcountnew = cartCount
                vm.confirmcartcountmenu = cartCount;
              }
    
              vm.menu_item.quantity = 1;
              vm.menu_item.specialrequest = '';
              $('#add_order').prop('disabled',false);
              $('#add_order').text("Add to order");
              vm.validation_errors = {};
              vm.error = '';
              //vm.activeTabInline = vm.menu_item.menu_category_id;
              vm.menuslist();
              this.callcart();
    
            }).catch(error => {
              $('#add_order').prop('disabled',false);
              $('#add_order').text("Add to order");
              if(error.response.data.data && error.response.data.code == 422){
                vm.validation_errors = error.response.data.data || {};
                if(error.response.data.data.length == 0){
                  vm.error = error.response.data.message || {};
                }
              }else if(error.response.data.message){
                vm.error = error.response.data.message || {};
              }
    
            });
          },
          increase: function() {
            let vm = this;
            vm.menu_item.quantity++;
          },
          decrease:  function() {
            let vm = this;
            if (vm.menu_item.quantity > 1) {
                vm.menu_item.quantity--;
            }
    
          },
          callcheckout(){
            this.is_proceed = true;
            this.showtippay = true;
          },
          fetchCartData(cartlist){
            this.cartdata  = cartlist;
          },
          callcart(){
            this.is_proceed = false;
          },
          getLoginUser(udata){
            this.loginUser = udata;
            this.disableAllTabsCall();
            this.$emit('enablelogoutbtn');
          },
          setCurrentTabMenu(menuname){
            this.$emit('callmenutab',menuname);
          },
          disableAllTabsCall(){
            this.$emit('disableheaderhab');
          },
          enableAllTabsCall(){
            this.$emit('enableheadertabs');
          },
         
          close_all_menu_nav(){
            $('.mobile_toggle_nav').hide();
          },
          callcarttabmenu(){
            this.$emit('callmenutab','carttb');
            $('.new-tablist-tabs a[href="#carttb"]').trigger('click');
          },
          changeCartCnt(newcnt){
            let postData = new FormData();
            postData.append("restaurant_id", this.restaurant_id);
            postData.append("menu_type", this.menu_type);
            postData.append("qrtoken_id", this.qrtokenid);
            postData.append("order_type", 'normal');
            axios.post('/patron/cart-item-totqty',postData).then(response => {
              this.confirmcartcountmenu = response.data.data;
              this.cartcountnew = response.data.data;
              this.$emit('resetcartitemcount',response.data.data);
            }).catch(error => {
            });
            //this.menuslist();
          },
          updateHomeMenulist(){
            this.menuslist();
          },
          async quickadd(id,currqty,availQty,sitem) {
            let currentqrcode = window.location.pathname.split("/").pop();
            let postDataw = new FormData();
            postDataw.append("restaurant_id",this.restaurant_id);
            axios.post('/api/restaurant-settings',postDataw).then(response => {
              if(response.data.data.delaydetail != null){
                //location.reload();
                window.location.href = "/restaurant/home/"+currentqrcode;
              }
            });
            let vm = this;
            vm.validation_errors = {};
            vm.error = '';
            document.getElementById("menuoverlay").style.display = "flex";
            vm.menu_item.qrtoken_id = vm.qrtokenid;
            vm.menu_item.title = sitem.title;
            vm.menu_item.price = sitem.price;
            vm.menu_item.menu_category_id = sitem.menu_category_id;
            vm.menu_item.description = sitem.description;
            vm.menu_item.image = sitem.images;
            vm.menu_item.specialrequest = sitem.specialrequest;
            vm.menu_item.quantity = currqty;
            vm.menu_item.id = id;
            
            await axios.post('/patron/add-item-to-cart',vm.menu_item).then(response => {
              
            }).catch(error => {
              if(error.response.data.data && error.response.data.code == 422){
                vm.validation_errors = error.response.data.data || {};
                if(error.response.data.data.length == 0){
                  vm.error = error.response.data.message || {};
                }
              }else if(error.response.data.message){
                vm.error = error.response.data.message || {};
              }
    
            });
            let postData = new FormData();
            postData.append("restaurant_id", this.restaurant_id);
            postData.append("menu_type", this.menu_type);
            postData.append("qrtoken_id", this.qrtokenid);
        postData.append("order_type", 'normal');
             axios.post('/patron/get-restaurant-categories',postData).then(response => {
              this.categories = response.data.response.categories;
              postData.append("menu_category_id", this.activeTab);
               axios.post('/patron/get-restaurant-menu-items',postData).then(response => {
                  this.menu_items = response.data.response.menu_items;
                  if(response.data.response.discounts.menu_cat_discounts != null){
                    this.menu_cat_discounts_title = response.data.response.discounts.menu_cat_discounts.title;
                  }
                  document.getElementById("menuoverlay").style.display = "none";
                  vm.callcart();
                  let cartCountText = $('#cartCount').text().trim();
                  let cartCount = parseFloat(cartCountText);
                  let cartCount1 = !isNaN(cartCount)?cartCount : 0;
                  let newcartCount = parseInt(cartCount1)+1;
                   $('.cartCount').text(newcartCount);
                  vm.cartcountnew = newcartCount;
                  vm.confirmcartcountmenu = newcartCount;
                  // vm.$emit('update-cart-count', newcartCount);
              });
    
            }).catch(error => {
            });
        },
        quickupdate(itemarr,actiontype,cmid,availQty) {
          let currentqrcode = window.location.pathname.split("/").pop();
          let postDataw = new FormData();
          postDataw.append("restaurant_id",this.restaurant_id);
          axios.post('/api/restaurant-settings',postDataw).then(response => {
            if(response.data.data.delaydetail != null){
              //location.reload();
              window.location.href = "/restaurant/home/"+currentqrcode;
            }
          });
          let vm = this;
          vm.validation_errors = {};
          vm.error = '';
    
          document.getElementById("menuoverlay").style.display = "flex";
          $('#qtyadjstclsid_'+cmid).prop('disabled',true);
          $('#qtyadjstclsid_'+cmid).addClass('menuicondidable');
          if(actiontype == 'add'){
            itemarr.quantity = itemarr.quantity + 1;
          }
          if(actiontype == 'minus'){
            itemarr.quantity = itemarr.quantity - 1;
          }
    
        if(itemarr.quantity > availQty){
          alert('The quantity is higher than the maximum allowed quantity');
          itemarr.quantity = itemarr.quantity - 1;
          document.getElementById("menuoverlay").style.display = "none";
                $('#qtyadjstclsid_'+cmid).prop('disabled',false);
                $('#qtyadjstclsid_'+cmid).removeClass('menuicondidable');
          return false;
        }
          if(itemarr.quantity <=0){
            let cartitemidval = itemarr.id
            if(confirm("Do you really want to remove?")){
               axios.delete('/patron/remove-cart-item/'+cartitemidval).then(response => {
    
              }).catch(error => {
              });
            }
          }
          if(itemarr.quantity > 0){
            axios.post('/patron/update-cart-item',itemarr).then(response => {
              let cartCount = response.data.data.cartTotal;
                $('#cartCount').text(cartCount);
            }).catch(error => {
            });
          }
          let postData = new FormData();
          postData.append("restaurant_id", this.restaurant_id);
          postData.append("menu_type", this.menu_type);
          postData.append("qrtoken_id", this.qrtokenid);
        postData.append("order_type", 'normal');
           axios.post('/patron/get-restaurant-categories',postData).then(response => {
            this.categories = response.data.response.categories;
            postData.append("menu_category_id", this.activeTab);
             axios.post('/patron/get-restaurant-menu-items',postData).then(response => {
                this.menu_items = response.data.response.menu_items;
                if(response.data.response.discounts.menu_cat_discounts != null){
                  this.menu_cat_discounts_title = response.data.response.discounts.menu_cat_discounts.title;
                }
                document.getElementById("menuoverlay").style.display = "none";
                $('#qtyadjstclsid_'+cmid).prop('disabled',false);
                $('#qtyadjstclsid_'+cmid).removeClass('menuicondidable');
                this.list();
                //vm.callcart();
            });
    
          }).catch(error => {
          });
        },
    
        },
      mounted: function() {
      }
    }
    
    
    
    
    
    
    
    
    
    </script>