<template>
  <div class="container">
    <div  class="row">
      <div class="col-md-12" v-if="!showOrderCompleted">
          <div class="receipt-yours-screen" style="margin-top: 20px;">
            <div class="card-stripe-section iphone">
                  <div class="card">
                    <form method="POST" id="stripepayform" name="stripepayform"   @submit.prevent="make_payment">
                      <div class="card-header bg-newCard">
                          <div class="row">
                              <div class="col-md-6"> <span>ENTER CARD DETAILS</span> </div>
                          </div>
                      </div>

                      <div class="card-body" style="">
                         <div class="row">
                           <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="cc-fname" class="control-label">First Name</label>
                                    <input id="cc-fname" type="text" class="input-lg form-control cc-cvc new-card-elements-cls" v-model="cardfields.fname" autocomplete="off" placeholder="First Name" data-parsley-required-message="Please first enter name" required>
                                  </div>
                              </div>
                           <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="cc-lname" class="control-label">Last Name</label>
                                    <input id="cc-lname" type="text" class="input-lg form-control cc-cvc new-card-elements-cls" v-model="cardfields.lname" autocomplete="off" placeholder="Last Name" data-parsley-required-message="Please last enter name"  required>
                                  </div>
                              </div>
                               <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="cc-email" class="control-label">Email-Id</label>
                                    <input id="cc-email" type="email" class="input-lg form-control cc-cvc new-card-elements-cls" v-model="cardfields.email" autocomplete="off" placeholder="example@gmail.com" data-parsley-required-message="Please enter email address"  required>
                                  </div>
                              </div>
                              <div class="col-md-4">
                                 <div class="form-group">
                                   <label for="cc-billing_zipcode" class="control-label">Billing zip code</label>
                                   <input id="cc-billing_zipcode" type="text" class="input-lg form-control cc-cvc new-card-elements-cls" v-model="cardfields.billing_zipcode" autocomplete="off"  data-parsley-required-message="Please enter billing zipcode" required>
                                 </div>
                             </div>
                             <div class="col-md-4">
                                 <div class="form-group">
                                   <label for="cc-billing_zipcode" class="control-label">Phone Number</label>
                                   <input id="cc-billing_zipcode" type="text" class="input-lg form-control cc-cvc new-card-elements-cls" v-model="cardfields.phone" autocomplete="off"  data-parsley-required-message="Please enter Phone number" required>
                                 </div>
                             </div>

                         </div>
                          <div class="row">
                            <div class="iframe-card-details" id="tilledccelements" style="display:none">
                              <label>CARD NUMBER<div id="card-number-element-guest3"></div></label>
                              <label>CARD EXPIRY<div id="card-expiration-element-guest3"></div></label>
                              <label>CARD CVC<div id="card-cvv-element-guest3"></div></label>

                            </div>

                          </div>
                          <div class="row">
                            <div class="col-md-6">
                                <button @click.prevent="validate_payment()" id="makepaymentbtn" class="bottom-fix-pay-btn btn mt-3">Place Order</button>
                            </div>
                          </div>
                      </div>
                    </form>
                    <!--end new card-->
                    <div class="text-danger mt-2" id="showPaymentErr">
                      <div v-if="validation_errors" class="text-danger text-danger text-center">
                        <p v-for="(v, k) in validation_errors" :key="k" class="text-sm">
                            {{v[0]}}
                        </p>
                      </div>
                      <div v-if="error" class="text-danger text-danger text-center">
                        <p v-if="error" :key="error" class="text-sm">
                          {{ error }}
                        </p>
                      </div>
                      <div v-if="errors" class="text-danger text-danger text-center">
                        <div v-for="(v, k) in errors" :key="k">
                          <p v-for="error in v" :key="error" class="text-sm">
                            {{ error }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
      </div>
      <div class="col-md-12" v-if="showOrderCompleted">
        <!-- <order-completed-page></order-completed-page> -->
        <!-- <guest-popup :OrderId=lastOrderId></guest-popup> -->
      </div>
    </div>
    <div class="modal fade" id="guestOrderSucess" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
           <div class="modal-dialog modal-lg modal-dialog-centered" role="document" >
             <div class="modal-content">
                 <button type="button" class="close handheld-close-popup" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                 </button>
               <div class="model-body">
                  <div class="row">
                        <div class="col-md-12">
                            <div class="congrates-popup-acount-created">
                              <span><i class="fas fa-check-circle" style="font-size: 80px;text-center:center;margin-top: 32px;"></i></span>
                              <h2>Your order has been sent to the kitchen.</h2>
                              <h3 style="text-align: center;margin: 37px;">Your order number is :  {{lastOrderId}}</h3>
                              <button type="button" class="btn menuitem-btn"  @click="callCartMenu">OK</button>
                            </div>
                        </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>

export default {
  props: ['restaurant_id','qrtokenid','tipval','connaccidv','paymntkeyvalv','enableguestpay'],
  data() {
      return {
        showOrderCompleted:false,
        isCartLoading:true,
        lastOrderId:'',
        cardfields:{
          cardNumber:'',
          cardExpiry:'',
          cardCvv:'',
          cardPostal:'12345',
          fname:'',
          lname:'',
          phone:'',
          email:'',
          billing_zipcode:''
        },
        validation_errors:{},
        error:'',
        errors:{},
        savedcarderror:'',
        is_enable_cvv:false,
        platform_fee_label:'',
        connaccid:'',
        paymntkeyval:'',
        tilledobj: "",
        tilledform:"",
        payment_intent_client_secret:'',
        ordid:'',
        payment_method_id:'',
      }
  },
  components:{
  },
  async mounted() {
      if(this.enableguestpay){
        $('#makepaymentbtn').prop('disabled',true)
        let postData = new FormData();
        postData.append("restaurant_id", this.restaurant_id);
        await axios.post('/api/restaurant-settings',postData).then(response => {
          this.platform_fee_label = response.data.data.platform_fee_label;
          this.connaccid = response.data.data.accountId;
          this.paymntkeyval = response.data.data.tilled_p_key;
        });

        var account_id = this.connaccid;
        var pk_PUBLISHABLE_KEY = this.paymntkeyval;
        const tilledAccountId = account_id;

        this.tilledobj = new Tilled(
          pk_PUBLISHABLE_KEY,
          tilledAccountId,
             { sandbox: "{{ Config::get('tilledpayment.sandbox_mode_status') }}" }

          );

          this.tilledform =  await this.tilledobj.form({
            payment_method_type: 'card',
          });

          // Optional styling of the fields
          const fieldOptions = {
            styles: {
              base: {
                fontFamily: 'Helvetica Neue, Arial, sans-serif',
                color: '#304166',
                fontWeight: '400',
                fontSize: '16px',
              },
              invalid: {
                ':hover': {
                  textDecoration: 'underline dotted red',
                },
                color: '#dc3545',
              },
              valid: {
                color: '#495057',
              },
            },
          };

          const cardNumberField3 = this.tilledform.createField('cardNumber', fieldOptions).inject('#card-number-element-guest3');
          this.tilledform.createField('cardExpiry', fieldOptions).inject('#card-expiration-element-guest3');
          this.tilledform.createField('cardCvv', fieldOptions).inject('#card-cvv-element-guest3');
          await this.tilledform.build();
          $('#tilledccelements').show();
          this.tilledform.on('validation', (event) => {
            if (event.field) {
              event.field.element.classList.remove('success_tilledinput');
              event.field.element.classList.remove('error_tilledinput');

              if (event.field.valid) {
                event.field.element.classList.add('success_tilledinput');
              } else {
                event.field.element.classList.add('error_tilledinput');
              }
            }

          });
          cardNumberField3.on('ready', (evt) => {
            $('#makepaymentbtn').prop('disabled',false)
          });

      }


  },
  created: function () {
  },
  methods:{

    validate_payment(){
      let vm = this;
      $('#stripepayform').parsley().whenValidate({

      }).done(function() {
        vm.make_payment();
      });
    },

    make_payment(){
          let vm = this;

            $('#makepaymentbtn').prop( "disabled", true );
            $('#makepaymentbtn').text("Please Wait...");
            this.tilledobj.createPaymentMethod({
              form: vm.tilledform,
              billing_details: {
                name: vm.cardfields.fname+" "+vm.cardfields.lname, // required
                address: {
                  country: 'US', // required
                   zip: vm.cardfields.billing_zipcode,
                },

                email: vm.cardfields.email
              },
              }).then((paymentpm) => {
                let formData = new FormData();
                formData.append("pmdata", JSON.stringify(paymentpm));
                formData.append("restaurant_id", vm.restaurant_id);
                formData.append("user_id", 0);
                formData.append("tip_perc", vm.tipval);
                formData.append("points", 0);
                formData.append("first_name", vm.cardfields.fname);
                formData.append("last_name", vm.cardfields.lname);
                formData.append("phone", vm.cardfields.phone);
                formData.append("name", vm.cardfields.fname+ +vm.cardfields.lname);
                formData.append("email", vm.cardfields.email);
                formData.append("method_name", 'createPaymentMethod');
                if($('#cardsaveid').is(':checked')){
                  formData.append("save_card_check", 'yes');
                }else{
                  formData.append("save_card_check", 'no');
                }
                formData.append("qrtoken_id", vm.qrtokenid);
                axios.post('/patron/create-tilled-paymentintent',formData).then(response => {
                  console.log("payment_intent_client_secret",response);
                  vm.payment_intent_client_secret = response.data.data.payment_client_secret;
                  vm.ordid = response.data.data.ord_id;
                  vm.payment_method_id = response.data.data.payment_method_id;
                  this.tilledobj.confirmPayment(vm.payment_intent_client_secret,{
                      payment_method: vm.payment_method_id
                    })
                    .then((payment) => {
                        console.log("pp paymentmethodrs ==",payment);
                        let payResData = new FormData();
                        if($('#cardsaveid').is(':checked')){
                          payResData.append("save_card_check", 'yes');
                        }else{
                          payResData.append("save_card_check", 'no');
                        }
                        payResData.append("method_name", 'confirmPayment');
                        payResData.append("pdata", JSON.stringify(payment));
                        payResData.append("order_id", vm.ordid);
                        payResData.append("restaurant_id", vm.restaurant_id);
                        payResData.append("user_id", 0);
                        payResData.append("tip_perc", vm.tipval);
                        payResData.append("first_name", vm.cardfields.fname);
                        payResData.append("last_name", vm.cardfields.lname);
                        formData.append("phone", vm.cardfields.phone);
                        payResData.append("name", vm.cardfields.fname+ +vm.cardfields.lname);
                        payResData.append("email", vm.cardfields.email);
                        payResData.append("qrtoken_id", vm.qrtokenid);
                        axios.post('/patron/save-order-payment',payResData).then(response => {
                          console.log("payment res ==", response);
                          $('#confirmCartCount').text('0');
                          vm.showOrderCompleted = true;
                          vm.lastOrderId = response.data.data;
                          $('#ratingtab').css("pointer-events", "none");
                          $('#guestOrderSucess').modal('show');
                          // this.$root.$emit('lastOrderId',response.data.data);
                          console.log('vm.lastOrderId');
                          console.log(vm.lastOrderId);
                          console.log(response.data);
                          $('.new-tablist-tabs a[href="#menu"]').trigger('click');
                        }).catch(error => {
                          console.log("payment res  errr==", error.response.data.data);
                          if(error.response.data.code == 422){
                            vm.error = error.response.data.data.last_payment_error;
                          }
                          $('#makepaymentbtn').prop( "disabled", false );
                          $('#makepaymentbtn').text("Place Order");
                        })
                      },
                      (err) => {
                          console.log("err pp ==",err);
                          vm.error = err.message;
                          $('#makepaymentbtn').prop( "disabled", false );
                          $('#makepaymentbtn').text("Place Order");

                          let paymentResData = new FormData();
                          paymentResData.append("method_name", 'confirmPayment');
                          paymentResData.append("order_id", vm.ordid);
                          paymentResData.append("restaurant_id", vm.restaurant_id);
                          paymentResData.append("user_id", 0);
                          paymentResData.append("payment_res", JSON.stringify(err));
                          paymentResData.append("payment_res_type", 'error');
                          paymentResData.append("qrtoken_id", vm.qrtokenid);
                          axios.post('/api/save-paymentGateway-response',paymentResData).then(response => {
                          });
                      },
                    );
                }).catch(error => {
                  vm.error = "something went wrong";
                  $('#makepaymentbtn').prop( "disabled", false );
                  $('#makepaymentbtn').text("Place Order");
                });
              },
              (err) => {
                console.log("333payment res  errr==", err.message);
                vm.error = err.message;
                $('#makepaymentbtn').prop( "disabled", false );
                $('#makepaymentbtn').text("Place Order");
                let paymentResData = new FormData();
                paymentResData.append("method_name", 'createPaymentMethod');
                paymentResData.append("order_id", "0");
                paymentResData.append("restaurant_id", vm.restaurant_id);
                paymentResData.append("user_id", 0);
                paymentResData.append("payment_res", JSON.stringify(err));
                paymentResData.append("payment_res_type", 'error');
                paymentResData.append("qrtoken_id", vm.qrtokenid);
                axios.post('/api/save-paymentGateway-response',paymentResData).then(response => {
                });
              }
            );

        },
        callCartMenu(){
          location.reload();
        }
  }
}


</script>
