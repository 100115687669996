<template>
  <div class="col-lg-6 col-md-6 col-sm-12">
    <!-- Vibe Questions -->
    <div class="card card-body new-card-body-padding flex-fill w-100">
      <div class="clearfix">
        <h5 class="card-title mb-0 float-left card-rating-font-head">Vibe Questions
        </h5>
        <a data-toggle="modal"
          data-target="#addQuestionModalPrimary" class="add-vibe mb-0 float-right"><i
            class="align-middle fas fa-fw fa-plus"></i></a>
      </div>
      <div v-if="vibequestions.length == 0" class="empty_employees empty_msg">No Vibe Questions found.</div>
      <div v-slimscroll="options">
      <div class="service-sec-rounded" v-for="(item,index) in vibequestions" :id="`card-vibeq-id-${item.id}`" >
        <div v-if="item.restaurant_id > 0" class="delete-vibe-que">
          <button  @click.prevent="delete_question(item)"   class="btn btn-sm float-right text-danger" ><i class="fa fa-trash" aria-hidden="true"></i></button>
          <a data-toggle="modal" data-target="#editQuestionModalPrimary" @click.prevent="edit_question(item)" class="btn btn-sm float-right text-danger"><i class="fa fa-edit" aria-hidden="true"></i></a>
        </div>
        <h2>{{item.title}}</h2>

        <div class="total-rating-star-services" v-if="item.type == 'rating'">

          <h3>{{star_rating_avg}}</h3>
          <star-rating :rating="star_rating_avg" :read-only="true" :increment="0.01" :star-size="35" :rounded-corners="true"  :show-rating="false"></star-rating>

        </div>
        <div class="" v-if="item.type == 'options' && item.vibe_options.length > 0" >
          <div class="clearfix yes-price-tag" v-for="(itemo,indexo) in item.vibe_options">
            <h5 class="float-left">
              <img v-if="itemo.icon_name !=''"  :src="'/img/frontend/vibe_que_icons/'+itemo.icon_name" :alt="itemo.icon_name">
              {{itemo.option_text}}
            </h5>
            <span class="float-right">{{itemo.received_sel_options_responses.length}}</span>
          </div>
        </div>

        <div class="total-pts-rewards">
          <div class="colum-pts-1">
            <h2>Reward</h2>
            <span>{{item.points}}pts</span>
          </div>
          <div class="colum-pts-1">
            <h2>Responses</h2>
            <span>{{item.received_responses_count}}</span>
          </div>
          <div class="colum-pts-1">
            <h2>Awards</h2>
            <span>0</span>
          </div>
        </div>
      </div>

      </div>

    </div>


    <div class="modal fade" id="addQuestionModalPrimary" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title add-employee-popup">Add Question</h5>
            <button type="button" class="close" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form method="POST" id="addque-form" name="addquefrm" @submit.prevent="submit"  data-parsley-validate >
          <div class="m-5">
            <label>Title</label>
            <input type="text" class="form-poup" v-model="fields.title" placeholder="Add Title" required>
            <label>Select Question Type</label>
            <select class="form-poup" v-model="fields.type" @change="handleOption($event)" required>
              <option value="">Select</option>
              <option value="rating">Rating</option>
              <option value="options">Options</option>
            </select>
            <div class="" v-if="showNumOptions">
              <label>Select Number of options</label>
              <select class="form-poup"   @change="generateInputs($event)" required>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div v-if="showNumOptions" class="border-bottom border-secondary mb-2">
              <label class=" font-weight-bold">Options</label>
            </div>
            <template v-if="showNumOptions" v-for="(n,nindex) in opt_inputs">
              <div class="row">
                <div class="col-6">
                  <input type="text" name="form_field" :key="nindex" v-model="fields.options_text[nindex]" class="form-poup" placeholder="Add options" required>
                </div>
                <div class="col-6 dropdown-add-question">
                  <v-select v-model="fields.options_icons[nindex]" :options="icons_list" :searchable="true" :clearable="false"  placeholder="Select Icon">
                    <template slot="option" slot-scope="option" >
                      <img :src="option.imagepath" /> {{option.text}}
                    </template>

                    <template slot="selected-option" slot-scope="option" :value="option.text">
                      <img :src=" option.imagepath" /> {{option.text}}
                    </template>
                  </v-select>
                </div>
              </div>
            </template>
            <label>Points</label>
            <input type="integer" name="form_field" class="form-poup" v-model="fields.points"  placeholder="10"required/>
            <button class="btn add-emp-btn" @click.prevent="add()" id="save_que">Add Question</button>
          </div>
          <div class="row text-center">
            <div v-if="errors" class="text-danger col-12">
              <div v-for="(v, k) in errors" :key="k">
                <p v-for="error in v" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>
            </div>
            <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
              <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                {{ problem_in_save_error }}
              </p>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Edit Vibe Question Start -->
    <div class="modal fade" id="editQuestionModalPrimary" @submit.prevent="submit" tabindex="-1" role="dialog"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title add-employee-popup">Edit Question</h5>
            <button type="button" class="close" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form method="POST" id="addque-form-edit" name="addquefrm-edit" @submit.prevent="submit_update"  data-parsley-validate>
          <div class="m-5">
            <label>Title</label>
            <input type="text" class="form-poup" v-model="fields.title"  required>
            <label>Select Question Type</label>
            <select class="form-poup" v-model="fields.type" @change="handleOption($event)" required>
              <option value="">Select</option>
              <option value="rating">Rating</option>
              <option value="options">Options</option>
            </select>
            <div class="" v-if="showNumOptions">
              <label>Select Number of options</label>
              <select class="form-poup"   @change="generateInputs($event)" required>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div v-if="showNumOptions" class="border-bottom border-secondary mb-2">
              <label class=" font-weight-bold">Options</label>
            </div>
            <template v-if="showNumOptions" v-for="(n,nindex) in opt_inputs">
              <div class="row">
                <div class="col-6">
                  <input type="text" name="form_field" :key="nindex" v-model="fields.options_text[nindex]" class="form-poup" placeholder="Add options" required>
                </div>
                <div class="col-6 dropdown-add-question">
                  <v-select v-model="fields.options_icons[nindex]" :options="icons_list" :searchable="true" :clearable="false"  placeholder="Select Icon">
                    <template slot="option" slot-scope="option" >
                      <img :src="option.imagepath" /> {{option.text}}
                    </template>

                    <template slot="selected-option" slot-scope="option" :value="option.text">
                      <img :src=" option.imagepath" /> {{option.text}}
                    </template>
                  </v-select>
                </div>
              </div>
            </template>
            <label>Points</label>
            <input type="integer" name="form_field" class="form-poup" v-model="fields.points"  required/>
            <button class="btn add-emp-btn" @click.prevent="update()" id="save_que-edit">Save Changes</button>
          </div>
          <div class="row text-center">
            <div v-if="errors" class="text-danger col-12">
              <div v-for="(v, k) in errors" :key="k">
                <p v-for="error in v" :key="error" class="text-sm">
                  {{ error }}
                </p>
              </div>
            </div>
            <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
              <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                {{ problem_in_save_error }}
              </p>
            </div>
          </div>
          </form>

        </div>
      </div>
    </div>
    <!-- Edit Vibe Question end -->
  </div>
</template>

<script>
    //import vibestarrating from  './VibeStarRatingComponent';
    export default {
      data() {
          return {

            options:{
                height:'800px',
                color: '#e9986f',
				        size: '10px',
                position: 'right',
                distance:'5px'
            },
            fields : {
              id:'',
              title:'',
              type:'',
              points:'',
              options_text:[],
              options_icons:[]
            },

            errors: {},
            iserrors: false,
            success: false,
            isLoading: false,
            loading:false,
            problem_in_save_error:'',
            vibequestions: {},
            showNumOptions:false,
            opt_inputs: 2,
            options_list:[2,3,4,5],
            icons_list:[],
            star_rating_avg:0
        }
      },
      // components:{
      //   'vibe-star-rating': vibestarrating
      // },
      created: function () {
        this.fetch_list();
        // this.interval = setInterval(() => {
        //   this.fetch_list();
        // },50000);
        this.get_icons_list();
      },
      methods:{
        fetch_list(){
          axios.get('/owner/vibe_questions/list').then(response => {
          this.vibequestions = response.data.response.vibeQuestionList;
          this.star_rating_avg = response.data.response.star_rating_avg;
          }).catch(error => {

          });
        },
        get_icons_list(){
          axios.get('/vibe_question_icon_list').then(response => {
          this.icons_list = response.data;
          }).catch(error => {

          });
        },
        add() {
          let vm = this;
          $('#addque-form').parsley().whenValidate({

          }).done(function() {
          vm.submit();
          });

        },
        handleOption(event) {
          if(event.target.value == 'options'){
            this.showNumOptions = true;
          }else{
            this.showNumOptions = false;
          }
        },
        generateInputs(event) {
          this.opt_inputs = parseInt(event.target.value);
        },

        submit() {
          let vm = this;
          $('#save_que').prop('disabled',true);
            vm.success = false;
            vm.errors = {};

            axios.post('/owner/vibe_questions/add', vm.fields).then(response => {

              this.vibequestions.push(response.data.response.vibeQuestion);
              //this.fetch_list();
              vm.success = true;
              $("#addQuestionModalPrimary .close").click();
              toastr.success('Question added successfully.');
              $('#save_que').prop('disabled',false);
              vm.fields.options_text = {};
              vm.showNumOptions = false;
              vm.fields.options_icons = {};
              vm.fields.title = ''; //Clear input fields.
              vm.fields.type='';
              vm.fields.points='';
            }).catch(error => {
              $('#save_que').prop('disabled',false);
              if (error.response.status === 400) {
                vm.problem_in_save_error = error.response.data.response.message || {};
              }
              else if (error.response.status === 422) {
                vm.errors = error.response.data.errors;
              }else{
                vm.errors = error.response.data.response.message || {};
              }
            });
        },

        delete_question: function (item) {
          let vm = this;

          if (item) {
          if(confirm("Do you really want to delete?")){
            let formData = new FormData();

            formData.append("id", item.id);
              axios.post('/owner/vibe_questions/destroy', formData).then(response => {
                this.vibequestions.splice(this.vibequestions.indexOf(item), 1);
                toastr.success('Question deleted successfully.');

              }).catch(error => {

              });
          }
          }
        },
        edit_question: function (item) {
          let vm = this;
          if (item) {
           vm.fields.id = item.id;
           vm.fields.title=item.title
           vm.fields.type=item.type
           vm.fields.points=item.points
           vm.fields.options_text=item.options_text
           vm.fields.options_icons=item.options_icons

          }
        },
        update() {
          let vm = this;
          $('#addque-form-edit').parsley().whenValidate({

          }).done(function() {
          vm.submit_update();
          });

        },
        submit_update() {
          let vm = this;
          $('#save_que-edit').prop('disabled',true);
            vm.success = false;
            vm.errors = {};
            axios.post('/owner/vibe_questions/updatevibe', vm.fields).then(response => {
              vm.fields = {}; //Clear input fields.
              // this.vibequestions.push(response.data.response.vibeQuestion);
              vm.success = true;
              $("#editQuestionModalPrimary .close").click();
              toastr.success('Question updated successfully.');
              $('#ave_que-edit').prop('disabled',false);
              vm.fields.options_text = {};
              vm.showNumOptions = false;
              vm.fields.options_icons = {};
              location.reload();
            }).catch(error => {
              $('#ave_que-edit').prop('disabled',false);
              if (error.response.status === 400) {
                vm.problem_in_save_error = error.response.data.response.message || {};
              }
              else if (error.response.status === 422) {
                vm.errors = error.response.data.errors;
              }else{
                vm.errors = error.response.data.response.message || {};
              }
            });
        },
      }
    }

</script>
