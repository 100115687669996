<template>
  <div style="width: 100%; height: 250px;">
    <div class="google-map" ref="googleMap"></div>
  </div>
</template>
<script type="text/javascript">
  import GoogleMapsApiLoader from 'google-maps-api-loader';
  export default {
    props: {
      mapConfig: Object,
      apiKey: String,
    },
    data() {
      return {
        google: null,
        map: null,
      }
    },
    async mounted() {
        const googleMapApi = await GoogleMapsApiLoader({
          apiKey: this.apiKey
        })
        this.google = googleMapApi
        this.initializeMap()
      },

  methods: {
    initializeMap() {
      //this.showPosition();
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      let infoWindow = new google.maps.InfoWindow({
        content: "Drag the pointer and select your location",
        position: this.mapConfig.center,
      });

      let marker = new google.maps.Marker({
        content: "Drag the pointer and select your location",
        position: this.mapConfig.center,
        map: this.map,
        draggable:true,
      });

      infoWindow.open(this.map);
      // marker.addListener("click", () => {
      //   this.map.setCenter(marker.getPosition());
      //   //console.log(marker.getPosition());
      // });

      marker.addListener('dragend',(mapsMouseEvent) => {
          let latlngarry = mapsMouseEvent.latLng.toJSON();
          let lat_value = latlngarry['lat'];
          let lng_value = latlngarry['lng'];
          this.$parent.fields.latitude = lat_value;
          this.$parent.fields.longitude = lng_value;
          $('#lat').val(lat_value);
          $('#lng').val(lng_value);
      });




      this.map.addListener("click", (mapsMouseEvent) => {
        // Close the current InfoWindow.
        infoWindow.close();
        // Create a new InfoWindow.
        infoWindow = new google.maps.InfoWindow({
          position: mapsMouseEvent.latLng,
        });

        if (marker && marker.setMap) {
            marker.setMap(null);
        }
        marker = new google.maps.Marker({
          position: mapsMouseEvent.latLng,
          map: this.map
        });

        // infoWindow.setContent(
        //
        //   JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
        // );
        //infoWindow.open(this.map);

        var latlngarry = mapsMouseEvent.latLng.toJSON();
        let lat_value = latlngarry['lat'];
        let lng_value = latlngarry['lng'];
        this.$parent.fields.latitude = lat_value;
        this.$parent.fields.longitude = lng_value;
        $('#lat').val(lat_value);
        $('#lng').val(lng_value);
      });
    },


    showPosition:()=> {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var positionInfo = "Your current position is (" + "Latitude: " + position.coords.latitude + ", " + "Longitude: " + position.coords.longitude + ")";
                alert(positionInfo);
                //document.getElementById("result").innerHTML = positionInfo;
            });
        } else {
            alert("Sorry, your browser does not support HTML5 geolocation.");
        }
    }


  },
  watch:{
    mapConfig:function(){
      this.initializeMap();
    }
  }


}




</script>
