<template>
  <div>
    <div class="d-flex justify-content-center" >
      <div class="spinner-border text-warning" role="status" id="attdnc-loader">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="clearfix" v-if="!loadingList">
      <h5 class="card-title mb-0 float-left">Who's at work</h5>
      <div class="ViewSchedulebtn">
        <a href="restaurant_employee/list" class="view-schedule-link mb-0 float-right">View Schedule</a>
      </div> 
    </div>

    <div class="user-list-img">
      <ul v-if="list.length > 0">
        <li v-for="item in list">
          <template v-for="empval in item.employee.restraurant_employee_attributes">
          <template v-if="empval.name == 'profile_photo'">
            <img v-if="empval.pivot.value !=''" :src="'/storage/employee_pictures/'+empval.pivot.value" alt="waiters-img">
            <img v-if="empval.pivot.value==''" src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" alt="">
          </template>
          </template>
          <img v-if="item.restraurant_employee_attributes == ''" src="https://gravatar.com/avatar/ee3bbaa0c537da55a11f2e818ab13ec5?s=80&amp;d=mp" alt="">
        </li>
      </ul>
      <ul v-else>
        <li id="emptywork">...</li>
      </ul>
    </div>
  </div>
</template>

<script>
    export default {
    props: ['restaurant_id'],
    data() {
        return {
          loadingList:true,
          list:{}
        }
    },

    created: function () {
      this.getEmployeesAttendance();
    },
    mounted(){
    },
    methods:{
      getEmployeesAttendance(){
        let formData = new FormData();
        formData.append("restaurant_id", this.restaurant_id);
        axios.post('/owner/restaurant_employee/get-employee-attendance', formData).then(response => {
          this.list = response.data.response.empattendance;
         
          if(this.list.length == 0){
            $('#emptywork').html('No one at work yet...<i class="far fa-frown-open fa-lg" style="color: #FFBE02;"></i>');
          }

          this.loadingList = false;
          $('#attdnc-loader').hide();
        }).catch(error => {

        });

      },
    }
  }
</script>
