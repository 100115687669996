<template>
    <div>
        <div class="content ">
            <div v-if="status !=''" class="delay_order">
                <p v-if="dely_time == 'closed'"><i class="far fa-clock"></i> This food truck is currently
                    closed but <span> offers preordering.</span>
                </p>
                <p v-else class="oder_text">Ordering has been dealyed for {{dely_time}} min.</p>
            </div>

            <!-- <p class="oder_text">Our kitchen is behind currently and orders are taking longer than usual.
                                            We promise it is worth the wait!</p> -->

            <div class="att_card">
            <div v-if="currentDateTime <= closedDateTime">
                <div v-if="dely_time !== 'closed'" class="card card_new" style="margin-bottom:10px;">
                    <div class="card-body">
                        <a :href="'/patron/restaurant/make-order/'+uuid" @click="removePreorder">
                            <div class="pickup">
                                <img :src="'../../../img/frontend/icons/order_pickup.png'" alt="">
                               
                               
                                <h5>Order for pick up now</h5>
                            </div>
                        </a>
                    </div>
                </div>
                <div v-else class="card card_new discard">
                    <div class="card-body">
                      
                            <div class="pickup">
                                <i class="fas fa-stopwatch"></i>
                                <h5>Order for pick up now</h5>
                            </div>
                      
                    </div>
                </div>
                <div v-if="preorderstatus !== null">
                <div v-if="preorderstatus === 1"  class="card card_new discard">
                    <div class="card-body">
                            <div class="pickup">
                                <i class="far fa-clock"></i>
                                <h5>Preorder for pickup later</h5>
                            </div>
                    </div>
                </div>
                <div v-else class="card card_new">
                    <div class="card-body">
                        <a :href="'/patron/restaurant/make-order/'+uuid" @click="setPreorder">
                            <div class="pickup">
                                <i class="far fa-clock"></i>
                                <h5>Preorder for pickup later</h5>
                            </div>
                        </a>
                    </div>
                </div>
                </div>
            </div>
            <div v-else>
                <div class="card card_new discard" style="margin-bottom: 10px;">
                    <div class="card-body">
                      
                            <div class="pickup">
                                <i class="fas fa-stopwatch"></i>
                                <h5>Order for pick up now</h5>
                            </div>
                      
                    </div>
                </div>
                <div v-if="preorderstatus !== null">
                <div v-if="preorderstatus === 1"  class="card card_new discard">
                    <div class="card-body">
                            <div class="pickup">
                                <i class="far fa-clock"></i>
                                <h5>Preorder for pickup later</h5>
                            </div>
                    </div>
                </div>
                <div v-else class="card card_new">
                    <div class="card-body">
                        <a :href="'/patron/restaurant/make-order/'+uuid" @click="setPreorder">
                            <div class="pickup">
                                <i class="far fa-clock"></i>
                                <h5>Preorder for pickup later</h5>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            </div>




            </div>
            <div class="content ower_logo">
                <h5>Powered by</h5>
                <img :src="'../img/frontend/firefly-final-logo.png'" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['restaurant_id','preorderstatus','uuid','currentDateTime','closedDateTime'],   
    data() {
        return {
          preorderstatus: 0,
          status:'',
          dely_time:'',
          dely_time_end:'',
        }
      },

      mounted(){
        this.getdelaydata();
      },
    methods: {
         getdelaydata() {
          let formData = new FormData();
          let restaurant_id = this.restaurant_id;
          formData.append("restaurant_id",restaurant_id);
          axios.post('/getorderdely-userend',formData).then(response => {
            this.status = response.data.data.status;          
            this.dely_time = response.data.data.data.dely_time;
            this.dely_time_end = response.data.data.data.dely_time_end;
            //console.log('response.data.data.delaytimeleft = '+response.data.data.delaytimeleft);
           if(response.data.data.data.status == 1){
              this.timer =  setTimeout(() => {this.deletedelay(response.data.data.data.id);}, response.data.data.delaytimeleft);
            }
          });
       },
       deletedelay(delayid) {
         console.log('delayid = '+delayid);
          let formData = new FormData();
          formData.append("delyid",delayid);
          axios.post('/deleteorderdelyuserend',formData).then(response => {
            console.log('response.data.data.status = '+response.data);
              if(response.data.data.status == 1){
                this.getdelaydata();
              }
          });
       },
       showMenus(rid, menutyp = '') {
             let restaurant_id = this.uuid;
            window.location.href = '{{url('/')}}/patron/restaurant/make-order/' + '{{$restaurant_table->uuid}}' + '/' + menutyp;
        },
        setPreorder(){
            localStorage.setItem('preorder',1);
        },
        removePreorder(){
            localStorage.removeItem('preorder');           
        }
    }
};
</script>