<template>
    <div>
         <div class="content" style="border-top: 1px solid #ccc;">
           <div class="setect_time_title">
                <div @click="redirectToAnotherPage()"> <i class="float-right fas fa-times"></i></div>
                        <h3>Select pick up date and time</h3>
                </div>
             <div class="calender_main_wrap">
                        <div class="calender_sec">
                            <div class="container">
                                    <div id="calendarEvent123"></div>
                            </div>
                        </div>
             </div>
            </div>
            
            
            <div class="date_header">

            <div v-for="(item, index) in events" >
                <div class="dt_tlt_dsbl">
                    <p>{{ currentDate(item.start_date) }}</p>
                </div>
                 <div class="sl_evt_tlt">
                    <p><i class="align-middle fas fa-map-marker-alt "></i> {{ item.name }} 
                        <br>
                    <span><i class="align-middle fas fa-clock" ></i>
                    {{ convertedStartTime(item.start_time) }} - {{ convertedEndTime(item.end_time) }}</span>
                    </p>
                    
                    <div class="time_dropdown">
                        <select class="form-poup" @change="handleSelectOption(item, $event.target.value)">
                            <option value="">Select</option>
                            <option v-for="time in generateTimeList(item.start_time, item.end_time)" :key="time.id">{{ time.time }}</option>
                        </select>
                    </div>
                </div>
            </div>
                <div class="time_btn" v-if="items.length > 0 || events.length > 0">
                    <button type="button" class="btn add-emp-btn" @click="handleClick"> Continue</button>
                </div>
         
            </div>
            
    </div>
</template>

<script>
export default {
  props: ['userid', 'restaurant_id'],
  data() {
    return {
      items: [],
      events: [],
      userid: null,
      timeList: [],
      isDataLoaded: false,
      restaurant_id: this.restaurant_id,
      selectedTimeOption: null,
      selectedItem: [],
      highlightedDates: []
    };
  },
  mounted() {
    const vm = this;
    vm.geteventdata().then(() => {
      // Ensure items is an array before mapping
      if (Array.isArray(vm.items)) {
        vm.highlightedDates = vm.items.map(item => item.event.start_date);
      } else {
        vm.highlightedDates = [];
        console.warn('Items is not an array:', vm.items);
      }

      $('#calendarEvent123').datepicker({
        inline: true,
        firstDay: 7,
        showOtherMonths: true,
        dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        beforeShowDay: vm.highlightEvent,
        onSelect: function (dateText, inst) {
          var clickedDate = $(this).datepicker('getDate');
          var formattedDate = $.datepicker.formatDate("yy-mm-dd", clickedDate);
          let formData = new FormData();
          formData.append("date", formattedDate);
          formData.append("restaurant_id", vm.restaurant_id);
          axios.post('/get-datetime-event-userend', formData).then(response => {
            vm.events = Object.values(response.data.dateevent)
              .filter(date => date.event !== null)
              .map(date => date.event);
            console.log(vm.events);

            let shouldstore = vm.items.some(item => item.event.start_date === formattedDate);
            vm.highlightedDates.push(shouldstore);
            vm.userid = response.data.userid;
            if (!Array.isArray(vm.events) || vm.events.length === 0) {
              // toastr.error('No event found for this date');
            }
          }).catch(error => {
            console.log(error);
          });
        }
      });
    });
  },
  methods: {
    geteventdata() {
      let formData = new FormData();
      formData.append("restaurant_id", this.restaurant_id);
      return axios.post('/get-datetime-eventdata-userend', formData).then(response => {
        console.log(response);
        if (response.data.events.length === 0) {
          toastr.error('No events Available.');
        }
        // Ensure dateevent is set as an array
        this.items = Array.isArray(response.data.dateevent) ? response.data.dateevent : [];
        console.log('this.items', this.items);
        this.isDataLoaded = true; // Set data retrieval flag
      }).catch(error => {
        console.log(error);
      });
    },
    highlightEvent(date) {
      if (!this.isDataLoaded) {
        return [true, '', ''];
      }
      // Format the current date to match the stored date format (YYYY-MM-DD)
      let formattedDate = this.formatDate(date);

      // Check if the formattedDate is included in any event's date range
      let isHighlighted = this.items.some(item => {
        let startDate = item.event.start_date;
        let endDate = item.event.end_date;
        return startDate <= formattedDate && formattedDate <= endDate;
      });

      // If the date is in any event's date range, highlight it
      if (isHighlighted) {
        return [true, 'event-highlight', '•'];
      } else {
        return [true, '', ''];
      }
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    generateTimeList(startTime, endTime) {
      const start = new Date();
      const [startHour, startMinute] = startTime.split(':');
      start.setHours(parseInt(startHour), parseInt(startMinute), 0);

      const end = new Date();
      const [endHour, endMinute] = endTime.split(':');
      end.setHours(parseInt(endHour), parseInt(endMinute), 0);

      const timeList = [];
      const timeInterval = 30 * 60 * 1000; // 30 minutes in milliseconds

      let index = 0;
      while (start <= end) {
        const time = {
          id: index,
          time: start.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })
        };
        timeList.push(time);
        start.setTime(start.getTime() + timeInterval);
        index++;
      }

      return timeList;
    },
    currentDate(item) {
      const date = new Date(item);
      return date.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric"
      });
    },
    convertedStartTime(time) {
      const [hours, minutes] = time.split(":");
      const d = new Date();
      d.setHours(hours);
      d.setMinutes(minutes);
      return d.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    convertedEndTime(time) {
      const [hours, minutes] = time.split(":");
      const d = new Date();
      d.setHours(hours);
      d.setMinutes(minutes);
      return d.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    handleSelectOption(item, selectedTime) {
      this.selectedTimeOption = selectedTime;
      this.selectedItem = item;
    },
    handleClick() {
      // Storing item and selectedTime in localStorage
      localStorage.setItem('item', JSON.stringify(this.selectedItem));
      localStorage.setItem('selectedTime', this.selectedTimeOption);
      history.back();
      $('.new-tablist-tabs a[href="#carttb"]').trigger('click');
    },
    redirectToAnotherPage() {
      history.back();
      $('.new-tablist-tabs a[href="#carttb"]').trigger('click');
    },
  },
};
</script>