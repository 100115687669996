<template>
    <div>
        <div class="col-md-6">
        <div class="calender_main_wrap">
            <div class="calender_sec">
                <div class="events-container"></div>
                <div v-if="mitems.length && mitems.length > 0" class="date_header b1">
                  <div v-for="(item, index) in mitems" @click="handleClick(item.id)" ref="button">
                  <div :class="[{'dt_tlt': item.id === activeEventId}, {'dt_tlt2': item.id !== activeEventId}]">
                      <p>{{ currentDate(item.start_date) }}</p>
                  </div>
                  <div :class="[{'evt_dtl': item.id === activeEventId}, {'evt_dtl2': item.id !== activeEventId}]">
                      <p class="loc_name"><i class="align-middle fas fa-map-marker-alt "></i>{{item.name}}</p>
                      <p><i class="align-middle fas fa-clock"></i>{{convertedStartTime(item.start_time)}} - {{convertedEndTime(item.end_time)}}</p> 
                      <span>
                          <p style="font-weight: 600;"><i class="align-middle fas fa-users"></i> {{item.estimated_attendance}}</p>
                         
                          <!-- <p style="float: right; width: 10%; margin-right: 13px;"><i class="align-middle far fa-heart"></i></p>  -->
                          <p style="float: right; width: 10%; margin-right: 4px; margin-top: 4px;">
                            <template v-if="item.likes && Array.isArray(item.likes) && item.likes.length > 0 && item.likes.status === 1">
                                <i class="align-middle fa fa-heart heart-red float-right"></i>
                            </template>
                            <template v-else>
                                <i class="align-middle far fa-heart float-right"></i>
                            </template>
                        </p>
                      </span> 
                  </div>
              </div>
              </div>
              <div v-else class='event-card text-center'>
                  <div class='event-name'>No events found </div>
              </div>
                    <!-- <event-market-place-list-component @handle-click-event="handleClickEvent"></event-market-place-list-component> -->
                </div>     
            </div>
        </div>
        <div class="col-md-6" style=" padding: 0; float: right;">
          <event-market-place-detail-component :firstmitems="firstmitems" :cards="cards" v-if="loaded"></event-market-place-detail-component>
      </div>
  </div>
</template>
<script>
export default {
    data(){
      return {
        firstmitems:[],
        cards:[],
        mitems:[],
        temp:[],
        loaded:false,
        isDataLoaded: false,
        activeEventId: null,
        highlightedDates: [],
        userid:null,
        restaurant_id:null
      }
  },
    mounted(){
    const vm = this;
     vm.geteventMarketPlacedata().then(() => {
      vm.highlightedDates = vm.mitems.map(item => item.start_date);
   $('#calendar1').datepicker({
            inline:true,
            firstDay: 7,
            showOtherMonths:true,
            dayNamesMin:['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            beforeShowDay: vm.highlightEvent,
            onSelect: function(dateText, inst) {
                var clickedDate = $(this).datepicker('getDate');
                var clickedTimestamp = clickedDate.getTime();
                var formattedDate = $.datepicker.formatDate("yy-mm-dd", clickedDate);
                let formData = new FormData();
                formData.append("date", formattedDate);
                formData.append("time", clickedTimestamp);
                axios.post('/owner/get-eventmarket-place-date-data',formData).then(response => {
                    vm.temp = response.data.future_bookings;
                    if(!Array.isArray(vm.temp) || vm.temp.length === 0){
                       // toastr.error('No event found for this date');
                    }else{
                        vm.mitems = vm.temp;  
                        let shouldstore = vm.mitems.some(item => item.start_date === formattedDate);
                        vm.highlightedDates.push(shouldstore);
                        vm.handleClick(vm.mitems[0].id);
                    }

                }).catch(error => {
                console.log(error);
                });
             }
            });
          });
    },
    methods: {
      geteventMarketPlacedata(){
       return axios.get('/owner/get-eventmarket-place-data').then(response => {
        console.log('/owner/get-eventmarket-place-data');
        console.log(response.data.future_bookings);
            Vue.set(this, 'mitems', response.data.future_bookings);
            this.isDataLoaded = true; 
            this.handleClick(this.mitems[0].id);
        }).catch(error => {
          console.log(error);
        });
      },
    // highlightEvent(date) {
    //      if (!this.isDataLoaded) {
    //         return [true, '', ''];
    //         }
    //         let formattedDate = $.datepicker.formatDate('yy-mm-dd', date);
    //         if(this.highlightedDates.includes(formattedDate)){
    //             return [true, 'event-highlight', '•'];
    //         }else {
    //          return [true, '', ''];
    //         }
    //     },
    highlightEvent(date) {
    if (!this.isDataLoaded) {
        return [true, '', ''];
    }
    
    // Format the current date to match the stored date format (YYYY-MM-DD)
    let formattedDate = formatDate(date);
    // Function to format the date to YYYY-MM-DD
    function formatDate(date) {
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Check if the formattedDate is included in any event's date range
    let isHighlighted = this.mitems.some(item => {
        let startDate = item.start_date;
        let endDate = item.end_date;
        return startDate <= formattedDate && formattedDate <= endDate;
    });

    // If the date is in any event's date range, highlight it
    if (isHighlighted) {
        return [true, 'event-highlight', '•'];
    } else {
        return [true, '', ''];
    }
},
    currentDate(item){
      const date = new Date(item);
      return date.toLocaleDateString("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    },
     handleClick(itemid){
        let formData = new FormData();
        formData.append("id", itemid);
        this.activeEventId = itemid;
        axios.post('/owner/get-eventmarket-place-single-data',formData).then(response => {
          console.log('get-eventmarket-place-single-data');
            this.cards = response.data.cards;
            this.firstmitems = response.data.future_bookings;
            this.loaded = true;
            console.log(this.cards);
            console.log(response.data.future_bookings);
          //  this.$emit('handle-click-event', this.mitem);
        }).catch(error => {
          console.log(error);
        });
       
    },
    
    convertedStartTime(time) {
      const [hours, minutes] = time.split(":");
      const d = new Date();
      d.setHours(hours);
      d.setMinutes(minutes);
      return d.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
    },
    convertedEndTime(time) {
      const [hours, minutes] = time.split(":");
      const d = new Date();
      d.setHours(hours);
      d.setMinutes(minutes);
      return d.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
    },
  },
}
</script>