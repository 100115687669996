<template>
  <div id="menu2" class="container tab-pane fade"><br>
    <div class="clearfix table-card-title-head">
      <h5 class="card-title mb-0 float-left table-card-title"><strong>3 of
          25</strong> Orders Completed&nbsp;&nbsp;&nbsp;<span><i
            class="align-middle  far fa-fw fa-clock"></i>23:34</span></h5>
      <a href="#" class="manage-tables-btn mb-0 float-right">History</a>
    </div>
    <div class="row">
      <!-- table1 -->
      <div class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="history-table-card">
          <div class="user-headeading-booking-history clearfix">
            <div class="float-left">
              <div><a href="#">Andrea</a>
              </div>
            </div>
          </div>
          <hr>
          <div class="outer-food-details-history">
            <div class="row">
              <div class="col-sm-7 col-7"  style="padding-right: 0;">
                <div class="order-name">
                  <span>1</span>
                  <p>RoastedTurkey Sandwich</p>
                </div>
              </div>
              <div class="col-sm-3 col-3" style="padding-right: 0;">
                <div class="item-price-history">
                  <span>23:34</span>
                </div>
              </div>
              <div class="col-sm-2 col-2" style="padding-left: 0;">
                <div class="history-check">
                  <span><i class="fa fa-check-square" aria-hidden="true"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div class="outer-food-details-history">
            <div class="row">
              <div class="col-sm-7 col-7"  style="padding-right: 0;">
                <div class="order-name">
                  <span>1</span>
                  <p>RoastedTurkey Sandwich</p>
                </div>
              </div>
              <div class="col-sm-3 col-3" style="padding-right: 0;">
                <div class="item-price-history">
                  <span>23:34</span>
                </div>
              </div>
              <div class="col-sm-2 col-2" style="padding-left: 0;">
                <div class="history-check">
                  <span><i class="fa fa-check-square" aria-hidden="true"></i></span>
                </div>
              </div>
            </div>
          </div>
          <!-- gree-color -->
          <div class="outer-food-details-history-green">
            <div class="row">
              <div class="col-sm-7 col-7"  style="padding-right: 0;">
                <div class="order-name-green">
                  <span>1</span>
                  <p>RoastedTurkey Sandwich</p>
                </div>
              </div>
              <div class="col-sm-3 col-3" style="padding-right: 0;">
                <div class="item-price-history-green">
                  <span>23:34</span>
                </div>
              </div>
            </div>
          </div>
          <!-- gree-red -->
          <div class="outer-food-details-history-red">
            <div class="row">
              <div class="col-sm-7 col-7"  style="padding-right: 0;">
                <div class="order-name-green">
                  <span>1</span>
                  <p>RoastedTurkey Sandwich</p>
                </div>
              </div>
              <div class="col-sm-3 col-3" style="padding-right: 0;">
                <div class="item-price-history-green">
                  <span>23:34</span>
                </div>
              </div>
            </div>
          </div>
          <div class="total-timing-price-history clearfix">
            <div class="float-left left-timing tab-top-history">
              <i class="align-middle  far fa-fw fa-clock"></i><a
                href="#">23:34</a>
            </div>
            <div class="float-right total-price-number-hostory">
              <span>$ 14.00</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created: function () {},
  methods: {
    kitchen_orders_list(){
      axios.post('/owner/orders/kitchen_orders_list').then(response => {
        console.log(response);
      }).catch(error => {

      });
    }
  },
};
</script>
