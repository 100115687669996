<style>
/* green background */
  .history-green {
    background: #00a51c;
    padding-top: 10px;
}
.history-green span{
color: #fff;
}
.history-green p{
color: #fff;
}
/* red background */
  .history-red {
    background: #d80b0bf7;
}
.history-red span{
color: #fff;
}
.history-red p{
color: #fff;
}
</style>

<template>

  <div id="kitchenmenu" class="container tab-pane fade"><br>
    <!-- <div class="clearfix table-card-title-head">
      <h5 class="card-title mb-0 float-left table-card-title"><strong>{{prepareditems}} of
          {{totalitems}}</strong> Orders Completed&nbsp;&nbsp;&nbsp;<span v-if="tolavgCompletionTime > 0">
            <i class="align-middle  far fa-fw fa-clock"></i>{{tolavgCompletionTime}}m avg completion</span></h5>
      
    </div> -->
    <div class="row kitchencard" id="menu-emp-id">
      <span class="loader" >
      <span class="content  text-center"><i class="fa fa-spinner fa-spin"></i> Please wait...</span>
      </span>
      <!-- table1 -->
      <div v-for="(item,index) in orders" class="row" style="width: 100%;">
      <div v-for="(sitem,sindex) in item.seats" class="col-md-4">
      <div v-if="sitem.cart_items && sitem.cart_items.length > 0">
       <div v-if="sitem.order.kitchen_status == null">
        <div v-if="sitem.closeCard == 0">
        <div class="history-table-card">
           <div class="user-headeading-booking clearfix">
              <div class="float-left">
                <div v-if="(sitem.order.guest_user_details != '')">
                  <span class="orderno" v-if="order = JSON.parse(sitem.order.guest_user_details)">{{order.firstname}} {{order.last_name}} :</span>
                </div>
                <div v-if="(sitem.order.user_id > 0)">
                  <span class="orderno" >{{sitem.order.orderuser.first_name}} {{sitem.order.orderuser.last_name}} :</span>
                </div>
              </div>
               <div class="float-right table-number">
                <span :class="`table-c-${sitem.order.id}`">{{sitem.order.id}}</span>
              </div>
           </div>
          <hr>
          <!-- Normal Table -->
          <div v-for="(citem, mindex) in sitem.cart_items" class="outer-food-details-history">
            <div :class="{ 'blank': citem.prepared == 0, 'history-green': citem.prepared == 1,'history-red': citem.cancelled == 1 }" class="row" :id="`menu-card-id-${citem.id}`">
              <div class="col-sm-6 col-6"  style="padding-right: 0;">
                <div class="order-name">
                  <span>{{citem.quantity}}</span>
                   <p>{{citem.menuitem.title}}
                    <br>
                    <span title="click to see special request" style="cursor: pointer;color:#ff5802;font-size: 12px;" @click="getSpecialRequestdata2(citem)" v-if="citem.other_data && citem.prepared == 0">{{(citem.other_data).substring(0,8)+"..." }}</span>
                  </p>
                </div>
              </div>
              <div class="col-sm-3 col-3" style="padding-right: 0;">
                <div class="item-price-history">
                  <span :id="'kttimer_clk_'+citem.id">{{calltablescantimer(citem.created_at,'kttimer_clk_'+citem.id)}}</span>
                </div>
              </div>
              <div class="col-sm-3 col-3" style="padding-left: 0;">
                <div v-if="citem.prepared == 0" class="history-check">
                  <button class="btn kitchen_done_btn" :id="`kitchen_done_btn_${citem.id}`" @click="mark_menu_done(citem.id,index)">Done</button>
                  <!-- <span><i class="fa fa-check-square" aria-hidden="true"></i></span> -->
                </div>
             
              </div>
            </div>
          </div>

           <!-- Normal Table end -->

          <!-- green-color -->
          <!-- end -->
          
          <!-- red -->
          <!-- end -->
          <div class="total-timing-price-history clearfix">
            <div v-if="item.tot_card_avgCompletionTime > 0" class="float-left left-timing tab-top-history">
              <i class="align-middle  far fa-fw fa-clock"></i><a
                >{{item.tot_card_avgCompletionTime}}m</a>
            </div>
            
            <div class="float-right total-price-number-hostory" v-if="(sitem.cart_items.length != sitem.preparedcart_items.length)">
              <button class="btn kitchen_complete_btn" @click="preparedAllOrderMenus(sitem.cart_items,sindex)">Complete</button>
            </div>
            <div class="float-right total-price-number-hostory" v-if="(sitem.cart_items.length == sitem.preparedcart_items.length)">
              <button class="btn kitchen_completed_btn" ><i class="fa fa-check" aria-hidden="true"></i> Completed</button>
            </div>
          </div>
        </div>
        </div>
        </div>
        </div>
      </div>
      </div>
      <!--to go orders-->
      <!-- model list of special request start -->
     
      <div class="modal fade" id="specialrequest_modelkitchen" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-header" style="background-color: #4256ce;">
                <h4 class="modal-title" style="color: #fff;">Special Requests:</h4></div>
            <div class="modal-body" v-if="sprequest">
             <ul class="list-group">
                  <li class="list-group-item">{{sprequest}}</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- model list of special request end -->
      <onsite-togo-kitchen-orders-component :restaurant_id="restaurant_id"></onsite-togo-kitchen-orders-component>
      
      <bar-kitchen-orders-component></bar-kitchen-orders-component>
    </div>
  </div>
</template>

<script>

    export default {
    props: ['restaurant_id'],
    data() {
        return {
          prepareditems:0,
          totalitems:0,
          sprequest:'',
          tolavgCompletionTime:'',
          cancelleditm:0,

          orderslength:0,
          sp_request:[],
          sprequest_data:[],
          activeTabInline:null,
          activeTab: null,
          activeMenuTitle:null,
          load_menu_items:false,
          // menu_items:{},
          // menu_categories:[],
          orders:[],
          foodtruck_orders:[],
          cartitemtotal:'',
          orderitemtotal:'',
          validation_errors:{},
          error:'',
          errors:{},
          selectedseatid:'',
          stindex:''
        }
    },

    created: function () {
      this.getRestaurantCurrentOrders();
    },
    mounted() {

      let vm = this;
    Echo.channel('item-confirmed-in-cart.'+vm.restaurant_id)
    .listen('ConfirmCartItem', (e) => {
          vm.getRestaurantCurrentOrders();
    });
    Echo.channel('item-or-seatitem-changes.'+vm.restaurant_id)
    .listen('CartItemOrSeatUpdated', (e) => {
          vm.getRestaurantCurrentOrders();
    });
  },
    methods:{
      getSpecialRequestdata2(item){
        console.log('Test-1');
        console.log(item);
         $('#specialrequest_modelkitchen').modal('show');
        this.sprequest = item.other_data;
      },
      getRestaurantCurrentOrders(){

        axios.post('/owner/orders/get_kitchen_orders').then(response => {
          this.orders = response.data.response.table_list.table_rows;
          this.prepareditems = response.data.response.tol_prepared_items;
          this.totalitems = response.data.response.tol_items;
          this.tolavgCompletionTime = response.data.response.tolavgCompletionTime;
            // var len = Object.keys(this.orders);
            // var i = len[0];
            // this.foodtruck_orders = this.orders[i];
           
          this.orderslength = response.data.response.totl_tbls;
          if(this.orderslength == 0){
            $('.empty_order_text').text('No Order Found...');
          }
        }).catch(error => {

        });
      },
      getRecentScanTableData(tid,rid){
        let formData = new FormData();
        formData.append("table_id", tid);
        formData.append("restaurant_id", rid);
        axios.post('/owner/orders/get_scan_table_detail',formData).then(response => {
          //console.log(response.data.response.table_row);
          this.orders.push(response.data.response.table_row);
          this.orderslength = this.orderslength + 1;
          if(this.orderslength == 0){
            $('.empty_order_text').text('No Order Found...');
          }
        }).catch(error => {

        });
      },       
      padTime(num) {
       return ("0"+num).slice(-2);
     },
     calltablescantimer(scanDate,elmId){
         let vm = this;
         setInterval(function(){
           let startTime = moment(scanDate);
           let endTime = moment();
           let minutes = endTime.diff(startTime, 'minutes');
           let time = minutes;
             $("#"+elmId).html(time+'m');
           }, 1000);

     },
      mark_menu_done(item_row_id,cindex,mcnt=null){
      $('#kitchen_done_btn_'+item_row_id).prop('disabled',true);
      let formData = new FormData();
      formData.append("item_id", item_row_id);
      formData.append("menu_cnt", mcnt);
      axios.post('/owner/orders/prepared_menu_item',formData).then(response => {
        $('#menu-emp-id').addClass('loading-spin');
        console.log('response.data.response.avgCompletionTime');
       // console.log(response);
        setTimeout( () => {
          $('#menu-emp-id').removeClass('loading-spin');
          this.prepareditems = this.prepareditems+1;
          this.getRestaurantCurrentOrders();
          //$('#cardavgtimecomplete_'+cindex).text(response.data.response.avgCompletionTime+'m');
        }, 5000);

        $('#kitchen_done_btn_'+item_row_id).prop('disabled',false);
      }).catch(error => {
        $('#kitchen_done_btn_'+item_row_id).prop('disabled',false);
      });
    },
      preparedAllOrderMenus(menuitemssrr,cindex){
       let menu_item_list_ids=[];
       $.each(menuitemssrr, function(key, value) {
         menu_item_list_ids.push(value.id);
       });

       this.mark_menu_done(menu_item_list_ids,cindex,'all');
     },

},
    computed: {
    }

    }
</script>
