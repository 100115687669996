<template>
  <div>
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close handheld-close-popup" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <form method="POST" id="signupform" name="signupform"  data-parsley-validate  @submit.prevent="submit">
          <div class="patron_signup_item create-account-diner">
            <fieldset class="m-3" v-if="step == 1">
              <h4>Create Account</h4>
              <p>Create a new user and add you're on the way.</p>
              <div >
                <input type="email" name="form_field" v-model="fields.email" class="form-poup"
                  placeholder="Email Address" data-parsley-required-message="Please enter email address"  required >
                <input type="password" name="form_field" v-model="fields.password" id="p_password"  class="form-poup"
                    placeholder="Password" data-parsley-required-message="Please enter password"  required >
                    <!-- data-parsley-equalto="#p_password" -->
                <input type="password" name="form_field" v-model="fields.confirm_password"  class="form-poup"
                        placeholder="Confirm password" data-parsley-required-message="Please enter confirm password" data-parsley-equalto-message="Confirm Password should be same as password" required >
                <input type="text" name="form_field" v-model="fields.first_name" class="form-poup"
                        placeholder="First Name" data-parsley-required-message="Please enter your first name"  required >
                <input type="text" name="form_field" v-model="fields.last_name" class="form-poup"
                                placeholder="Last Name" data-parsley-required-message="Please enter your last name"  required >
                <input type="text" name="form_field" v-model="fields.billing_address" class="form-poup"
                                placeholder="Billing Address" data-parsley-required-message="Please enter Billing Address" required >

                
                
                <select class="form-poup" data-parsley-required-message="Please select state" v-model="fields.state" required  @change='getCities()'>
                  <option value="" selected>-- Select State --</option>
                  <option v-for="state in states"  :value="state.id"> {{ state.state_name }}
                  </option>
                </select>
                <select class="form-poup" data-parsley-required-message="Please select city" v-model="fields.city" required>
                  <option value="" selected>-- Select City --</option>
                  <option v-for='city in cities' :value='city.id'>{{ city.city }}</option>
                </select>
                <input type="text" name="form_field" v-model="fields.billing_zipcode"  class="form-poup"
                  placeholder="Billing Zip code" data-parsley-required-message="Please enter billing zip code" v-on:keyup="removeCharForminput($event)" pattern="[0-9]+" maxlength="5" minlength="5" required >
              </div>
              <button class="btn btn-create" id="saveBtn">Create Account</button>
              <div v-if="isLoading">Please Wait...</div>
              <div class="col-md-10 offset-md-1">
                <div class="row">
                  <div class="col-md-12">
                    <div v-if="errors" class="text-danger restaurant-reg-errors float-left">
                      <div v-for="(v, k) in errors" :key="k">
                        <p v-for="error in v" :key="error" class="text-sm">
                          {{ error }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div v-if="problem_in_save_error" class="text-danger restaurant-reg-errors float-left">
                      <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                        {{ problem_in_save_error }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>


          </div>
        </form>

      </div>
    </div>
  </div>
</template>
<script src="{{asset('js/frontend/parsley.js')}}" type="text/javascript"></script>
<script>
export default {
props: ['country_id'],
data() {
    return {
      step: 1,
      fields: {
        first_name:'',
        last_name:'',
        email:'',
        password:'',
        confirm_password:'',
        city:'',
        state:'',
        billing_address:'',
        billing_zipcode:'',
        paymentForm:1

      },
      states:{},
      cities:{},
      showprev:false,
      errors: {},
      success: false,
      isLoading: false,
      loaded: true,
      problem_in_save_error:''
    }
},
created: function () {
  let formData = new FormData();

  formData.append("country_id", this.country_id);
  axios.post('/get-states',formData).then(response => {
    this.states = response.data.response.states;
  }).catch(error => {

  });
},
methods:{
  removeCharForminput(ev){
    var pv = ev.target.value;
    if (pv.match(/[^0-9]/g) !== null) {
        ev.target.value ='';
    }
  },
  getCities: function() {
    let vm = this;
    let formData = new FormData();
    formData.append("state_id", vm.fields.state);
    axios.post('/get-cities',formData).then(response => {
      vm.cities = response.data.response.cities;
    }).catch(error => {
    });
  },

  submit: function() {
    let vm = this;

      $('#saveBtn').prop('disabled',true);
      axios.post('/patron/register',vm.fields).then(response => {
        alert(response.data.response.message);
        $("#signUpModalPrimary2 .close").click();
        vm.form_field =  {};
        location.reload();
      }).catch(error => {
        console.log(error);
        vm.showprev = true;
        vm.loaded = true;
        console.log(error.response.data.errors);
        console.log(error.response.status);
        if (error.response.status === 400) {
          vm.problem_in_save_error = error.response.data.response.message || {};
          $('#saveBtn').prop('disabled',false);
          vm.isLoading = false;
          vm.enableprev = true;
        }else{
          vm.errors = error.response.data.errors || {};
          $('#saveBtn').prop('disabled',false);
          vm.isLoading = false;
          vm.enableprev = true;
        }
      });
    // });
  }
},
}
</script>
