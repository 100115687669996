<template>
    <div>
    <form @submit.prevent="submitForm">
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
         <button class="mr-l activated_menu_btn" type="submit" :disabled="isSubmitting">Submit</button>
    </form>
    </div>
</template>
<style>
.ck.ck-editor {
 height: 200px !important;
}
.ck.ck-content.ck-editor__editable {
    height: 120px;
}
.ck .ck-editor__main p{
font-size: 14px !important;
}
</style>
<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        data() {
            return {
                editor: ClassicEditor,
                editorData: '',
                isSubmitting: false,
                editorConfig: {
                    placeholder: "Enter your text here..."
                }
            };
        },
        mounted() {
                this.fetchEditorData();
            },
        methods: {
             fetchEditorData() {
                    axios.get('/owner/get-save-about').then(response => {
                            this.editorData = response.data.data; // Assuming the content is returned in the 'content' field
                        console.log(response.data.data);
                        }).catch(error => {
                            console.error(error);
                    });
                },
            submitForm() {
                 this.isSubmitting = true;
                const formData = new FormData();
                formData.append('content', this.editorData);
                axios.post('/owner/manage-restaurant/save',formData).then(response => {
                    if(response.data.status){
                        toastr.success(response.data.message);
                        this.editorData = formData.get('content');
                      }else{
                        toastr.error(response.data.message)
                      }
                    setTimeout(() => {
                                window.location.href = '/owner/manage-restaurant';
                            }, 1000);
                    console.log(response.data);
                }).catch(error => {
                    console.error(error);
                }).finally(() => {
                    this.isSubmitting = false;
                });
            }
            }
    }
</script>
