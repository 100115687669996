<template>
  <div id="kitchenmenu" class="container tab-pane fade"><br>
    <div class="clearfix table-card-title-head">
      <h5 class="card-title mb-0 float-left table-card-title"><strong>{{prepareditems}} of
          {{totalitems}}</strong> Orders Completed&nbsp;&nbsp;&nbsp;<span v-if="tolavgCompletionTime > 0">
            <i class="align-middle  far fa-fw fa-clock"></i>{{tolavgCompletionTime}}m avg completion</span></h5>
      <a href="#" class="manage-tables-btn mb-0 float-right">History</a>
    </div>
    <div class="row kitchencard" id="menu-emp-id">
      <span class="loader" >
      <span class="content  text-center"><i class="fa fa-spinner fa-spin"></i> Please wait...</span>
      </span>
      <!-- table1 -->

      <div v-for="(item,index) in empcards" class="col-12 col-md-6 col-lg-6 col-xl-4">
        <div class="history-table-card">
          <div class="user-headeading-booking-history clearfix">
            <div class="float-right">
            <!-- {{getSpecialRequest(item)}} -->

            <!-- <span style="cursor: pointer;background-color: #007bff;font-size:smaller" @click="getSR(item.customer_menus_items)" class="badge badge-danger" v-if="sp_request.length != ''">SR </span> -->
            </div>
            <div class="float-left">
              <div><a href="#">{{item.employee.user.name}} </a>
              </div>


            </div>
          </div>
          <hr>

          <div v-for="(menu_row, mindex) in item.customer_menus_items" class="outer-food-details-history">
            <div class="row" :id="`menu-card-id-${menu_row.id}`">
              <div class="col-sm-6 col-6"  style="padding-right: 0;">
                <div class="order-name">
                  <span>{{menu_row.quantity}}</span>
                  <p>{{menu_row.menuitem.title}}
                    <br>
                    <span title="click to see special request" style="cursor: pointer;color:#ff5802;font-size: 12px;" @click="getspecialRequest(menu_row)" v-if="menu_row.other_data">{{ (menu_row.other_data).substring(0,8)+"..." }}</span>
                  </p>

                </div>
              </div>
              <div class="col-sm-3 col-3" style="padding-right: 0;">
                <div class="item-price-history">
                  <span :id="'kttimer_clk_'+menu_row.id">{{calltablescantimer(menu_row.created_at,'kttimer_clk_'+menu_row.id)}}</span>
                </div>
              </div>
              <div class="col-sm-3 col-3" style="padding-left: 0;">
                <div class="history-check">
                  <button class="btn kitchen_done_btn" :id="`kitchen_done_btn_${menu_row.id}`" @click="mark_menu_done(menu_row.id,index)">Done</button>
                  <!-- <span><i class="fa fa-check-square" aria-hidden="true"></i></span> -->
                </div>
              </div>
            </div>
          </div>

          <!-- gree-color -->
          <div v-for="(menu_row, mindex) in item.customer_menus_items_prepared" class="outer-food-details-history-green">
            <div class="row">
              <div class="col-sm-7 col-7"  style="padding-right: 0;">
                <div class="order-name-green">
                  <span>{{menu_row.quantity}}</span>
                  <p>{{menu_row.menuitem.title}}</p>
                </div>
              </div>
              <div class="col-sm-3 col-3" style="padding-right: 0;">
                <div class="item-price-history-green">
                  <span>{{menu_row.menuReceivedAndPreparedTime}}m</span>
                </div>
              </div>
              <div class="col-sm-2 col-2" style="padding-left: 0;">
                <div class="prepared-check">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- gree-red -->
          <div v-for="(menu_row, mindex) in item.customer_menus_items_cancelled" class="outer-food-details-history-red">
            <div class="row">
              <div class="col-sm-7 col-7"  style="padding-right: 0;">
                <div class="order-name-green">
                  <span>{{menu_row.quantity}}</span>
                  <p>{{menu_row.menuitem.title}}</p>
                </div>
              </div>
              <div class="col-sm-5 col-5" style="padding-right: 0;">
                <div class="item-price-history-green">
                  <span>CANCELLED</span>
                </div>
              </div>
            </div>
          </div>
          <div class="total-timing-price-history clearfix">
            <div v-if="item.tot_card_avgCompletionTime > 0" class="float-left left-timing tab-top-history">
              <i class="align-middle  far fa-fw fa-clock"></i><a
                >{{item.tot_card_avgCompletionTime}}m</a>
            </div>
            <div class="float-right total-price-number-hostory" v-if="item.customer_menus_items.length > 0">
              <button class="btn kitchen_complete_btn" @click="preparedAllOrderMenus(item.customer_menus_items,index)">Complete</button>
            </div>
            <div class="float-right total-price-number-hostory" v-else>
              <button class="btn kitchen_completed_btn" ><i class="fa fa-check" aria-hidden="true"></i> Completed</button>
            </div>
          </div>
        </div>
      </div>
      <!--to go orders-->
       <!-- model list of special request start -->

      <div class="modal fade" id="specialrequest_model2" tabindex="-1" role="dialog"
        aria-hidden="true" data-backdrop="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="modal-header" style="background-color: #4256ce;">
                <h4 class="modal-title" style="color: #fff;">Special Requests:</h4></div>
            <div class="modal-body" v-if="sprequest">
             <ul class="list-group">
                  <li class="list-group-item">{{sprequest}}</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- model list of special request end -->
      <onsite-togo-kitchen-orders-component :restaurant_id="restaurant_id"></onsite-togo-kitchen-orders-component>
      <bar-kitchen-orders-component></bar-kitchen-orders-component>
    </div>
  </div>
</template>

<script>
export default {
  props: ['restaurant_id'],
  data() {
    return {
      empcards:[],
      prepareditems:0,
      totalitems:0,
      specialrequest:[],
      sp_request:{},
      sprequest:'',
      sprequest_data:[],
      tolavgCompletionTime:'',
      cancelleditm:0
    };
  },

  created: function () {
    this.menu_orders();
  },
  mounted() {
    let vm = this;
    Echo.channel('item-confirmed-in-cart.'+vm.restaurant_id)
    .listen('ConfirmCartItem', (e) => {
          vm.menu_orders();
    });
    Echo.channel('item-or-seatitem-changes.'+vm.restaurant_id)
    .listen('CartItemOrSeatUpdated', (e) => {
          vm.menu_orders();
    });
  },
  methods: {
    menu_orders(){
      let formData = new FormData();
      formData.append("restaurant_id", this.restaurant_id);
      axios.post('/owner/orders/menu_orders_for_kitchen',formData).then(response => {

        this.empcards = response.data.response.card_list.card_rows;
        this.prepareditems = response.data.response.tol_prepared_items;
        this.totalitems = response.data.response.tol_items;
        this.tolavgCompletionTime = response.data.response.tolavgCompletionTime;


      }).catch(error => {

      });
    },
    mark_menu_done(item_row_id,cindex){
      $('#kitchen_done_btn_'+item_row_id).prop('disabled',true);
      let formData = new FormData();
      formData.append("item_id", item_row_id);
      axios.post('/owner/orders/prepared_menu_item',formData).then(response => {
        $('#menu-emp-id').addClass('loading-spin');

        console.log(response.data.response.avgCompletionTime);

        setTimeout( () => {
          $('#menu-emp-id').removeClass('loading-spin');
          this.prepareditems = this.prepareditems+1;
          this.menu_orders();
          //$('#cardavgtimecomplete_'+cindex).text(response.data.response.avgCompletionTime+'m');
        }, 5000);

        $('#kitchen_done_btn_'+item_row_id).prop('disabled',false);
      }).catch(error => {
        $('#kitchen_done_btn_'+item_row_id).prop('disabled',false);
      });
    },
     getSR_old(items){
       $('#specialrequest_model2').modal('show');
        this.sprequest_data = items;
        // console.log('specialrequest_model2');
        // console.log(this.sprequest_data);

    },
    getspecialRequest(item){
        $('#specialrequest_model2').modal('show');
        this.sprequest = item.other_data;
      },
    getSpecialRequest_old(item, index){
      let store = Array();
     let arr = item.customer_menus_items;
     arr.forEach((v, i) => {
       if(v.other_data){
         store.push(v.other_data);
       }
     });

      this.sp_request = store;
      console.log(this.sp_request);
      },
    padTime(num) {
       return ("0"+num).slice(-2);
     },
     calltablescantimer(scanDate,elmId){
         let vm = this;
         setInterval(function(){
           let startTime = moment(scanDate);
           let endTime = moment();
           let minutes = endTime.diff(startTime, 'minutes');
           let time = minutes;
             $("#"+elmId).html(time+'m');
           }, 1000);

     },
     preparedAllOrderMenus(menuitemssrr,cindex){
      console.log('menuitemssrr');
      console.log(menuitemssrr);
       let menu_item_list_ids=[];
       $.each(menuitemssrr, function(key, value) {
         menu_item_list_ids.push(value.id);
       });

       this.mark_menu_done(menu_item_list_ids,cindex);

       console.log('menu_item_list_ids');
       console.log(menu_item_list_ids);
     }
  },
};
</script>
