<template>
  <div>
      <div class="Menu_wrap" style="padding:15px;">
          <div v-if="menus.length > 0">
          <div v-for="(item,index) in menus">
            <div  v-for="(itemd,indexd) in item.restaurant_discounts" class="row menu_name_wrap ">
              <div class="col-md-8">
                <div class="sp_menu_name">
                      <h5>{{itemd.title}}</h5>
                          <p>
                            {{ displayRemainingDays(itemd.valid_from, itemd.valid_to) }}
                          </p>
                  </div>
              </div>
              <div class="col-md-4">
                  <div class="sp_btn">
                      <button   class="btn edit" type="button" @click.prevent="edit_discount(itemd.collection_for)">
                          <i class="align-middle fas fa-pencil-alt"></i> Edit
                      </button>
                      
                      <button v-if="itemd.paid == null && itemd.active == 1" class="btn blast" type="button" @click.prevent="blastThisSpecialMenu(itemd.collection_for)">
                          <img :src="`../../img/frontend/icons/blast.png`" alt="loudspeaker" class=""> Blast
                      </button>
                      <button v-else class="btn blast" type="button" disabled>
                          <img :src="`../../img/frontend/icons/blast.png`" alt="loudspeaker" class=""> Blast
                      </button>
                      <button v-if="itemd.active == 1" class="btn sp_active" type="button">Active</button>
                      <button v-else class="btn blast in_active" type="button" disabled>Inactive</button>
                  </div>
              </div>
           </div>
          </div>
          </div>
          <div v-else>
          <div class="special_no_menu">No menu specials have been added</div>
          </div>
      </div>
      

<!-- edit popup -->
<div class="modal fade" id="editDiscount" tabindex="-1" role="dialog" aria-hidden="true" >
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header add-schedule-model-header">
                        <button @click="close_edit_popup()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form method="POST" id="editdiscount-form" name="editdiscountfrm" @submit.prevent="submit_update_discount" data-parsley-validate >
                        <div class="m-4">
                          <div class="row">
                            <div class="col-md-12">
                                <div class="clearfix edit-treat-rewards">
                                    <h3 class="float-left">Edit Special Menu</h3>
                                </div>
                            </div>
                              <div class="col-md-12">
                                  <div class="treat-itle">
                                      <label>Special Menu Title </label>
                                      <input type="text" v-model="edit_discount_fields.title" class="form-poup-shift-type" placeholder="$1.00 Off any appetizer" required="required">
                                  </div>
                                  <div class="row">
                                      <div class="col-md-8">
                                          <div class="item-descount">

                                              <label>Select item or category to discount</label>
                                              <treeselect v-model="edit_discount_fields.sel_menu_item_ids" :multiple="true" :options="menu_items" :flat="true" :auto-deselect-descendants="true"  :disable-branch-nodes="true"  placeholder="Open the menu item" noOptionsText="No items available" :show-count="true"  required/>


                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-5 col-12">
                                  <div class="treat-amount">
                                      <label>Special Menu Amount</label>
                                  </div>
                                  <div class="row">
                                      <div class="col-md-12">
                                          <input type="radio" name="discount_type" v-model="edit_discount_fields.type" value="amount" class="" > <label>Dollars Off</label>
                                          <input type="radio" name="discount_type" v-model="edit_discount_fields.type" value="percentage" class="" > <label>Percentage</label>
                                          <input type="text"  v-model="edit_discount_fields.treat" placeholder="1.00" class="form-poup-shift-type" data-parsley-type='number' required>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-2 col-12"></div>
                              <div class="col-md-5 col-12">
                                  <div class="treat-amount">
                                      <label>Special Menu Duration</label>
                                  </div>
                                  <div class="row">
                                      <div class="col-md-6">
                                          <label class="treat-label">Start Date</label>
                                          <input type="date"   id="valid_from" v-model="edit_discount_fields.valid_from" class="form-poup-shift-type" required>
                                      <!-- <b-form-datepicker id="valid_from" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields.valid_from" class="form-poup-shift-type" required></b-form-datepicker> -->
                                      </div>
                                      <div class="col-md-6">
                                          <label class="treat-label">End Date</label> 
                                          <input type="date"  id="valid_to" v-model="edit_discount_fields.valid_to"  class="form-poup-shift-type" required>
                                          <!-- <b-form-datepicker id="valid_to" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields.valid_to" class="form-poup-shift-type" required></b-form-datepicker> -->
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-12">
                                  <label class="checkbox-container">Active
                                     <input type="checkbox"  name="discount_status" v-model="edit_discount_fields.active" value="1" class=""/>
                                    <span class="checkmark"></span>
                                  </label>
                              </div>

                          </div>
                            <div class="clearfix main-btn-sec-shift">
                                <button class="remove-shift-btn mb-0 float-left" id="del_discount" @click.prevent="delete_discount()">REMOVE</button>
                                <button class="btn save-shift-btn float-right" id="update_discount" @click.prevent="update_discount()">Save Changes</button>

                            </div>
                        </div>

                        <div class="row text-center">
                          <div v-if="errors" class="text-danger col-12">
                            <div v-for="(v, k) in errors" :key="k">
                              <p v-for="error in v" :key="error" class="text-sm">
                                {{ error }}
                              </p>
                            </div>
                          </div>
                          <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                            <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                              {{ problem_in_save_error }}
                            </p>
                          </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <!-- edit end/ -->
         <!-- Blast MOdel -->
         <div class="modal fade" id="blastModel" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
              <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 580px;">
                <form method="POST" id="blastform" name="blastform" @submit.prevent="submit_blast_form" data-parsley-validate >
                      <div class="modal-content" id="card-reward-id">
                      
                        <button type="button" class="close handheld-close-popup" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="modal-body">
                          <div class="newblast">
                              <h6>New Blast</h6>
                              <p>Your account will be charged on your Blast start date. One message per type per day.</p>
                          </div>                             
                          
                          <div class="blastlabel">
                            <p>Blast Text <span>(240 characters max)</span></p>
                            <input type="text" name="firstName" v-model="edit_discount_fields.title" class="form-poup mb-2" required>
                          </div>
                          <div class="BlastType">
                            <p>Blast Type</p>
                            <select class="form-poup"  required v-model="edit_discount_fields.mailtype">
                              <option selected>Text and Email</option>
                            </select>
                          </div>
                        </div>      
                        <hr style="border: 1px solid #f6f6f6;width: 100%;margin: 0;" />
                            <div class="modal-body">
                              <div class="bookeventlabel">
                                  <p>Menu special or event for your blast</p>
                                 <select class="form-poup"  required v-model="edit_discount_fields.reaminingdays">
                                    <option :value="edit_discount_fields.reaminingdays" v-html="getFormattedOptionText"></option>
                                  </select>
                              </div>
                            </div>
                          <hr style="border: 1px solid #f6f6f6;width: 100%;margin: 0;" />
                            <div class="modal-body">  
                              <div class="Menu_wrap ">
                                <div class="menu_name_wrap ">
                                  <div class="row" style="padding: 0px 10px;">
                                    <div class="col-md-6">
                                      <div class="sp_menu_name">
                                        <h5>Duration of Blast</h5>
                                        <p>Number of days you want messages sent</p>
                                      </div>    
                                    </div>

                                    <!-- <div class="col-md-3">
                                      <div class="startday">
                                        <select name="country" class="form-control" v-model="edit_discount_fields.valid_from">
                                          <option>{{ edit_discount_fields.valid_from }}</option>
                                        </select>
                                      </div>
                                      
                                    </div>
                                    <div class="col-md-3">
                                      <div class="startday">
                                        <select name="country" class="form-control" v-model="edit_discount_fields.valid_to">
                                          <option :value="edit_discount_fields.valid_to"></option>
                                        </select>
                                      </div>
                                    </div> -->
                                     <div class="col-md-3">
                                          <label class="treat-label">Start Date</label>
                                          <!-- <input type="date"   id="valid_from" v-model="edit_discount_fields.valid_from" placeholder="11/02/2020" class="form-poup-shift-type" required> -->
                                      <b-form-datepicker id="valid_from" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields.valid_from" class="form-poup-shift-type" required></b-form-datepicker>
                                      </div>
                                       <div class="col-md-3">
                                          <label class="treat-label">End Date</label>
                                          <!-- <input type="date"  id="valid_to" v-model="edit_discount_fields.valid_to" placeholder="11/31/2020" class="form-poup-shift-type" required> -->
                                          <b-form-datepicker id="valid_to" placeholder="mm-dd-yy" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  v-model="edit_discount_fields.valid_to" class="form-poup-shift-type" required></b-form-datepicker>
                                      </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          <hr style="border: 1px solid #f6f6f6;width: 100%;margin: 0;" />
                            <div class="modal-body"> 
                              <div class="preyday">
                                <h6>Blast Details</h6>
                                <p>Your blast will only go to customers that have purchased from you. </p>
                              </div> 
                              <div class="prey_list">
                                <div class="col-md-12">
                                  <div class="bl_details">
                                    <div class="msg_email">
                                      <p><i class="far fa-envelope"></i><span>  {{ edit_discount_fields.emailcount }} emails </span></p>
                                    </div>
                                    <div class="msg_email">
                                      <p><i class='fas fa-mobile-alt'></i><span>{{ edit_discount_fields.phonecount }} text messages</span></p>
                                    </div>
                                    <div class="msg_email">
                                      <h3>$5.00 x  {{edit_discount_fields.days}} day(s)</h3>
                                    </div>
                                  </div>  
                                </div>
                              </div>
                              <div class="prey_list tlt_sec">
                                <div class="col-md-12">
                                  <div class="bl_details">
                                    <div class="msg_email tlt_smg">
                                      <p><span>{{edit_discount_fields.emailcount + edit_discount_fields.phonecount}} total messages</span></p>
                                    </div>
                                   
                                    <div class="msg_email">
                                      <h3>$ {{edit_discount_fields.amount_to_pay}}</h3>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                              <div class="agree_check">
                                <input type="checkbox" id="terms_and_service" name="terms_and_service" v-model="edit_discount_fields.terms_and_service">
                                <label for="vehicle1">I agree to Fireflyy Blasts <span @click="openLinkInNewTab">Terms of Service</span></label><br>
                              </div>
                                <div class="evnt_btns">
                                  <div v-if="isLoading" class="loader-overlay123">
                                   <span class="loader123" >
                                    <span class="content"><i style="color: #ff5802;" class="fa fa-spinner fa-spin"></i> Please wait...</span>
                                  </span>
                                </div>
                                  <div class="innterbtn-edit cancel_event">
                                      <button type="button" data-dismiss="modal" aria-label="Close"> Cancel</button>
                                  </div>
                                  <div class="Evt_btn">
                                      <button type="submit" class="btn add-emp-btn float-right" id="submit-blast" @click.prevent="submit_blast_validate()">Start Blast</button>
                                  </div>
                                </div>
                                <div class="row text-center">
                                <div v-if="errors" class="text-danger col-12">
                                  <div v-for="(v, k) in errors" :key="k">
                                    <p v-for="error in v" :key="error" class="text-sm">
                                      {{ error }}
                                    </p>
                                  </div>
                                </div>
                                <div v-if="problem_in_save_error" class="text-danger col-12 text-danger text-center">
                                  <p v-if="problem_in_save_error" :key="problem_in_save_error" class="text-sm">
                                    {{ problem_in_save_error }}
                                  </p>
                                </div>
                              </div>
                            </div>
                      </div>
              </form>
              </div>
            </div>
              <!-- blast popup end -->
             
  </div>
</template>

<script>

  export default {
  props:['current_date'],
  data() {
      return {
          fields: {
            points:'',
            amount:'',
            reward_id:''
          },
          reward:{},
          errors: {},
          blastStatus: true,
          iserrors: false,
          success: false,
          isLoading: false,
          loading:false,
          problem_in_save_error:'',
          menu_categories:{},
          menu_items:[],
          menus:{},
          menu_id:'',
          discount_fields: {
            menu_item_ids: [],
            title: '',
            valid_from: '',
            valid_to: '',
            treat: '',
            menu_id:'',
            type:'amount',
            active:false
          },
          selectoptionData: [],
          selectEventoptionData: [],
          edit_discount_fields_two: {
            menu_item_ids: [],
            days: '',
            title: '',
            valid_from: '',
            valid_to: '',
            // treat: '',
            id:'',
            // menu_id:'',
            type:'',
            // sel_menu_item_ids:[],
            active:'',
            // collection_for:'',
            reaminingdays:'',
            userdetails: {},
            emailcount: '',
            terms_and_service:0,
            amount_to_pay: 0,
            phonecount: '',
            selectedType: "1",
            selectedOption: "",
            mailtype: 'Text and Email'
          },
          edit_discount_fields: {
            menu_item_ids: [],
            days: '',
            title: '',
            valid_from: '',
            valid_to: '',
            treat: '',
            id:'',
            menu_id:'',
            type:'',
            sel_menu_item_ids:[],
            active:'',
            collection_for:'',
            reaminingdays:'',
            userdetails: {},
            emailcount: '',
            terms_and_service:0,
            amount_to_pay: 0,
            phonecount: '',
            mailtype: 'Text and Email'
          }
      }
  },
  watch: {
      'edit_discount_fields.valid_from': 'updateDaysAndAmount',
      'edit_discount_fields.valid_to': 'updateDaysAndAmount',
      'edit_discount_fields.reaminingdays': 'updateDaysAndAmount'
    },
  computed: {
    
    getFormattedOptionText() {
      return `<p>${this.edit_discount_fields.title} <br /><span>${this.edit_discount_fields.reaminingdays}</span></p>`;
    },
  },
  mounted(){
    this.get_menus();
    this.getMenuItems();
  },
  methods:{
    openLinkInNewTab() {
      const url = '/owner/manage_treats_rewards/policy';
        window.open(url, '_blank');
      },
    updateDaysAndAmount() {
        const validFrom = new Date(this.edit_discount_fields.valid_from);
        const validTo = new Date(this.edit_discount_fields.valid_to);
  
        if (!isNaN(validFrom) && !isNaN(validTo)) {
          const timeDiff = Math.abs(validTo - validFrom);
          const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  
          this.edit_discount_fields.days = daysDiff;
          this.edit_discount_fields.reaminingdays = daysDiff + ' day' + (daysDiff !== 1 ? 's' : '') + ' remaining Expires ' + this.edit_discount_fields.valid_to;
          this.edit_discount_fields.amount_to_pay = daysDiff * 5;
        } else {
          // Handle invalid date input
          this.edit_discount_fields.days = null;
          this.edit_discount_fields.amount_to_pay = null;
        }
      },
    displayRemainingDays(validFrom, validTo) {
      const currentDate = new Date();
      const startDate = new Date(validFrom);
      const endDate = new Date(validTo);
      const daysDiff = Math.ceil((endDate - currentDate) / (1000 * 60 * 60 * 24));
  
      let blastStatus = true;
  
      if (daysDiff < 0) {
        blastStatus = false;
        return 'Expired at ' + validTo;
      } else if (daysDiff === 0) {
        blastStatus = true;
        return '1 day remaining Expires ' + validTo;
      } else {
        blastStatus = true;
        return daysDiff + ' day' + (daysDiff !== 1 ? 's' : '') + ' remaining Expires ' + validTo;
      }
    },
    get_menus(){
      axios.get('/owner/menus/get-restaurant-menus').then(response => {
      this.menus = response.data.response.menus;
      }).catch(error => {
        console.error('Error fetching menus:', error);
      });
    },
    getMenuItems(){
      axios.get('/owner/manage_treats_rewards/new_get_r_menu_categories').then(response => {
        this.menu_items = response.data.response.item_options;
        this.discount_fields.menu_id = response.data.response.menu_id;
        console.log('getMenuItems');
      console.log(this.menu_items);

      }).catch(error => {
        console.log(error);
      });

    },
    
    add_discount() {
      let vm = this;
      $('#adddiscount-form123').parsley().whenValidate({

      }).done(function() {
      vm.submit_discount();
      });

    },
    edit_discount(udId){
      $('#editDiscount').modal('show');
        let formData = new FormData();

        formData.append("collection_for", udId);
        axios.post('/owner/manage_treats_rewards/get_discount', formData).then(response => {
          this.edit_discount_fields.collection_for = response.data.response.discountRow.collection_for;
          this.edit_discount_fields.menu_id = response.data.response.discountRow.menu_id;
          this.edit_discount_fields.title = response.data.response.discountRow.title;
          this.edit_discount_fields.valid_from = response.data.response.discountRow.valid_from;
          this.edit_discount_fields.valid_to = response.data.response.discountRow.valid_to;
          this.edit_discount_fields.type = response.data.response.discountRow.type;
          this.edit_discount_fields.treat = response.data.response.discountRow.treat;
          this.edit_discount_fields.sel_menu_item_ids = response.data.response.menu_item_ids;
          this.edit_discount_fields.active = response.data.response.discountRow.active;
          let dataformenu = { collection_for: response.data.response.discountRow.collection_for,menu_id:response.data.response.discountRow.menu_id};
          this.getMenuItems();
        }).catch(error => {
        });
    },
    blastThisSpecialMenu(udId){
      $('#blastModel').modal('show');
        let formData = new FormData();
        formData.append("collection_for", udId);
        axios.post('/owner/manage_treats_rewards/get_discount', formData).then(response => {
          console.log('/owner/manage_treats_rewards/get_discount');
          this.edit_discount_fields.id = response.data.response.discountRow.id;
          this.edit_discount_fields.collection_for = response.data.response.discountRow.collection_for;
          this.edit_discount_fields.menu_id = response.data.response.discountRow.menu_id;
          this.edit_discount_fields.title = response.data.response.discountRow.title;
          // this.edit_discount_fields.valid_from = response.data.response.discountRow.valid_from;
          // this.edit_discount_fields.valid_to = response.data.response.discountRow.valid_to;
          this.edit_discount_fields.type = response.data.response.discountRow.type;
          this.edit_discount_fields.treat = response.data.response.discountRow.treat;
          this.edit_discount_fields.sel_menu_item_ids = response.data.response.menu_item_ids;
          this.edit_discount_fields.active = response.data.response.discountRow.active;
          this.edit_discount_fields.emailcount = response.data.response.emailcount;
          this.edit_discount_fields.phonecount = response.data.response.phonecount;
          this.edit_discount_fields.userdetails = response.data.response.emailphonelist;
          let dataformenu = { collection_for: response.data.response.discountRow.collection_for,menu_id:response.data.response.discountRow.menu_id};
          
          this.getMenuItems();
        }).catch(error => {
        });
    },
    update_discount() {
      let vm = this;
      $('#editdiscount-form').parsley().whenValidate({

      }).done(function() {
      vm.submit_update_discount();
      });

    },
    submit_update_discount() {
      $('#update_discount').prop('disabled',true);
      axios.post('/owner/manage_treats_rewards/update_discount', this.edit_discount_fields).then(response => {

        setTimeout( () => {
          $('#editDiscount').modal('hide');
          this.get_menus();
          toastr.success('Special Menu updated successfully.');
          $('#update_discount').prop('disabled',false);
          this.edit_discount_fields = {};
          location.reload();
        }, 3000);


      }).catch(error => {
        $('#update_discount').prop('disabled',false);
        if (error.response.status === 400) {
          this.problem_in_save_error = error.response.data.response.message || {};
        }
        else if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }else{
          this.errors = error.response.data.response.message || {};
        }
      });
    },
    submit_blast_validate() {
      if (!this.edit_discount_fields.terms_and_service) {
      toastr.error('Please agree to the Terms of Service before submitting.');
      return;
      }
      let vm = this;
      $('#blastform').parsley().whenValidate({

      }).done(function() {
      vm.submit_blast_form();
      });

    },
    
    submit_blast_form(){
      axios.post('/owner/manage_treats_rewards/add_blast_menu', this.edit_discount_fields).then(response => {
            if(response.data.status == 422){
                toastr.error(response.data.message)
                setTimeout(() => {
                window.location.href = '/owner/specialmenu';
                }, 1000);
            }else{
                this.resdata = response.data.response.resdata;
                window.location.href = '/owner/manage_treats_rewards/make-blast-payment?blast=' + this.resdata;
            }
            
        }).catch(error => {
        this.isLoading = false;
        $('#submit-blast').prop('disabled',false);
        if (error.response.status === 400) {
          this.problem_in_save_error = error.response.data.response.message || {};
        }
        else if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }else{
          this.errors = error.response.data.response.message || {};
        }
        // setTimeout( () => {
        //   $('#blastModel').modal('hide')
        //   location.reload();
        //   this.edit_discount_fields = {};
        // }, 3000);
      });
    },

    delete_discount(){

      $('#del_discount').prop('disabled',true);
      let formData = new FormData();

      formData.append("collection_for", this.edit_discount_fields.collection_for);
      axios.post('/owner/manage_treats_rewards/delete_discount',formData ).then(response => {

        setTimeout( () => {
          $('#editDiscount').modal('hide');
          this.get_menus();
          toastr.success('Treat deleted successfully.');
          $('#del_discount').prop('disabled',false);
          this.edit_discount_fields = {};
        }, 7000);


      }).catch(error => {
        $('#del_discount').prop('disabled',false);
        if (error.response.status === 400) {
          vm.problem_in_save_error = error.response.data.response.message || {};
        }
        else if (error.response.status === 422) {
          vm.errors = error.response.data.errors;
        }else{
          vm.errors = error.response.data.response.message || {};
        }
      });
    },

    close_popup(){
      $('#adddiscount-form123').parsley().reset();
    },
    close_edit_popup(){
      $('#editdiscount-form').parsley().reset();
    }
    
}


  }
</script>